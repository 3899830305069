import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAlert } from "react-alert";
import { fetchSendTask } from "../apidata/fetchTask";
import { fetchUserList } from "../apidata/fetchUser";

export default function SendTask({ checkedid, sampleItems,usermapItems,changeUi }) {
  
  const {
    handleSubmit,
    errors,
    reset,
    register,
    unregister,
    setValue,
    watch,
  } = useForm();

  useEffect(() => {
    register({ name: "checkedid" });
    register({ name: "labchargename" }, { required: "名字不能空" });
    return () => unregister;
  }, [register, unregister]);

  useEffect(() => {
    setValue("checkedid", checkedid);   
  }, [setValue, checkedid]);

  const [checkedItems, setCheckedItems] = useState(); //选中的数据
  // 开单人
  const [userItems, setUserItems] = useState([]);
  const [userError, setUserError] = useState();

  useEffect(() => {
    const sampleItemsFilter = sampleItems.filter((item, index) => {
      return checkedid.indexOf(item.id) !== -1;
    });
    setCheckedItems(sampleItemsFilter);    
  }, [checkedid,sampleItems,setValue]);

  

  const [msg, setMsg] = useState();
  const alert = useAlert();


  // 加载实验员
  useEffect(() => {
    (async () => {
      try {
        const json = await fetchUserList(2);
        setUserItems(json);  
        const sample= sampleItems.find(c=>c.id===checkedid[0]);
        const user_id = usermapItems.find((c) => c.stdcode ===sample.samplecode).user_id;
        setValue("labchargeid",user_id);       
      } catch (error) {
        setUserError(error.message || error.statusText);
      }
    })();
  }, [checkedid,sampleItems,usermapItems, setValue]);

  const labchargeid = watch("labchargeid");

  useEffect(() => {
    if (userItems && labchargeid) {
      setValue(
        "labchargename",
        userItems.find((c) => c.id === parseInt(labchargeid)).realname
      );
    }
  }, [labchargeid, userItems, setValue]);

  const onSubmit = async (data) => {
    try {
      const json = await fetchSendTask(data);
      alert.success(json.msg); 
      changeUi(json.msg);
      reset();

    } catch (error) {
      setMsg(error.message);
    }
  };

  return (
    <div>
      {msg}
      {errors.labchargeid?.message}
      {errors.labchargename?.message}
      <p>我要发放以下任务</p>
      <ol>
        {checkedItems &&
          checkedItems.map((item) => (
            <li key={item.id}>
              <p>
               {usermapItems?.find((c) => c.stdcode === item.samplecode)?.name}[拟]
                {`JHYP-${item.samplecode}-${
                  item.year
                }-${item.samplenumber.toString().padStart(4, "0")}`}
                {item.samplenickname}
              </p>
            </li>
          ))}
      </ol>

      <form onSubmit={handleSubmit(onSubmit)}>
        <label>指派任务给:</label>
        <select
          name="labchargeid"
          ref={register({
            required: "没有选择到"
          })}
        >
          <option disabled value="1">
            请选择任务负责人
          </option>
          {userItems &&
            userItems.map((item) => (
              <option key={item.id} value={item.id}>
                {item.realname}
              </option>
            ))}
        </select>
        {userError}
        <input type="submit" style={{marginLeft:"30px"}} />
       
      </form>
    </div>
  );
}
