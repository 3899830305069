import baseURL from "../Config";

export const fetchDocList = async (sample_id) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/doc/docbysample/${sample_id}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token")),
      },
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAddDoc = async (data) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/doc/add`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token")),
      },
      body: JSON.stringify(data),
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchDeleteDoc = async (id) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/doc/deletedoc/${id}`, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token")),
      },
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};
