import baseURL from "../Config";



export const fetchCustArgumentAdd = async (argumentpkg_id,data) => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/argumentpkg/addcustargument/${argumentpkg_id}`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        },
        body: JSON.stringify(data)
      }
    );
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};


export const fetchCustArgumentUpdate = async (argumentpkg_id,argument_id,data) => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/argumentpkg/custargument/${argumentpkg_id}/${argument_id}`,
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        },
        body: JSON.stringify(data)
      }
    );
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};


export const fetchCustArgumentDelete = async (argumentpkg_id,argument_id) => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/argumentpkg/custargument/${argumentpkg_id}/${argument_id}`,
      {
        method: "delete",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        }
      }
    );
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};




