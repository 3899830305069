import baseURL from "../Config";

export const fetchSampleByCustomer = async (
  customer_id,
  startdate,
  enddate
) => {
  try {
    const res = await fetch(
      `${baseURL}/sample/exportbycustomer/${customer_id}/${startdate}/${enddate}`,
      {
        method: "get",
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      }
    );
    if (res.ok) {
      return res;
    } else {
      throw new Error(res.statusText + "可能没有数据,请重选日期");
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchSampleByProject = async (project_id, startdate, enddate) => {
  try {
    const res = await fetch(
      `${baseURL}/sample/exportbyproject/${project_id}/${startdate}/${enddate}`,
      {
        method: "get",
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      }
    );
    if (res.ok) {
      return res;
    } else {
      throw new Error(res.statusText + "可能没有数据,请重选日期");
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchSampleByTrustperson = async (
  trustperson,
  startdate,
  enddate
) => {
  try {
    const res = await fetch(
      `${baseURL}/sample/exportbytrustperson/${startdate}/${enddate}`,
      {
        method: "post",
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
        body: JSON.stringify({ trustperson })
      }
    );
    if (res.ok) {
      return res;
    } else {
      throw new Error(res.statusText + "可能没有数据,请重选日期");
    }
  } catch (error) {
    throw new Error(error);
  }
};



// 内部对帐
export const fetchSampleInnerByDate = async ( 
  startdate,
  enddate
) => {
  try {
    const res = await fetch(
      `${baseURL}/sample/exportinnerbydate/${startdate}/${enddate}`,
      {
        method: "get",
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      }
    );
    if (res.ok) {
      return res;
    } else {
      throw new Error(res.statusText + "可能没有数据,请重选日期");
    }
  } catch (error) {
    throw new Error(error);
  }
};



// 内部对帐
export const fetchSumGroupByProject = async ( 
  startdate,
  enddate
) => {
  try {
    const res = await fetch(
      `${baseURL}/sample/sumgroupbyproject/${startdate}/${enddate}`,
      {
        method: "get",
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      }
    );
    if (res.ok) {
      return res;
    } else {
      throw new Error(res.statusText + "可能没有数据,请重选日期");
    }
  } catch (error) {
    throw new Error(error);
  }
};





// 内部对帐
export const fetchSampleInnerByCustomer = async (
  customer_id,
  startdate,
  enddate
) => {
  try {
    const res = await fetch(
      `${baseURL}/sample/exportinnerbycustomer/${customer_id}/${startdate}/${enddate}`,
      {
        method: "get",
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      }
    );
    if (res.ok) {
      return res;
    } else {
      throw new Error(res.statusText + "可能没有数据,请重选日期");
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchSampleInnerByProject = async (project_id, startdate, enddate) => {
  try {
    const res = await fetch(
      `${baseURL}/sample/exportinnerbyproject/${project_id}/${startdate}/${enddate}`,
      {
        method: "get",
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      }
    );
    if (res.ok) {
      return res;
    } else {
      throw new Error(res.statusText + "可能没有数据,请重选日期");
    }
  } catch (error) {
    throw new Error(error);
  }
};


// 按工程,按天汇总
export const fetchSumByCustomer = async (
  customer_id,
  startdate,
  enddate
) => {
  try {
    const res = await fetch(
      `${baseURL}/sample/exportsumbycustomer/${customer_id}/${startdate}/${enddate}`,
      {
        method: "get",
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      }
    );
    if (res.ok) {
      return res;
    } else {
      throw new Error(res.statusText + "可能没有数据,请重选日期");
    }
  } catch (error) {
    throw new Error(error);
  }
};