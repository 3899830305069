import React, { useState, useEffect } from "react";
import AsyncSelect from "react-select/async";
import { useForm } from "react-hook-form";
import { css } from "emotion";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";

import { fetchFindStandard } from "../apidata/fetchStandard";

import StandardAdd from "./StandardAdd";
import StandardUpdate from "./StandardUpdate";
import StandardUpdateIsenable from "./StandardUpdateIsenable";

export default function StandardSelect() {
  const { register, unregister, setValue, errors, clearErrors } = useForm();

  // register control component
  useEffect(() => {
    register({ name: "standards" }, { require: "不能为空" });
    return () => unregister;
  }, [register, unregister]);

  // server 提示信息
  const [msg, setMsg] = useState(null);

  // 新增
  const [showDialogAdd, setShowDialogAdd] = useState(false);
  const openAdd = () => setShowDialogAdd(true);
  const closeAdd = () => setShowDialogAdd(false);
  // 修改
  const [showDialogUpdate, setShowDialogUpdate] = useState(false);
  const openUpdate = () => setShowDialogUpdate(true);
  const closeUpdate = () => setShowDialogUpdate(false);
  // 停用
  const [showDialogUpdateIsenable, setShowDialogUpdateIsenable] = useState(
    false
  );
  const openUpdateIsenable = () => setShowDialogUpdateIsenable(true);
  const closeUpdateIsenable = () => setShowDialogUpdateIsenable(false);

  const [standardValues, setStandardValues] = useState();
  const promiseOptions = (inputValue) => {
    const a = new Promise((reslove, reject) => {
      if (inputValue === null) {
        return reject("error");
      }
      if (inputValue.length < 1) {
        return reject("error");
      }

      (async () => {
        try {
          const json = await fetchFindStandard(inputValue);
          return reslove(json);
        } catch (e) {
          return reject(e.message || e.statusText);
        }
      })();
    });

    return a;
  };
  //选择标准,aysnc
  const onChange = (event) => {
    if (event) {
      setValue("standards", event); // form 的值
      setStandardValues(event);
      return clearErrors("standards");
    }
  };

  const addUi = (json) => {
    setMsg(json.msg);
    setStandardValues(json.data);
    closeAdd();
    setTimeout(() => {
      setMsg(null);
    }, 2000);
  };

  const updateUi = async (json) => {
    setMsg(json.msg);
    setStandardValues(json.data);
    setTimeout(() => {
      setMsg(null);
    }, 2000);
    closeUpdate();
    closeUpdateIsenable();
  };

  return (
    <>
      {msg}
      <form>
        <div className="form-group row">
          <label class="col-sm-1 col-form-label">查找标准:</label>
          <AsyncSelect
            className={css`
              display: inlneblock;
              width: 90%;
              margin-left: 1%;
            `}
            name="standards"
            isMulti={false}
            cacheOptions
            defaultOptions
            loadOptions={promiseOptions}
            getOptionValue={(option) => option["id"]}
            onChange={onChange}
            getOptionLabel={(option) => `${option.stdcode} | ${option.name}`}
            value={standardValues}
            placeholder="请输入标准代码或名称,进行模糊查找,并注意大小写"
          />
          {errors.standards && errors.standards.message}
        </div>

        <div className="form-group row">
          <div className="col-sm-12">
            <p>
              <span
                style={{ color: standardValues?.isenable ? "black" : "red" }}
              >
                {standardValues
                  ? standardValues.isenable
                    ? "状态: 正常"
                    : "状态: 已停用"
                  : ""}
              </span>
            </p>
            <p>标准代码: {standardValues?.stdcode}</p>
            <p>标准名称: {standardValues?.name}</p>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-sm-1">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                e.preventDefault();
                openAdd();
              }}
            >
              新增
            </button>
          </div>

          <div className="col-sm-1">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                e.preventDefault();
                openUpdate();
              }}
              disabled={!standardValues}
            >
              修改
            </button>
          </div>

          <div className="col-sm-2">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                e.preventDefault();
                openUpdateIsenable();
              }}
              disabled={!standardValues}
            >
              启用或停用
            </button>
          </div>
        </div>
      </form>

      {showDialogAdd && (
        <Dialog aria-label="modal" isOpen={showDialogAdd} onDismiss={closeAdd}>
          <StandardAdd addUi={addUi} />
          <hr />
          <button onClick={closeAdd}>取消</button>
        </Dialog>
      )}

      {showDialogUpdate && (
        <Dialog
          aria-label="modal"
          isOpen={showDialogUpdate}
          onDismiss={closeUpdate}
        >
          <StandardUpdate
            currentStandard={standardValues}
            updateUi={updateUi}
          />
          <hr />
          <button onClick={closeUpdate}>取消</button>
        </Dialog>
      )}

      {showDialogUpdateIsenable && (
        <Dialog
          aria-label="modal"
          isOpen={showDialogUpdateIsenable}
          onDismiss={closeUpdateIsenable}
        >
          <StandardUpdateIsenable
            currentStandard={standardValues}
            updateUi={updateUi}
          />
          <hr />
          <button onClick={closeUpdateIsenable}>取消</button>
        </Dialog>
      )}
    </>
  );
}
