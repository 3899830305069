import React, { useState, useEffect, useRef } from "react";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { css } from "emotion";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import {
  AlertDialog,
  AlertDialogLabel,
  AlertDialogDescription,
} from "@reach/alert-dialog";
import {
  fetchLinkAdd,
  fetchLinkDelete,
} from "../apidata/fetchCategoryLinkStandard";
import { fetchFindStandard, fetchStandards } from "../apidata/fetchStandard";
import { fetchCategories } from "../apidata/fetchCategoryData";

import StandardAdd from "./StandardAdd";
import CategoryLinkStandardUpdate from "./CategoryLinkStandardUpdate.js";

export default function StandardLink() {
  const {
    register,
    unregister,
    setValue,
    handleSubmit,
    errors,
    clearErrors,
  } = useForm();

  // register control component
  useEffect(() => {
    register({ name: "category" }, { require: "不能为空" });
    register({ name: "standards" }, { require: "不能为空" });
    return () => unregister;
  }, [register, unregister]);

  // server 提示信息
  const [msg, setMsg] = useState(null);
  const kinds = ["试验依据和判定标准", "试验依据", "判定标准"];

  // 加载类别
  const [categoryItems, setCategoryItems] = useState([]); //db
  const [categoryValues, setCategoryValues] = useState(null);
  const [categoryError, setCategoryError] = useState(null);

  const [standardItems, setStandardItems] = useState(null);
  const [standardsError, setStandardsError] = useState(null);

  const [currentLink, setCurrentLink] = useState();

  // 新增
  const [showDialogAdd, setShowDialogAdd] = useState(false);
  const openAdd = () => setShowDialogAdd(true);
  const closeAdd = () => {
    setShowDialogAdd(false);
  };

  // 修改
  const [showDialogUpdate, setShowDialogUpdate] = useState(false);
  const openUpdate = () => setShowDialogUpdate(true);
  const closeUpdate = () => {
    setShowDialogUpdate(false);
  };

  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const cancelRef = useRef();
  const openDelete = () => setShowDialogDelete(true);
  const closeDelete = () => setShowDialogDelete(false);

  // 加载大类
  useEffect(() => {
    (async () => {
      try {
        const json = await fetchCategories();
        setCategoryItems(json);
      } catch (e) {
        setCategoryError(e.message || e.statusText);
      }
    })();
  }, []);

  // 选择大类
  const handleChangeCategory = (selectedOption) => {
    setValue("category", selectedOption); // form 的值
    setCategoryValues(selectedOption); // 控件的值
  };

  const [complete, setComplete] = useState(false);

  // 显示所在类的标准
  useEffect(() => {
    if (!categoryValues) {
      return;
    }

    (async () => {
      try {
        const json = await fetchStandards(categoryValues.id);
        setStandardItems(json);
      } catch (e) {
        setStandardsError(e.message || e.statusText);
      }
    })();
  }, [categoryValues, complete]);

  const [standardValues, setStandardValues] = useState();
  const promiseOptions = (inputValue) => {
    const a = new Promise((reslove, reject) => {
      if (inputValue === null) {
        return reject("error");
      }
      if (inputValue.length < 1) {
        return reject("error");
      }

      (async () => {
        try {
          const json = await fetchFindStandard(inputValue);
          return reslove(json);
        } catch (e) {
          return reject(e.message || e.statusText);
        }
      })();
    });

    return a;
  };
  //选择标准,aysnc
  const onChange = (event) => {
    if (event) {
      setValue("standards", event); // form 的值
      setStandardValues(event);
      return clearErrors("standards");
    }
  };

  // 新增link
  const onSubmit = async (data) => {
    try {
      const json = await fetchLinkAdd(data);
      setMsg(json.msg);
      setValue("standards", null);
      setStandardValues(null);
      clearErrors("category");
      clearErrors("standards");
      setComplete(!complete);
      setTimeout(() => {
        setMsg(null);
      }, 5000);
    } catch (error) {
      setMsg(error.message || error.statusText);
    }
  };

  const addUi = (json) => {
    setMsg(json.msg);
    setTimeout(() => {
      setMsg(null);
    }, 2000);
  };

  const updateUi = async (json) => {
    try {
      setMsg(json.msg);
      setStandardItems(
        standardItems.map((i) =>
          i.id === json.data.standard_id
            ? { ...i, kind: json.data.kind, sorting: json.data.sorting }
            : i
        )
      );
      setTimeout(() => {
        setMsg(null);
      }, 5000);
    } catch (error) {
      setMsg(error.message);
    }
    closeUpdate();
  };

  const deleteLink = async () => {
    try {
      const json = await fetchLinkDelete({
        category_id: categoryValues.id,
        standard_id: currentLink.id,
      });
      setMsg(json.msg);
      setStandardItems(standardItems.filter((c) => c.id !== currentLink.id));
      setTimeout(() => {
        setMsg(null);
      }, 2000);
    } catch (error) {
      setMsg(error.message);
    }
    setShowDialogDelete(false);
  };

  return (
    <>
      {msg}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group row">
          <label className="col-sm-1 col-form-label">类目:</label>
          <Select
            className={css`
              display: inlneblock;
              width: 50%;
              margin-left: 1%;
            `}
            name="category"
            placeholder="项目(类别)"
            value={categoryValues}
            options={categoryItems}
            onChange={handleChangeCategory}
            // react-select/issues/3018
            getOptionValue={(option) => option["id"]}
            getOptionLabel={(option) =>
              `${option.certificate === 0 ? "公路" : "水运"} | ${
                option.stdcode
              } | ${option.name}`
            }
          />
          {categoryError}
          {errors.category && errors.category.message}

          <div style={{ marginLeft: "20px" }} className="col-sm-2">
            <button
              onClick={(e) => {
                e.preventDefault();
                if (standardValues) {
                  setStandardValues([
                    ...standardValues,
                    { id: 596, stdcode: "/", name: "/" },
                  ]);
                  setValue("standards", [
                    ...standardValues,
                    { id: 596, stdcode: "/", name: "/" },
                  ]);
                } else {
                  setStandardValues([{ id: 596, stdcode: "/", name: "/" }]);
                  setValue("standards", [{ id: 596, stdcode: "/", name: "/" }]);
                }
                clearErrors("standards");
              }}
            >
              加"/"的标准
            </button>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-1 col-form-label">挂标准:</label>
          <AsyncSelect
            className={css`
              display: inlneblock;
              width: 90%;
              margin-left: 1%;
            `}
            name="standards"
            isMulti
            cacheOptions
            defaultOptions
            loadOptions={promiseOptions}
            getOptionValue={(option) => option["id"]}
            onChange={onChange}
            getOptionLabel={(option) => `${option.stdcode} | ${option.name}`}
            value={standardValues}
            placeholder="请输入标准代码或名称,进行模糊查找,并注意大小写"
          />
          {standardsError}
          {errors.standards && errors.standards.message}
        </div>
        <div className="form-group row">
          <label htmlFor="kind" className="col-sm-1 col-form-label">
            类型:
          </label>
          <div className="col-sm-5">
            <select
              name="kind"
              ref={register}
              required
              className={css`
                width: 50%;
                height: 40px;
              `}
            >
              <option value="0">试验依据 | 判定标准</option>
              <option value="1">仅试验依据</option>
              <option value="2">仅判定标准</option>
            </select>
            {errors.kind && errors.kind.message}
          </div>
          <div className="col-sm-2">
            <button
              type="submit"
              onClick={handleSubmit}
              className="btn btn-primary"
            >
              保存
            </button>
          </div>
          <div className="col-sm-3">
            <button
              onClick={(e) => {
                e.preventDefault();
                openAdd();
              }}
              className="btn btn-primary"
            >
              找不到标准,我要新增
            </button>
          </div>
        </div>
      </form>
      <hr />

      <table className="table table-sm table-hover">
        <thead className="thead-dark">
          <tr>
            <th>代码</th>
            <th>名称</th>
            <th>类型</th>
            <th>排序</th>
            <th>操作</th>
            <th>操作</th>
          </tr>
        </thead>

        <tbody>
          {standardItems ? (
            standardItems.map((item, index) => (
              <tr key={index}>
                <td>{item.stdcode}</td>
                <td>{item.name}</td>
                <td>{kinds[item.kind]}</td>
                <td>{item.sorting}</td>

                <td>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setCurrentLink(item);
                      openUpdate();
                    }}
                  >
                    修改
                  </button>
                </td>

                <td>
                  <button
                    className="btn btn-danger"
                    onClick={() => {
                      setCurrentLink(item);
                      openDelete();
                    }}
                  >
                    删除
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>没有数据哦!!</td>
            </tr>
          )}
        </tbody>
      </table>

      {showDialogAdd && (
        <Dialog aria-label="modal" isOpen={showDialogAdd} onDismiss={closeAdd}>
          <StandardAdd addUi={addUi} />
          <hr />
          <button onClick={closeAdd}>取消</button>
        </Dialog>
      )}

      {showDialogUpdate && (
        <Dialog
          aria-label="modal"
          isOpen={showDialogUpdate}
          onDismiss={closeUpdate}
        >
          <CategoryLinkStandardUpdate
            currentLink={currentLink}
            category_id={categoryValues.id}
            updateUi={updateUi}
          />
          <hr />
          <button onClick={closeUpdate}>取消</button>
        </Dialog>
      )}

      {showDialogDelete && (
        <AlertDialog onDismiss={closeDelete} leastDestructiveRef={cancelRef}>
          <AlertDialogLabel style={{ textAlign: "center" }}>
            请再次确认是否删除?
          </AlertDialogLabel>
          <AlertDialogDescription>
            <p>代码:{currentLink.stdcode}</p>
            <p>名称:{currentLink.name}</p>
          </AlertDialogDescription>
          <div className="alert-buttons">
            <button onClick={deleteLink}>是, 删除</button>
            <button
              style={{ marginLeft: "30px" }}
              ref={cancelRef}
              onClick={closeDelete}
            >
              取消, 我不愿意
            </button>
          </div>
        </AlertDialog>
      )}
    </>
  );
}
