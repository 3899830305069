import React, { useState, useRef, useEffect } from "react";
import ContentEditable from "react-contenteditable";

export default function ExtraInfoList({
  initValues = [],
  handleExtraInfoChange,
  isdelete = false
}) {
  const [data, setData] = useState(initValues);
  const [row, setRow] = useState({ name: "", value: "" });
  const firstEditable = useRef();

  const disableNewlines = event => {
    const keyCode = event.keyCode || event.which;
    if (keyCode === 13) {
      event.returnValue = false;
      if (event.preventDefault) event.preventDefault();
    }
  };

  const pasteAsPlainText = event => {
    event.preventDefault();
    const text = event.clipboardData.getData("text/plain");
    document.execCommand("insertHTML", false, text);
  };

  const highlightAll = () => {
    setTimeout(() => {
      document.execCommand("selectAll", false, null);
    }, 0);
  };

  const trimSpaces = string => {
    return string
      .replace(/&nbsp;/g, "")
      .replace(/&amp;/g, "&")
      .replace(/&gt;/g, ">")
      .replace(/&lt;/g, "<");
  };


  //  为新增准备信息
  const handleContentEditable = event => {
    const {
      currentTarget: {
        dataset: { column }
      },
      target: { value }
    } = event;
    setRow({ ...row, [column]: value });
  };

  // 新增
  const add = () => {
    let newId = 0;
    if (data && data.length > 0) {
      newId =
        Math.max.apply(
          Math,
          data.map(i => i.id)
        ) + 1;
    }

    // 输入修正
    const trimmedRow = {
      ...row,
      name: trimSpaces(row.name).trim(),
      value: trimSpaces(row.value).trim()     
    };
    if (Array.isArray(data)){
      setData([...data, { ...trimmedRow, id: newId, print: true }]); // null,不能执行此操作,所以Props不能为null
    }
    else{
      setData([{ ...trimmedRow, id: newId, print: true }]); // data===null
    }

    // 清空
    setRow({ name: "", value: "" });
    firstEditable.current.focus();
  };

  // 修改 名称或内容
  const handleContentEditableUpdate = event => {
    const {
      currentTarget: {
        dataset: { row, column } // row 代表找到修改行的id data-row={item.id}
      },
      target: { value }
    } = event;
      
    setData(
      data.map(item => {
        return item.id === parseInt(row, 10)
          ? { ...item, [trimSpaces(column).trim()]: trimSpaces(value).trim() }
          : item;
      })
    );
  };

  // 修改  是否打印
  const hanlePirntChange = e => {
    setData(data.map(item => (item === e ? { ...e, print: !e.print } : item)));
  };

  // 删除
  const deleteRow = row => {
    setData(data.filter(item => item !== row));
  };

  // props call back
  useEffect(() => {
    handleExtraInfoChange(data);
  }, [data, handleExtraInfoChange]);

  useEffect(() => {
    setData(initValues);
  }, [initValues]);

  return (
    <div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th style={{ width: "8%" }}>是否打印</th>
            <th style={{ width: "10%" }}>名称</th>
            <th style={{ width: "72%" }}>内容</th>
            <th style={{ width: "10%" }}>操作</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item) => (
              <tr key={item.id}>
                <td style={{ width: "8%" }}>
                  <span
                    onClick={() => {
                      hanlePirntChange(item);
                    }}
                  >
                    {item.print ? "打印" : "不打印"}
                  </span>
                </td>

                <td style={{ width: "10%" }}>
                  <ContentEditable
                    html={item.name}
                    data-column="name"
                    data-row={item.id}
                    className="content-editable"
                    onKeyPress={disableNewlines}
                    onPaste={pasteAsPlainText}
                    onFocus={highlightAll}
                    onChange={handleContentEditableUpdate}
                  />
                </td>
                <td style={{ width: "72%" }}>
                  <ContentEditable
                    html={item.value}
                    data-column="value"
                    data-row={item.id}
                    className="content-editable"
                    onKeyPress={disableNewlines}
                    onPaste={pasteAsPlainText}
                    onFocus={highlightAll}
                    onChange={handleContentEditableUpdate}
                  />
                </td>
                <td style={{ width: "10%" }}>
                  <button
                    disabled={isdelete}
                    onClick={(e) => {
                      e.preventDefault();
                      deleteRow(item);
                    }}
                  >
                    删除
                  </button>
                </td>
              </tr>
            ))}

          <tr>
            <td disabled={false}></td>
            <td>
              <ContentEditable
                html={row.name}
                data-column="name"
                className="content-editable"
                innerRef={firstEditable}
                onKeyPress={disableNewlines}
                onPaste={pasteAsPlainText}
                onFocus={highlightAll}
                onChange={handleContentEditable}
              />
            </td>
            <td>
              <ContentEditable
                html={row.value}
                data-column="value"
                className="content-editable"
                onKeyPress={disableNewlines}
                onPaste={pasteAsPlainText}
                onFocus={highlightAll}
                onChange={handleContentEditable}
              />
            </td>
            <td>
              <button disabled={!row.name} onClick={e=>{
                  e.preventDefault();
                  add();
              }}>
                新增
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <hr />
      <ul>
        <p>样品附加信息条数:{data && data.length}</p>
        {data &&
          data.map((item, index) => (
            <li key={index}>
              {item.id}-- {item.print ? "打印" : "不打印"}--{item.name}--
              {item.value}
            </li>
          ))}
      </ul>
    </div>
  );
}
