import React, { useState, useEffect } from "react";
import ContentEditable from "react-contenteditable";
import Select from "react-select";


// 控件 收件
export default function ArgumentProperty({
  initValues = [],
  handleArgumentPropertyChange,
  transmitArgumentItems,
  isdelete = false
}) {
  let firstEditable;
  const [argumentItems, setArgumentItems] = useState([]);
  const [argumentValues, setArgumentValues] = useState();

  useEffect(() => {
    setArgumentItems(transmitArgumentItems);
  }, [transmitArgumentItems]);

  // 选择参数
  const handleChangeArgument = selectedOption => {
    setArgumentValues(selectedOption); // 控件的值
  };

  const [data, setData] = useState(initValues);
  const [row, setRow] = useState({
    id: "",
    stdcode: "",
    name: "",
    property: ""
  });

  const disableNewlines = event => {
    const keyCode = event.keyCode || event.which;
    if (keyCode === 13) {
      event.returnValue = false;
      if (event.preventDefault) event.preventDefault();
    }
  };

  const pasteAsPlainText = event => {
    event.preventDefault();
    const text = event.clipboardData.getData("text/plain");
    document.execCommand("insertHTML", false, text);
  };

  const highlightAll = () => {
    setTimeout(() => {
      document.execCommand("selectAll", false, null);
    }, 0);
  };

  const trimSpaces = string => {
    return string
      .replace(/&nbsp;/g, "")
      .replace(/&amp;/g, "&")
      .replace(/&gt;/g, ">")
      .replace(/&lt;/g, "<");
  };

  //  为新增准备信息
  const handleContentEditable = event => {
    const {
      currentTarget: {
        dataset: { column }
      },
      target: { value }
    } = event;
    setRow({ ...row, [column]: value });
  };

  // 新增
  const add = () => {
    if(!argumentValues){
      return ;
    }
   
    if(data && data.length > 0){   
        // 没有才加进去
        if(data.findIndex(c=>c.id===argumentValues.id) ===-1){
        setData([
          ...data,
          {
            id: argumentValues.id,
            stdcode: argumentValues.stdcode,
            name: argumentValues.name,
            property: trimSpaces(row.property)
          }
        ]); 
        setArgumentItems(argumentItems.filter(c=>c.id !== argumentValues.id ))  
        // 清空
        setRow({ id: "", stdcode: "", name: "", property: "" });
        setArgumentValues(null);
        firstEditable.focus();
      }
  }
  else{

    setData([     
      {
        id: argumentValues.id,
        stdcode: argumentValues.stdcode,
        name: argumentValues.name,
        property: trimSpaces(row.property)
      }
    ]); 
    // 清空    
    setArgumentItems(argumentItems.filter(c=>c.id !== argumentValues.id ))   
    setRow({ id: "", stdcode: "", name: "", property: "" });
    setArgumentValues(null);   
    firstEditable.focus();
  }

  };

  // 修改每一项时,动态更新该条记录
  const handleContentEditableUpdate = event => {
    const {
      currentTarget: {
        dataset: { row, column } // row 代表找到修改行的id data-row={item.id}
      },
      target: { value }
    } = event;

    setData(
      data.map(item => {
        return item.id === parseInt(row, 10)
          ? { ...item, [column]: value }
          : item;
      })
    );
  };

  // 删除
  const deleteRow = row => {
    setData(data.filter(item => item !== row));
    
    if(argumentItems.length > 0){   
      // 没有才加进去
      if(argumentItems.findIndex(c=>c.id===row.id) ===-1){
        setArgumentItems([...argumentItems,row])
      }
    }else{
      setArgumentItems([row])
    }
  };

  // props call back
  useEffect(() => {
    handleArgumentPropertyChange(data);
  }, [data, handleArgumentPropertyChange]);

  useEffect(() => {
    setData(initValues);
  }, [initValues]);

  return (
    <div>
      <button onClick={(e)=>{
          e.preventDefault();
          setData(transmitArgumentItems);
      }}>全加进来,下边填好会覆盖,慎用</button>
       <button onClick={(e)=>{
          e.preventDefault();
          setData(null);        
      }}>我想删除下边我所填的,慎用</button>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th style={{ width: "10%" }}>代码</th>
            <th style={{ width: "55%" }}>名称</th>
            <th style={{ width: "20%" }}>属性说明</th>
            <th style={{ width: "15%" }}>操作</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map(item => (
              <tr key={item.id}>
                <td style={{ width: "10%" }}>{item.stdcode}</td>
                <td style={{ width: "55%" }}>{item.name}</td>
                <td style={{ width: "20%" }}>
                  <ContentEditable
                    html={item.property}
                    data-column="property"
                    data-row={item.id}
                    className="content-editable"
                    onKeyPress={disableNewlines}
                    onPaste={pasteAsPlainText}
                    onFocus={highlightAll}
                    onChange={handleContentEditableUpdate}
                  />
                </td>
                <td style={{ width: "15%" }}>
                  <button
                    disabled={isdelete}
                    onClick={e => {
                      e.preventDefault();
                      deleteRow(item);
                    }}
                  >
                    删除
                  </button>
                </td>
              </tr>
            ))}

          <tr>
            <td colSpan="2">
              <Select               
                name="product"
                ref={ref => {
                  firstEditable = ref;
                }}
                placeholder="参数"
                value={argumentValues}
                options={argumentItems}
                onChange={handleChangeArgument}
                getOptionValue={option => option["id"]}
                getOptionLabel={option => `${option.stdcode}--${option.name}`}
              />
            </td>
            <td>
              <ContentEditable
                html={row.property}
                data-column="property"
                className="content-editable"
                onKeyPress={disableNewlines}
                onPaste={pasteAsPlainText}
                onFocus={highlightAll}
                onChange={handleContentEditable}
              />
            </td>
            <td>
              <button disabled={!argumentValues || !row.property} onClick={add}>
                新增
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <hr />
      <p>参数属性备注:{data && data.length}条</p>
    </div>
  );
}
