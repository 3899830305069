import baseURL from "../Config";


export const fetchSampleCodeAll = async () => {
  try {
    const res = await fetch(`${baseURL}/api/v1/samplecode/allsamplecode`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};