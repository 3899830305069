import React, { useState, useEffect } from "react";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { css } from "emotion";
import { fetchCategories } from "../apidata/fetchCategoryData";
import { fetchArguments } from "../apidata/fetchArgument";
import {
  fetchLinkAdd,
  fetchLinkDelete,
  fetchLinkStandards,
} from "../apidata/fetchArgumentLinkData";
import baseURL from "../Config";
import ArgumentLinkStandardEdite from "./ArgumentLinkStandardEdite";
export default function ArgumentLinkStandard() {
  const {
    register,
    unregister,
    setValue,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    triggerValidation,
  } = useForm();

  // register control component
  useEffect(() => {
    register({ name: "category" }, { require: "不能为空" });
    register({ name: "argument" }, { require: "不能为空" });
    register({ name: "standards" }, { require: "不能为空" });

    return () => unregister;
  }, [register, unregister]);

  const kinds = ["试验依据和判定标准", "试验依据", "判定标准"];
  // server 提示信息
  const [msg, setMsg] = useState(null);

  // 加载类别
  const [categoryItems, setCategoryItems] = useState([]); //db
  const [categoryValues, setCategoryValues] = useState(null);
  const [categoryError, setCategoryError] = useState(null);

  // 参数
  const [argumentItems, setArgumentItems] = useState([]); //db
  const [argumentValues, setArgumentValues] = useState(null);
  const [argumentError, setArgumentError] = useState(null);

  const [standardsError, setStandardsError] = useState(null);

  // 当前参数挂的标准
  const [linkStandardItems, setLinkStandardItems] = useState(null);

  const [complete, setComplete] = useState(false);

  // 加载大类
  useEffect(() => {
    (async () => {
      try {
        const json = await fetchCategories();
        setCategoryItems(json);
      } catch (e) {
        setCategoryError(e.message || e.statusText);
      }
    })();
  }, []);

  // 按大类填充参数
  useEffect(() => {
    if (!categoryValues) {
      return;
    }
    setArgumentValues(null);
    setValue("argument", null);
    (async () => {
      try {
        const json = await fetchArguments(categoryValues.id);
        setArgumentItems(json);
      } catch (error) {
        setArgumentError(error.message || error.statusText);
      }
    })();
  }, [categoryValues, setValue]);

  useEffect(() => {
    if (!argumentValues) {
      return;
    }

    (async () => {
      try {
        const json = await fetchLinkStandards(argumentValues["id"]);
        setLinkStandardItems(json);
      } catch (error) {
        setArgumentError(error.message || error.statusText);
      }
    })();
  }, [argumentValues, complete]);

  // 选择大类
  const handleChangeCategory = (selectedOption) => {
    if (selectedOption) {
      setValue("category", selectedOption); // form 的值
      setCategoryValues(selectedOption); // 控件的值

      return clearErrors("category");
    } else {
      setError("category", "notMatch", "没有选择到类别!");
    }
  };

  // 选择参数
  const handleChangeArguments = (selectedOption) => {
    if (selectedOption) {
      setValue("argument", selectedOption); // form 的值
      setArgumentValues(selectedOption); // 控件的值
      return clearErrors("argument");
    } else {
      setError("argument", "notMatch", "没有选择到样品编号!");
    }
  };

  const [standardValues, setStandardValues] = useState();
  const promiseOptions = (inputValue) => {
    const a = new Promise((reslove, reject) => {
      if (inputValue === null) {
        return reject("error");
      }
      if (inputValue.length < 1) {
        return reject("error");
      }
      fetch(`${baseURL}/api/v1/standard/find`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
        body: JSON.stringify({ query: inputValue.toUpperCase() }),
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          return reslove(res);
        })
        .catch((error) => {
          setStandardsError(error.message);
          return reject(error);
        });
    });

    return a;
  };
  //选择标准,aysnc
  const onChange = (event) => {
    if (event) {
      setValue("standards", event); // form 的值
      setStandardValues(event);
      return clearErrors("standards");
    }
  };

  const [isEdite, setIsEdite] = useState(false);

  const [updateRow, setUpdateRow] = useState();
  const selectRow = (item) => {
    setUpdateRow(item);
    setIsEdite(true);
  };

  const deleteStandard = async (item) => {
    try {
      const json = await fetchLinkDelete(item);
      setMsg(json.msg);
      setComplete(!complete);
      setTimeout(() => {
        setMsg(null);
      }, 2000);
    } catch (error) {
      setMsg(error.message);
    }
  };

  const onSubmit = async (data) => {
    triggerValidation();
    if (!categoryValues || !argumentValues || !standardValues) {
      alert("信息不完整?");
      return;
    }

    console.log(data);
    const json = await fetchLinkAdd(data);
    setMsg(json.msg);
    setComplete(!complete);
    setStandardValues(null);
    setTimeout(() => {
      setMsg(null);
    }, 2000);
  };

  return (
    <div style={{ boxSizing: "border-box" }}>
      {msg}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label
            className={css`
              width: 5%;
              margin-left: 0%;
              display: inline-block;
            `}
          >
            类目:
          </label>
          <Select
            className={css`
              width: 80%;
              margin-left: 0%;
              display: inline-block;
            `}
            name="category"
            placeholder="项目(类别)"
            value={categoryValues}
            options={categoryItems}
            onChange={handleChangeCategory}
            // react-select/issues/3018
            getOptionValue={(option) => option["id"]}
            getOptionLabel={(option) => `${option.stdcode} | ${option.name}`}
          />
          {categoryError}
          {errors.category && errors.category.message}
        </div>

        <div>
          <label
            className={css`
              width: 5%;
              margin-left: 0%;
              display: inline-block;
            `}
          >
            参数选择:
          </label>
          <Select
            className={css`
              width: 80%;
              margin-left: 0%;
              margin-top: 10px;
              display: inline-block;
            `}
            name="arguments"
            isMulti={false}
            closeMenuOnSelect={true}
            isClearable
            placeholder="参数(只能单选)"
            value={argumentValues}
            options={argumentItems}
            onChange={handleChangeArguments}
            // react-select/issues/3018
            getOptionValue={(option) => option["id"]}
            getOptionLabel={(option) => `${option.stdcode} | ${option.name}`}
          />
          {argumentError}
        </div>

        <div>
          <span
            className={css`
              width: 5%;
              margin-left: 0%;
              margin-top: 10px;
              display: inline-block;
            `}
          >
            规范标准:
          </span>
          <AsyncSelect
            className={css`
              width: 80%;
              margin-left: 0%;
              margin-top: 10px;
              display: inline-block;
            `}
            name="standards"
            isMulti
            cacheOptions
            defaultOptions
            loadOptions={promiseOptions}
            getOptionValue={(option) => option["id"]}
            onChange={onChange}
            getOptionLabel={(option) => `${option.stdcode} | ${option.name}`}
            value={standardValues}
            placeholder="标准(可多选)"
          />
          {standardsError}
          {errors.standards && errors.standards.message}
        </div>

        <div>
          <label
            htmlFor="kind"
            className={css`
              width: 5%;
              margin-left: 0%;
              margin-top: 10px;
              display: inline-block;
            `}
          >
            类型:
          </label>

          <select
            name="kind"
            ref={register}
            required
            className={css`
              width: 40%;
              margin-left: 0%;
              margin-top: 10px;
              display: inline-block;
            `}
          >
            <option value="0">试验依据 | 判定标准</option>
            <option value="1">仅试验依据</option>
            <option value="2">仅判定标准</option>
          </select>
          {errors.name && errors.name.message}
          <span>如果不清楚这里怎么选,可以不选.</span>
          <button
            onClick={(e) => {
              e.preventDefault();
              if (standardValues) {
                setStandardValues([
                  ...standardValues,
                  { id: 596, stdcode: "/", name: "/" },
                ]);
                setValue("standards", [
                  ...standardValues,
                  { id: 596, stdcode: "/", name: "/" },
                ]);
              } else {
                setStandardValues([{ id: 596, stdcode: "/", name: "/" }]);
                setValue("standards", [{ id: 596, stdcode: "/", name: "/" }]);
              }
            }}
          >
            加"/"标准
          </button>
        </div>

        <button
          type="submit"
          onClick={handleSubmit}
          className="btn btn-primary"
        >
          保存
        </button>
      </form>

      <ol>
        {linkStandardItems &&
          linkStandardItems.map((item, index) => (
            <li
              key={index}
              className={css`
                margin-bottom: 5px;
              `}
            >
              {item.stdcode}--{item.name}--{kinds[item.kind]}{" "}
              <button
                onClick={() => {
                  selectRow(item);
                }}
              >
                修改
              </button>
              <button
                className={css`
                  margin-left: 40px;
                  display: inline-block;
                `}
                onClick={() => {
                  deleteStandard(item);
                }}
              >
                删除
              </button>
            </li>
          ))}
      </ol>
      <hr />

      {isEdite ? <ArgumentLinkStandardEdite item={updateRow} /> : null}
      <button
        onClick={(e) => {
          e.preventDefault();
          setIsEdite(false);
        }}
        disabled={!isEdite}
      >
        取消修改
      </button>
    </div>
  );
}
