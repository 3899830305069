import baseURL from "../Config";

export const fetchLinkAdd = async data => {
  try {
    const res = await fetch(`${baseURL}/api/v1/argumentlinkstandard/addlink`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body: JSON.stringify(data)
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};


export const fetchLinkDelete = async data => {
  try {
    const res = await fetch(`${baseURL}/api/v1/argumentlinkstandard/deletelink`, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body: JSON.stringify({argument_id:data.argument_id,standard_id:data.id})
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};


export const fetchLinkUpdate = async data => {
  try {
    const res = await fetch(`${baseURL}/api/v1/argumentlinkstandard/updatelink`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body: JSON.stringify(data)
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};




export const fetchLinkStandards = async argument_id => {
  try {
    const res = await fetch(`${baseURL}/api/v1/argumentlinkstandard/standards/${argument_id}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};
