import React, { useState, useEffect } from "react";
import { fetchTask } from "../apidata/fetchTask";
import {
  statusflags,
  payments,
  samplesources,
  sendways,
  testkinds,
  sampledealwiths,
} from "../Enum";

export default function TaskDetail({ sample }) {
  const [task, setTask] = useState();
  const [msg, setMsg] = useState();
  useEffect(() => {
    (async () => {
      try {
        const res = await fetchTask(sample.id);
        if (res.ok) {
          const json = await res.json();
          setTask(json);
        } else {
          setMsg("还没有创建任务");
        }
      } catch (error) {
        setTask(null);
        setMsg(error.message || error.statusText);
      }
    })();
  }, [sample]);
  return (
    <div>
      <p>{msg}</p>

      <p>当前状态:{statusflags?.find((c) => c.id === sample.status)?.name}</p>
      <p>
        发任务(发):{task?.sendname}&nbsp;
        {task?.send_at.substring(0, 19).replace("T", " ")}
      </p>
      <p>
        实验负责人(接):{task?.labchargename}&nbsp;
        {task?.accept_at?.substring(0, 19).replace("T", " ")}
      </p>
      <p>实验完成时间:{task?.finish_at?.substring(0, 19).replace("T", " ")}</p>
      <br />
      <p>收样日期:{sample.receivepersondate}</p>
      <p>委托日期:{sample.trustdate} </p>
      <p>报告日期:{sample.reportdate} </p>
      <p>
        委托单号:
        {sample.trust.certificate === 0 ? "JHWT" : "JHSWT"}
        {`-${sample.trust.year}-${sample.trust.trustnumber
          .toString()
          .padStart(4, "0")}`}
      </p>
      <p>
        样品单号:
        {sample.num1 === 1 &&
          `JHYP-${sample.year}-${
            sample.samplecode
          }-${sample.samplenumber.toString().padStart(4, "0")}`}
        {sample.num1 > 1 &&
          [...Array(sample.num1)].map((i, index) => (
            <span style={{ display: "block" }} key={index}>{`JHYP-${
              sample.year
            }-${sample.samplecode}-${sample.samplenumber
              .toString()
              .padStart(4, "0")}(${index + 1})`}</span>
          ))}
      </p>

      <p>样品名称:{sample.samplenickname}</p>
      <p>委托单位:{sample.trust.trustname}</p>
      <p>工程名称:{sample.trust.projectname} </p>
      <p>委托人:{sample.trust.trustperson}</p>
      <p>委托人电话:{sample.trust.trustpersonphone}</p>
      <p>样品处置:{sampledealwiths[sample.trust.sampledealwith]}</p>
      <p>报告份数:{sample.trust.reportqty}份</p>
      <p>报告领取方式:{sendways[sample.trust.sendway]}</p>
      <p>寄送地址:{sample.trust.address} </p>

      <p>
        {sample.ismiddlereport
          ? `需要中间报告:${sample.middlereportdate}`
          : "不需要中间报告"}{" "}
      </p>

      <p>检测参数:</p>
      <ol>
        {sample.arguments &&
          sample.arguments.map((item) => (
            <li key={item.id}>
              {item.stdcode}--{item.name}|
              {sample.argumentsproperty &&
                sample.argumentsproperty.find((c) => c.id === item.id) &&
                sample.argumentsproperty.find((c) => c.id === item.id)
                  ?.property}
              --单价:
              {sample.argumentsprice.find((c) => c.argument_id === item.id) &&
                sample.argumentsprice.find((c) => c.argument_id === item.id)
                  ?.price}
              元
            </li>
          ))}
      </ol>
      <p>{sample.isone ? "整套" : "非整套"}</p>
      <p>
        参数金额:
        {sample.isone
          ? sample.onecost
          : sample.argumentsprice
          ? sample.argumentsprice.map((c) => c.price).reduce((x, y) => x + y, 0)
          : 0}
      </p>

      <p>附加附费用:</p>
      <ol>
        {sample.extracost &&
          sample.extracost.map((item) => (
            <li key={item.id}>
              {item.name}--单价:{item.price}元x{item.qty}
              {item.unit}--{item.remark}
            </li>
          ))}
      </ol>

      <p>工程折扣:{sample.discount}</p>
      <p>代码折扣:{sample.codediscount}</p>
      <p>
        {sample.othername}:{sample.othercost}{" "}
      </p>
      <p>总费用:{sample.totalcost} </p>
      <p>检测依据:</p>
      <ol>
        {sample.accordings &&
          sample.accordings.map((item) => (
            <li key={item.id}>
              {item.stdcode}--{item.name}
            </li>
          ))}
      </ol>

      <p>判定依据:</p>
      <ol>
        {sample.standards &&
          sample.standards.map((item) => (
            <li key={item.id}>
              {item.stdcode}--{item.name}
            </li>
          ))}
      </ol>
      <hr />

      <p>
        委托人签名日期:
        {sample.trustpersondate}
      </p>

      <hr />
      <p>取样人:{sample.trust.sampleperson} </p>
      <p>取样人证号:{sample.trust.sampleperson_cardnumber} </p>
      <p>见证单位:{sample.trust.lookname} </p>
      <p>见证人:{sample.trust.lookperson} </p>
      <p>见证证号:{sample.trust.lookperson_cardnumber} </p>
      <p>抽样单位:{sample.trust.samplingname} </p>
      <p>抽样人:{sample.trust.samplingperson} </p>
      <p>抽样人证号:{sample.trust.sampleperson_cardnumber} </p>
      <p>支付方式:{payments[sample.trust.payment]}</p>
      <p>样品来源:{samplesources[sample.trust.samplesource]}</p>
      <p>测试种类:{testkinds[sample.trust.testkind]}</p>

      <hr />
      <p>工程部位:{sample.projectpart} </p>
      <p>取样地址:{sample.sampleaddress} </p>
      <p>样品名称:{sample.samplenickname} </p>
      <p>样品描述:{sample.samplestatus} </p>
      <p>样品数量:{sample.numberofsample} </p>
      <p>样品组数:{sample.num1} </p>
      <p>样品每组数量:{sample.num2} </p>
      <p>规格/型号/牌号:{sample.modelnumber} </p>
      <p>制造工厂:{sample.makefactory} </p>
      <p>设计要求:{sample.designrequire} </p>
      <p>委托文档:{sample.trustdoc} </p>
      <p>备注:{sample.remark}</p>
      <p>附加信息:</p>
      <ol>
        {sample.extrainfo &&
          sample.extrainfo.map((item) => (
            <li key={item.id}>
              {item.print ? "打印" : "不打印"}|{item.name}:{item.value}
            </li>
          ))}
      </ol>
    </div>
  );
}
