import React from 'react'

export default function CustArgumentTable({custArgumentItems,editRow, deleteItem}) {
    return (
        <ol>
        {custArgumentItems &&
          custArgumentItems.map((item) => (
            <li key={item.id}>
              {item.id}--{item.stdcode}--{item.name}---{item.remak}
              --排序({item.sorting})
              <button onClick={()=> editRow(item)} className="btn btn-primary">
                修改序号
              </button>
              <span> </span>
              <button                
                onClick={() => deleteItem(item)}
                className="btn btn-danger"
              >               
                删除
              </button>
            </li>
          ))}
      </ol>
    )
}
