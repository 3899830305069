import baseURL from "../Config";

export const fetchToken = async (data) => {
  try {
    const res = await fetch(`${baseURL}/qiniu/token`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token")),
      },
      body: JSON.stringify(data),
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchDownloadurl = async (data) => {
  try {
    const res = await fetch(`${baseURL}/qiniu/downloadurl`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token")),
      },
      body: JSON.stringify(data),
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};
