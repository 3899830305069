import baseURL from "../Config";


export const fetchPriceListByArgument = async (argument_id) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/price/pricelist/${argument_id}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};


export const fetchAddPrice = async (data) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/price/addprice`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body: JSON.stringify(data)
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchUpdatePrice = async (data) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/price/updateprice/${data.id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body: JSON.stringify(data)
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchDeletePrice = async (price_id) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/price/deleteprice/${price_id}`, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};

// 当前订单的价格(根据工程的价格版本)
export const fetchPriceByVer = async data => {
  try {
    const res = await fetch(`${baseURL}/api/v1/price/argumentprice`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body: JSON.stringify(data)
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};

