import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { css } from "emotion";

import Pagination from "../components/Pagination";
import ProjectNameUpdate from "./ProjectNameUpdate";
import CodeDiscount from "./CodeDiscount";

import { fetchCustomerName } from "../apidata/fetchCustomerData";
import { Link } from "react-router-dom";

import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import {
  AlertDialog,
  AlertDialogLabel,
  AlertDialogDescription,
} from "@reach/alert-dialog";

import { fetchCustomers } from "../apidata/fetchCustomerData";
import {
  fetchProjectCount,
  fetchProjectNames,
  fetchProjectNameExists,
  fetchProjectNameAdd,
  fetchProjectNameUpdate,
  fetchProjectNameUpdateCodeDiscount,
  fetchProjectNameDelete,
} from "../apidata/fetchProjectNameData";

import { fetchPriceVerList } from "../apidata/fetchPriceVer.js";

export default function ProjectName() {
  const {
    register,
    unregister,
    setValue,
    handleSubmit,
    errors,
    clearErrors,
    reset,
    setError,
    watch,
  } = useForm();

  useEffect(() => {
    register({ name: "customer" });
    setValue("discount", 1);
    return () => unregister;
  }, [register, unregister, setValue]);

  const [loadingAdd, setloadingAdd] = useState(false);

  const [showDialogCodeDiscount, setShowDialogCodeDiscount] = useState(false);
  const openCodeDiscount = () => setShowDialogCodeDiscount(true);
  const closeCodeDiscount = () => setShowDialogCodeDiscount(false);

  const [showDialogUpdate, setShowDialogUpdate] = useState(false);
  const openUpdate = () => setShowDialogUpdate(true);
  const closeUpdate = () => setShowDialogUpdate(false);

  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const cancelRef = useRef();
  const openDelete = () => setShowDialogDelete(true);
  const closeDelete = () => setShowDialogDelete(false);

  const [msg, setMsg] = useState();
  const [loading, setLoading] = useState(false);

  // 价格版本
  const [priceVerItems, setPriceVerItems] = useState();

  // 委托单位
  const [customerValues, setCustomerValues] = useState();
  const [customerError, setCustomerError] = useState();

  // 工程名称
  const [projectNameItems, setProjectNameItems] = useState([]);
  const [projectNameError, setProjectNameError] = useState();

  // 总数
  const [count, setCount] = useState(0);
  // 分页相关
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);

  const [currentProject, setCurrentProject] = useState();

  useEffect(() => {
    (async () => {
      try {
        const json = await fetchPriceVerList();
        setPriceVerItems(json);
      } catch (e) {
        setMsg(e.message || e.statusText);
      }
    })();
  }, []);

  // 委托单位下所有工程名称
  useEffect(() => {
    if (customerValues) {
      setLoading(true);
      (async () => {
        try {
          const json = await fetchProjectNames(
            customerValues.id,
            pageSize,
            currentPage
          );
          setProjectNameItems(json);
        } catch (error) {
          setProjectNameError(error.message || error.statusText);
        }
      })();
      setLoading(false);
    }
  }, [customerValues, pageSize, currentPage]);

  useEffect(() => {
    if (customerValues) {
      (async () => {
        try {
          const json = await fetchProjectCount(customerValues.id);
          setCount(json.count);
        } catch (error) {
          setMsg(error.message || error.statusText);
        }
      })();
    }
  }, [customerValues]);

  // pull server data  异步 委托单位数据
  const promiseOptions = async (inputValue) => {
    if (inputValue === null) {
      return Promise.reject("error");
    }
    if (inputValue.length < 1) {
      return Promise.reject("error");
    }

    try {
      const json = await fetchCustomers(inputValue);
      setCustomerValues(null);
      return json;
    } catch (error) {
      setCustomerError(error.message || error.statusText);
      return Promise.reject(error);
    }
  };

  // 查询委托单位名称
  const handleCustomerChange = (event) => {
    setValue("customer", event); // form 的值
    setValue("customername", event.name);
    setValue("customerpinyin", event.pinyin);
    setCustomerValues(event);
    setCurrentPage(1);

    if (!event) {
      setError("customer", "notMatch", "没有选择到委托单位?");
    }
    return clearErrors("customer");
  };

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const validateCustomerNameDb = async (name) => {
    if (customerValues) {
      return true;
    }
    try {
      const has = await fetchCustomerName(name);
      if (has) {
        return false || "已存在";
      } else {
        return true;
      }
    } catch (error) {
      setMsg("系统错误");
    }
  };

  const validateProjectNameDb = async (name) => {
    if (customerValues) {
      try {
        const json = await fetchProjectNameExists(customerValues.id, name.trim());
        return !json || "已存在";
      } catch (error) {
        setMsg("exist api error!");
      }
    } else {
      if (!watch("customername")) {
        return false || "委托单位还没有填好.";
      }
    }
  };

  // 增加工程  Add
  const onSubmit = async (data) => {
    try {
      setloadingAdd(true);
      const json = await fetchProjectNameAdd(data);
      setProjectNameItems([json.data, ...projectNameItems]);
      setCount(count + 1);
      setMsg(json.msg);
      if (data.customer) {
        setValue("name", null);
        setValue("discount", 1);
      } else {
        setValue("customername", null);
        setValue("customerpinyin", null);
        setValue("name", null);
        setValue("discount", 1);
      }
      setloadingAdd(false);
      setTimeout(() => {
        setMsg(null);
      }, 2000);
    } catch (error) {
      setMsg(error.message);
    }
  };
  // 修改基本信息(名字,折扣,价格版本)
  const update = async (data) => {
    try {
      const json = await fetchProjectNameUpdate(currentProject.id, data);
      setMsg(json.msg);
      setProjectNameItems(
        projectNameItems.map((c) =>
          c.id === currentProject.id ? json.data : c
        )
      );
      closeUpdate();
      setTimeout(() => {
        setMsg(null);
      }, 2000);
    } catch (error) {}
  };

  const [codeDiscountJson, setCodeDiscountJson] = useState();

  // 子控上传数据到父控件 用于价面显示和保存
  const updateCodeDiscount = (data) => {
    console.log(data);
    setCodeDiscountJson(data);
  };

  //  修改代码折扣
  const updateCodeDiscountDb = async () => {
    try {
      const json = await fetchProjectNameUpdateCodeDiscount(
        currentProject.id,
        codeDiscountJson
      );
      setMsg(json.msg);
      setProjectNameItems(
        projectNameItems.map((c) =>
          c.id === currentProject.id ? json.data : c
        )
      );
      closeCodeDiscount();
      setTimeout(() => {
        setMsg(null);
      }, 2000);
    } catch (error) {
      setMsg(error.message);
    }
  };

  const destroyProject = async () => {
    try {
      const json = await fetchProjectNameDelete(currentProject.id);
      setMsg(json.msg);
      setProjectNameItems(
        projectNameItems.filter((c) => c.id !== currentProject.id)
      );
      setCount(count - 1);
      setTimeout(() => {
        setMsg(null);
      }, 6000);
    } catch (error) {
      setMsg(error.message);
      setTimeout(() => {
        setMsg(null);
      }, 6000);
    }
    setShowDialogDelete(false);
  };

  return (
    <div>
      <h3 style={{ textAlign: "center" }}>工程名称维护</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group row">
          <label className="col-sm-1 col-form-label">已有委托单位:</label>
          <AsyncSelect
            name="trustname"
            className={css`
              width: 70%;
              margin-left: 1%;
              height: atuo;
              display: inline-block;
            `}
            cacheOptions
            defaultOptions
            loadOptions={promiseOptions}
            getOptionValue={(option) => option["id"]}
            onChange={handleCustomerChange}
            getOptionLabel={(option) => `${option.pinyin} | ${option.name}`}
            value={customerValues}
            placeholder="请输入委托单位名称"
          />
          {customerError}
          <button
            className={css`
              width: 10%;
              margin-left: 1%;
              height: 35px;
              display: inline-block;
            `}
            onClick={(e) => {
              e.preventDefault();
              setValue("customer", null); // form 的值
              setValue("customername", null);
              setValue("customerpinyin", null);
              setCustomerValues(null);
            }}
          >
            清除单位信息
          </button>
        </div>
        <div className="form-group row">
          <label htmlFor="name" className="col-sm-1 col-form-label">
            新增委托单位:
          </label>
          <div className="col-sm-8">
            <input
              name="customername"
              ref={register({
                minLength: {
                  value: 3,
                  message: "不能小于3个字",
                },
                maxLength: {
                  value: 128,
                  message: "不能大于128个字",
                },
                validate: (value) => validateCustomerNameDb(value), //异步验证
              })}
              type="text"
              className="form-control"
            />
            {errors.customername && errors.customername.message}
          </div>
          <label htmlFor="pinyin" className="col-sm-1 col-form-label">
            拼音首字母:
          </label>
          <div className="col-sm-2">
            <input
              name="customerpinyin"
              ref={register({
                minLength: {
                  value: 2,
                  message: "不能小于2个字",
                },
                maxLength: {
                  value: 10,
                  message: "不能大于10个字",
                },
              })}
              type="text"
              className="form-control"
              required
            />
          </div>
        </div>

        <hr />
        <div className="form-group row">
          <label htmlFor="projectname" className="col-sm-1 col-form-label">
            工程名称:
          </label>
          <div className="col-sm-10">
            <input
              name="name"
              ref={register({
                minLength: {
                  value: 1,
                  message: "不能小于1个字",
                },
                maxLength: {
                  value: 128,
                  message: "不能大于128个字",
                },
                validate: (value) => validateProjectNameDb(value.trim()), //异步验证
              })}
              type="text"
              className="form-control"
            />
            {projectNameError}
            {errors.name && errors.name.message}
          </div>

          <div className="col-sm-5">
            {errors.customerpinyin && errors.customerpinyin.message}
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-1 col-form-label">价格版本</label>
          <div className="col-sm-3">
            <select
              name="pricever"
              ref={register({
                required: "不能为空",
              })}
            >
              {priceVerItems &&
                priceVerItems.map((item, index) => (
                  <option key={index} value={item.code}>
                    {item.name}
                  </option>
                ))}
            </select>
            {errors.pricever?.message}
          </div>
          <label className="col-sm-1 col-form-label">折扣:</label>
          <div className="col-sm-2">
            <input
              ref={register({
                pattern: {
                  value: /^[0-9]*\.?[0-9]*$/,
                  message: "数字哦",
                },
                validate: {
                  min: (value) => parseFloat(value, 10) >= 0 || "应该大于0",
                  max: (value) => parseFloat(value, 10) <= 1 || "小于等于1",
                },
              })}
              name="discount"
              type="text"
            />
            {errors.discount?.message}
          </div>
          <div className="col-sm-2">
            <button
              disabled={loadingAdd}
              type="submit"
              onClick={handleSubmit}
              className="btn btn-primary"
            >
              保存
            </button>
            <input
               style={{marginLeft:"40px"}}
              type="reset"
              onClick={() => {
                reset({ pinyin: null, name: null });
              }}
              className="btn btn-primary"
            />
            <Link to="/customerlist">返回</Link>
          </div>
        </div>
      </form>
      <div className="alert alert-primary" role="alert">
        {msg}
      </div>
      <table className="table table-sm table-hover">
        <thead className="thead-dark">
          <tr>
            <th>识别号</th>
            <th>工程名称</th>
            <th>价格版本</th>
            <th>工程折扣</th>
            <th>操作</th>
            <th>操作(不常用)</th>
            <th>操作</th>
          </tr>
        </thead>
        <caption>当前查询共{count}条</caption>
        <tbody>
          {projectNameItems &&
            projectNameItems.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>
                  {
                    priceVerItems.find(({ code }) => code === item.pricever)
                      .name
                  }
                </td>
                <td>{item.discount}</td>

                <td>
                  <button
                    onClick={() => {
                      setCurrentProject(item);
                      openUpdate();
                    }}
                    className="btn btn-secondary"
                  >
                    修改
                  </button>
                </td>
                <td>
                  <button
                    onClick={() => {
                      setCurrentProject(item);
                      openCodeDiscount();
                    }}
                    className="btn btn-secondary"
                  >
                    修改代码折扣
                  </button>
                </td>

                <td>
                  <button
                    onClick={() => {
                      setCurrentProject(item);
                      openDelete();
                    }}
                    className="btn btn-danger"
                  >
                    删除
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <Pagination
        pageSize={pageSize}
        totalRecords={count}
        currentPage={currentPage}
        onPageChange={paginate}
        defaultPageNeighbours={2}
      />
      <span>{loading && "正在加载中,请等待..."}</span>
      {showDialogCodeDiscount && (
        <Dialog
          isOpen={showDialogCodeDiscount}
          onDismiss={closeCodeDiscount}
          aria-label={"test"}
        >
          {currentProject?.name}
          <CodeDiscount
            initValues={currentProject?.codediscount}
            handleCodeDiscountChange={updateCodeDiscount}
          />

          <button
            onClick={() => {
              updateCodeDiscountDb();
            }}
          >
            保存修改
          </button>
          <button ref={cancelRef} onClick={closeCodeDiscount}>
            取消, 我不愿意
          </button>
        </Dialog>
      )}

      {showDialogUpdate && (
        <Dialog
          isOpen={showDialogUpdate}
          onDismiss={closeUpdate}
          aria-label={"test"}
        >
          {currentProject?.name}
          <ProjectNameUpdate
            priceVerItems={priceVerItems}
            currentProject={currentProject}
            update={update}
          />
          <hr />
          <button ref={cancelRef} onClick={closeUpdate}>
            取消, 我不愿意
          </button>
        </Dialog>
      )}

      {showDialogDelete && (
        <AlertDialog onDismiss={closeDelete} leastDestructiveRef={cancelRef}>
          <AlertDialogLabel style={{ textAlign: "center" }}>
            请再次确认是否删除?
          </AlertDialogLabel>
          <AlertDialogDescription>{currentProject.name}</AlertDialogDescription>
          <div className="alert-buttons">
            <button onClick={destroyProject}>是, 删除</button>{" "}
            <button ref={cancelRef} onClick={closeDelete}>
              取消, 我不愿意
            </button>
          </div>
        </AlertDialog>
      )}
    </div>
  );
}
