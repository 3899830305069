import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";

import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import {
  AlertDialog,
  AlertDialogLabel,
  AlertDialogDescription
} from "@reach/alert-dialog";


import { fetchSampleCodeAll } from "../apidata/fetchSampleCodeData";

import {
  fetchArgumentPkgsJh,
  fetchPkgAddJh,
  fetchPkgDelete,
  fetchPkgUpdateJh,
  fetchPkgCopyJh  
} from "../apidata/fetchArgumentPkgData";
import { fetchPriceVerList } from "../apidata/fetchPriceVer.js";

import CustomerArgument from "./CustArgument";
import ArgumentPkgAddJh from "./ArgumentPkgAddJh";
import ArgumentPkgUpdateJh from "./ArgumentPkgUpdateJh";
import ArgumentPkgCopyJh from "./ArgumentPkgCopyJh";
import PkgPrice from "./PkgPrice";

export default function ArgumentPkgNameJh() {
  const [msg, setMsg] = useState();
  // 大类别
  // const [categoryItems, setCategoryItems] = useState([]); //db
  // const [categoryValues, setCategoryValues] = useState(null);
  // const [categoryError, setCategoryError] = useState(null);

  // 大类别
  const [sampleCodeItems, setSmapleCodeItems] = useState([]); //db
  const [sampleCodeValues, setSampleCodeValues] = useState(null);
  const [sampleCodeError, setSampleCodeError] = useState(null);

  // 自定义参数包
  const [argumentPkgItems, setArgumentPkgItems] = useState([]); //db
  // const [argumentPkgValues, setArgumentPkgValues] = useState(null);
  const [argumentPkgError, setArgumentPkgError] = useState(null);

  const [currentPkg, setCurrentPkg] = useState();
  // 价格版本
  const [priceVerItems, setPriceVerItems] = useState();

  // 参数维护
  const [showDialogArgument, setShowDialogArgument] = useState(false);
  const openArgument = () => setShowDialogArgument(true);
  const closeArgument = () => setShowDialogArgument(false);


   // 价格维护
   const [showDialogPkgPrice, setShowDialogPkgPrice] = useState(false);
   const openPkgPrice = () => setShowDialogPkgPrice(true);
   const closePkgPrice = () => setShowDialogPkgPrice(false);

 
  // 新增
  const [showDialogAdd, setShowDialogAdd] = useState(false);
  const openAdd = () => setShowDialogAdd(true);
  const closeAdd = () => {
    setShowDialogAdd(false);
  };

  // 修改
  const [showDialogUpdate, setShowDialogUpdate] = useState(false);
  const openUpdate = () => setShowDialogUpdate(true);
  const closeUpdate = () => {
    setShowDialogUpdate(false);
  };


   // 复制
   const [showDialogCopy, setShowDialogCopy] = useState(false);
   const openCopy = () => setShowDialogCopy(true);
   const closeCopy = () => {
     setShowDialogCopy(false);
   };
 

  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const cancelRef = useRef();
  const openDelete = () => setShowDialogDelete(true);
  const closeDelete = () => setShowDialogDelete(false);

  // 加载样品代码
  useEffect(() => {
    (async () => {
      try {
        const json = await fetchSampleCodeAll();
        setSmapleCodeItems(json);
      } catch (e) {
        setSampleCodeError(e.message || e.statusText);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const json = await fetchPriceVerList();
        setPriceVerItems(json);
      } catch (e) {
        setMsg(e.message || e.statusText);
      }
    })();
  }, []);

  // 动态填充参数包
  useEffect(() => {
    if (!sampleCodeValues) {
      return;
    }
    (async () => {
      try {
        const json = await fetchArgumentPkgsJh(sampleCodeValues.stdcode);
        setArgumentPkgItems(json);
      } catch (error) {
        setArgumentPkgError(error.message || error.statusText);
      }
    })();
  }, [sampleCodeValues]);

  // 选择大类
  const handleChangeSampleCode = selectedOption => {
    setSampleCodeValues(selectedOption); // 控件的值
  };

  const add = async data => {
    try {
      const json = await fetchPkgAddJh(data);
      setMsg(json.msg);
      setArgumentPkgItems([json.data, ...argumentPkgItems]);
      closeAdd();      
      setTimeout(() => {
        setMsg(null);
      }, 2000);
    } catch (error) {}
  };

  const update = async data => {
    try {
      const json = await fetchPkgUpdateJh(currentPkg.id, data);
      console.log(json);
      setArgumentPkgItems(
        argumentPkgItems.map(item =>
          item.id === currentPkg.id ? json.data : item
        )
      );
      setMsg(json.msg);
      closeUpdate();
      setTimeout(() => {
        setMsg("");
      }, 2000);
    } catch (error) {
      setMsg(error);
    }
  };


  const copy = async data => {
    try {
      const json = await fetchPkgCopyJh(currentPkg.id,data);
      setMsg(json.msg);
      setArgumentPkgItems([json.data, ...argumentPkgItems]);
      closeCopy();      
      setTimeout(() => {
        setMsg(null);
      }, 2000);
    } catch (error) {}
  };


  const deletePkg = async () => {
    try {
      const json = await fetchPkgDelete(currentPkg.id);
      setMsg(json.msg);
      setArgumentPkgItems(argumentPkgItems.filter(c => c.id !== currentPkg.id));
      setTimeout(() => {
        setMsg(null);
      }, 2000);
    } catch (error) {}

    setShowDialogDelete(false);
  };

  return (
    <div>
      <h3 style={{ textAlign: "center" }}>自定义参数包维护</h3>
      <button onClick={openAdd}>新增</button>
      {msg}
      <div>
        <label className="label">样品代码:</label>
        <Select
          className="select-small"
          name="samplecode"
          placeholder="样品代码"
          value={sampleCodeValues}
          options={sampleCodeItems}
          onChange={handleChangeSampleCode}
          // react-select/issues/3018
          getOptionValue={option => option["id"]}
          getOptionLabel={option => `${option.category.certificate===0 ? "公路" : "水运"} | ${option.stdcode} | ${option.name}`}
        />
        <div style={{ display: "inlneblock", color: "red" }}>
          {sampleCodeError} {argumentPkgError}
        </div>
      </div>

      <table className="table table-sm table-hover">
        <thead className="thead-dark">
          <tr>
            <th>序号</th>
            <th>自定义参数包</th>
            <th>是否全选</th>
            <th>排序</th>           
            <th>操作</th>
            <th>操作</th>
            <th>操作</th>
            <th>操作</th>
            <th>操作</th>
          </tr>
        </thead>

        <tbody>
          {argumentPkgItems ? (
            argumentPkgItems.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>
                  {item.name > 30
                    ? item.name?.substring(0, 30) + "..."
                    : item.name}
                </td>
                <td>{item.checkall ? "全选" : "单选"}</td>
                
                <td>{item.sorting}</td>

                <td>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setCurrentPkg(item);
                      openArgument();
                    }}
                  >
                    内参数维护
                  </button>
                </td>
                
               

                <td>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setCurrentPkg(item);
                      openUpdate();
                    }}
                  >
                    修改名字
                  </button>
                </td>

                <td>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setCurrentPkg(item); 
                      openPkgPrice();                     
                    }}
                  >
                    套餐价格维护
                  </button>
                </td>

                <td>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setCurrentPkg(item);
                    openCopy();
                  }}
                >
                  克隆参数包
                </button>
                </td>

                <td>
                  <button
                    className="btn btn-danger"
                    onClick={() => {
                      setCurrentPkg(item);
                      openDelete();
                    }}
                  >
                    删除
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>没有数据哦!!</td>
            </tr>
          )}
        </tbody>
      </table>

      {showDialogArgument && (
        <Dialog
          aria-label="modal"
          isOpen={showDialogArgument}
          onDismiss={closeArgument}
        >
          {currentPkg.name}
          <CustomerArgument currentArgumentPkg={currentPkg} />
          <hr />
          <button onClick={closeArgument}>取消</button>
        </Dialog>
      )}
      {showDialogPkgPrice && (
        <Dialog
          aria-label="modal"
          isOpen={showDialogPkgPrice}
          onDismiss={closePkgPrice}
        >
          {currentPkg.name}
          <PkgPrice argumentpkg_id={currentPkg.id} />
          <hr />
          <button onClick={closePkgPrice}>取消</button>
        </Dialog>
      )}
      <Dialog aria-label="modal" isOpen={showDialogAdd} onDismiss={closeAdd}>
        <ArgumentPkgAddJh priceVerItems={priceVerItems} add={add} />
        <hr/>
        <button onClick={closeAdd}>取消</button>
      </Dialog>

      {showDialogUpdate && (
        <Dialog
          aria-label="modal"
          isOpen={showDialogUpdate}
          onDismiss={closeUpdate}
        >
          <ArgumentPkgUpdateJh
            priceVerItems={priceVerItems}
            update={update}
            currentArgumentPkg={currentPkg}
          />
          <hr />
          <button onClick={closeUpdate}>取消</button>
        </Dialog>
      )}

      {showDialogCopy && (
        <Dialog
          aria-label="modal"
          isOpen={showDialogCopy}
          onDismiss={closeCopy}
        >
          <ArgumentPkgCopyJh copy={copy} currentArgumentPkg={currentPkg} priceVerItems={priceVerItems} />
          <hr />
          <button onClick={closeCopy}>取消</button>
        </Dialog>
      )}

      {showDialogDelete && (
        <AlertDialog onDismiss={closeDelete} leastDestructiveRef={cancelRef}>
          <AlertDialogLabel style={{ textAlign: "center" }}>
            请再次确认是否删除?
          </AlertDialogLabel>
          <AlertDialogDescription>{currentPkg.name}</AlertDialogDescription>
          <div className="alert-buttons">
            <button onClick={deletePkg}>是, 删除</button>
            <button ref={cancelRef} onClick={closeDelete}>
              取消, 我不愿意
            </button>
          </div>
        </AlertDialog>
      )}
    </div>
  );
}
