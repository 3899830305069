import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

export default function PkgPriceAdd({ verItems, add, priceItems }) {
  const { register, unregister, handleSubmit, setValue, errors } = useForm();

  useEffect(() => {
    return () => unregister;
  }, [register, unregister]);

  const submit = data => {
    add(data);
    setValue("price", null);
  };

  const validateVer = value => {
    const temp = priceItems.find(c => c.ver === parseInt(value));
    if (temp === undefined) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(submit)}>
        <div className="form-group row">
          <label className="col-sm-1 col-form-label">版本</label>
          <div className="col-sm-8">
            <select
              name="ver"
              ref={register({
                required: "不能为空",
                validate: value =>
                  validateVer(value) || "该版本价格已存在,修改就可以"
              })}
            >
              {verItems &&
                verItems.map((item, index) => (
                  <option key={index} value={item.code}>
                    {item.name}
                  </option>
                ))}
            </select>
            {errors.ver?.message}
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-1 col-form-label">价格</label>
          <div className="col-sm-8">
            <input
              ref={register({
                required: "不能为空"
              })}
              name="price"
              type="number"
              step="0.01"
            />
            {errors.price?.message}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-3">
            <button
              type="submit"
              onClick={handleSubmit}
              className="btn btn-primary"
            >
              增加
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
