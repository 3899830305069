import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import { css } from "emotion";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";

import { fetchCustomers } from "../apidata/fetchCustomerData";
import CustomerForm from "./CustomerForm";

export default function CustomerSelect() {
  // server 提示信息
  const [msg, setMsg] = useState(null);
  // 新增
  const [showDialogAdd, setShowDialogAdd] = useState(false);
  const openAdd = () => setShowDialogAdd(true);
  const closeAdd = () => setShowDialogAdd(false);
  // 修改
  const [showDialogUpdate, setShowDialogUpdate] = useState(false);
  const openUpdate = () => setShowDialogUpdate(true);
  const closeUpdate = () => setShowDialogUpdate(false);

  const [customerValues, setCustomerValues] = useState();
  const promiseOptions = (inputValue) => {
    const a = new Promise((reslove, reject) => {
      if (inputValue === null) {
        return reject("error");
      }
      if (inputValue.length < 1) {
        return reject("error");
      }

      (async () => {
        try {
          const json = await fetchCustomers(inputValue);
          return reslove(json);
        } catch (e) {
          return reject(e.message || e.statusText);
        }
      })();
    });

    return a;
  };
  //选择标准,aysnc
  const onChange = (event) => {
    if (event) {
      setCustomerValues(event);
    }
  };

  const addUi = (json) => {
    setMsg(json.msg);
    setCustomerValues(json.data);
    closeAdd();
    setTimeout(() => {
      setMsg(null);
    }, 3000);
  };

  const updateUi = async (json) => {
    setMsg(json.msg);
    setCustomerValues(json.data);
    setTimeout(() => {
      setMsg(null);
    }, 3000);
    closeUpdate();
  };

  return (
    <>
      {msg}
      <form>
        <div className="form-group row">
          <label className="col-sm-2 col-form-label">查找委托单位:</label>
          <AsyncSelect
            className={css`
              width: 95%;
              margin-left: 1%;
            `}
            name="standards"
            isMulti={false}
            cacheOptions
            defaultOptions
            loadOptions={promiseOptions}
            getOptionValue={(option) => option["id"]}
            onChange={onChange}
            getOptionLabel={(option) => `${option.pinyin} | ${option.name}`}
            value={customerValues}
            placeholder="请输入委托单位拼音或名称,进行模糊查找,拼音注意用小写"
          />
        </div>

        <div className="form-group row">
          <div className="col-sm-12">
            <p>委托单位拼音: {customerValues?.pinyin}</p>
            <p>委托单位名称: {customerValues?.name}</p>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-sm-1">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                e.preventDefault();
                openAdd();
              }}
            >
              新增
            </button>
          </div>

          <div className="col-sm-1">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                e.preventDefault();
                if (customerValues) {
                  openUpdate();
                }
              }}
              disabled={!customerValues}
            >
              修改
            </button>
          </div>
        </div>
      </form>

      {showDialogAdd && (
        <Dialog aria-label="modal" isOpen={showDialogAdd} onDismiss={closeAdd}>
          <CustomerForm changeUi={addUi} />
          <hr />
          <button onClick={closeAdd}>取消</button>
        </Dialog>
      )}

      {showDialogUpdate && (
        <Dialog
          aria-label="modal"
          isOpen={showDialogUpdate}
          onDismiss={closeUpdate}
        >
          <CustomerForm
            currentCustomer={customerValues}
            isEdite={true}
            changeUi={updateUi}
          />
          <hr />
          <button onClick={closeUpdate}>取消</button>
        </Dialog>
      )}
    </>
  );
}
