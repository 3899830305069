import React, { useState,useEffect } from "react";
import {useForm} from "react-hook-form";
import Select from "react-select";
import baseURL from "../Config";

const MakeOrder = () => {
  const { register, handleSubmit, errors, watch, setValue } = useForm();
  const [data, setData] = useState();
  const onSubmit = data => {
    setData(data);
    console.log(data);
  };
  const [values, setReactSelect] = useState({ selectedOption: [] });
  const handleMultiChange = selectedOption => {
    //let {value : code,label: name}=selectedOption;
    setValue("categorys", selectedOption);  
    setReactSelect({ selectedOption });
  };
  useEffect(()=>{
      register({name:"categorys"});
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  const [options,setOptions]=useState([])
  useEffect(()=>{
    const fetchData= async()=>{
      const res = await  fetch(`${baseURL}/api/v1/category/categories`,{
        method: "get",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + JSON.parse(sessionStorage.getItem("token"))
        }});
        
      const json =await res.json();
      console.log(json);
      // setOptions(json.map(item=>{
      //       const newitem = {};
      //       newitem["id"]=item.id;
      //       newitem["value"]=item.stdcode ;
      //       newitem["label"]=`${item.stdcode} | ${item.name}`;
      //       return newitem;
      // }));
      setOptions(json);
      

    } ;
    fetchData();

  },[])

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input name="firstname" ref={register} />
        <input name="lastname" ref={register} />
        <input name="age" ref={register} />
        {errors.age && "error age"}
        <Select         
          name="filters1"
          placeholder="参数"
          value={values.selectedOption}
          options={options}
          onChange={handleMultiChange}   
          className="col-md-4 " 
          maxMenuHeight = {500}
          // react-select/issues/3018
          getOptionValue={option => option['id']}
          getOptionLabel={option => `${option.stdcode} | ${option.name}`}
        />
        <input type="submit" />
      </form>
      <div>{watch("age")}</div>
      <div>{JSON.stringify(data, null, 2)}</div>
    </div>
  );
};

export default MakeOrder;
