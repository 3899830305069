import React, { useState, useEffect, useRef } from "react";
import Pagination from "../components/Pagination";
import { css } from "emotion";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import {
  AlertDialog,
  AlertDialogLabel,
  AlertDialogDescription,
} from "@reach/alert-dialog";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { not, intersection, union } from "../common/CheckHelper";

import {
  fetchSendReportCount,
  fetchSendReportList,
  fetchDeleteSendReport,
} from "../apidata/fetchSendReport";
import SendReportForm from "./SendReportForm";

export default function SampleReportList() {
  const [loading, setLoading] = useState(false);

  const [msg, setMsg] = useState(null);

  // 待选的id集
  const [items, setItems] = useState([]);
  // 已选的id集
  const [checked, setChecked] = useState([]);

  // 批量修改
  const [showDialogUpdate, setShowDialogUpdate] = useState(false);
  const openUpdate = () => setShowDialogUpdate(true);
  const closeUpdate = () => {
    setShowDialogUpdate(false);
  };

  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const cancelRef = useRef();
  const openDelete = () => setShowDialogDelete(true);
  const closeDelete = () => setShowDialogDelete(false);

  // 总数
  const [count, setCount] = useState(1);
  useEffect(() => {
    (async () => {
      try {
        const temp = await fetchSendReportCount();
        setCount(temp);
      } catch (error) {
        setMsg(`系统异常,请联系开发公司.:${error.message || error.statusText}`);
      }
    })();
  }, []);
  //分页相关
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  // 发放列表
  const [sendReportItems, setSendReportItems] = useState(null);
  const [currentSendReport, setCurrentReport] = useState();

  const [complete, setComplete] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const temp = await fetchSendReportList(pageSize, currentPage);
        setSendReportItems(temp);
        setItems(temp.map((c) => c.id));
        setChecked([]);
        setLoading(false);
      } catch (error) {
        setMsg(`系统异常,请联系开发公司.:${error.message || error.statusText}`);
      }
    })();
  }, [pageSize, currentPage, complete]);

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // 单选
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value); // 不在选择集合,加进去
    } else {
      newChecked.splice(currentIndex, 1); // 在选择的集合里,拿出来
    }

    setChecked(newChecked);
  };

  // 已选数量
  const numberOfChecked = (items) => intersection(checked, items).length;

  // 全选
  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      //已全选情况,置空
      setChecked(not(checked, items));
    } else {
      // 部分选择,或者没有选
      setChecked(union(checked, items));
    }
  };

  const updateUi = (json) => {
    setMsg(json.msg);
    closeUpdate();
    setComplete((prev) => !prev);
  };

  const DeleteSendReport = async () => {
    try {
      const json = await fetchDeleteSendReport(currentSendReport.id);
      setMsg(json.msg);
      setSendReportItems(
        sendReportItems.filter((c) => c.id !== currentSendReport.id)
      );
      setCount(count - 1);
      setTimeout(() => {
        setMsg(null);
      }, 6000);
    } catch (error) {
      setMsg(error.message);
      setTimeout(() => {
        setMsg(null);
      }, 6000);
    }
    setShowDialogDelete(false);
  };

  return (
    <div>
      <h1 style={{ textAlign: "center" }}>已发报告{count}</h1>
      <button
        className={css`
          margin-left: 30px;
        `}
        disabled={checked.length === 0}
        onClick={() => {
          openUpdate();
        }}
      >
        批量修改报告
      </button>
      <table className="table table-sm table-hover">
        <thead>
          <tr>
            <th>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={handleToggleAll(items)}
                    checked={
                      numberOfChecked(items) === items.length &&
                      items.length !== 0
                    }
                    indeterminate={
                      numberOfChecked(items) !== items.length &&
                      numberOfChecked(items) !== 0
                    }
                    disabled={items.length === 0}
                    inputProps={{ "aria-label": "all items selected" }}
                  />
                }
                label={"全选"}
              />
            </th>
            <th>收件人</th>
            <th>收件电话</th>
            <th>发放方式</th>
            <th>地址</th>
            <th>快递公司</th>
            <th>运单号</th>
            <th>备注</th>
            <th>提交日期</th>
            <th>提交人</th>
            <th>签发日期</th>
            <th>签发人</th>
            <th>发放日期</th>
            <th>发放人</th>
            <th>操作</th>
          </tr>
        </thead>

        <tbody>
          {sendReportItems  ? (
            sendReportItems.map( item => (
              <tr key={item.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked.indexOf(item.id) !== -1}
                      onChange={handleToggle(item.id)}
                      value={item.id}
                      color="primary"
                    />
                  }
                  label={`${item.certificate===0 ? "JHWT" : "JHSWT"}-${item.year}-${item.trustnumber?.toString().padStart(4, "0")}`}
                />
                <td>{item.receiveperson}</td>
                <td>{item.phone}</td>
                <td>{item.sendway === 0 ? "自取" : "邮寄"}</td>
                <td>
                  {item.address?.length > 15
                    ? item.address?.substring(0, 15)
                    : item.address}
                </td>
                <td>{item.express}</td>
                <td>{item.trackingnumber}</td>
                <td>
                  {item.remark?.length > 15
                    ? item.remark?.substring(0, 15)
                    : item.remark}
                </td>
                <td>{item.labchargedate}</td>
                <td>{item.labchargename}</td>
                <td>{item.publishdate}</td>
                <td>{item.publishname}</td>
                <td>{item.create_at?.substring(0, 10)}</td>
                <td>{item.user_realname}</td>
                <td>
                  <button
                    onClick={() => {
                      setCurrentReport(item);
                      openDelete();
                    }}
                  >
                    删除
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>没有数据哦!!</td>
            </tr>
          )}
        </tbody>
      </table>

      <Pagination
        pageSize={pageSize}
        totalRecords={count}
        currentPage={currentPage}
        onPageChange={paginate}
        defaultPageNeighbours={2}
      />
      <span>{loading && "正在加载!!,请等待"}</span>
      <span>{msg}</span>

      {showDialogUpdate && (
        <Dialog
          aria-label="modal"
          isOpen={showDialogUpdate}
          onDismiss={closeUpdate}
        >
          <SendReportForm
            checked={checked}
            sampleItems={sendReportItems}
            changeUi={updateUi}
            isEdite={true}
          />
          {/* <SendReportUpdate initValues={currentSendReport} update={update} /> */}
          <hr />
          <button onClick={closeUpdate}>取消</button>
        </Dialog>
      )}

      {showDialogDelete && (
        <AlertDialog onDismiss={closeDelete} leastDestructiveRef={cancelRef}>
          <AlertDialogLabel style={{ textAlign: "center" }}>
            请再次确认是否删除下边委托单的发放记录?
          </AlertDialogLabel>
          <AlertDialogDescription>{`委托单号:JHWT-${
            currentSendReport.year
          }-${currentSendReport.trustnumber
            .toString()
            .padStart(4, "0")} 收件人:${
            currentSendReport.receiveperson
          }`}</AlertDialogDescription>
          <div className="alert-buttons">
            <button onClick={DeleteSendReport}>是, 删除</button>
            <hr />
            <button ref={cancelRef} onClick={closeDelete}>
              取消, 我不愿意
            </button>
          </div>
        </AlertDialog>
      )}
    </div>
  );
}
