import baseURL from "../Config";


export const fetchPriceListByArgumentpkg = async (argumentpkg_id) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/pkgprice/pricelist/${argumentpkg_id}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};


export const fetchAddPkgPrice = async (data) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/pkgprice/addprice`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body: JSON.stringify(data)
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchUpdatePkgPrice = async (data) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/pkgprice/updateprice/${data.id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body: JSON.stringify(data)
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchDeletePkgPrice = async (pkgprice_id) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/pkgprice/deleteprice/${pkgprice_id}`, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};

// 当前订单的价格(根据工程的价格版本)  套餐价
export const fetchPkgPriceByVer = async (argumentpkg_id,ver) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/pkgprice/getprice/${argumentpkg_id}/${ver}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};

