import React,{useState,useEffect} from 'react'


import {fetchExtraCostListByArgument,fetchAddExtraCost,fetchUpdateExtraCost,fetchDeleteExtraCost} from  "../apidata/fetchExtraCost.js"
import {fetchPriceVerList} from  "../apidata/fetchPriceVer.js"

import ExtraCostUpdate from "./ExtraCostUpdate"
import ExtraCostAdd from "./ExtraCostAdd"

export default function ExtraCost({argument_id}) {
    const [ExtraCostItems,setExtraCostItems]=useState();
    const [ExtraCostError,setExtraCostError]=useState();

    const [priceVerItems,setPriceVerItems]=useState();
    const [currentExtraCost,setCurrentExtraCost]=useState();

    const [isEdite,setIsEdite]=useState(false);
    const [msg,setMsg]=useState();


    useEffect(() => {
        if(!argument_id){
            return;
        }

         
        Promise.all([
            (async () => {
                try {             
                  const json = await fetchExtraCostListByArgument(argument_id);
                  setExtraCostItems(json);
                } catch (e) {
                  setExtraCostError(e.message || e.statusText);
                }
              })(),

              (async () => {
                try {             
                  const json = await fetchPriceVerList();
                  setPriceVerItems(json);
                } catch (e) {
                  setExtraCostError(e.message || e.statusText);
                }
              })()
                  
            ]
        )
        
    }, [argument_id])



    const addExtraCost = async data =>{       
        
        try {
            const json = await fetchAddExtraCost({...data,argument_id:argument_id});
            setMsg(json.msg);                    
            setExtraCostItems([json.data,...ExtraCostItems]);            
            setTimeout(() => {    
             setMsg(null);         
            }, 2000);
          } catch (error) {
            setMsg(error.message || error.statusText);
          }
      
    }

    const updateExtraCost = async data =>{       
        const newPrice={id:data.id,name:data.name,price:parseFloat(data.price),remark:data.remark}
        try {
            const json = await fetchUpdateExtraCost(newPrice);
            setMsg(json.msg);
            const newList = ExtraCostItems.map(c=>c.id===newPrice.id ? newPrice : c);
            setExtraCostItems(newList);
            setIsEdite(false);
            setTimeout(() => {    
             setMsg(null);         
            }, 2000);
          } catch (error) {
            setMsg(error.message || error.statusText);
          }
      
    }

    const deleteExtraCost = async item=>{
        try {
            const json = await fetchDeleteExtraCost(item.id);
            setMsg(json.msg);

            const newList = ExtraCostItems.filter(c=>c.id!==item.id );
            setExtraCostItems(newList);

            setIsEdite(false);
            
            setTimeout(() => {    
             setMsg(null);         
            }, 2000);
          } catch (error) {
            setMsg(error.message || error.statusText);
          }

        const newList = ExtraCostItems.filter(c=>c.id!==item.id );
        setExtraCostItems(newList);

    }

    return (
        <div>
            {ExtraCostError}
            {msg}
            <ol>
            {ExtraCostItems && priceVerItems && ExtraCostItems.map( (item,index)=>(
                <li key={index} >
                    名称:{item.name}--价格:{item.price}元--单位:{item.unit}--说明:{item.remark}
                    <button
                    onClick={() => {
                      setCurrentExtraCost(item);   
                      setIsEdite(true);                     
                    }}
                    className="btn btn-secondary"
                  >
                      修改
                  </button>

                  <button
                    onClick={() => {
                       deleteExtraCost(item);
                    }}
                    className="btn btn-secondary"
                  >
                      删除
                  </button>
                </li>
            ) )}
             </ol>
             { isEdite ? (
                <div style={{border:"2px red solid"}}>
                {isEdite && <ExtraCostUpdate extraCost={currentExtraCost}  update={updateExtraCost} />}
                {isEdite && <button onClick={(e)=>{
                    e.preventDefault();
                    setIsEdite(false);
    
                }} >取消修改</button>}
                </div>) :
                <ExtraCostAdd add={addExtraCost} />

             }
            
        </div>
    )
}




