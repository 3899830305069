import React,{useState,useEffect} from 'react'

export default function CustArgumentForm({setIsEdite,currentCustArgument,update}) {

    const [current,setCurrent]=useState(currentCustArgument);
    const [temp,setTemp]=useState(currentCustArgument.sorting)
   
    useEffect(() => {
        setCurrent(currentCustArgument); 
        setTemp(currentCustArgument.sorting);
       
    }, [currentCustArgument])

    // 一定要这样写
    useEffect(() => {
        if(!isNaN(parseInt(temp))){
        setCurrent(pre => {return {...pre, sorting:parseInt(temp)}}); 
        }         
    }, [temp])

    const handleChange =(e)=>{
        setTemp(e.target.value);        
    }

    return (
        <form onSubmit={event=>{
            event.preventDefault();
            // 提交这里修改item是不生效的,应写到useEffect,要注意
            if(isNaN(parseInt(temp))){
              return;
            }              
           
            console.log(current);           
            update(current.id,current);
            setIsEdite(false);
            }
        }
        >  
      
        <span>正在修改:{current.stdcode}{current.name}</span>
        <hr />  
		<label>排序:</label>        
		<input type="number" name="sorting" value={temp} onChange={handleChange} />
		<button type="submit">修改参数</button>
        <button type="reset" onClick={()=>{setIsEdite(false)}}>取消</button>
        </form>
    )
}
