import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import {
  fetchExtraModelUpdateName,
  fetchExtraModelExists
} from "../apidata/fetchExtraModelData";

export default function ExtraInfoModelNameUpdate({
  currentExtraInfo,
  updateUi
}) {
  const { register, setValue, handleSubmit, errors, reset } = useForm();

  // 修改
  useEffect(() => {
    setValue("name", currentExtraInfo.name);
  }, [currentExtraInfo, setValue]);

  const [msg, setMsg] = useState();

  const validateNameDb = async name => {
    if (name === currentExtraInfo.name) {
      return true;
    }
    try {
      console.log(currentExtraInfo);
      const has = await fetchExtraModelExists(
        currentExtraInfo.category_id,
        name
      );
      console.log(has);
      if (has) {
        return false || "已存在";
      } else {
        return true;
      }
    } catch (error) {
      setMsg("系统错误");
    }
  };
  const onSubmit = async data => {
    try {
      const json = await fetchExtraModelUpdateName(currentExtraInfo.id, data);
      updateUi(json);
    } catch (error) {
      setMsg(error.message);
    }
  };

  return (
    <div>
      <h3 style={{ textAlign: "center" }}> "模板名字修改" </h3>
      <div className="alert alert-primary" role="alert">
        {msg}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group row">
          <label htmlFor="name" className="col-sm-1 col-form-label">
            名字:
          </label>
          <div className="col-sm-5">
            <input
              name="name"
              ref={register({
                minLength: {
                  value: 3,
                  message: "不能小于3个字"
                },
                maxLength: {
                  value: 128,
                  message: "不能大于128个字"
                },
                validate: value => validateNameDb(value) //异步验证
              })}
              type="text"
              className="form-control"
              required
            />
          </div>
          <div className="col-sm-5">{errors.name && errors.name.message}</div>
        </div>

        <button
          type="submit"
          onClick={handleSubmit}
          className="btn btn-primary"
        >
          保存
        </button>
        <span> </span>
        <input
          type="reset"
          onClick={() => {
            reset();
          }}
          className="btn btn-primary"
        />
      </form>
    </div>
  );
}
