import React from 'react'
import { useParams, useLocation } from "react-router-dom";
import SampleForm from  "./SampleForm"

export default function SampleEdit() {
    const {operate,data}=useLocation();
 
    const { id } = useParams();  
    
    return (
        <>
            <SampleForm id={id} operate={operate} initValues={data} />
        </>
    )
}
