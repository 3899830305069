import baseURL from "../Config";

export const fetchArguments = async (category_Id) => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/argument/arguments/${category_Id}/poor`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      }
    );
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchArgumentsFromPkg = async (argumentPkg_id) => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/argumentpkg/custarguments/${argumentPkg_id}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      }
    );
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchStdcodeExists = async (stdcode) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/argument/existsstdcode`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token")),
      },
      body: JSON.stringify({ stdcode }),
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchNameExists = async (category_id, name) => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/argument/existsname/${category_id}`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
        body: JSON.stringify({ name }),
      }
    );

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};
