import React,{useState} from 'react'




function AddProductForm(props) {
    const initProduct ={id:null,name:"",price:0};
    const [product,setProduct]=useState(initProduct);


    const handleInputChange = e=>{
        const {name,value}=e.target;
        setProduct({...product,[name]:value})
    }
   



    return (
        <form onSubmit={event=>{
            event.preventDefault();
            if(!product.name || !product.price){
                return;
            }
            props.addProduct(product)
            setProduct(initProduct);
            }
        }
        >
        <label>参数名称:</label>
		<input type="text" name="name" value={product.name} onChange={handleInputChange} />
		<label>价格:</label>
		<input type="text" name="price" value={product.price} onChange={handleInputChange} />
		<button type="submit">增加参数</button>
        </form>
    )
}




export default  AddProductForm;
