import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { css } from "emotion";

import { fetchStdcodeExists, fetchNameExists } from "../apidata/fetchArgument";
import { fetchCategories } from "../apidata/fetchCategoryData";

const initValues = {
  stdcode: null,
  name: null,
  method: null,
  remark: null,
  sorting: 0,
  category: null,
};

function ArgumentForm({
  saveTodb,
  isEdite = false,
  fillValues = initValues,
}) {
  const {
    register,
    unregister,
    handleSubmit,
    errors,
    clearErrors,
    watch,
    setValue,
    reset,
  } = useForm({
    defaultValues: fillValues,
  });

  useEffect(() => {
    register({ name: "category" }, { required: "类目不能为空" });
    return () => unregister;
  }, [register, unregister]);

  const [msg, setMsg] = useState();

  // 读取类别
  const [categoryItem, setCategoryItems] = useState();
  const [categoryValues, setCategoryValues] = useState();

  useEffect(() => {
    const {
      stdcode,
      name,
      method,
      unit,
      remark,
      sorting,
      category,
    } = fillValues;

    if (isEdite) {
      setValue("stdcode", stdcode);
      setValue("name", name);
      setValue("method", method);
      setValue("unit", unit);
      setValue("remark", remark);
      setValue("sorting", sorting);
      setValue("category", category); // form 的值
      setCategoryValues(category); // 控件的值
    }
  }, [fillValues, setValue, isEdite]);

  useEffect(() => {
    (async () => {
      try {
        const json = await fetchCategories();
        setCategoryItems(json);
      } catch (e) {
        setMsg(e.message || e.statusText);
      }
    })();
  }, []);

  const handleMultiChange = (selectedOption) => {
    setCategoryValues(selectedOption); // 控件的值
    setValue("category", selectedOption); // form 的值
    setValue("stdcode", selectedOption.stdcode);
    return clearErrors("category");
  };

  //提交到父类保存
  const onSubmit = (data) => {
    if (categoryValues === null) {
      alert("没有选择到类目,请重新选择");
      return;
    }
    saveTodb(data);
  };

  const handleCancel = () => {
    reset({});
    setCategoryValues(null);
  };

  const validateStdcodeDb = async (value) => {
    if (isEdite && value === fillValues.stdcode) {
      return true;
    }
    try {
      const json = await fetchStdcodeExists(value);
      return !json || "已存在此编号的参数,有问题去修改就可以";
    } catch (error) {
      return false || "server api error";
    }
  };

  const validateNameDb = async (value) => {
    if (isEdite && value === fillValues.name) {
      return true;
    }
    if (categoryValues) {
      try {
        const json = await fetchNameExists(categoryValues.id, value);
        return !json || "已存在此名称的参数,有问题去修改就可以";
      } catch (error) {
        return false || "server api error";
      }
    } else {
      return false || "没有选择到类目";
    }
  };

  return (
    <>
      {isEdite ? (
        <h3 style={{ textAlign: "center" }}>检测参数(修改)</h3>
      ) : (
        <h3 style={{ textAlign: "center" }}>检测参数(新增)</h3>
      )}
      {msg}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group row">
          <label htmlFor="category" className="col-sm-2 col-form-label">
            类目
          </label>
          <div className="col-sm-10">
            <Select
              className={css`
                width: 50%;
                margin-left: -10%;
              `}
              name="categorySelect"
              placeholder="类目(公路GL,水运SY)"
              value={categoryValues}
              options={categoryItem}
              onChange={handleMultiChange}
              // react-select/issues/3018
              getOptionValue={(option) => option["id"]}
              getOptionLabel={(option) =>
                `${option.certificate === 0 ? "公路" : "水运"} | ${
                  option.stdcode
                } | ${option.name}`
              }
            />
            <span style={{ color: "red" }}>{errors.category?.message}</span>
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="stdcode" className="col-sm-1 col-form-label">
            参数代码:
          </label>
          <div className="col-sm-5">
            <input
              name="stdcode"
              ref={register({
                required: "不能为空",
                pattern: {
                  value: /[A-Za-z0-9]{8,9}/,
                  message: "必须为8位或9个字,前面4位为类别,自动生成",
                },
                validate: {
                  a: (value) => 8 <= value.length <= 9 || "位数必须为8/9位数",
                  b: async (value) => validateStdcodeDb(value), //异步验证
                },
              })}
              type="text"
              className="form-control"
              required
            />
          </div>
          <div className="col-sm-5">
            {errors.stdcode && errors.stdcode.message}
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="name" className="col-sm-1 col-form-label">
            参数名称
          </label>
          <div className="col-sm-5">
            <input
              name="name"
              ref={register({
                required: "不能为空",
                maxLength: {
                  value: 128,
                  message: "不能大于128个字",
                },
                validate: (value) => validateNameDb(value),
              })}
              type="text"
              className="form-control"
            />
          </div>
          <div className="col-sm-5">{errors.name && errors.name.message}</div>
        </div>

        <div className="form-group row">
          <label htmlFor="unit" className="col-sm-1 col-form-label">
            单位
          </label>
          <div className="col-sm-5">
            <input
              name="unit"
              ref={register({
                required: "不能为空",
                maxLength: {
                  value: 5,
                  message: "不能大于5个字",
                },
              })}
              type="text"
              className="form-control"
            />
          </div>
          <div className="col-sm-5">{errors.unit && errors.unit.message}</div>
        </div>

        <div className="form-group row">
          <label htmlFor="method" className="col-sm-1 col-form-label">
            方法名称:
          </label>
          <div className="col-sm-5">
            <input
              name="method"
              ref={register({
                maxLength: {
                  value: 32,
                  message: "不能大于32个字",
                },
              })}
              type="text"
              className="form-control"
            />
          </div>
          <div className="col-sm-5">
            {errors.method && errors.method.message}
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="remark" className="col-sm-1 col-form-label">
            备注
          </label>
          <div className="col-sm-5">
            <input
              name="remark"
              ref={register({
                maxLength: {
                  value: 64,
                  message: "不能大于64个字",
                },
              })}
              type="text"
              className="form-control"
            />
          </div>
          <div className="col-sm-5">
            {errors.remark && errors.remark.message}
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="sorting" className="col-sm-1 col-form-label">
            顺序号:
          </label>
          <div className="col-sm-5">
            <input
              name="sorting"
              ref={register({
                required: "不能为空",
                validate: {
                  positive: (value) => parseInt(value) >= 0 || "不能小于0",
                  lessThanTen: (value) =>
                    parseInt(value) < 100 || "不能大于100",
                },
              })}
              type="number"
              className="form-control"
              defaultValue="0"
            />
          </div>
          <div className="col-sm-5">
            {errors.sorting && errors.sorting.message}
          </div>
        </div>

        {/* <input type="submit" /> */}
        <button
          type="submit"
          onClick={handleSubmit}
          className="btn btn-primary"
        >
          保存
        </button>

        <button
          style={{ marginLeft: "50px" }}
          type="reset"
          onClick={handleCancel}
          className="btn btn-secondary"
        >
          清空
        </button>
      </form>
      <hr />
      <div>{watch("stdcode")}</div>
    </>
  );
}

export default ArgumentForm;
