import baseURL from "../Config";

// 按收样日期汇总金额
export const fetchQueryMountByProject = async (
  project_id,
  receiverpersondate
) => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/sample/querymountbyproject/${project_id}/${receiverpersondate}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      }
    );

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

// 按工程
export const fetchReceiveperonsdateByProject = async (project_id) => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/sample/receivepersondatebyproject/${project_id}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      }
    );

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

// add
export const fetchRepayAdd = async (data) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/repay/add`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token")),
      },
      body: JSON.stringify(data),
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

// update
export const fetchRepayUpdate = async (id, data) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/repay/update/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token")),
      },
      body: JSON.stringify(data),
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

// update callhistory
export const fetchRepayUpdateCallHistory = async (id, data) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/repay/updatecallhistory/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token")),
      },
      body: JSON.stringify(data),
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

// update status
export const fetchRepayUpdateStatus = async (id, data) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/repay/updatestatus/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token")),
      },
      body: JSON.stringify(data),
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

// delete
export const fetchRepayDelete = async (id) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/repay/deleterepay/${id}`, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token")),
      },
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else if (res.status === 403) {
      throw new Error("亲,你无权限删除");
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchRepayCount = async (customer_id) => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/repay/countbycustomer/${customer_id}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      }
    );

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchRepayList = async (customer_id, pagesize, page) => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/repay/querybycustomer/${customer_id}/${pagesize}/${page}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      }
    );

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};
