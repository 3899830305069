import React,{useState} from 'react'
import ProductTable from "./ProductTable"
import AddProductForm from "./AddProductForm"
import EditProductForm from "./EditProductForm"


export default function ProductApp() {
    //{id:1,name:"ab ",price:0}
    const productData=[];
    const [products,Setproducts]=useState(productData)
    const initialFormState = { id: null, name: '', price: '' }
    const [currentProduct,SetCurrentProduct]=useState(initialFormState)

    const [editing,setEditing]=useState(false);

    const addProduct= product=>{
        product.id=products.length+1;
        Setproducts([...products,product]);
    }

    const deleteProduct= id=>{
        setEditing(false);       
        Setproducts(products.filter(product=>product.id !==id));
    }


    const updateProduct= (id,updateproduct)=>{
        setEditing(false);       
        Setproducts(products.map(product=>product.id ===id? updateproduct:product));
    }

    const editRow = product =>{
        setEditing(true);
        //SetCurrentProduct({id:product.id,name:product.name,price:product.price})
        SetCurrentProduct(product)
        
    }





    return (
        <div className="container">
        <h1>CRUD App with Hooks</h1>
        <div className="flex-row">
            <div className="flex-large">
                {editing ? (
                    <>
                        <h2>修改参数</h2>
                        <EditProductForm
                            editing={editing}
                            setEditing={setEditing}
                            currentProduct={currentProduct}
                            updateProduct={updateProduct}
                        />
                    </>
                ) : (
                    <>
                        <h2>增加参数</h2>
                        <AddProductForm addProduct={addProduct} />
                    </>
                )}
            </div>
            <div className="flex-large">
                <h2>参数列表</h2>
                <ProductTable products={products} editRow={editRow} deleteProduct={deleteProduct} />
            </div>
        </div>
    </div>
    )
}
