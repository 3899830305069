import baseURL from "../Config";

export const fetchUserRealName = async id => {
    try {
      const res = await fetch(
        `${baseURL}/api/v1/user/sampleown/${id}`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
          }
        }
      );
      const json = await res.json();
      return json;
    } catch (error) {
      throw new Error(error);
    }
  };



  
export const fetchUserList= async (department_id) => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/user/list/${department_id}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        }
      }
    );
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};