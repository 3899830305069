import baseURL from "../Config";

// 近期新工程
export const fetchAnalysisNewProject = async (startdate) => {
    try {
      const res = await fetch(
        `${baseURL}/api/v1/analysis/newproject/${startdate}`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
          }
        }
      );
      if (res.ok) {
        const json = await res.json();
        return json;
      } else {
        throw new Error("请求异常!");
      }
    } catch (error) {
      throw new Error(error);
    }
  };


  // 导出文件 新工程信息Excel  数据分析用 
export const fetchProjectInfoExcel = async startdate => {
  try {
    const res = await fetch(`${baseURL}/sample/analysis/excel/${startdate}`, {
      method: "get",
      responseType: 'blob',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
    });    
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

  