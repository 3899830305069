import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { css } from "emotion";
import DatePicker, { registerLocale } from "react-datepicker";
import zhCN from "date-fns/locale/zh-CN";
import format from "date-fns/format";
import subMonths from "date-fns/subMonths";
import addDays from "date-fns/addDays";

import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";

import { fetchProjectNameAll } from "../apidata/fetchProjectNameData";
import {
  fetchQueryMountByProject,
  fetchReceiveperonsdateByProject,
} from "../apidata/fetchRepay";
import { fetchTrustAllInfo } from "../apidata/fetchTrustData";
import {
  fetchInvoiceByCustomer,
  fetchInvoiceAdd,
  fetchInvoiceUpdate,
} from "../apidata/fetchInvoice";

import InvoiceForm from "./InvoiceForm";

import { useAlert } from "react-alert";

export default function RepayForm({ isEdite, currentRepay, submitRepay }) {
  registerLocale("zhCN", zhCN); //日期中文化
  const alert = useAlert();
  const {
    register,
    unregister,
    handleSubmit,
    errors,
    setValue,
    clearErrors,
  } = useForm();

  useEffect(() => {
    register({ name: "customer_id" }, { required: "委托单位不能为空" });
    register({ name: "project_id" }, { required: "工程名称id不能为空" });
    register({ name: "projectname" }, { required: "工程名称不能为空" });
    register({ name: "invoice" });
    register({ name: "receivepersondate" });
    register({ name: "settlement" });
    register({ name: "paydate" });
    register({ name: "tofinancedate" });
    register({ name: "senddate" });
    setValue("receivepersondate", format(new Date(), "yyyy-MM-dd"));
    setValue("settlement", format(new Date(), "yyyy-MM-dd"));
    setValue("paydate", format(new Date(), "yyyy-MM-dd"));
    setValue("tofinancedate", format(new Date(), "yyyy-MM-dd"));
    setValue("senddate", format(new Date(), "yyyy-MM-dd"));
    return () => unregister;
  }, [register, unregister, setValue]);

  const [msg, setMsg] = useState();

  // 开票信息
  const [showDialogInvoice, setShowDialogInvoice] = useState(false);
  const openInvoice = () => setShowDialogInvoice(true);
  const closeInvoice = () => setShowDialogInvoice(false);

  const [receivepersondate, setReceivepersondate] = useState(new Date());
  const [settlement, setSettlement] = useState(new Date()); // 结算日期
  const [payDate, setPayDate] = useState(new Date());
  const [tofinanceDate, setTofinanceDate] = useState(new Date());
  const [sendDate, setSendDate] = useState(new Date());

  // 工程名称
  const [projectNameItems, setProjectNameItems] = useState([]); //db
  const [projectNameValues, setProjectNameValues] = useState();
  const [projectNameError, setProjectNameError] = useState();

  // 开票资料
  const [invoiceItems, setInvoiceItems] = useState([]); //db
  const [invoiceValues, setInvoiceValues] = useState();
  const [invoiceError, setInvoiceError] = useState();

  const [receivepersondateItems, setReceivepersondateItems] = useState([]); //db
  const [receiveperonsdateError, setReceiveperonsdateError] = useState();

  const [trustError, setTrustError] = useState();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValue("customer_id", currentRepay.customer_id);
    setValue("invoicetype", "0"); // 默认
    console.log(currentRepay);
    if (isEdite) {
      setValue("project_id", currentRepay.project_id);
      setValue("projectname", currentRepay.projectname);
      setProjectNameValues({
        id: currentRepay.project_id,
        name: currentRepay.projectname,
      });
      setValue("invoicetype", "0"); // 默认
      setValue("receivepersondate", currentRepay.receivepersondate);
      setReceivepersondate(Date.parse(currentRepay.receivepersondate));
      setValue("trustperson", currentRepay.trustperson);
      setValue("trustpersonphone", currentRepay.trustpersonphone);
      setValue("address", currentRepay.address);
      setValue("amount", currentRepay.amount);
      setValue("payamount", currentRepay.payamount);
      setValue("invoicenumber", currentRepay.invoicenumber);
      setValue("settlement", currentRepay.settlement);
      setSettlement(Date.parse(currentRepay.settlement));
      setValue("paydate", currentRepay.paydate);
      setPayDate(Date.parse(currentRepay.paydate));
      setValue("tofinancedate", currentRepay.tofinancedate);
      setTofinanceDate(Date.parse(currentRepay.tofinancedate));
      setValue("senddate", currentRepay.senddate);
      setSendDate(Date.parse(currentRepay.senddate));
      setValue("invoice", currentRepay.invoice);
      setInvoiceValues(currentRepay.invoice);
      setValue("invoicetype", currentRepay.invoicetype.toString());
      setValue("remark", currentRepay.remark);
    }
  }, [isEdite, currentRepay, setValue]);

  // 按委托单位取到对应的工程名称,对应的开票资料
  useEffect(() => {
    (async () => {
      try {
        const json = await fetchProjectNameAll(currentRepay.customer_id);
        setProjectNameItems(json);
      } catch (error) {
        setProjectNameError(error.message || error.statusText);
      }
    })();
    (async () => {
      try {
        const json = await fetchInvoiceByCustomer(currentRepay.customer_id);
        setInvoiceItems(json);
      } catch (error) {
        setProjectNameError(error.message || error.statusText);
      }
    })();
  }, [currentRepay]);

  // 工程名称 得出分组日期,工程相关信息
  useEffect(() => {
    if (projectNameValues) {
      (async () => {
        try {
          const json = await fetchReceiveperonsdateByProject(
            projectNameValues.id
          );
          setReceiveperonsdateError(null);
          setReceivepersondateItems(json);
        } catch (error) {
          setReceiveperonsdateError(error.message || error.statusText);
          setTimeout(() => {
            setReceiveperonsdateError(null);
          }, 3000);
        }

        try {
          const json = await fetchTrustAllInfo(projectNameValues.id);
          setTrustError(null);
          setValue("trustperson", json.trustperson);
          setValue("trustpersonphone", json.trustpersonphone);
          setValue("address", json.address);
        } catch (error) {
          setTrustError(error.message || error.statusText);
          setTimeout(() => {
            setTrustError(null);
          }, 3000);
        }
        clearErrors();
      })();
    }
  }, [projectNameValues, setValue, clearErrors]);

  // 查询工程名称
  const handleProjectNameChange = (e) => {
    setProjectNameValues(e);
    setValue("project_id", e.id);
    setValue("projectname", e.name);
    return setProjectNameError(null);
  };

  //
  const handleInvoiceChange = (e) => {
    setInvoiceValues(e);
    setValue("invoice", e);
    return setInvoiceError(null);
  };

  const queryByProject = async () => {
    if (!projectNameValues) {
      setMsg("没有选到工程");
      setTimeout(() => {
        setMsg(null);
      }, 6000);
      return;
    }
    try {
      const res = await fetchQueryMountByProject(
        projectNameValues.id,
        format(receivepersondate, "yyyy-MM-dd")
      );
      setValue("amount", res);
    } catch (error) {
      setMsg("结账失败" + error.message);
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    }
  };

  const [isEditeInvoice, setIsEditeInvoice] = useState(false);
  const [currentInvoice, setCurrentInvoice] = useState();

  const updateInvoice = async (data) => {
    setLoading(true);
    if (isEditeInvoice) {
      try {
        const res = await fetchInvoiceUpdate(invoiceValues.id, data);
        alert.success(res.msg);
        setInvoiceItems(
          invoiceItems.map((c) => (c.id === invoiceValues.id ? res.data : c))
        );
        setInvoiceValues(res.data);
        closeInvoice();
      } catch (error) {
        alert.error(error.message);
      }
    } else {
      try {
        const res = await fetchInvoiceAdd(data);
        alert.success(res.msg);
        setInvoiceItems([res.data, ...invoiceItems]);
        setInvoiceValues(res.data);
        closeInvoice();
      } catch (error) {
        alert.error(error.message);
      }
    }
    setLoading(false);
  };

  const submit = (data) => {
    submitRepay(data);
  };

  return (
    <>
      <div
        className={css`
          margin: 0;
          box-sizing: border-box;
        `}
      >
        <form onSubmit={handleSubmit(submit)}>
          <div
            className={css`
              margin-bottom: 10px;
            `}
          >
            <label>工程名称:</label>
            <Select
              className={css`
                width: 80%;
                height: atuo;
                display: inline-block;
              `}
              name="project"
              placeholder="请选输入委托单位后,再来选择工程名称"
              value={projectNameValues}
              options={projectNameItems}
              onChange={handleProjectNameChange}
              // react-select/issues/3018
              getOptionValue={(option) => option["id"]}
              getOptionLabel={(option) => option.name}
            />
            <div
              className={css`
                margin-top: 5px;
                margin-bottom: 10px;
              `}
            ></div>
            <span style={{ color: "red" }}>
              {projectNameError}
              {errors.project_id?.message}
              {errors.projectname?.message}
            </span>
          </div>

          <div>
            <div style={{ display: "inline-block" }}>
              <label>提取日期:</label>
              <DatePicker
                dateFormat="yyyy-MM-dd"
                selected={receivepersondate}
                onChange={(date) => {
                  setReceivepersondate(date);
                  setValue("receivepersondate", format(date, "yyyy-MM-dd"));
                }}
                minDate={subMonths(new Date(), 60)}
                maxDate={addDays(new Date(), 1)}
                locale="zhCN"
              />
              {errors.receivepersondate?.message}
            </div>
            <div style={{ display: "inline-block", marginLeft: "5px" }}>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  queryByProject();
                }}
              >
                提取所选日期的金额
              </button>
              <span>请按下边提示选结算日期</span>
              <span style={{ color: "red" }}>
                {receiveperonsdateError}
                {trustError}
              </span>
            </div>
          </div>
          <p>
            {receivepersondateItems &&
              receivepersondateItems.map((item, index) => (
                <span key={item.a}>
                  [{index + 1}].{item.a}&nbsp;&nbsp;
                </span>
              ))}
          </p>

          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="trustperson">委托人(或对方财务对接人)</label>
              <span style={{ color: "red" }}>
                {errors.trustperson?.message}
              </span>
              <input
                className="form-control form-control-sm"
                type="text"
                ref={register({
                  required: "不能为空",
                  maxLength: {
                    value: 16,
                    message: "不能大于16个字",
                  },
                })}
                name="trustperson"
              />
              {errors.trustperson?.message}
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="trustperosnphone">联系电话:</label>
              <span style={{ color: "red" }}>
                {errors.trustpersonphone?.message}
              </span>
              <input
                className="form-control form-control-sm"
                type="text"
                ref={register({
                  required: "不能为空",
                  maxLength: {
                    value: 13,
                    message: "不能大于13个字",
                  },
                })}
                name="trustpersonphone"
              />
              {errors.trustpersonphone?.message}
            </div>
            <div className="form-group col-md-3">
              <label>发票种类:</label>
              <span>{errors.invoicetype?.message}</span>
              <div>
                <input
                  type="radio"
                  ref={register({
                    required: "不能为空",
                  })}
                  name="invoicetype"
                  value="0"
                  id="one"
                />
                <label htmlFor="one">专用发票</label>
                <input
                  type="radio"
                  ref={register({
                    required: "不能为空",
                  })}
                  name="invoicetype"
                  value="1"
                  id="two"
                />
                <label htmlFor="two">普通发票</label>
                {errors.invoicetype?.message}
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label
              htmlFor="address"
              className="col-form-label"
              style={{ marginLeft: "10px" }}
            >
              发票寄送地址:
            </label>
            <div className="col-sm-10 ">
              <input
                type="text"
                className="form-control form-control-sm"
                ref={register({
                  maxLength: {
                    value: 128,
                    message: "不能大于128个字",
                  },
                })}
                placeholder="选填"
                name="address"
              />
              <span style={{ color: "red" }}>{errors.address?.message}</span>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="amount">结算金额:</label>
              <span style={{ color: "red" }}>{errors.amount?.message}</span>
              <input
                type="number"
                className="form-control form-control-sm"
                ref={register({
                  required: "不能为空",
                })}
                step="0.01"
                name="amount"
                defaultValue="0"
                min="0"
              />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="payamount">转帐金额:</label>
              <span style={{ color: "red" }}>{errors.payamount?.message}</span>
              <input
                type="number"
                className="form-control form-control-sm"
                ref={register({
                  required: "不能为空",
                })}
                step="0.01"
                name="payamount"
                defaultValue="0"
                min="0"
              />
            </div>

            <div className="form-group col-md-3">
              <label htmlFor="invoicenumber">发票号码:</label>
              <span style={{ color: "red" }}>
                {errors.invoicenumber?.message}
              </span>
              <input
                type="text"
                className="form-control form-control-sm"
                ref={register({
                  maxLength: {
                    value: 24,
                    message: "不能大于24个字",
                  },
                })}
                name="invoicenumber"
                placeholder="可以留空"
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="settlement">结算日期:</label>
              <span style={{ color: "red" }}>{errors.settlement?.message}</span>
              <div>
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={settlement}
                  onChange={(date) => {
                    setSettlement(date);
                    setValue("settlement", format(date, "yyyy-MM-dd"));
                  }}
                  minDate={subMonths(new Date(), 5)}
                  maxDate={addDays(new Date(), 60)}
                  locale="zhCN"
                />
              </div>
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="paydate">转帐日期:</label>
              <span style={{ color: "red" }}>{errors.paydate?.message}</span>
              <div>
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={payDate}
                  onChange={(date) => {
                    setPayDate(date);
                    setValue("paydate", format(date, "yyyy-MM-dd"));
                  }}
                  locale="zhCN"
                />
              </div>
              {errors.paydate?.message}
            </div>

            <div className="form-group col-md-3">
              <label htmlFor="tofinancedate">给财务日期:</label>
              <span style={{ color: "red" }}>
                {errors.tofinancedate?.message}
              </span>
              <div>
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={tofinanceDate}
                  onChange={(date) => {
                    setTofinanceDate(date);
                    setValue("tofinancedate", format(date, "yyyy-MM-dd"));
                  }}
                  locale="zhCN"
                />
              </div>
              {errors.tofinancedate?.message}
            </div>

            <div className="form-group col-md-3">
              <label htmlFor="senddate">发票寄送日期:</label>
              <span style={{ color: "red" }}>{errors.sendate?.message}</span>
              <div>
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={sendDate}
                  onChange={(date) => {
                    setSendDate(date);
                    setValue("senddate", format(date, "yyyy-MM-dd"));
                  }}
                  locale="zhCN"
                />
              </div>
            </div>
          </div>

          <div
            className={css`
              margin-bottom: 10px;
            `}
          >
            <label>开票资料:</label>
            <Select
              className={css`
                width: 70%;
                height: atuo;
                display: inline-block;
              `}
              name="invoice"
              placeholder="先选,选不到右边新增(此项可选填)"
              value={invoiceValues}
              options={invoiceItems}
              onChange={handleInvoiceChange}
              // react-select/issues/3018
              getOptionValue={(option) => option["id"]}
              getOptionLabel={(option) => option.name}
            />

            <div style={{ display: "inline-block", marginLeft: "5px" }}>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  if (currentRepay.customer_id) {
                    setIsEditeInvoice(false);
                    setCurrentInvoice({
                      customer_id: currentRepay.customer_id,
                    });
                    openInvoice();
                  }
                }}
              >
                新增
              </button>
            </div>
            <div style={{ display: "inline-block", marginLeft: "5px" }}>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  if (invoiceValues) {
                    setIsEditeInvoice(true);
                    setCurrentInvoice(invoiceValues);
                    openInvoice();
                  }
                }}
              >
                修改
              </button>
              <span style={{ color: "red" }}>
                {invoiceError}
                {errors.invoice?.message}
              </span>
            </div>
          </div>

          <div className="form-group row">
            <label
              htmlFor="remark"
              className="col-form-label"
              style={{ marginLeft: "10px" }}
            >
              备注:
            </label>
            <div className="col-sm-10 ">
              <input
                type="text"
                className="form-control form-control-sm"
                ref={register({
                  maxLength: {
                    value: 128,
                    message: "不能大于128个字",
                  },
                })}
                placeholder="选填"
                name="remark"
              />
              <span style={{ color: "red" }}>{errors.remark?.message}</span>
            </div>
          </div>

          <div>
            <button
              type="submit"
              disabled={loading}
              onClick={handleSubmit}
              className="btn btn-primary"
            >
              保存
            </button>
          </div>
        </form>
        {msg}
      </div>

      {showDialogInvoice && (
        <Dialog
          aria-label="modal"
          isOpen={showDialogInvoice}
          onDismiss={closeInvoice}
        >
          <p>开票信息</p>
          <InvoiceForm
            isEdite={isEditeInvoice}
            invoice={currentInvoice}
            update={updateInvoice}
          />
          <hr />
          <button onClick={closeInvoice}>取消</button>
        </Dialog>
      )}
    </>
  );
}
