import baseURL from "../Config";

// 用于按类目挂标准
export const fetchStandards = async category_id => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/standard/standards/${category_id}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        }
      }
    );
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};
// 根据isenable 过滤 用于开样品单
export const fetchStandardsFilter = async categoryValues => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/standard/standardsfilter/${categoryValues.id}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        }
      }
    );
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchStandardCount = async () => {
  try {
    const res = await fetch(`${baseURL}/api/v1/standard/count`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
    });

    if (res.ok) {
      const json = await res.json();
      return json.count;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

//  分页查找
export const fetchStandardItems = async (pageSize, currentPage) => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/standard/standardpage/${pageSize}/${currentPage}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        }
      }
    );

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchStandardAdd = async data => {
  try {
    const res = await fetch(`${baseURL}/api/v1/standard/add`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body: JSON.stringify(data)
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchStandardExists = async stdcode => {
  try {
    const res = await fetch(`${baseURL}/api/v1/standard/stdcode/exists`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body: JSON.stringify({ stdcode: stdcode })
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchStandardUpdate = async (id, data) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/standard/update/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body: JSON.stringify(data)
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchStandardUpdateIsenable = async id => {
  try {
    const res = await fetch(`${baseURL}/api/v1/standard/updateisenable/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchFindStandard = async query => {
  try {
    const res = await fetch(`${baseURL}/api/v1/standard/find`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body: JSON.stringify({ query: query })
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};
