import React from "react";
import NumberList from "./NumberList";

export default function OrderView({ order }) {
  if (!order) {
    return <p>无数据</p>;
  } else {
    return (
      <div>
        <ol>
          <li>工程名称:{order.projectname}</li>
          <li>委托单位:{order.delegrateunit_name}</li>

          <li>
            <span>委托编号:</span>
            {order.wtcode}
          </li>
          <li>
            <span>样品代号:</span>
            {order.samplecode}
          </li>
          <li>
            <span>样品名称:</span>
            {order.samplename}
          </li>
          <li>
            <span>样品描述</span>
            {order.sampleview}
          </li>
          <li>
            <span>样品编号:</span>
            {order.ypcode}
          </li>

          <li>
            <span>检测参数:</span>
            {order.categorysmalljson && (
              <NumberList lists={order.categorysmalljson} />
            )}
          </li>
          <li>
            <span>检测依据:</span>
            {order.standardjson && <NumberList lists={order.standardjson} />}
          </li>
          <li>
            <span>判定依据:</span>{" "}
            {order.decidejson && <NumberList lists={order.decidejson} />}
          </li>

          <li>
            <span>样品来源:</span>
            {order.samlesource}
          </li>

          <li>
            <span>送样人:</span>
            {order.sample_person}
          </li>
          <li>
            <span>送样人证号:</span>
            {order.sampleperson_certificatenumber}
          </li>

          <li>
            <span>见证单位:</span>
            {order.lookunit_name}
          </li>

          <li>
            <span>见证人:</span>
            {order.look_person}
          </li>

          <li>
            <span>见证人证号:</span>
            {order.look_certificatenumber}
          </li>
          <li>
            <span>工程部位:</span>
            {order.projectpart}
          </li>
          <li>
            <span>取样地址:</span>
            {order.sampleaddress}
          </li>

          <li>
            <span>样品数量</span>
            {order.num}
          </li>
          <li>
            <span>型号:</span>
            {order.mytype}
          </li>

          <li>
            <span>出厂型号:</span> {order.outfactorycode}
          </li>
          <li>
            <span>批量:</span> {order.lotnum}
          </li>
          <li>
            <span>出厂日期:</span> {order.make_date}
          </li>
          <li>
            <span>生产日期:</span> {order.make_factory}
          </li>
          <li>
            <span>委托日期:</span> {order.delegrate_date}
          </li>
          <li>
            <span>设计要求:</span> {order.designrequire}
          </li>
          <li>
            <span>龄期:</span> {order.agetime}
          </li>

          <li>
            <span>样品状态:</span> {order.samplestatus}
          </li>
          <li>
            <span>样品处置:</span> {order.sampledealwith}
          </li>
          <li>
            <span>文件:</span> {order.trustdoc}
          </li>
          <li>
            <span>测试种类:</span> {order.testkind}
          </li>
          <li>
            <span>备注:</span> {order.remark}
          </li>
          <li>
            <span>委托人:</span> {order.delegrate_person}
          </li>

          <li>
            <span>委托电话:</span> {order.delegrate_phone}
          </li>

          <li>
            <span>报告日期:</span> {order.reportdate}
          </li>

          <li>
            <span>总消费:</span> {order.totalcost}
          </li>
          <li>
            <span>支付方式:</span> {order.payment}
          </li>
          <li>
            <span>是否邮寄:</span> {order.sendway}
          </li>

          <li>
            <span>邮寄地址:</span> {order.address}
          </li>
          <li>
            <span>填单:</span>
            {order.username}
          </li>
          <li>
            <span>委托人签名日期</span> {order.delegrate_persondate}
          </li>
          <li>
            <span>收样人签名日期</span>
            {order.received_persondate}
          </li>
          <li>
            <span>监理单位:</span>
            {order.samplingunit}
          </li>
          <li>
            <span>监理人:</span>
            {order.sampling_person}
          </li>
          <li>
            <span>监理人证号:</span>
            {order.sampling_certificatenumber}
          </li>
          <li>
            <span>批号:</span>
            {order.batchnumber}
          </li>
        </ol>
      </div>
    );
  }
}
