import baseURL from "../Config";


// 停用
  export const fetchProjectnames = async (query) => {
    try {
      const res = await fetch(`${baseURL}/api/v1/order/projectnames`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        },
        body: JSON.stringify({"query":query})
      });
  
      if (res.ok) {
        const json = await res.json();
        return json;
      } else {
        throw new Error("server can't deal request!");
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  // 委托人数据查询
  export const fetchTrustPersons = async (query) => {
    try {
      const res = await fetch(`${baseURL}/api/v1/trust/trustperson`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        },
        body: JSON.stringify({"query":query})
      });
  
      if (res.ok) {
        const json = await res.json();
        return json;
      } else {
        throw new Error("server can't deal request!");
      }
    } catch (error) {
      throw new Error(error);
    }
  };


  export const fetchAddresses = async (customer_id) => {
    try {
      const res = await fetch(`${baseURL}/api/v1/trust/addresses/${customer_id}`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        }
      });
  
      if (res.ok) {
        const json = await res.json();
        return json;
      } else {
        throw new Error("server can't deal request!");
      }
    } catch (error) {
      throw new Error(error);
    }
  };


  // 一次性带出委托单位的信息
export const fetchTrustAllInfo = async  (project_id) => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/trust/trustbyproject/${project_id}`,{
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
    }
    );
    if(res.ok){
      const json = await res.json();
      return json;
    }
    else if(res.status===404){
      throw new Error("系统不存在此工程的委托单");
    }
    else{
      throw new Error("out of server");
    }
    
  } catch (error) {
    throw new Error(error);
  }
};

  

