import React, { useState, useEffect } from "react";
import {useForm} from "react-hook-form";
import {fetchStandardExists,fetchStandardAdd} from  "../apidata/fetchStandard"

export default function StandardAdd({addUi}) {
  const {
    register,
    unregister,  
    handleSubmit,
    errors,
    reset
  } = useForm();

  // register control component
  useEffect(() => {    
    return () => unregister;
  }, [register, unregister]);


  const [msg, setMsg] = useState(null);
  
  const validateStdcodeDb = async stdcode => {
    try {
      const has = await fetchStandardExists(stdcode.trim());
      if (has) {
        return false || "此标准已收录,请到按类目挂标准作关联就可以了!!!";
      } else {
        return true;
      }
    } catch (error) {
      setMsg("系统错误");
    }
  };

  const onSubmit =async data => {
    try {
      const json = await fetchStandardAdd({...data,"stdcode":data.stdcode.trim()});
      if (addUi){
         addUi(json);
      }     
      reset();     
    } catch (error) {
      setMsg(error.message);
    }
   
  };

  return (
    <>
      <h2 style={{textAlign:"center"}}>新增标准</h2>
      {msg && <h2>{msg}</h2>}
      <form onSubmit={handleSubmit(onSubmit)}> 
        <div className="form-group row">
          <label htmlFor="stdcode" className="col-sm-1 col-form-label">
            标准编码:
          </label>
          <div className="col-sm-6">
            <input
              name="stdcode"
              placeholder = "必填"
              ref={register({
                required: '不能为空',
                minLength: {
                  value: 1,
                  message: "不能小于1个字"
                },
                maxLength: {
                  value: 64,
                  message: "不能大于64个字"
                },
                validate: value => validateStdcodeDb(value) //异步验证
              })}
              type="text"
              className="form-control"              
            />      
            {errors.stdcode && errors.stdcode.message}
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="name" className="col-sm-1 col-form-label">
            名称:
          </label>
          <div className="col-sm-11">
            <input
              name="name"
              ref={register({              
                maxLength: {
                  value: 128,
                  message: "不能大于128个字"
                }
              })}
              type="text"
              className="form-control"
              placeholder = "可以为空,不填写"
            />
          </div>
          <div className="col-sm-5">{errors.name && errors.name.message}</div>
        </div>
        <hr />

        <button
          type="submit"
          onClick={handleSubmit}
          className="btn btn-primary"
        >
          保存
        </button>        
        <input
          style = {{marginLeft : "30px"}}
          type="reset"
          onClick={() => {
            reset();
          }}
          className="btn btn-primary"
        />
      </form>
    </>
  );
}

