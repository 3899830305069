import React, { useState, useEffect, useRef } from "react";
import Pagination from "../components/Pagination";
import {
  fetchStandardCount,
  fetchStandardItems
} from "../apidata/fetchStandard";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import {
  AlertDialog,
  AlertDialogLabel,
  AlertDialogDescription
} from "@reach/alert-dialog";

import StandardAdd from "./StandardAdd";
import StandardUpdate from "./StandardUpdate";
import StandardUpdateIsenable from "./StandardUpdateIsenable";

export default function StandardList() {
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState(null);
  const [currentStandard, setCurrentStandard] = useState();

  // 新增
  const [showDialogAdd, setShowDialogAdd] = useState(false);
  const openAdd = () => setShowDialogAdd(true);
  const closeAdd = () => {
    setShowDialogAdd(false);
  };

  // 修改
  const [showDialogUpdate, setShowDialogUpdate] = useState(false);
  const openUpdate = () => setShowDialogUpdate(true);
  const closeUpdate = () => {
    setShowDialogUpdate(false);
  };

  // 启用,禁用
  const [showDialogUpdateIsenable, setShowDialogUpdateIsenable] = useState(
    false
  );
  const openUpdateIsenable = () => setShowDialogUpdateIsenable(true);
  const closeUpdateIsenable = () => {
    setShowDialogUpdateIsenable(false);
  };

  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const cancelRef = useRef();
  const openDelete = () => setShowDialogDelete(true);
  const closeDelete = () => setShowDialogDelete(false);

  // 总数
  const [count, setCount] = useState(1);
  useEffect(() => {
    (async () => {
      try {
        const temp = await fetchStandardCount();
        setCount(temp);
      } catch (error) {
        setMsg(`系统异常,请联系开发公司.:${error.message || error.statusText}`);
      }
    })();
  }, []);
  //分页相关
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(15);
  // 标准列表
  const [standardItems, setStandardItems] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const temp = await fetchStandardItems(pageSize, currentPage);
        setStandardItems(temp);
        setLoading(false);
      } catch (error) {
        setMsg(`系统异常,请联系开发公司.:${error.message || error.statusText}`);
      }
    })();
  }, [pageSize, currentPage]);

  // Change page
  const paginate = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const addUi = json => {
    setMsg(json.msg);
    setStandardItems([json.data, ...standardItems]);
    closeAdd();
    setTimeout(() => {
      setMsg(null);
    }, 2000);
  };

  const updateUi = json => {
    setMsg(json.msg);
    setStandardItems(
      standardItems.map(c => (c.id === currentStandard.id ? json.data : c))
    );
    closeUpdate();
    closeUpdateIsenable();
    setTimeout(() => {
      setMsg(null);
    }, 2000);
  };

  return (
    <div>
      <h1 style={{ textAlign: "center" }}>标准维护</h1>
      <button onClick={openAdd}>新增</button>
      <table className="table table-sm table-hover">
        <thead className="thead-dark">
          <tr>
            <th>序号</th>
            <th>状态</th>
            <th>代码</th>
            <th>名称</th>
            <th>操作</th>
            <th>操作</th>
            <th>操作</th>
          </tr>
        </thead>

        <tbody>
          {standardItems ? (
            standardItems.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>
                  <span style={{ color: item.isenable ? "black" : "red" }}>
                  {item.isenable ? "正常" : "已停用"}
                  </span>
                </td>
                <td>{item.stdcode}</td>
                <td>{item.name ? item.name : "/"}</td>
                <td>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setCurrentStandard(item);
                      openUpdate();
                    }}
                  >
                    修改
                  </button>
                </td>

                <td>
                  <button
                    onClick={() => {
                      setCurrentStandard(item);
                      openUpdateIsenable();
                    }}
                    className="btn btn-secondary"
                    style={{
                      marginLeft: "20px",
                      color: item.isenable ? "black" : "red"
                    }}
                  >
                    {" "}
                    停用或启用
                  </button>
                </td>
                <td>
                  <button
                    onClick={() => {
                      setCurrentStandard(item);
                      openDelete();
                    }}
                    style={{ marginLeft: "20px" }}
                    className="btn btn-danger"
                  >
                    删除
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>没有数据哦!!</td>
            </tr>
          )}
        </tbody>
      </table>

      <Pagination
        pageSize={pageSize}
        totalRecords={count}
        currentPage={currentPage}
        onPageChange={paginate}
        defaultPageNeighbours={2}
      />
      <span>{loading && "正在加载!!,请等待"}</span>
      <span>{msg}</span>

      {showDialogAdd && (
        <Dialog aria-label="modal" isOpen={showDialogAdd} onDismiss={closeAdd}>
          <StandardAdd addUi={addUi} />
          <hr />
          <button onClick={closeAdd}>取消</button>
        </Dialog>
      )}

      {showDialogUpdate && (
        <Dialog
          aria-label="modal"
          isOpen={showDialogUpdate}
          onDismiss={closeUpdate}
        >
          <StandardUpdate
            updateUi={updateUi}
            currentStandard={currentStandard}
          />
          <hr />
          <button onClick={closeUpdate}>取消</button>
        </Dialog>
      )}

      {showDialogUpdateIsenable && (
        <Dialog
          aria-label="modal"
          isOpen={showDialogUpdateIsenable}
          onDismiss={closeUpdateIsenable}
        >
          <StandardUpdateIsenable
            updateUi={updateUi}
            currentStandard={currentStandard}
          />
          <hr />
          <button onClick={closeUpdateIsenable}>取消</button>
        </Dialog>
      )}

      {showDialogDelete && (
        <AlertDialog onDismiss={closeDelete} leastDestructiveRef={cancelRef}>
          <AlertDialogLabel style={{ textAlign: "center" }}>
            请再次确认是否删除?
          </AlertDialogLabel>
          <AlertDialogDescription>
            <h2>此功能暂不支持,敬请期待...</h2>
            <p>标准代码:{currentStandard.stdcode}</p>
            <p>标准名称:{currentStandard.name}</p>
          </AlertDialogDescription>
          <div className="alert-buttons">
            <button className="btn btn-danger" onClick={() => {}}>
              是, 删除
            </button>
            <button
              style={{ marginLeft: "20px" }}
              ref={cancelRef}
              onClick={closeDelete}
            >
              取消, 我不愿意
            </button>
          </div>
        </AlertDialog>
      )}
    </div>
  );
}
