import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { css } from "emotion";

import { fetchSampleCodeAll } from "../apidata/fetchSampleCodeData";

import { fetchPkgNameExists } from "../apidata/fetchArgumentPkgData.js";

export default function ArgumentPkgAddJh({ priceVerItems, add }) {
  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    errors,
    clearErrors,
  } = useForm();
  // 样品代码
  const [sampleCodeItems, setSmapleCodeItems] = useState([]); //db
  const [sampleCodeValues, setSampleCodeValues] = useState(null);
  const [sampleCodeError, setSampleCodeError] = useState(null);

  useEffect(() => {
    register({ name: "samplecode" }, { required: "没有选择样品代码?" });
    return () => unregister;
  }, [register, unregister, setValue]);

  // 加载样品代码
  useEffect(() => {
    (async () => {
      try {
        const json = await fetchSampleCodeAll();
        setSmapleCodeItems(json);
      } catch (e) {
        setSampleCodeError(e.message || e.statusText);
      }
    })();
  }, []);

  // 选择样品代码
  const handleChangeSampleCode = (selectedOption) => {
    setValue("samplecode", selectedOption); // form 的值
    setSampleCodeValues(selectedOption); // 控件的值
    return clearErrors("samplecode");
  };

  const validatePkgNameDb = async (value) => {
    if (sampleCodeValues) {
      try {
        const json = await fetchPkgNameExists(
          sampleCodeValues.category.id,
          value
        );
        return !json || "已存在";
      } catch (error) {
        alert("exist api error!");
      }
    } else {
      return false || "没有样品代码";
    }
  };

  const submit = (data) => {
    add(data);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(submit)}>
        <div className="form-group row">
          <label for="inputSampleCode" class="col-sm-2 col-form-label">
            样品代码:
          </label>

          <Select
            className={css`
              display: inlneblock;
              width: 70%;
              margin-left: 2%;
            `}
            name="samplecode"
            placeholder="样品代码"
            value={sampleCodeValues}
            options={sampleCodeItems}
            onChange={handleChangeSampleCode}
            // react-select/issues/3018
            getOptionValue={(option) => option["id"]}
            getOptionLabel={(option) => `${option.stdcode} | ${option.name}`}
          />
          <div style={{ display: "inlneblock", color: "red" }}>
            {sampleCodeError}
            {errors.samplecode && errors.samplecode.message}
          </div>
        </div>
        <div className="form-group row">
          <label for="inputName" class="col-sm-2 col-form-label">
            包名:
          </label>
          <div class="col-sm-10">
            <input
              id="inputName"
              className="form-control"
              ref={register({
                required: "不能为空",
                maxLength: {
                  value: 128,
                  message: "不能大于128个字",
                },
                validate: (value) => validatePkgNameDb(value), //异步验证
              })}
              name="name"
              type="text"
            />
            {errors.name?.message}
          </div>
        </div>
        <div className="form-group row">
          <label for="inputCheckall" class="col-sm-2 col-form-label">
            是否全选:
          </label>
          <div class="col-sm-1">
            <input
              id="inputChall"
              className="form-control"
              name="checkall"
              type="checkbox"
              ref={register}
            />
            {errors.checkall?.message}
          </div>
        </div>

        <div className="form-group row">
          <label for="inputSorting" class="col-sm-2 col-form-label">
            排序号:
          </label>
          <div class="col-sm-5">
            <input
              id="inputSorting"
              className="form-control"
              ref={register({
                required: "不能为空",
              })}
              name="sorting"
              type="number"
              defaultValue="0"
            />
            {errors.sorting?.message}
          </div>
        </div>

        <button
          type="submit"
          onClick={handleSubmit}
          className="btn btn-primary"
        >
          增加
        </button>
      </form>
    </div>
  );
}
