import React from 'react'

// 数组展示
export default function NumberList({lists}) {
    return (
        <ol>
       {lists.map((item) =>
         <li key={item.Code.toString()}>
           {item.Code}--{item.Name}
         </li>
       )}
     </ol>
    )
}



   