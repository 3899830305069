import React from "react";
import Login from "./components/Login";
import Home from "./Home";
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
export const AuthContext = React.createContext();

const options = {
  timeout: 5000,
  position: positions.TOP_CENTER
};

const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
  refreshtoken: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      sessionStorage.setItem("user", JSON.stringify(action.payload.user));
      sessionStorage.setItem("token", JSON.stringify(action.payload.token));
      sessionStorage.setItem(
        "refreshtoken",
        JSON.stringify(action.payload.refreshtoken)
      );     
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
        refreshtoken: action.payload.refreshtoken
      };
    case "LOGOUT":
      sessionStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    default:
      return state;
  }
};

function App() {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const token = JSON.parse(sessionStorage.getItem("token"));
    const refreshtoken = JSON.parse(sessionStorage.getItem("refreshtoken"));
    if (user && token) {
      dispatch({
        type: "LOGIN",
        payload: {
          user,
          token,
          refreshtoken
        }
      });
    }
  }, []);
  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      <Provider template={AlertTemplate} {...options}>
        <div>{!state.isAuthenticated ? <Login /> : <Home />}</div>
      </Provider>
    </AuthContext.Provider>
  );
}

export default App;
