import React, { useState, useEffect } from "react";
import { css } from "emotion";
import { useForm } from "react-hook-form";
import { Link, useParams, useLocation } from "react-router-dom";

import { sendways, sampledealwiths } from "../Enum";
// import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { fetchAddSendReport } from "../apidata/fetchSendReport";

import DatePicker, { registerLocale } from "react-datepicker";
import zhCN from "date-fns/locale/zh-CN";
import "../components/react-datepicker.min.css";
import format from "date-fns/format";

export default function SendReportAdd(props) {
  const { id } = useParams();
  const location = useLocation();
  const [msg, setMsg] = useState();
  const { data } = location;

  const {
    register,
    unregister,
    handleSubmit,
    watch,
    setValue,
    reset,
  } = useForm();

  registerLocale("zhCN", zhCN); //日期中文化
  const [labchargeDate,setLabChargeDate] = useState(new Date());
  const [publishDate,setPublishDate] = useState(new Date());

  useEffect(() => {
    setValue("receiveperson", data.trust.trustperson);
    setValue("phone", data.trust.trustpersonphone);
    setValue("address", data.trust.address);
    setValue("sendway", Boolean(data.trust.sendway));
    setValue("labchargedate", format(new Date(), "yyyy-MM-dd", new Date()));
    setValue("publishdate", format(new Date(), "yyyy-MM-dd", new Date()));
    register({ name: "sample_id" });
    setValue("sample_id", id);

    return () => unregister;
  }, [register, unregister, setValue, data, id]);

  const sendway = watch("sendway");



  const handleLabchargedate =(date)=>{
      setValue("labchargedate", format(date, "yyyy-MM-dd", new Date()));
      setLabChargeDate(date);
  }

  const handlePublishdate =(date)=>{
    setValue("publishdate", format(date, "yyyy-MM-dd", new Date()));
    setPublishDate(date);
}

  const submit = async (data) => {
    try {
      const json = await fetchAddSendReport(data);
      setMsg(json.msg);
      reset();
      setValue("receiveperson", "");
      setValue("phone", "");
      setValue("address", "");
      setValue("sendway", false);
      setValue("sample_id", id);
    } catch (error) {
      setMsg(error.message);
    }
  };

  return (
    <div
      className={css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: max-content;
        // grid-template-rows: 1fr 1fr 1fr 1fr; //一样高
        grid-gap: 5px;
        box-sizing: border-box;
      `}
    >
      <div
        className={css`
          grid-column: 1;
          grid-row: 1;
          border: 1px solid black;
          padding: 5px;
        `}
      >
        <h2>
          <Link to="/samplelist2">返回主查询</Link>
        </h2>
        <h2>
          <Link to="/">返回最近3天待发报告</Link>
        </h2>
        <h1>报告发放</h1>
        {msg}
        <form onSubmit={handleSubmit(submit)}>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label">接收人</label>
            <div className="col-sm-6">
              <input
                className="form-control"
                type="text"
                ref={register({
                  required: "不能为空",
                  maxLength: {
                    value: 20,
                    message: "不能大于20个字",
                  },
                })}
                name="receiveperson"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label">接收人电话</label>
            <div className="col-sm-6">
              <input
                className="form-control"
                type="text"
                ref={register({
                  required: "不能为空",
                  maxLength: {
                    value: 14,
                    message: "不能大于14个字",
                  },
                })}
                name="phone"
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="sendway" className="col-sm-2 col-form-label">
              是否邮寄
            </label>
            <div className="col-sm-1">
              <input
                className="form-control"
                name="sendway"
                type="checkbox"
                ref={register}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label">地址</label>
            <div className="col-sm-10">
              <input
                className="form-control"
                type="text"
                ref={register({
                  maxLength: {
                    value: 128,
                    message: "不能大于128个字",
                  },
                })}
                name="address"
                disabled={!sendway}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-2 col-form-label">快递公司</label>
            <div className="col-sm-6">
              <input
                className="form-control"
                type="text"
                ref={register({
                  maxLength: {
                    value: 10,
                    message: "不能大10个字",
                  },
                })}
                name="express"
                disabled={!sendway}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-2 col-form-label">运单号</label>
            <div className="col-sm-6">
              <input
                className="form-control"
                type="text"
                ref={register({
                  maxLength: {
                    value: 15,
                    message: "不能大于15个字",
                  },
                })}
                name="trackingnumber"
                disabled={!sendway}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-2 col-form-label">备注</label>
            <div className="col-sm-6">
              <input
                className="form-control"
                type="text"
                ref={register({
                  maxLength: {
                    value: 128,
                    message: "不能大于128个字",
                  },
                })}
                name="remark"             
              />
            </div>
          </div>

        
          
          <div className="form-group row">
            <label className="col-sm-2 col-form-label">提交人</label>
            <div className="col-sm-6">
              <input
                className="form-control"
                type="text"
                ref={register({
                  maxLength: {
                    value: 10,
                    message: "不能大于10个字",
                  },
                })}
                name="labchargename"             
              />
            </div>
            <label className="label">提交日期:</label>
              <DatePicker
                dateFormat="yyyy年MM月dd日"
                selected={labchargeDate}
                onChange={handleLabchargedate}             
                locale="zhCN"
              />
          </div>


          <div className="form-group row">
            <label className="col-sm-2 col-form-label">批准人</label>
            <div className="col-sm-6">
              <input
                className="form-control"
                type="text"
                ref={register({
                  maxLength: {
                    value: 10,
                    message: "不能大于10个字",
                  },
                })}
                name="publishname"             
              />
            </div>
            <label className="label">批准日期:</label>
              <DatePicker
                dateFormat="yyyy年MM月dd日"
                selected={publishDate}
                onChange={handlePublishdate}             
                locale="zhCN"
              />
          </div>


          <div className="col-sm-2">
            <input className="form-control" type="submit" />
            {msg}
          </div>
        </form>
      </div>

      <div
        className={css`
          grid-column: 2;
          grid-row: 1;
          border: 1px solid black;
          padding: 5px;
        `}
      >
        <p>
          委托单号:
          {`JHWT-${
            data.trust.year
          }-${data.trust.trustnumber.toString().padStart(4, "0")}`}
        </p>
        <p>委托单位:{data.trust.trustname}</p>
        <p>工程名称:{data.trust.projectname} </p>
        <p>委托人:{data.trust.trustperson}</p>
        <p>委托人电话:{data.trust.trustpersonphone}</p>
        <p>
          样品单号:
          {`JHWT-${data.samplecode}-${
            data.year
          }-${data.samplenumber.toString().padStart(4, "0")}`}
        </p>
        <p>样品名称:{data.samplenickname} </p>
        <p>报告日期:{format(parseISO(data.reportdate), "yyyy年MM月dd日")} </p>
        <p>委托日期:{format(parseISO(data.trustdate), "yyyy年MM月dd日")} </p>

        <p>样品处置:{sampledealwiths[data.trust.sampledealwith]}</p>

        <p>报告领取方式:{sendways[data.trust.sendway]}</p>
        <p>寄送地址:{data.trust.address} </p>
      </div>
    </div>
  );
}
