import baseURL from "../Config";


export const fetchCategories = async () => {
  try {
    const res = await fetch(`${baseURL}/api/v1/category/categories`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchSampleCodes = async categoryValues => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/samplecode/bycategory/${categoryValues.id}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        }
      }
    );
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchMaxId = async sampleCodeValues => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/sample/samplenumber/${sampleCodeValues.category.certificate}/${sampleCodeValues.stdcode}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        }
      }
    );
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};





export const fetchStandardsPart = async argumentIdList => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/argumentlinkstandard/standards/filter`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        },
        body:JSON.stringify({argument_id_list:argumentIdList})
      }
    );
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};




// xxxx
export const fetchTrustNumber = async  trustNumber => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/sample/existstrustnumber/${trustNumber}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        }
      }
    );
    const json = await res.json();
    return json.result;
  } catch (error) {
    throw new Error(error);
  }
};



// xxxx
export const fetchSampleNumber = async (samplecode, sampleNumber) => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/sample/existssamplenumber/${samplecode}/${sampleNumber}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        }
      }
    );
    const json = await res.json();
    return json.result;
  } catch (error) {
    throw new Error(error);
  }
};
// 分页查找
export const fetchSamplesPart = async (pageSize, currentPage) => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/sample/samplespart?pagesize=${pageSize}&page=${currentPage}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        }
      }
    );
    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("请求异常!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

// 各种分页查找 计数
export const fetchSampleQueryCount = async (kind,data) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/sample/querycount/${kind}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body:JSON.stringify(data)
    });

    if (res.ok) {
      const json = await res.json();
      return json.count;
    } else {
      throw new Error("请求异常!");
    }
  } catch (error) {
    throw new Error(error);
  }
};
// 各种分页查找
export const fetchSamplesQuery = async (kind, data) => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/sample/query/${kind}`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        },
        body:JSON.stringify(data)

      }
    );
    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("请求异常!");
    }
  } catch (error) {
    throw new Error(error);
  }
};


export const fetchSampleCount = async () => {
  try {
    const res = await fetch(`${baseURL}/api/v1/sample/count`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
    });

    if (res.ok) {
      const json = await res.json();
      return json.count;
    } else {
      throw new Error("请求异常!");
    }
  } catch (error) {
    throw new Error(error);
  }
};


export const fetchSampleAdd = async (data) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/sample/sample/add`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body: JSON.stringify(data)
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    }else if(res.status===403){
      throw new Error("亲,你的登录过期或者没有权限,请在浏览器再打开一个Tab,退出后重新登录再尝试提交.");
    }else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchSampleEdite = async (id,data) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/sample/sample/edite/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body: JSON.stringify(data)
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else if(res.status===403){
      throw new Error("亲,你的登录过期或者没有权限,请在浏览器再打开一个Tab,退出后重新登录再尝试提交.");
    }else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};


export const fetchSampleStatusChange = async (id,status) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/sample/changestatus/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },  
      body: JSON.stringify({status})      
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!"+res.statusText);
    }
  } catch (error) {
    throw new Error(error);
  }
};





// 工程部位
export const fetchProjectpart = async (project_id) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/sample/projectpart/${project_id}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};


// 取样地址
export const fetchSampleaddress = async (project_id) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/sample/sampleaddress/${project_id}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

// 样品呢称
export const fetchSamplenickname = async (category_id) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/sample/samplenickname/${category_id}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

// 样品描述
export const fetchSamplestatus = async (samplenickname)=>{
  try {
    const res = await fetch(`${baseURL}/api/v1/sample/samplestatus/${samplenickname}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};



// 委托文档
export const fetchTrustdoc = async (category_id) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/sample/trustdoc/${category_id}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};


export const fetchDesignrequire = async (category_id) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/sample/designrequire/${category_id}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchNumberofsample = async (category_id) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/sample/numberofsample/${category_id}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchModelnumber = async (category_id) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/sample/modelnumber/${category_id}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};


// 增加空单,占座,留号
export const fetchSampleBlankAdd = async (data) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/sample/sampleblank/add`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body: JSON.stringify(data)
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};


//  filter by reportdate count
export const fetchSampleFilterReportCount = async () => {
  try {
    const res = await fetch(`${baseURL}/api/v1/sample/filterReportCount`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
    });

    if (res.ok) {
      const json = await res.json();
      return json.count;
    } else {
      throw new Error("请求异常!");
    }
  } catch (error) {
    throw new Error(error);
  }
};


// 分页filter by reportdate
export const fetchSamplesfilterReport = async (pageSize, currentPage) => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/sample/filterReport?pagesize=${pageSize}&page=${currentPage}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        }
      }
    );
    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("请求异常!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

// 导出文件 对帐单--按日期区间  
export const fetchSamplePaymentExcel = async (start,end,asc,certificate) => {
  try {
    const res = await fetch(`${baseURL}/sample/exportpayment/${start}/${end}/${asc}/${certificate}`, {
      method: "get",
      responseType: 'blob',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
    });    
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

// 导出文件 对帐单--按日期区间+委托人 
export const fetchSamplePaymentByTrustPersonExcel = async (trustperson,start,end,asc) => {
  try {
    const res = await fetch(`${baseURL}/sample/exportpaymentbytrustperson/${start}/${end}/${asc}`, {
      method: "post",
      responseType: 'blob',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body: JSON.stringify({"trustperson":trustperson})
    });    
    return res;
  } catch (error) {
    throw new Error(error);
  }
};


// 导出文件 任务单 
export const fetchRwExcel = async id => {
  try {
    const res = await fetch(`${baseURL}/sample/exportrw/${id}`, {
      method: "get",
      responseType: 'blob',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
    });    
    return res;
  } catch (error) {
    throw new Error(error);
  }
};


// 导出文件 委托单Excel 
export const fetchSampleExcel = async id => {
  try {
    const res = await fetch(`${baseURL}/sample/excel/${id}`, {
      method: "get",
      responseType: 'blob',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
    });    
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

// 导出文件 价格清单Excel 
export const fetchPriceExcel = async id => {
  try {
    const res = await fetch(`${baseURL}/sample/exportprice/${id}`, {
      method: "get",
      responseType: 'blob',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
    });    
    return res;
  } catch (error) {
    throw new Error(error);
  }
};



// 导出文件 委托单pdf
export const fetchSamplePdf = async id => {
  try {
    const res = await fetch(`${baseURL}/sample/pdf/${id}`, {
      method: "get",
      responseType: 'blob',
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
    });    
    return res;
  } catch (error) {
    throw new Error(error);
  }
};




// 改样品单号,取到旧时应有的号吗
export const fetchOldSampleNumber = async (certificate,samplecode, sample_id) => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/sample/onlysamplecode/${certificate}/${samplecode}/${sample_id}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        }
      }
    );
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};

// 改样品单号
export const fetchOnlysamplecodeUpdate = async (id,data) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/sample/onlysamplecode/update/${id}`,
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        },
        body: JSON.stringify(data)
      }
    );
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};


export const fetchLastSample = async () => {
  try {
    const res = await fetch(`${baseURL}/api/v1/sample/lastsample`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};




export const fetchDeleteSample = async (id) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/sample/deletesample/${id}`, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
      
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    }else if(res.status===403){
      throw new Error("亲,你没有权限,请联系你的分管主管或系统管理员来处理.");
    } else {
      throw new Error("server can't deal request!"+res.statusText);
    }
  } catch (error) {
    throw new Error(error);
  }
};




