import baseURL from "../Config";


export const fetchExtraCostListByArgument = async (argument_id) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/extracost/extracostlist/${argument_id}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};


export const fetchAddExtraCost = async (data) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/extracost/addextracost`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body: JSON.stringify(data)
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchUpdateExtraCost = async (data) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/extracost/updateextracost/${data.id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body: JSON.stringify(data)
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchDeleteExtraCost = async (extracost_id) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/extracost/deleteprice/${extracost_id}`, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};

// 当前订单所选参数的 附加费用
export const fetchExtracostByArgument = async data => {
  try {
    const res = await fetch(`${baseURL}/api/v1/extracost/extracostbyargument`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body: JSON.stringify(data)
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};



