import baseURL from "../Config";



// 开票列表
export const fetchInvoiceByCustomer = async (customer_id) => {
    try {
      const res = await fetch(`${baseURL}/api/v1/invoice/invoicelist/${customer_id}`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        }
      });
  
      if (res.ok) {
        const json = await res.json();
        return json;
      } else {
        throw new Error("server can't deal request!");
      }
    } catch (error) {
      throw new Error(error);
    }
  };


// add
export const fetchInvoiceAdd = async (data) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/invoice/addinvoice`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body:JSON.stringify(data)
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

 // update
 export const fetchInvoiceUpdate = async (id,data) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/invoice/updateinvoice/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body:JSON.stringify(data)
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

  
  