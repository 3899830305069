import baseURL from "../Config";


export const fetchCustomerName = async  name => {
    try {
      const res = await fetch(
        `${baseURL}/api/v1/customer/exists`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
          },
          body: JSON.stringify({"name":name})
        }

      );
      const json = await res.json();
      return json.result;
    } catch (error) {
      throw new Error(error);
    }
  };


  export const fetchCustomerAdd = async (data) => {
    try {
      const res = await fetch(`${baseURL}/api/v1/customer/customers`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        },
        body: JSON.stringify(data)
      });
  
      if (res.ok) {
        const json = await res.json();
        return json;
      } else {
        throw new Error("server can't deal request!");
      }
    } catch (error) {
      throw new Error(error);
    }
  };


  export const fetchCustomerEdite = async (id,data) => {
    try {
      const res = await fetch(`${baseURL}/api/v1/customer/customer/${id}`, {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        },
        body: JSON.stringify(data)
      });
  
      if (res.ok) {
        const json = await res.json();
        return json;
      } else {
        throw new Error("server can't deal request!");
      }
    } catch (error) {
      throw new Error(error);
    }
  };


  export const fetchCustomers = async (query) => {
    try {
      const res = await fetch(`${baseURL}/api/v1/customer/find`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        },
        body: JSON.stringify({"query":query})
      });
  
      if (res.ok) {
        const json = await res.json();
        return json;
      } else {
        throw new Error("server can't deal request!");
      }
    } catch (error) {
      throw new Error(error);
    }
  };

 //  分页查找
  export const fetchCustomerItems = async (pageSize, currentPage) => {
    try {
      const res = await fetch(`${baseURL}/api/v1/customer/customers?pagesize=${pageSize}&page=${currentPage}`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        }
        
      });
  
      if (res.ok) {
        const json = await res.json();
        return json;
      } else {
        throw new Error("server can't deal request!");
      }
    } catch (error) {
      throw new Error(error);
    }
  };


  export const fetchCustomerCount = async () => {
    try {
      const res = await fetch(`${baseURL}/api/v1/customer/count`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        }       
      });
  
      if (res.ok) {
        const json = await res.json();
        return json.count;
      } else {
        throw new Error("server can't deal request!");
      }
    } catch (error) {
      throw new Error(error);
    }
  };



