import React, { useState, useEffect } from "react";
import SampleCodeForm from "./components/SampleCodeForm";
import Pagination from "./components/Pagination";
import baseURL from "./Config";

import Modal from "./components/Modal";
import useToggle from "./components/Toggle";

function getCount() {
  const fetchData = async () => {
    const res = await fetch(`${baseURL}/api/v1/samplecode/count`,{
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
    });
    const res1 = await res.json();
    return res1;
  };

  return fetchData().count;
}

export default function SampleCode() {
  const [loading, setLoading] = useState(false);

  const [msg, setMsg] = useState(null);

  // 样品列表
  const [items, setItems] = useState(null);

  // 分页相关
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);

  // 修改
  const [currentSample, setCurrentSample] = useState();
  const [isEdite, setIsEdite] = useState(false);

  // 反馈完成,指示清空
  const [complete, setComplete] = useState(false);

  // 总数
  const [count, setCount] = useState(() => getCount());
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(`${baseURL}/api/v1/samplecode/count`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        }
      });
      const res1 = await res.json();
      setCount(res1.count);
    };

    fetchData();
  }, []);

  // 分页取数据
  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      const res = await fetch(
        `${baseURL}/api/v1/samplecode/samplecodes?pagesize=${pageSize}&page=${currentPage}`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
          }
        }
      );

      const result = await res.json();
      setItems(result);
      setLoading(false);

      // else{
      //   const error=res.error;
      //   setMsg(error.message || error.statusText);
      // }
    };
    fetchPosts().catch(setMsg);
  }, [pageSize, currentPage, complete]);

  // Change page
  const paginate = pageNumber => {
    setCurrentPage(pageNumber);
  };

  //取得要修改的item
  const editRow = item => {
    setCurrentSample(item);
    setIsEdite(true);
  };
  // 保存,持久
  const saveTodb = data => {
    if (isEdite) {
      UpdateSample(data);
    } else {
      addSample(data);
    }
    setTimeout(() => {
      setMsg(null);
    }, 5000);
  };

  // 打开确认对话框删除
  const [open, setOpen] = useToggle(false);
  const deleteItem = item => {
    setCurrentSample(item);
    setOpen(true);
  };

  const handleCancel = () => {
    setIsEdite(false);
  };

  return (
    <>
      <div>
        {open && (
          <Modal open={open} toggle={setOpen}>
            <h1>请再三确认哦???</h1>
        <div> {currentSample.stdcode}--{currentSample.name}</div>
            <form onSubmit={e => e.preventDefault()}>
              {/* 实删 */}
              <button
                type="submit"
                onClick={() => {
                  deleteSample(currentSample);
                  setOpen(false);
                  setIsEdite(false);
                }}
                className="btn btn-danger"
              >
                确定要删除
              </button>
              <button type="reset" onClick={setOpen} className="btn btn-info" >
                取消
              </button>
            </form>
          </Modal>
        )}
      </div>
      <SampleCodeForm
        saveTodb={saveTodb}
        complete={complete}
        isEdite={isEdite}
        // 不是修改,就不要传props,重要
        fillValues={isEdite && currentSample}
      />
      {/* 操作提示区 */}
      {msg && (
        <div className="alert alert-primary" role="alert">
          {msg}
        </div>
      )}
      <button type="reset" onClick={handleCancel} className="btn btn-secondary">
        取消修改
      </button>

      {/* 数据列表区 */}
      <table className="table table-sm table-hover">
        <thead className="thead-dark">
          <tr>
            <th>序号</th>
            <th>大类别</th>
            <th>类别</th>
            <th>类别代码</th>
            <th>样品代码</th>
            <th>样品名称</th>
            <th>备注</th>
            <th>操作</th>
            <th>操作</th>
          </tr>
        </thead>

        <tbody>
          {items ? (
            items.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item.category.certificate===0 ? "公路" : "水运"}</td>
                <td>{item.category.name}</td>
                <td>{item.category.stdcode}</td>
                <td>{item.stdcode}</td>
                <td>{item.name}</td>
                <td>{item.remark}</td>
                <td>
                  <button
                    onClick={() => {
                      editRow(item);
                    }}
                    className="btn btn-secondary"
                  >
                    修改
                  </button>
                </td>
                <td>
                  <button
                    onClick={() => {
                      deleteItem(item);
                    }}
                    className="btn btn-danger"
                  >
                    删除
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>没有数据哦!!</td>
            </tr>
          )}
        </tbody>
      </table>

      <Pagination
        pageSize={pageSize}
        totalRecords={count}
        currentPage={currentPage}
        onPageChange={paginate}
        defaultPageNeighbours={2}
      />
      <span>{loading && "正在加载!!,请等待"}</span>
      <div>{count}</div>
    </>
  );

  function deleteSample(item) {
    fetch(`${baseURL}/api/v1/samplecode/samplecode/${item.id}`, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body: null
    })
      .then(res => {
        if (res.status === 200) {
          setMsg("删除成功");
        }
      })
      .catch(error => {
        setMsg(error.message || error.statusText);
      });
    // 处理界面
    setIsEdite(false);
    setItems(items.filter(i => i.id !== item.id));
  }

  function addSample(data) {
    fetch(`${baseURL}/api/v1/samplecode/samplecodes`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body: JSON.stringify(data)
    })
      .then(res => {
        if (res.ok) {
          console.log(res.status);
          return res.json();
        }
        throw res;
      })
      .then(res => {
        setMsg("新增成功");
        setComplete(!complete);
      })
      .catch(error => {
        setMsg(error.message || error.statusText);
      });
  }

  function UpdateSample(data) {
    fetch(`${baseURL}/api/v1/samplecode/samplecode/${currentSample.id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body: JSON.stringify(data)
    })
      .then(res => {
        if (res.ok) {
          console.log(res.status);
          setComplete(!complete);
          setIsEdite(false);
          return res.json();
        }
        throw res;
      })
      .then(res => {
        setMsg("修改成功");
      })
      .catch(error => {
        setMsg(error.message || error.statusText);
      });
  }
}



