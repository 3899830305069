import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  fetchCustomerName,
  fetchCustomerAdd,
  fetchCustomerEdite,
} from "../apidata/fetchCustomerData";

export default function CustomerForm({
  currentCustomer,
  isEdite = false,
  changeUi,
}) {
  const { register, setValue, errors, handleSubmit, reset } = useForm();

  // 修改
  useEffect(() => {
    if (isEdite) {
      setValue("name", currentCustomer.name);
      setValue("pinyin", currentCustomer.pinyin);
    }
  }, [isEdite, currentCustomer, setValue]);

  const [msg, setMsg] = useState();
  const validateStdcodeDb = async (name) => {
    if (isEdite && name === currentCustomer.name) {
      return true;
    }
    try {
      const has = await fetchCustomerName(name);
      if (has) {
        return false || "已存在";
      } else {
        return true;
      }
    } catch (error) {
      setMsg("系统错误");
    }
  };
  const onSubmit = async (data) => {
    if (isEdite) {
      if (
        currentCustomer.pinyin === data.pinyin &&
        currentCustomer.name === data.name
      ) {
        setMsg("信息没有更改,无需更改请返回");
        setTimeout(() => {
          setMsg(null);
        }, 2000);
        return;
      }
      try {
        const json = await fetchCustomerEdite(currentCustomer.id, data);
        changeUi(json);
      } catch (error) {
        setMsg(error.message);
      }
    } else {
      try {
        const json = await fetchCustomerAdd(data);
        setMsg(json.msg);
        reset();
        changeUi(json);
      } catch (error) {
        setMsg(error.message);
      }
    }
  };

  return (
    <div>
      <h3 style={{ textAlign: "center" }}>
        {isEdite ? "修改委托单位" : "新增委托单位"}
      </h3>
      {isEdite && (
        <h4 style={{ textAlign: "center", color: "red" }}>
          修改单位后,请忽忘修改该单位的委托单!
        </h4>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group row">
          <label htmlFor="pinyin" className="col-sm-1 col-form-label">
            拼音:
          </label>
          <div className="col-sm-5">
            <input
              name="pinyin"
              ref={register({
                minLength: {
                  value: 2,
                  message: "不能小于2个字",
                },
                maxLength: {
                  value: 10,
                  message: "不能大于10个字",
                },
              })}
              type="text"
              className="form-control"
              placeholder="pinyin"
              required
            />
          </div>
          <div className="col-sm-5">
            {errors.pinyin && errors.pinyin.message}
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="name" className="col-sm-1 col-form-label">
            名字:
          </label>
          <div className="col-sm-11">
            <input
              name="name"
              ref={register({
                minLength: {
                  value: 1,
                  message: "不能小于1个字",
                },
                maxLength: {
                  value: 128,
                  message: "不能大于128个字",
                },
                validate: (value) => validateStdcodeDb(value), //异步验证
              })}
              type="text"
              className="form-control"
              required
            />
          </div>
          <div className="col-sm-5">{errors.name && errors.name.message}</div>
        </div>
        {msg}
        <hr />
        <button
          type="submit"
          onClick={handleSubmit}
          className="btn btn-primary"
        >
          保存
        </button>
        <input
          style={{
            marginLeft: "80px",
          }}
          type="reset"
          onClick={() => {
            reset();
          }}
          className="btn btn-primary"
        />
      </form>
    </div>
  );
}
