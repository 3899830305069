import React,{useState,useEffect} from 'react'


import {fetchPriceListByArgumentpkg,fetchAddPkgPrice,fetchUpdatePkgPrice,fetchDeletePkgPrice} from  "../apidata/fetchPkgPrice"
import {fetchPriceVerList} from  "../apidata/fetchPriceVer.js"

import PkgPriceUpdate from "./PkgPriceUpdate"
import PkgPriceAdd from "./PkgPriceAdd"

export default function PkgPrice({argumentpkg_id}) {
    const [priceItems,setPriceItems]=useState();
    const [priceError,setPriceError]=useState();

    const [priceVerItems,setPriceVerItems]=useState();
    const [currentPkgPrice,setCurrentPkgPrice]=useState();

    const [isEdite,setIsEdite]=useState(false);
    const [msg,setMsg]=useState();


    useEffect(() => {
        if(!argumentpkg_id){
            return;
        }

         
        Promise.all([
            (async () => {
                try {             
                  const json = await fetchPriceListByArgumentpkg(argumentpkg_id);
                  setPriceItems(json);
                } catch (e) {
                  setPriceError(e.message || e.statusText);
                }
              })(),

              (async () => {
                try {             
                  const json = await fetchPriceVerList();
                  setPriceVerItems(json);
                } catch (e) {
                  setPriceError(e.message || e.statusText);
                }
              })()
                  
            ]
        )
        
    }, [argumentpkg_id])



    const addPrice = async data =>{       
        const newPrice={id:data.id,ver:parseInt(data.ver),price:parseFloat(data.price),argumentpkg_id:argumentpkg_id}
        try {
            const json = await fetchAddPkgPrice(newPrice);
            setMsg(json.msg);                    
            setPriceItems([json.data,...priceItems]);            
            setTimeout(() => {    
             setMsg(null);         
            }, 2000);
          } catch (error) {
            setMsg(error.message || error.statusText);
          }
      
    }

    const updatePrice = async data =>{       
        const newPrice={id:data.id,ver:parseInt(data.ver),price:parseFloat(data.price)}
        try {
            const json = await fetchUpdatePkgPrice(newPrice);
            setMsg(json.msg);
            const newList = priceItems.map(c=>c.id===newPrice.id ? newPrice : c);
            setPriceItems(newList);
            setIsEdite(false);
            setTimeout(() => {    
             setMsg(null);         
            }, 2000);
          } catch (error) {
            setMsg(error.message || error.statusText);
          }
      
    }

    const deletePrice = async item=>{
        try {
            const json = await fetchDeletePkgPrice(item.id);
            setMsg(json.msg);

            const newList = priceItems.filter(c=>c.id!==item.id );
            setPriceItems(newList);

            setIsEdite(false);
            
            setTimeout(() => {    
             setMsg(null);         
            }, 2000);
          } catch (error) {
            setMsg(error.message || error.statusText);
          }

        const newList = priceItems.filter(c=>c.id!==item.id );
        setPriceItems(newList);

    }

    return (
        <div>
            {priceError}
            {msg}
            <ol>
            {priceItems && priceVerItems && priceItems.map( (item,index)=>(
                <li key={index} >
                    {item.price}元--({priceVerItems.find( ({code})=>code===item.ver ).name })
                    <button
                    onClick={() => {
                      setCurrentPkgPrice(item);   
                      setIsEdite(true);                     
                    }}
                    className="btn btn-secondary"
                  >
                      修改
                  </button>
                  <span>&nbsp;&nbsp;&nbsp;</span>
                  <button
                    onClick={() => {
                       deletePrice(item);
                    }}
                    className="btn btn-secondary"
                  >
                      删除
                  </button>
                </li>
            ) )}
             </ol>
             { isEdite ? (
                <div style={{border:"2px red solid"}}>
                {isEdite && <PkgPriceUpdate pkgprice={currentPkgPrice} verItems={priceVerItems} update={updatePrice} />}
                {isEdite && <button onClick={(e)=>{
                    e.preventDefault();
                    setIsEdite(false);
    
                }} >取消修改</button>}
                </div>) :
                <PkgPriceAdd  verItems={priceVerItems}  priceItems={priceItems} add={addPrice} />

             }
            
        </div>
    )
}




