import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Pagination from "./Pagination";
import { fetchSamplesPart, fetchSampleCount } from "../apidata/fetchSampleData";

import { css } from "emotion";
import {
  fetchRwExcel,
  fetchSampleExcel,
  fetchSamplePdf
} from "../apidata/fetchSampleData";

export default function SampleList() {
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState(null);

  const [exportRwLoading, setExportRwLoading] = useState(false);
  const [exportSampleExcelLoading, setExportSampleExcelLoading] = useState(
    false
  );
  const [exportSamplePdfLoading, setExportSamplePdfLoading] = useState(false);

  // 总数
  const [count, setCount] = useState(1);
  useEffect(() => {
    (async () => {
      try {
        const temp = await fetchSampleCount();
        setCount(temp);
      } catch (error) {
        setMsg(`系统异常,请联系开发公司.:${error.message || error.statusText}`);
      }
    })();
  }, []);
  //分页相关
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(3);
  // 样品列表
  const [sampleItems, setSampleItems] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const temp = await fetchSamplesPart(pageSize, currentPage);
        setSampleItems(temp);
        setLoading(false);
      } catch (error) {
        setMsg(`系统异常,请联系开发公司.:${error.message || error.statusText}`);
      }
    })();
  }, [pageSize, currentPage]);

  // Change page
  const paginate = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const exportRwExcel = async item => {
    setExportRwLoading(true);
    try {
      const res = await fetchRwExcel(item.id);
      const blob = await res.blob();
      let url = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = url;
      link.download = `${item.trust.certificate===0 ? "JHRW":"JHSRW"}-${
        item.trust.year
      }-${item.trust.trustnumber.toString().padStart(4, "0")}`;
      link.click();
      window.URL.revokeObjectURL(url);
      setMsg("导出成功");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    } catch (error) {
      setMsg("导出失败" + error.message);
    }
    setExportRwLoading(false);
  };

  const exportSampleExcel = async item => {
    setExportSampleExcelLoading(true);
    try {
      const res = await fetchSampleExcel(item.id);
      const blob = await res.blob();
      let url = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = url;
      link.download = `${item.trust.certificate===0 ? "JHWT":"JHSWT"}-${
        item.trust.year
      }-${item.trust.trustnumber.toString().padStart(4, "0")}`;
      link.click();
      window.URL.revokeObjectURL(url);
      setMsg("导出成功");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    } catch (error) {
      setMsg("导出失败" + error.message);
    }
    setExportSampleExcelLoading(false);
  };

  const exportSamplePdf = async item => {
    setExportSamplePdfLoading(true);
    try {
      const res = await fetchSamplePdf(item.id);
      const blob = await res.blob();
      let url = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = url;
      link.download = `${item.trust.certificate===0 ? "JHWT":"JHSWT"}-${
        item.trust.year
      }-${item.trust.trustnumber.toString().padStart(4, "0")}`;
      link.click();
      window.URL.revokeObjectURL(url);
      setMsg("导出成功");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    } catch (error) {
      setMsg("导出失败" + error.message);
    }
    setExportSamplePdfLoading(false);
  };

  return (
    <div>
      <h1 style={{ textAlign: "center" }}>总样品单数据{count}</h1>
      <ol>
        {sampleItems &&
          sampleItems.map(item => (
            <li key={item.id}>
              <dl>
                <dt>
                  <Link
                    style={{ display: " inline-block" }}
                    to={{
                      pathname: `/sampledetail/${item.id}`,
                      data: item
                    }}
                  >
                    {item.trust.certificate===0 ? "JHWT":"JHSWT"}{`-${item.trust.year}-${item.trust.trustnumber.toString().padStart(4, "0")}`}
                    (点我查看)
                  </Link>
                  委托单位:{item.trust.trustname}
                </dt>

                <dd>
                  委托人:{item.trust.trustperson}
                  {item.trust.trustpersonphone}
                </dd>
                <dd>报告日期:{item.reportdate}</dd>
                <dd>委托日期:{item.trustdate}</dd>
                <dd>
                  样品编号:
                  {`JHYP-${item.year}-${
                    item.samplecode
                  }-${item.samplenumber.toString().padStart(4, "0")}`}
                  &nbsp;&nbsp;&nbsp;金额:&yen;{item.totalcost}
                </dd>
                <dd>样品名称:{item.samplenickname}</dd>
                <dd>规格型号:{item.modelnumber}</dd> 
                <dd>工程名称:{item.trust.projectname}</dd>
                <dd>
                  <Link
                    style={{ display: " inline-block" }}
                    to={{
                      pathname: `/samplejh/${item.id}`,
                      data: item,
                      operate: "copy"
                    }}
                  >
                    复制
                  </Link>
                  <Link
                    style={{ display: " inline-block" }}
                    to={{
                      pathname: `/samplejh/${item.id}`,
                      data: item,
                      operate: "edite"
                    }}
                  >
                    修改
                  </Link>
                  <Link
                  style={{ display: " inline-block" }}
                    to={{
                      pathname: `/sampleupdatesamplecode/${item.id}`,
                      data: item
                    }}
                  >
                    仅修改样品单号
                  </Link>
                </dd>
                <dd>{msg}</dd>
                <dd>
                  <button
                    className={css`
                      margin-left: 5px;
                    `}
                    disabled={exportSampleExcelLoading}
                    onClick={() => {
                      exportSampleExcel(item);
                    }}
                  >
                    {exportSampleExcelLoading ? "导出中.." : "导出委托单excel"}
                  </button>

                  <button
                    className={css`
                      margin-left: 15px;
                    `}
                    disabled={exportSamplePdfLoading}
                    onClick={() => {
                      exportSamplePdf(item);
                    }}
                  >
                    {exportSamplePdfLoading ? "导出中.." : "导出委托单pdf"}
                  </button>

                  <button
                    className={css`
                      margin-left: 15px;
                    `}
                    disabled={exportRwLoading}
                    onClick={() => {
                      exportRwExcel(item);
                    }}
                  >
                    {exportRwLoading ? "导出中.." : "导出任务单"}
                  </button>
                </dd>
              </dl>
            </li>
          ))}
      </ol>

      <Pagination
        pageSize={pageSize}
        totalRecords={count}
        currentPage={currentPage}
        onPageChange={paginate}
        defaultPageNeighbours={2}
      />
      <span>{loading && "正在加载!!,请等待"}</span>
      <span>{msg}</span>
    </div>
  );
}
