import React, { useState, useEffect } from "react";

import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import {useForm} from "react-hook-form";

import { css } from "emotion";

import baseURL from "../Config";

import CustArgumentForm from "./CustArgumentForm.js";
import CustArgumentTable from "./CustArgumentTable.js";

// 取消使用 2020-03-21
export default function ArgumentPkg() {
  const {
    register,
    unregister,
    setValue,
    handleSubmit,
    errors,
    reset
  } = useForm();

  // register control component
  useEffect(() => {
    register({ name: "category" }, { require: "nono" });
    register({ name: "argumentpkg" });
    register({ name: "arguments" });
    return () => unregister;
  }, [register, unregister]);

  const [msg, setMsg] = useState(null);

  // 加载类别
  const [categoryItems, setCategoryItems] = useState([]); //db 
  const [categoryValues, setCategoryValues] = useState(null);
  const [categoryError, setCategoryError] = useState(null);

  // 自定义包
  const [argumentPkgItems, setArgumentPkgItems] = useState([]); //数据源
  const [argumentPkgValues, setArgumentPkgValues] = useState(null); //选择
  const [pkgError, setPkgError] = useState(null);

  // 自定义参数(原来已打包的,用于显示)
  const [custArgumentItems, setCustArgumentItems] = useState(null); //

  // 选择参数
  const [argumentsItems, setArgumentsItems] = useState([]); //db
  const [argumentsValues, setArgumentsValues] = useState(null);
  const [argumentsError, setArgumentsError] = useState(null);

  // 选择大类
  const handleChangeCategory = selectedOption => {
    setValue("category", selectedOption); // form 的值
    setCategoryValues(selectedOption); // 控件的值,可不要
    
  };

// 加载大类
 useEffect(() => {
  const fetchData = async () => {  
    try {
      const res = await fetch(
        `${baseURL}/api/v1/category/categories`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + JSON.parse(sessionStorage.getItem("token"))
          }
        });
      const json = await res.json();     
      setCategoryItems(json)
    } catch (error) {
       setCategoryError(error);
    }
  };
  fetchData();
 }, [])

  // 动态填充参数包
  useEffect(() => {
    if (categoryValues === null) {
      return;
    }
    //清空
    setArgumentPkgValues(null);
    setCustArgumentItems(null);
    setValue("argumentpkg", null);
    const fetchData = async () => {
      try {
        const res = await fetch(
          `${baseURL}/api/v1/argumentpkg/pkgname/${categoryValues.id}`,
          {
            method: "get",
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer " + JSON.parse(sessionStorage.getItem("token"))
            }
          }
        );
        const json = await res.json();
        setArgumentPkgItems(json);
      } catch (error) {
        setPkgError(error.message || error.statusText);
      }
    };
    fetchData();
  }, [categoryValues, setValue]);

  // 动态填充参数
  useEffect(() => {
    if (categoryValues === null) {
      return;
    }
    setArgumentsValues(null);
    setValue("arguments", null);
    const fetchData = async () => {
      try {
        const res = await fetch(
          `${baseURL}/api/v1/argument/arguments/${categoryValues.id}/poor`,
          {
            method: "get",
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer " + JSON.parse(sessionStorage.getItem("token"))
            }
          }
        );
        const json = await res.json();
        setArgumentsItems(json);
      } catch (error) {
        setArgumentsError(error.message || error.statusText);
      }
    };
    fetchData();
  }, [categoryValues, setValue]);

  // 已自定义好的参数
  useEffect(() => {
    if (argumentPkgValues === null) {
      return;
    }
    if (argumentPkgValues["id"] === argumentPkgValues["name"]) {
      return;
    }
    const fetchData = async () => {
      try {
        const res = await fetch(
          `${baseURL}/api/v1/argumentpkg/custarguments/${argumentPkgValues.id}`,
          {
            method: "get",
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer " + JSON.parse(sessionStorage.getItem("token"))
            }
          }
        );
        const json = await res.json();
        setCustArgumentItems(json);
      } catch (error) {
        setPkgError(error.message || error.statusText);
      }
    };
    fetchData();
  }, [argumentPkgValues, setCustArgumentItems]);

  // 验证新增还是选择自定义参数包名
  const isValidNewOption = (inputValue, selectValue, selectOptions) => {
    if (
      inputValue.trim().length === 0 ||
      selectOptions.find(option => option.name === inputValue)
    ) {
      return false;
    }
    return true;
  };

  // 处理参数包(新增和选择两种情况)
  const handleChangePkg = (newValue, { action }) => {
    switch (action) {
      case "select-option":
        setArgumentPkgValues(newValue); // 控件的值
        setValue("argumentpkg", newValue);
        break;
      case "create-option":
        if (argumentPkgItems !== null) {
          const options = [...argumentPkgItems, newValue];
          setArgumentPkgItems(options);
          setArgumentPkgValues(newValue); // 控件的值
          setValue("argumentpkg", newValue);
        }
        break;
      default:
        break;
    }
  };

  // 先择参数
  const handleChangeArguments = selectedOption => {
    setValue("arguments", selectedOption); // form 的值
    setArgumentsValues(selectedOption); // 控件的值,可不要
  };

  //提交
  const onSubmit = data => {
    if (
      (categoryValues === null) |
      (argumentsValues === null) |
      (argumentPkgValues == null)
    ) {
      alert("有数据没有选好?");
    } else {
      fetch(`${baseURL}/api/v1/argumentpkg/addargumentpkg`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        },
        body: JSON.stringify(data)
      })
        .then(res => {
          if (res.ok) {
            console.log(res.status);
            reset({ category: null, argumentpkg: null, arguments: null }); // form 的值
            setCategoryValues(null);
            setArgumentPkgValues(null)
            setArgumentsValues(null);
            setCustArgumentItems(null);
            return res.json();
          }
          throw res;
        })
        .then(res => {
          setMsg("新增成功");
        })
        .catch(error => {
          setMsg(error.message || error.statusText);
        });
    }
  };

  const [currentCustArgument, setCurrentCustArgument] = useState();  
  const [isEdite, setIsEdite] = useState(false);
  

  //取得要修改的item
  const editRow = item => {
    setCurrentCustArgument(item);    
    setIsEdite(true);
  };

  const updateItem = (id,updateCustArgument) => {
    
    setCustArgumentItems(
      custArgumentItems.map(item =>
        item.id === id ? updateCustArgument : item
      )
    );
   

    // 改数据库
    fetch(
      `${baseURL}/api/v1/argumentpkg/custargument/${currentCustArgument.argumentpkg_id}/${currentCustArgument.id}`,
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        },
        body: JSON.stringify({ sorting: updateCustArgument.sorting})
      }
    )
      .then(res => {
        if (res.status === 200) {
          setMsg("修改成功!");
          setIsEdite(false);
        }
      })
      .catch(error => {
        setMsg(error.message || error.statusText);
      });
  };

  // 删除中间表
  const deleteItem = item => {
    fetch(
      `${baseURL}/api/v1/argumentpkg/custargument/${item.argumentpkg_id}/${item.id}`,
      {
        method: "delete",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        },
        body: null
      }
    )
      .then(res => {
        if (res.status === 200) {
          setMsg("删除成功");
        }
      })
      .catch(error => {
        setMsg(error.message || error.statusText);
      });
    // 处理界面
    setCustArgumentItems(custArgumentItems.filter(i => i.id !== item.id));
  };

  return (
    <>
    <h3 style={{textAlign:"center"}}>自定义参数包维护</h3>
      {msg}
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>类目:</label><Select
          className={css`
            width: 50%;
            margin-left: -0%;
          `}
          name="category"
          placeholder="项目(类别)"
          value={categoryValues}
          options={categoryItems}
          onChange={handleChangeCategory}
          // react-select/issues/3018
          getOptionValue={option => option["id"]}
          getOptionLabel={option => `${option.stdcode} | ${option.name}`}
        />
        {categoryError}
       
        {errors.category && errors.category.message}
        <hr />
        <label>自定义包:</label> <CreatableSelect
          className={css`
            width: 50%;
            margin-left: -0%;
          `}
          name="argumentpkg"
          placeholder="可以选择也可以输入"
          value={argumentPkgValues}
          options={argumentPkgItems}
          getOptionValue={option => option.id}
          getOptionLabel={option => option.name}
          // 取新值
          getNewOptionData={(inputValue, optionLabel) => ({
            id: inputValue,
            name: optionLabel
          })}
          isValidNewOption={isValidNewOption}
          onChange={handleChangePkg}
        />
        {pkgError}
        {argumentPkgValues ? argumentPkgValues.name : "你现在什么也没有选到哦?" }
        
        <hr />

        <label>参数选择:</label><Select
          className={css`
            width: 50%;
            margin-left: -0%;
          `}
          name="argumnents"
          isMulti
          closeMenuOnSelect={false}
          isClearable
          placeholder="参数(可多选)"
          value={argumentsValues}
          options={argumentsItems}
          onChange={handleChangeArguments}
          // react-select/issues/3018
          getOptionValue={option => option["id"]}
          getOptionLabel={option => `${option.stdcode} | ${option.name}`}
        />
        {argumentsError}      
       

        {/* <input type="submit" /> */}
        <button
          type="submit"
          onClick={handleSubmit}
          className="btn btn-primary"
        >
          保存
        </button>
      </form>
      <hr />
      
       {/* 修改区 */}
      {isEdite? (
         <CustArgumentForm setIsEdite={setIsEdite} currentCustArgument={currentCustArgument} update={updateItem} />

      ):(
          <div></div>
      )
      }

      {/* 选择的数据 */}
      <ol>
        {argumentsValues &&
          argumentsValues.map(item => (
            <li key={item.id}>
              {item.id}--{item.stdcode}--{item.name}--{item.price}--{item.remak}
            </li>
          ))}
      </ol>



      {/* 数据展示 */}
      <CustArgumentTable custArgumentItems={custArgumentItems} editRow={editRow} deleteItem={deleteItem} />

     
    </>
  );
}
