import React, { useState, useRef } from "react";

import { css } from "emotion";
import { useAlert } from "react-alert";

import {
  AlertDialog,
  AlertDialogLabel,
  AlertDialogDescription,
} from "@reach/alert-dialog";
import "@reach/dialog/styles.css";

import { fetchRepayUpdateStatus } from "../apidata/fetchRepay";

export default function RepayDetail({ currentRepay, updateUi }) {
  const alert = useAlert();

  // 确认
  const [showDialogComfirm, setShowDialogComfirm] = useState(false);
  const cancelRef = useRef();
  const openComfirm = () => setShowDialogComfirm(true);
  const closeComfirm = () => setShowDialogComfirm(false);

  const [status, setStatus] = useState(currentRepay.status);

  const [loading, setLoading] = useState(false);

  const submit = async (data) => {
    setLoading(true);
    try {
      const obj = { ...currentRepay, status };
      const res = await fetchRepayUpdateStatus(currentRepay.id, { status });
      updateUi(currentRepay.id, obj);
      alert.success(res.msg);
      closeComfirm();
    } catch (error) {
      alert.error(error.message);
    }
    setLoading(false);
  };

  return (
    <>
      <div
        className={css`
          margin: 0;
          box-sizing: border-box;
        `}
      >
        <div>
          <button
            disabled={loading}
            onClick={(e) => {
              e.preventDefault();
              setStatus(1);
              openComfirm();
            }}
            className="btn btn-primary"
          >
            完成
          </button>
          <button
            style={{ marginLeft: "20px" }}
            disabled={loading}
            onClick={(e) => {
              e.preventDefault();
              setStatus(0);
              openComfirm();
            }}
            className="btn btn-primary"
          >
            撒消
          </button>
        </div>
        <p>当前状态({status === 0 ? "催款中" : "完成"})</p>
        <p>来样日期:{currentRepay.receivepersondate}</p>
        <p>
          结账金额:{currentRepay.amount}&nbsp;&nbsp;结账日期:
          {currentRepay.settlement}
        </p>
        <p>
          支付金额:{currentRepay.payamount}&nbsp;&nbsp;支付日期:
          {currentRepay.paydate}
        </p>
        <p>给财务日期:{currentRepay.tofinancedate}</p>
        <p>工程名称:{currentRepay.projectname}</p>
        <p>
          委托人:{currentRepay.trustperson}&nbsp;&nbsp;委托人电话:
          {currentRepay.trustpersonphone}
        </p>
        <p>发票号:{currentRepay.invoicenumber}</p>
        <p>
          开票资料1:{currentRepay.invoice?.name}{" "}
          {currentRepay.invoice?.taxnumber}
        </p>
        <p>
          开票资料2:{currentRepay.invoice?.address}{" "}
          {currentRepay.invoice?.phone}
        </p>
        <p>
          开票资料2:{currentRepay.invoice?.bank}{" "}
          {currentRepay.invoice?.banknumber}
        </p>
        <p>备注:{currentRepay.remark}</p>

        <p>催款记录</p>
        <ol>
          {currentRepay.callhistory &&
            currentRepay.callhistory.map((c) => (
              <li key={c.id}>
                时间:
                {new Date(c.id)
                  .toISOString()
                  .substring(0, 19)
                  .replace("T", " ")}
                --催款人:{c.name}--内容:{c.content}
              </li>
            ))}
        </ol>
      </div>

      {showDialogComfirm && (
        <AlertDialog onDismiss={closeComfirm} leastDestructiveRef={cancelRef}>
          <AlertDialogLabel style={{ textAlign: "center", color: "red" }}>
            请再次确认是否删除?
          </AlertDialogLabel>
          <AlertDialogDescription>
            <p>日期:{currentRepay.receivepersondate}</p>
            <p>结账金额:{currentRepay.amount}</p>
          </AlertDialogDescription>
          <div className="alert-buttons">
            <button className="btn btn-danger" onClick={submit}>
              是的,按我说的做
            </button>
            <button
              style={{ marginLeft: "20px" }}
              ref={cancelRef}
              onClick={closeComfirm}
            >
              取消, 我不愿意
            </button>
          </div>
        </AlertDialog>
      )}
    </>
  );
}
