import React,{useState,useEffect} from 'react';
import  baseURL from "../Config";
import OrderView from "./OrderView";

import '../App.css';



const App3 = ()=>{
  return(  
    
    <div >
      <div className="row">
        <div className="col-sm">
            <Order />  
        </div>
        <div className="col-sm">
           <Order />  
        </div>
        <div className="col-sm">
           <Order />  
        </div>
      </div>
  </div>
  )
}


const useGiphy = (query)=>{
  const [result,setResult]=useState();
  const [loading,setLoading]=useState(false);

  useEffect (()=>{
    async function fetchData() {
      try{
        setLoading(true);      
        const response=await fetch(`${baseURL}/api/v1/order/JHWT-2019-${query.toString().padStart(4, "0")}`);
        const data=await response.json();        
        console.log(data);
        setResult(data);
      }
      catch{
        setResult(null);
      }
      finally{
        setLoading(false);
      }
    }
    
    if(query !== ''){
      fetchData();
    }


  },[query])

  return [result,loading];

}




const Order = ()=>{
  const [search,setSearch] =useState();
  const [query,setQuery]=useState();
  const [result,loading]=useGiphy(query);
  
  
  return (
     <div>
       <form className="form-group" onSubmit={ e=>{
         e.preventDefault();
        
         setQuery(search);  
       }}>
         
         <div className="input-group mb-3">
         <label htmlFor="wtcode" className="form-control form-control-lg">委托单号:</label>

         <input type="number" id="wtcode"  className="form-control form-control-lg" value={search} onChange={e=> setSearch(e.target.value) } autoComplete="off" />
            <div className="input-group-append">
              <span className="input-group-text" id="basic-addon2"> <button type="submit" className="btn btn-primary">查找</button></span>
            </div>
          </div>

        <small id="emailHelp" className="form-text text-muted">输入例子:1001</small>
     
        
         <span>{search}</span>
         {loading ? (<div>loading</div>):(          
               <OrderView order={result}  />
          
         )}         
       </form>
     </div>
  )
}






export default App3;

