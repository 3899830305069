import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Pagination from "../components/Pagination";
import { fetchSampleFilterReportCount,fetchSamplesfilterReport } from "../apidata/fetchSampleData";

export default function SampleListFilterByReport() {
  const [loading, setLoading] = useState(false);

  const [msg, setMsg] = useState(null);

  // 总数
  const [count, setCount] = useState(1);
  useEffect(() => {
    (async () => {
      try {
        const temp = await fetchSampleFilterReportCount();
        setCount(temp);
      } catch (error) {
        setMsg(`系统异常,请联系开发公司.:${error.message || error.statusText}`);
      }
    })();
  }, []);
  //分页相关
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  // 样品列表
  const [sampleItems, setSampleItems] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const temp = await fetchSamplesfilterReport(pageSize, currentPage);
        setSampleItems(temp);
        setLoading(false);
      } catch (error) {
        setMsg(`系统异常,请联系开发公司.:${error.message || error.statusText}`);
      }
    })();
  }, [pageSize, currentPage]);

  // Change page
  const paginate = pageNumber => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <h1 style={{ textAlign: "center" }}>最近3天待发报告{count}</h1>
      {/* 数据列表区 */}
      <table className="table table-sm table-hover">
        <thead className="thead-dark">
          <tr>
            <th>序号</th>
            <th>委托单号</th>
            <th>委托人</th>
            <th>委托人电话</th>
            <th>委托单位</th>
            <th>样品编号</th>
            <th>样品名称</th>
            <th>报告日期</th>
            <th>委托日期</th>           
            <th>工程名称</th>            
            <th>操作</th>
          </tr>
        </thead>

        <tbody>
          {sampleItems ? (
            sampleItems.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>
                  <Link
                    to={{
                      pathname: `/sampledetail/${item.id}`,
                      data: item
                    }}
                  >
                    {item.trust.certificate===0 ? "JHWT":"JHSWT"}{`-${
                      item.trust.year
                    }-${item.trust.trustnumber.toString().padStart(4, "0")}`}                    
                  </Link>
                </td>
                <td>{item.trust.trustperson}</td>
                <td>{item.trust.trustpersonphone}</td>
                <td>{item.trust.trustname?.length > 12 ? item.trust.trustname?.substring(0, 12) + "..." : item.trust.trustname }</td>
                <td>               
                  {`JHYP-${item.year}-${
                    item.samplecode
                  }-${item.samplenumber.toString().padStart(4, "0")}`}
                </td>
                <td>{item.samplenickname}</td>
                <td>{item.reportdate}</td>
                <td>{item.trustdate}</td>
                <td>{item.trust.projectname?.length > 15 ? item.trust.projectname?.substring(0,15)+ "..." :item.trust.projectname }</td>
                <td>
                <Link
                    to={{
                      pathname: `/sendreport/${item.id}`,
                      data: item
                    }}
                  >
                     发放
                  </Link>
                </td>
                
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>没有数据哦!!</td>
            </tr>
          )}
        </tbody>
      </table>

      <Pagination
        pageSize={pageSize}
        totalRecords={count}
        currentPage={currentPage}
        onPageChange={paginate}
        defaultPageNeighbours={2}
      />
      <span>{loading && "正在加载!!,请等待"}</span>
      <span>{msg}</span>
    </div>
  );
}
