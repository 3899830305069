import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import Select from "react-select";
import {
  fetchOldSampleNumber,
  fetchOnlysamplecodeUpdate
} from "../apidata/fetchSampleData";

import { fetchSampleCodeAll } from "../apidata/fetchSampleCodeData";

export default function SampleUpdateSampleCode(props) {
  const { id } = useParams();
  const location = useLocation();
  const [msg, setMsg] = useState();
  const { data } = location;

  const { register, unregister, handleSubmit, errors, setValue } = useForm();

  useEffect(() => {
    register({ name: "samplecode" }, { required: "样品编号不能为空" });
    return () => unregister;
  }, [register, unregister]);

  // 样品代码
  const [sampleCodeItems, setSampleCodeItems] = useState([]); //db
  const [sampleCodeValues, setSampleCodeValues] = useState(null);
  const [sampleCodeError, setSampleCodeError] = useState(null);

  // 服务端maxid
  const [maxId, setMaxId] = useState(null);

  const [oldSample, setOldSample] = useState();

  const [isDisabled,setIsDisabled ] = useState(false);

  // 加载样品代码
  useEffect(() => {
    (async () => {
      try {
        const json = await fetchSampleCodeAll();
        setSampleCodeItems(json);
      } catch (error) {
        setSampleCodeError(error.message || error.statusText);
      }
    })();
  }, []);

  // 找到旧单开单时该应有的单号
  useEffect(() => {
    if (sampleCodeValues) {
      (async () => {
        try {
          const json = await fetchOldSampleNumber(sampleCodeValues.category.certificate,sampleCodeValues.stdcode, id);
          setMaxId(json.samplemax);
          setOldSample(json.sample);
        } catch (error) {
          setMsg(error.message || error.statusText);
        }
      })();
    }
  }, [sampleCodeValues, id]);

  // 选择样品代码
  const handleChangeSampleCode = selectedOption => {
    setValue("samplecode", selectedOption); // form 的值
    setSampleCodeValues(selectedOption); // 控件的值
  };

  const onSubmit = async data => {
    try {
      const json = await fetchOnlysamplecodeUpdate(id, data);
      setMsg(json.msg);
      setSampleCodeValues(null);
      setValue("samplecode", null);
      setIsDisabled(true);


    } catch (error) {
      setMsg(error.message);
    }
  };

  return (
    <div>
      <h2>
        <Link to="/samplelist2">返回主查询</Link>
      </h2>
      <h3>待修改的委托单信息</h3>
      <p>
        委托单号:
        {`JHWT-${data.trust.year}-${data.trust.trustnumber
          .toString()
          .padStart(4, "0")}`}
      </p>

      <p>
        样品单号:
        {`JHWT-${data.samplecode}-${
          data.year
        }-${data.samplenumber.toString().padStart(4, "0")}`}
      </p>
      <p>样品名称:{data.samplenickname} </p>
      <p>委托单位:{data.trust.trustname}</p>
      <p>工程名称:{data.trust.projectname} </p>
      <p>委托人:{data.trust.trustperson}</p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group row">
          <label className="label">样品代码:</label>
          <Select
            isDisabled={isDisabled}
            className="select-small"
            name="samplecode"
            placeholder="样品代码(公路的代码只能改为公路,水运的只能改水运的.如不符合同类修改,请作废,再重开)"
            value={sampleCodeValues}
            options={sampleCodeItems.filter(c=>c.category.certificate===data.trust.certificate)}
            onChange={handleChangeSampleCode}
            // react-select/issues/3018
            getOptionValue={option => option["id"]}
            getOptionLabel={option => `${option.stdcode} | ${option.category.certificate===0? "公路":"水运"} | ${option.name}`}
          />
          <div style={{ display: "inlneblock", color: "red" }}>
            {sampleCodeError}
            {errors.samplecode && errors.samplecode.message}
          </div>
        </div>
        <div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label"> 新改为的样品单号:</label>
           <label className="col-sm-3">
            {sampleCodeValues &&
              maxId &&
              `JHWT-${sampleCodeValues.stdcode}-${
                data.year
              }-${maxId.toString().padStart(4, "0")}`}
            </label>
            {msg}
        </div>

          <div className="form-group row">
             <div  className="col-sm-2">
              <input  type="submit" disabled={isDisabled} />
              <Link to="/samplelist2">返回主查询</Link>
              </div>
          </div>
          
        
          <div style={{ color: "red" }}>
            {oldSample && (
              <>
                <p>已影响到下边的委托单及其以后的委托单,请逐一修改</p>
                <p>{`委托单号:JHWT-${
                  oldSample.trust.year
                }-${oldSample.trust.trustnumber
                  .toString()
                  .padStart(4, "0")}`}</p>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
