import baseURL from "../Config";




export const fetchAddSendReport = async (data) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/sendreport/add`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body: JSON.stringify(data)
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAddSendReportMulti = async (data) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/sendreport/addmulti`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body: JSON.stringify(data)
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};


export const fetchUpdateSendReportMulti = async (data) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/sendreport/updatemulti`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body: JSON.stringify(data)
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchUpdateSendReport = async (id,data) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/sendreport/update/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body: JSON.stringify(data)
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchDeleteSendReport = async (id) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/sendreport/delete/${id}`, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};



//  count
export const fetchSendReportCount = async () => {
  try {
    const res = await fetch(`${baseURL}/api/v1/sendreport/count`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
    });

    if (res.ok) {
      const json = await res.json();
      return json.count;
    } else {
      throw new Error("请求异常!");
    }
  } catch (error) {
    throw new Error(error);
  }
};
// 分页
export const fetchSendReportList = async (pageSize, currentPage) => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/sendreport/sendReportList?pagesize=${pageSize}&page=${currentPage}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        }
      }
    );
    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("请求异常!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

