import React from 'react'
import { useParams, useLocation } from "react-router-dom";
import SampleFormJh from  "./SampleFormJh"

export default function SampleEditJh() {
    const {operate,data}=useLocation();
 
    const { id } = useParams();  
    
    return (
        <>
            <SampleFormJh id={id} operate={operate} initValues={data} />
        </>
    )
}
