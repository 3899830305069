import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { css } from "emotion";
import { fetchLinkUpdate } from "../apidata/fetchCategoryLinkStandard";

export default function CategoryLinkStandardUpdate({
  currentLink,
  category_id,
  updateUi
}) {
  const { register, handleSubmit, setValue, errors, reset } = useForm();

  useEffect(() => {
    setValue("kind", currentLink.kind);
    setValue("sorting", currentLink.sorting);
  }, [currentLink, setValue]);

  const [msg, setMsg] = useState();

  const onSubmit = async data => {
    const data1 = {
      ...data,
      standard_id: currentLink.id,
      category_id: category_id
    };
    try {
      const json = await fetchLinkUpdate(data1);
      updateUi(json);
    } catch (error) {
      setMsg(error.message || error.statusText);
    }
  };

  return (
    <>
      <h2>修改标准的类型和排序</h2>
      {msg && <h2>{msg}</h2>}
      <p>标准代码:{currentLink.stdcode}</p>
      <p>标准名称:{currentLink.name}</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group row">
          <label htmlFor="skind" className="col-sm-1 col-form-label">
            类型:
          </label>
          <div className="col-sm-10">
            <select
              name="kind"
              ref={register}
              required
              className={css`
                width: 50%;
              `}
            >
              <option value="0">试验依据 | 判定标准</option>
              <option value="1">仅试验依据</option>
              <option value="2">仅判定标准</option>
            </select>
            {errors.kind && errors.kind.message}
          </div>
        </div>
        <hr />

        <div className="form-group row">
          <label htmlFor="sorting" className="col-sm-1 col-form-label">
            排序:
          </label>
          <div className="col-sm-5">
            <input
              name="sorting"
              ref={register({
                min: {
                  value: 0,
                  message: "不能小于0"
                },
                max: {
                  value: 100,
                  message: "不能大于100"
                }
              })}
              type="number"
              className="form-control"
              required
            />
            {errors.sorting && errors.sorting.message}
          </div>
        </div>

        <button
          type="submit"
          onClick={handleSubmit}
          className="btn btn-primary"
        >
          保存
        </button>
        <span> </span>
        <input
          type="reset"
          onClick={() => {
            reset();
          }}
          className="btn btn-primary"
        />
      </form>
    </>
  );
}
