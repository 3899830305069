import { useState, useEffect } from "react";
import baseURL from "../Config";

export default function useQuerySampleStatus(query,isFetch) {
  const [names, setNames] = useState([]);

  useEffect(() => {
    if (isFetch && query && query.trim() !== "") {
      let isFresh = true;
      fetchNames(query).then(items => {
        if (isFresh) setNames(items);
      });
      return () => (isFresh = false);
    }
  }, [query,isFetch]);

  return names;
}

const cache = {};
function fetchNames(value) {
  if (cache[value]) {
    return Promise.resolve(cache[value]);
  }
  return fetch(`${baseURL}/api/v1/sample/samplestatuses`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
    },
    body: JSON.stringify({ query: value })
  })
    .then(res => res.json())
    .then(result => {
      cache[value] = result;
      return result;
    });
}
