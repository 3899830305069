import React, { useContext, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { css } from "emotion";
import { useAlert } from "react-alert";

import {
  AlertDialog,
  AlertDialogLabel,
  AlertDialogDescription,
} from "@reach/alert-dialog";
import "@reach/dialog/styles.css";
import { AuthContext } from "../App";

import { fetchRepayUpdateCallHistory } from "../apidata/fetchRepay";

export default function CallHistory({ currentRepay, updateUi }) {
  const { state } = useContext(AuthContext);
  const alert = useAlert();
  const { register, handleSubmit, errors, reset } = useForm();

  // 删除
  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const cancelRef = useRef();
  const openDelete = () => setShowDialogDelete(true);
  const closeDelete = () => setShowDialogDelete(false);

  // 工程名称
  const [items, setItems] = useState(currentRepay.callhistory || []);

  const [loading, setLoading] = useState(false);

  const submit = async (data) => {
    setLoading(true);
    try {
      const newItems = [
        { ...data, id: Date.now(), name: state.user.realname },
        ...items,
      ];
      setItems(newItems);
      const obj = { callhistory: newItems };
      const res = await fetchRepayUpdateCallHistory(currentRepay.id, obj);
      updateUi(currentRepay.id, obj);
      alert.success(res.msg);
      reset({});
    } catch (error) {
      alert.error(error.message);
    }
    setLoading(false);
  };

  const [line, setLine] = useState();
  const deleteLine = async () => {
    try {
      const newItems = items.filter((c) => c.id !== line.id);
      setItems(newItems);
      const obj = { callhistory: newItems };
      const res = await fetchRepayUpdateCallHistory(currentRepay.id, obj);
      updateUi(currentRepay.id, obj);
      console.log(res.message);
      alert.success("删除成功!");
      closeDelete();
    } catch (error) {
      alert.error(error.message);
    }
  };

  return (
    <>
      <div
        className={css`
          margin: 0;
          box-sizing: border-box;
        `}
      >
        <form onSubmit={handleSubmit(submit)}>
          <div className="form-group">
            <label htmlFor="content">催款内容:</label>
            <span style={{ color: "red" }}>{errors.content?.message}</span>
            <input
              type="text"
              className="form-control"
              ref={register({
                required: "不能为空",
                maxLength: {
                  value: 128,
                  message: "不能大于128个字",
                },
              })}
              name="content"
              placeholder="请简要记录通话信息"
            />
          </div>

          <div>
            <button
              type="submit"
              disabled={loading}
              onClick={handleSubmit}
              className="btn btn-primary"
            >
              增加催款记录
            </button>
          </div>
        </form>
        <ol>
          {items &&
            items.map((c) => (
              <li key={c.id}>
                时间:
                {new Date(c.id)
                  .toISOString()
                  .substring(0, 19)
                  .replace("T", " ")}
                --催款人:{c.name}--内容:{c.content}
                <button
                  onClick={() => {
                    setLine(c);
                    openDelete();
                  }}
                >
                  删除
                </button>
              </li>
            ))}
        </ol>
      </div>

      {showDialogDelete && (
        <AlertDialog onDismiss={closeDelete} leastDestructiveRef={cancelRef}>
          <AlertDialogLabel style={{ textAlign: "center", color: "red" }}>
            请再次确认是否删除?
          </AlertDialogLabel>
          <AlertDialogDescription>
            <p>日期:{currentRepay.receivepersondate}</p>
            <p>结账金额:{currentRepay.amount}</p>
          </AlertDialogDescription>
          <div className="alert-buttons">
            <button className="btn btn-danger" onClick={deleteLine}>
              是, 删除
            </button>
            <button
              style={{ marginLeft: "20px" }}
              ref={cancelRef}
              onClick={closeDelete}
            >
              取消, 我不愿意
            </button>
          </div>
        </AlertDialog>
      )}
    </>
  );
}
