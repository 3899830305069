import React, { useState, useEffect } from "react";
import Pagination from "../components/Pagination";
import {
  fetchAnalysisNewProject,
  fetchProjectInfoExcel
} from "../apidata/fetchcAnalysisData";
import format from "date-fns/format";
import subDays from "date-fns/subDays";
import subMonths from "date-fns/subMonths";

import DatePicker from "react-datepicker";
import "../components/react-datepicker.min.css";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";


export default function AnalysisReport() {
  const [loading, setLoading] = useState(false);

  const [msg, setMsg] = useState(null);

  // 总数
  const [count, setCount] = useState(1);
  //分页相关
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(15);
  // 数据列表
  const [newProjectItems, setNewProjectItems] = useState(); //db
  const [currentItems, setCurrentItems] = useState(); //client 分页

  const [days, setDays] = useState(3);
  const [startDate, setStartdDate] = useState(subDays(Date.now(), 3));

  const [project, setProject] = useState();

  // 新增或修改
  const [showDialog, setShowDialog] = useState(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);

  useEffect(() => {
    (async () => {
      try {
        const temp = format(subDays(Date.now(), 3), "yyyy-MM-dd");
        const json = await fetchAnalysisNewProject(temp);
        setNewProjectItems(json);
        setCount(json ? json.length : 0);
      } catch (error) {
        setMsg(`系统异常,请联系开发公司.:${error.message || error.statusText}`);
      }
    })();
  }, []);

  useEffect(() => {
    if (newProjectItems) {
      const offset = (currentPage - 1) * pageSize;
      setCurrentItems(newProjectItems.slice(offset, offset + pageSize));
    }
  }, [newProjectItems, pageSize, currentPage]);

  // Change page
  const paginate = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const handleDaysChange = event => {
    setDays(parseInt(event.target.value));
    setStartdDate(subDays(Date.now(), parseInt(event.target.value))); //显示日期控件
  };

  // 报告日期
  const handleStartDateChange = date => {
    if (!date) {
      return;
    }
    setStartdDate(date);
  };

  const select = async () => {
    setLoading(true);
    try {
      const temp = format(startDate, "yyyy-MM-dd");
      const json = await fetchAnalysisNewProject(temp);
      setNewProjectItems(json);
      setCount(json ? json.length : 0);
    } catch (error) {
      setMsg(`系统异常,请联系开发公司.:${error.message || error.statusText}`);
    }
    setLoading(false);
  };

  const exportExcel = async () => {
    setLoading(true);
    try {
      const res = await fetchProjectInfoExcel(format(startDate, "yyyy-MM-dd"));
      const blob = await res.blob();
      let url = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = url;
      link.download = `新工程${new Date().toLocaleDateString()}`;
      link.click();
      window.URL.revokeObjectURL(url);
      setMsg("导出成功");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    } catch (error) {
      setMsg("导出失败" + error.message);
    }
    setLoading(false);
  };

  return (
    <div>
      <form>
        <label>检索范围</label>
        <select name="initdays" value={days} onChange={handleDaysChange}>
          <option value="3">3天</option>
          <option value="7">一周</option>
          <option value="15">半个月</option>
          <option value="30">一个月</option>
          <option value="90">三个月</option>
        </select>

        <label className="label">检索日期:从</label>
        <DatePicker
          dateFormat="yyyy-MM-dd日"
          selected={startDate}
          onChange={handleStartDateChange}
          minDate={subMonths(new Date(), 3)}
          maxDate={subDays(Date.now(), 3)}
        />
        <span>至今天</span>
        <div>
          <button onClick={select} disabled={loading}
            className="btn btn-primary">
            {loading ? "加载中" : "查询"}
          </button>
          <button 
            className="btn btn-primary" 
            style = {{marginLeft : "25px"}}         
           
            onClick={() => {
              exportExcel();
            }}
            disabled={loading}
          >
            {loading ? "下载中" : "导出excel"}
          </button>
        </div>
      </form>

      <h1 style={{ textAlign: "center" }}>最新工程项目信息{count}条</h1>
      <table className="table table-sm table-hover">
        <thead className="thead-dark">
          <tr>
            <th>序号</th>
            <th>委托单位</th>
            <th>工程名称</th>
            <th>委托人</th>
            <th>委托人电话</th>
            <th>地址</th>
            <th>最近来访</th>
            <th>首次来访</th>

            <th>操作</th>
          </tr>
        </thead>

        <tbody>
          {currentItems ? (
            currentItems.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>

                <td>
                  {item.trustname?.length > 12
                    ? item.trustname?.substring(0, 12) + "..."
                    : item.trustname}
                </td>
                <td>
                  {item.projectname?.length > 25
                    ? item.projectname?.substring(0, 25) + "..."
                    : item.projectname}
                </td>
                <td>{item.trustperson}</td>

                <td>{item.trustpersonphone}</td>
                <td>
                  {item.address?.length > 12
                    ? item.address?.substring(0, 12) + "..."
                    : item.address}
                </td>
                <td>
                  {item.lastdate &&
                    format(new Date(item.lastdate), "yyyy-MM-dd")}
                </td>
                <td>
                  {item.firstdate &&
                    format(new Date(item.firstdate), "yyyy-MM-dd")}
                </td>
                <td>
                  <button
                    onClick={() => {
                      setProject(item);
                      open();
                    }}
                  >
                    详情
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>没有数据哦!!</td>
            </tr>
          )}
        </tbody>
      </table>

      <Pagination
        pageSize={pageSize}
        totalRecords={count}
        currentPage={currentPage}
        onPageChange={paginate}
        defaultPageNeighbours={2}
      />
      <span>{loading && "正在加载!!,请等待"}</span>
      <span>{msg}</span>

      {showDialog && (
        <Dialog aria-label="modal" isOpen={showDialog} onDismiss={close}>
          {project && (
            <ol>
              <li>委托单位:{project.trustname}</li>
              <li>工程名称:{project.projectname}</li>
              <li>委托人:{project.trustperson}</li>
              <li>委托人电话{project.trustpersonphone}</li>
              <li>地址:{project.address}</li>
              <li>
                最近来访:
                {project.lastdate &&
                  format(new Date(project.lastdate), "yyyy-MM-dd")}
              </li>
              <li>
                首次来访:
                {project.firstdate &&
                  format(new Date(project.firstdate), "yyyy-MM-dd")}
              </li>
            </ol>
          )}
          <button onClick={close}>关闭</button>
        </Dialog>
      )}
    </div>
  );
}
