import React, { useState, useEffect } from "react";
import { css } from "emotion";
import { fetchLinkUpdate } from "../apidata/fetchArgumentLinkData";


export default function ArgumentLinkStandardEdite({item}) {

    const [currentLink,setCurrentLink]=useState(item)
    const  [kind,setKind]=useState(item.kind);
  
    const  [msg,setMsg]=useState();  
  
      useEffect(() => {
        setCurrentLink(item);        
       }, [item])
  
       useEffect(() => {
         setKind(currentLink?.kind)       
       }, [currentLink])
  
  
    const handleChange= e=>{   
      setKind(e.target.value)
    }
  
    const save =async ()=> {
   
      
      setCurrentLink({...currentLink,kind:kind});
             try {
      const json=await fetchLinkUpdate({...currentLink,kind:kind});      
      setMsg(json.msg);
      setTimeout(() => {
         setMsg(null);        
      }, 2000);
      document.location.reload();
    } catch (error) {
      setMsg(error.message);    
  
    }
    };
  
  
    return (
      <div>    
        {msg}
        <h3>原内容</h3>        
        <p>标准代码:{currentLink?.stdcode}</p>
        <p>标准名称:{currentLink?.name}</p>
        <div>
         <label htmlFor="kind"  className={css`
                width: 5%;
                margin-left: 0%;
                margin-top: 10px;
                display: inline-block;
              `}>
              类型:
            </label>          
              <select
                name="kind"            
                value={kind}
                onChange={handleChange}
                className={css`
                width: 40%;
                margin-left: 0%;
                margin-top: 10px;
                display: inline-block;
              `}
              >
                <option value="0">试验依据 | 判定标准</option>
                <option value="1">仅试验依据</option>
                <option value="2">仅判定标准</option>
              </select>              
              </div>       
            <button onClick={save} >保存</button>
            
       
      </div>
    )
  }