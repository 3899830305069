import React,{useState,useEffect} from 'react'






function EditProductForm(props) {
    // 写成currenProduct 传错props导致不成功, 调试了很久,错误注意了
    const [product,setProduct]=useState(props.currentProduct);
    

    useEffect(() => {
        setProduct(props.currentProduct);
       
    }, [props])


    const handleInputChange = e=>{
        const {name,value}=e.target;
        setProduct({...product,[name]:value})
    }
   



    return (
        <form onSubmit={event=>{
            event.preventDefault();
            if(!product.name || !product.price){
                return;
            }
            props.updateProduct(product.id,product);
            }
        }
        >
        <label>参数名称:</label>
		<input type="text" name="name" value={product.name} onChange={handleInputChange} />
		<label>价格:</label>
		<input type="text" name="price" value={product.price} onChange={handleInputChange} />
		<button type="submit">修改参数</button>
        <button type="reset" onClick={()=>{props.setEditing(false)}}>取消</button>
        </form>
    )
}




export default  EditProductForm;
