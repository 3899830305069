import React, { useState, useEffect } from "react";
import { css } from "emotion";
import { useForm } from "react-hook-form";

import DatePicker, { registerLocale } from "react-datepicker";
import zhCN from "date-fns/locale/zh-CN";
import "../components/react-datepicker.min.css";
import format from "date-fns/format";

import {
  fetchAddSendReportMulti,
  fetchUpdateSendReportMulti,
} from "../apidata/fetchSendReport";

export default function SendReportForm({
  checked,
  sampleItems,
  changeUi,
  isEdite,
}) {
  const {
    register,
    unregister,
    handleSubmit,
    watch,
    setValue,
    reset,
  } = useForm();

  registerLocale("zhCN", zhCN); //日期中文化
  const [labchargeDate, setLabChargeDate] = useState(new Date());
  const [publishDate, setPublishDate] = useState(new Date());

  const [msg, setMsg] = useState();
  const [checkedItems, setCheckedItems] = useState(); //选中的数据

  useEffect(() => {
    register({ name: "checkedid" });
    register({ name: "labchargedate" });
    register({ name: "publishdate" });
    return () => unregister;
  }, [register, unregister]);

  useEffect(() => {
    const sampleItemsFilter = sampleItems.filter((item, index) => {
      return checked.indexOf(item.id) !== -1;
    });
    setCheckedItems(sampleItemsFilter);

    if (isEdite) {
      setValue("receiveperson", sampleItems[0].receiveperson);
      setValue("phone", sampleItems[0].phone);
      setValue("address", sampleItems[0].address);
      setValue("sendway", Boolean(sampleItems[0].sendway));
      setValue("express", sampleItems[0].express);
      setValue("trackingnumber", sampleItems[0].trackingnumber);
      setValue("remark", sampleItems[0].remark);
      setValue("labchargename", sampleItems[0].labchargename);
      setValue("labchargedate", sampleItems[0].labchargedate);
      setValue("publishname", sampleItems[0].publishname);
      setValue("publishdate", sampleItems[0].publishdate);
      setValue("checkedid", checked);

      setLabChargeDate(Date.parse(sampleItems[0].labchargedate));
      setPublishDate(Date.parse(sampleItems[0].publishdate));
    } else {
      setValue("receiveperson", sampleItems[0].trust.trustperson);
      setValue("phone", sampleItems[0].trust.trustpersonphone);
      setValue("address", sampleItems[0].trust.address);
      setValue("sendway", Boolean(sampleItems[0].trust.sendway));
      setValue("labchargedate", format(new Date(), "yyyy-MM-dd", new Date()));
      setValue("publishdate", format(new Date(), "yyyy-MM-dd", new Date()));
      setValue("checkedid", checked);
    }
  }, [sampleItems, isEdite, checked, setValue]);

  const sendway = watch("sendway");

  const handleLabchargedate = (date) => {
    setValue("labchargedate", format(date, "yyyy-MM-dd", new Date()));
    setLabChargeDate(date);
  };

  const handlePublishdate = (date) => {
    setValue("publishdate", format(date, "yyyy-MM-dd", new Date()));
    setPublishDate(date);
  };

  const submit = async (data) => {
    if (isEdite) {
      try {
        const json = await fetchUpdateSendReportMulti(data);
        setMsg(json.msg);
        changeUi(json.msg);
        reset();
      } catch (error) {
        setMsg(error.message);
      }
    } else {
      try {
        const json = await fetchAddSendReportMulti(data);
        setMsg(json.msg);
        changeUi(json.msg);
        reset();
      } catch (error) {
        setMsg(error.message);
      }
    }
  };

  return (
    <div
      className={css`
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: max-content;
        // grid-template-rows: 1fr 1fr 1fr 1fr; //一样高
        grid-gap: 5px;
        box-sizing: border-box;
      `}
    >
      <div
        className={css`
          grid-column: 1;
          grid-row: 2;
          border: 1px solid black;
          padding: 5px;
        `}
      >
        <h2>批量发放上边的报告,请先核对</h2>
        <h1>报告发放</h1>
        <form onSubmit={handleSubmit(submit)}>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label">接收人</label>
            <div className="col-sm-6">
              <input
                className="form-control"
                type="text"
                ref={register({
                  required: "不能为空",
                  maxLength: {
                    value: 20,
                    message: "不能大于20个字",
                  },
                })}
                name="receiveperson"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label">接收人电话</label>
            <div className="col-sm-6">
              <input
                className="form-control"
                type="text"
                ref={register({
                  required: "不能为空",
                  maxLength: {
                    value: 14,
                    message: "不能大于14个字",
                  },
                })}
                name="phone"
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="sendway" className="col-sm-2 col-form-label">
              是否邮寄
            </label>
            <div className="col-sm-1">
              <input
                className="form-control"
                name="sendway"
                type="checkbox"
                ref={register}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label">地址</label>
            <div className="col-sm-10">
              <input
                className="form-control"
                type="text"
                ref={register({
                  maxLength: {
                    value: 128,
                    message: "不能大于128个字",
                  },
                })}
                name="address"
                disabled={!sendway}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-2 col-form-label">快递公司</label>
            <div className="col-sm-6">
              <input
                className="form-control"
                type="text"
                ref={register({
                  maxLength: {
                    value: 10,
                    message: "不能大10个字",
                  },
                })}
                name="express"
                disabled={!sendway}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-2 col-form-label">运单号</label>
            <div className="col-sm-6">
              <input
                className="form-control"
                type="text"
                ref={register({
                  maxLength: {
                    value: 15,
                    message: "不能大于15个字",
                  },
                })}
                name="trackingnumber"
                disabled={!sendway}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-2 col-form-label">提交人</label>
            <div className="col-sm-5">
              <input
                className="form-control"
                type="text"
                ref={register({
                  maxLength: {
                    value: 10,
                    message: "不能大于10个字",
                  },
                })}
                name="labchargename"
              />
            </div>
            <label className="label">提交日期:</label>
            <DatePicker
              dateFormat="yyyy年MM月dd日"
              selected={labchargeDate}
              onChange={handleLabchargedate}
              locale="zhCN"
            />
            <span></span>
          </div>

          <div className="form-group row">
            <label className="col-sm-2 col-form-label">批准人</label>
            <div className="col-sm-5">
              <input
                className="form-control"
                type="text"
                ref={register({
                  maxLength: {
                    value: 10,
                    message: "不能大于10个字",
                  },
                })}
                name="publishname"
              />
            </div>
            <label className="label">批准日期:</label>
            <DatePicker
              dateFormat="yyyy年MM月dd日"
              selected={publishDate}
              onChange={handlePublishdate}
              locale="zhCN"
            />
          </div>

          <div className="form-group row">
            <label className="col-sm-2 col-form-label">备注</label>
            <div className="col-sm-10">
              <input
                className="form-control"
                type="text"
                ref={register({
                  maxLength: {
                    value: 128,
                    message: "不能大于128个字",
                  },
                })}
                name="remark"
              />
            </div>
          </div>

          <div className="col-sm-2">
            <input className="form-control" type="submit" />
            {msg}
          </div>
        </form>
      </div>

      <div
        className={css`
          grid-column: 1;
          grid-row: 1;
          border: 1px solid black;
          padding: 5px;
        `}
      >
        <ol>
          {!isEdite &&
            checkedItems &&
            checkedItems.map((item) => (
              <li>
                <p>
                  {item.trust.trustperson}(
                  {`JHWT-${
                    item.trust.year
                  }-${item.trust.trustnumber.toString().padStart(4, "0")}`}
                  )
                </p>
              </li>
            ))}
          {isEdite &&
            checkedItems &&
            checkedItems.map((item) => (
              <li>
                <p>
                  {item.receiveperson}(
                  {`JHWT-${item.year}-${item.trustnumber
                    .toString()
                    .padStart(4, "0")}`}
                  )
                </p>
              </li>
            ))}
        </ol>
      </div>
    </div>
  );
}
