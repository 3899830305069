import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import {
  fetchCategories,
  fetchSampleCodes,
  fetchMaxId,
} from "../apidata/fetchSampleData";

import { fetchSampleBlankAdd } from "../apidata/fetchSampleData";

export default function SampleBlank() {
  const {
    register,
    unregister,
    handleSubmit,
    errors,
    setValue,
    setError,
    clearErrors,
  } = useForm();

  useEffect(() => {
    register({ name: "category" }, { required: "类目不能为空?" });
    register({ name: "samplecode" }, { required: "样品编号不能为空" });
    return () => unregister;
  }, [register, unregister]);

  const [msg, setMsg] = useState();
  // 大类别
  const [categoryItems, setCategoryItems] = useState([]); //db
  const [categoryValues, setCategoryValues] = useState(null);
  const [categoryError, setCategoryError] = useState(null);

  // 样品代码
  const [sampleCodeItems, setSampleCodeItems] = useState([]); //db
  const [sampleCodeValues, setSampleCodeValues] = useState(null);
  const [sampleCodeError, setSampleCodeError] = useState(null);

  // 服务端两个maxid
  const [maxId, setMaxId] = useState(null);

  // 显示单号的日期年,以server为准
  const currentday = new Date();
  const year = currentday.getFullYear();

  // 加载大类
  useEffect(() => {
    (async () => {
      try {
        const json = await fetchCategories();
        setCategoryItems(json);
      } catch (e) {
        setCategoryError(e.message || e.statusText);
      }
    })();
  }, []);

  // 加载样品代码
  useEffect(() => {
    if (!categoryValues) {
      return;
    }
    setSampleCodeValues(null); //清空下一级
    setValue("samplecode", null);
    (async () => {
      try {
        const step2 = await fetchSampleCodes(categoryValues);
        setSampleCodeItems(step2);
      } catch (error) {
        setSampleCodeError(error.message || error.statusText);
      }
    })();
  }, [categoryValues, setValue]);

  // 找到最大样品号
  useEffect(() => {
    if (!sampleCodeValues) {
      return;
    }
    (async () => {
      try {
        const json = await fetchMaxId(sampleCodeValues);
        setMaxId(json);
      } catch (error) {
        setSampleCodeError(error.message || error.statusText); //要改成样号取不到
      }
    })();
  }, [sampleCodeValues]);

  // 选择大类
  const handleChangeCategory = (selectedOption) => {
    if (selectedOption) {
      setValue("category", selectedOption); // form 的值
      setCategoryValues(selectedOption); // 控件的值
      return clearErrors("category");
    } else {
      setError("category", "notMatch", "没有选择到类别!");
    }
  };

  // 选择样品代码
  const handleChangeSampleCode = (selectedOption) => {
    if (selectedOption) {
      setValue("samplecode", selectedOption); // form 的值
      setSampleCodeValues(selectedOption); // 控件的值
      return clearErrors("samplecode");
    } else {
      setError("samplecode", "notMatch", "没有选择到样品编号!");
    }
  };

  const onSubmit = async (data) => {
    try {
      const json = await fetchSampleBlankAdd(data);
      setMsg(json.msg);
      setTimeout(() => {
        setMsg("");
        setCategoryValues(null);
        setValue("category", null);
        setSampleCodeValues(null);
        setValue("samplecode", null);
        setValue("num", 1);
      }, 2000);
    } catch (error) {
      setMsg(error.message);
    }
  };

  return (
    <div>
      <h3 style={{ textAlign: "center" }}>预留委找单号和样品单号(占用号码)</h3>
      {msg}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group row">
          <i className="glyphicon glyphicon-cloud"></i>
          <label className="label">类目:</label>
          <Select
            className="select-small"
            name="category"
            placeholder="项目(类别)"
            value={categoryValues}
            options={categoryItems}
            onChange={handleChangeCategory}
            // react-select/issues/3018
            getOptionValue={(option) => option["id"]}
            getOptionLabel={(option) =>
              `${option.certificate === 0 ? "公路" : "水运"} | ${
                option.stdcode
              } | ${option.name}`
            }
          />
          <div style={{ display: "inlneblock", color: "red" }}>
            {categoryError}
            {errors.category && errors.category.message}
          </div>
        </div>

        <div className="form-group row">
          <label className="label">样品代码:</label>
          <Select
            className="select-small"
            name="samplecode"
            placeholder="样品代码"
            value={sampleCodeValues}
            options={sampleCodeItems}
            onChange={handleChangeSampleCode}
            // react-select/issues/3018
            getOptionValue={(option) => option["id"]}
            getOptionLabel={(option) => `${option.stdcode} | ${option.name}`}
          />
          <div style={{ display: "inlneblock", color: "red" }}>
            {sampleCodeError}
            {errors.samplecode && errors.samplecode.message}
          </div>
        </div>
        <div className="form-group row">
          <label className="label">留单数量:</label>
          <input
            type="number"
            ref={register({
              min: {
                value: 1,
                message: "最小为1",
              },
              max: {
                value: 10,
                message: "最大为10",
              },
            })}
            name="num"
            defaultValue="1"
          />

          {errors.num && errors.num.message}
        </div>
        <div>
          {maxId &&
            sampleCodeValues &&
            `委托单号:JHWT-${year}-${maxId?.trustnumber
              .toString()
              .padStart(4, "0")}`}
        </div>

        <div>
          {maxId &&
            sampleCodeValues &&
            `样品单号(起始号):JHYP-${year}-${
              sampleCodeValues?.stdcode
            }-${maxId?.samplenumber.toString().padStart(4, "0")}`}
        </div>

        <input
          className="btn btn-primary"
          style={{ marginLeft: "10%" }}
          type="submit"
        />
      </form>
    </div>
  );
}
