// 在a集合而不在b集合的元素
export function not(a,b) {
    return a.filter(value => b.indexOf(value)===-1)
    
  }
// a,b 的交集
export  function intersection(a, b) {
    return a.filter(value => b.indexOf(value) !== -1);
  }
  
 // a,b 的并集 
export   function union(a, b) {
    return [...a, ...not(b, a)];
  }