import React, { useState, useEffect, useRef, useCallback } from "react";
import { fetchToken, fetchDownloadurl } from "../apidata/fetchQiniu";
import { fetchAddDoc, fetchDeleteDoc, fetchDocList } from "../apidata/fetchDoc";
import { useAlert } from "react-alert";
import { useDropzone } from "react-dropzone";

import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";

import {
  AlertDialog,
  AlertDialogLabel,
  AlertDialogDescription,
} from "@reach/alert-dialog";
import "@reach/dialog/styles.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function Doc({ sample }) {
  const classes = useStyles();
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const [name, setName] = useState();
  const [extName, setExtName] = useState();

  const [myFiles, setMyFiles] = useState([]);

  const [docItems, setDocItems] = useState([]);
  const [currentDoc, setCurrentDoc] = useState();

  // 删除附件
  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const cancelRef = useRef();
  const openDelete = () => setShowDialogDelete(true);
  const closeDelete = () => setShowDialogDelete(false);

  useEffect(() => {
    (async () => {
      try {
        const json = await fetchDocList(sample.id);
        setDocItems(json);
      } catch (error) {
        alert.error(error.message);
      }
    })();
  }, [sample, alert]);

  // 转移到本地state
  const onDrop = useCallback((acceptedFiles) => {
    //setMyFiles([...myFiles, ...acceptedFiles])
    setMyFiles([...acceptedFiles]);
    setName(
      acceptedFiles[0].path.substring(0, acceptedFiles[0].path.lastIndexOf("."))
    );
    setExtName(acceptedFiles[0].path.split(".").pop());
  }, []);
  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop, //转移到本地
    accept: ".jpg,.jpeg,.png,.zip,.rar,.word,.xlsx,.xls,.ppt,.pptx,.pdf",
  });

  const upload = async (e) => {
    e.preventDefault();
    if (myFiles.length === 0) {
      alert.error("请选择文件后,再来点上传");
      return;
    }
    if (!myFiles[0].path.includes(".")) {
      alert.error("你上传的文件没有扩展名,不能上传");
      return;
    }
    const newName = `doc/${sample.samplecode.toLowerCase()}/${sample.year}/${
      sample.id
    }_${name.trim()}.${extName}`;
    setLoading(true);
    try {
      const token = await fetchToken({ key: newName });
      let formData = new FormData();
      formData.append("key", newName);
      formData.append("token", token.token);
      formData.append("file", myFiles[0]);
      //http://upload-z2.qiniup.com
      const res = await fetch(`https://upload-z2.qiniup.com`, {
        method: "post",
        headers: {
          //"Content-Type": "multipart/form-data"
          //"Content-Type": 'application/x-www-form-urlencoded'  有这些都不行
        },
        body: formData,
      });
      if (res.ok) {
        const json = await res.json();
        const obj = {
          name: `${name.trim()}.${extName}`,
          hash: json.hash,
          samplecode: sample.samplecode.toLowerCase(),
          year: sample.year,
          sample_id: sample.id,
        };
        const result = await fetchAddDoc(obj);
        setDocItems([result.data, ...docItems]);
        alert.success(result.msg);
        setMyFiles([]);
        setName("");
        setExtName("");
      } else {
        alert.error(JSON.stringify(res.statusText));
      }
    } catch (error) {
      alert.error(JSON.stringify(error.message));
    }
    setLoading(false);
  };

  const deleteDoc = async () => {
    try {
      const res = await fetchDeleteDoc(currentDoc.id);
      alert.success(res.msg);
      setDocItems(docItems.filter((c) => c.id !== currentDoc.id));
      closeDelete();
    } catch (error) {
      alert.error(error.message);
      closeDelete();
    }
  };
  // 下载
  const download = async (item) => {
    setLoading2(true);
    try {
      const key = `doc/${item.samplecode}/${item.year}/${item.sample_id}_${item.name}`;
      const res = await fetchDownloadurl({ key });
      const file = await fetch(res.downloadurl, {
        method: "get",
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const blob = await file.blob();
      let url = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = url;
      link.download = item.name;
      link.click();
      window.URL.revokeObjectURL(url);
      alert.success(JSON.stringify("下载成功"));
    } catch (error) {
      alert.error(error.message);
    }
    setLoading2(false);
  };

  return (
    <div>
      <p>
        委托单号:
        {sample.trust.certificate === 0 ? "JHWT" : "JHSWT"}
        {`-${sample.trust.year}-${sample.trust.trustnumber
          .toString()
          .padStart(4, "0")}`}
        &nbsp;&nbsp;委托人:{sample.trust.trustperson}&nbsp;&nbsp;样品名称:
        {sample.samplenickname}
      </p>
      <div className="container" style={{ border: "3px blue dotted" }}>
        <div
          {...getRootProps({ className: "dropzone" })}
          style={{ minHeight: "150px" }}
        >
          <input {...getInputProps()} />
          <p>把文件拖拽到框中,然后点上传</p>
          <p>单文件最大5M</p>
          <button type="button" onClick={open}>
            选择文件
          </button>
        </div>
        <aside>
          <ul>
            {myFiles.map((file) => (
              <li key={file.path}>
                {file.path} - {(file.size / 1024).toFixed(2)}kb
              </li>
            ))}
          </ul>
        </aside>
      </div>
      <form className="form-group">
        <div className="form-group">
          <label htmlFor="name">新文件名(通常不需要改动):</label>
          <input
            type="text"
            className="form-control"
            id="name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          .{extName}
        </div>
        <button
          disabled={loading}
          className="btn btn-primary mb-2"
          onClick={upload}
        >
          上传
        </button>
        <button
          className="btn btn-primary mb-2"
          style={{ marginLeft: "20px" }}
          onClick={(e) => {
            e.preventDefault();
            setMyFiles([]);
            setName("");
            setExtName("");
          }}
        >
          清除
        </button>
      </form>

      <div className={classes.root}>
        {docItems.map((c) => (
          <Accordion key={c.id}>
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>{c.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                上传时间:
                {c.create_at.toString().substring(0, 19).replace("T", " ")}
              </Typography>
              <button
                disabled={loading2}
                onClick={() => {
                  download(c);
                }}
              >
                下载
              </button>
              <button
                disabled={loading2}
                style={{ marginLeft: "30px" }}
                onClick={() => {
                  setCurrentDoc(c);
                  openDelete();
                }}
              >
                删除
              </button>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>

      {showDialogDelete && (
        <AlertDialog onDismiss={closeDelete} leastDestructiveRef={cancelRef}>
          <AlertDialogLabel style={{ textAlign: "center", color: "red" }}>
            请再次确认是否删除?
          </AlertDialogLabel>
          <AlertDialogDescription>
            <p>名称:{currentDoc.name}</p>
          </AlertDialogDescription>
          <div className="alert-buttons">
            <button className="btn btn-danger" onClick={deleteDoc}>
              是, 删除
            </button>
            <button
              style={{ marginLeft: "20px" }}
              ref={cancelRef}
              onClick={closeDelete}
            >
              取消, 我不愿意
            </button>
          </div>
        </AlertDialog>
      )}
    </div>
  );
}
