import baseURL from "../Config";





export const fetchPkgAdd = async data => {
  try {
    const res = await fetch(`${baseURL}/api/v1/argumentpkg/addargumentpkgname`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body: JSON.stringify(data)
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};
// 交航界面
export const fetchPkgAddJh = async data => {
  try {
    const res = await fetch(`${baseURL}/api/v1/argumentpkg/addargumentpkgnamejh`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body: JSON.stringify(data)
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};



export const fetchPkgUpdate = async (id,data) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/argumentpkg/operate/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body: JSON.stringify(data)
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

//交航界面
export const fetchPkgUpdateJh = async (id,data) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/argumentpkg/operatejh/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body: JSON.stringify(data)
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

//交航界面
export const fetchPkgCopyJh = async (id,data) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/argumentpkg/copypkg/${id}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body: JSON.stringify(data)
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};


export const fetchPkgDelete = async id => {
  try {
    const res = await fetch(`${baseURL}/api/v1/argumentpkg/deleteargumentandname/${id}`, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      }
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchPkgNameExists = async (category_id,name) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/argumentpkg/exists/${category_id}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body: JSON.stringify({"name":name}) 
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};





export const fetchArgumentPkgs = async category_id => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/argumentpkg/pkgnamejh/${category_id}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        }
      }
    );
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};



export const fetchArgumentPkgsJh = async sampleCode => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/argumentpkg/pkgnamejh/${sampleCode}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        }
      }
    );
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};





