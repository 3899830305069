import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { css } from "emotion";
import { fetchSampleCodeAll } from "../apidata/fetchSampleCodeData";
import { fetchPkgNameExists } from "../apidata/fetchArgumentPkgData.js";

export default function ArgumentPkgCopyJh({
  priceVerItems,
  currentArgumentPkg,
  copy
}) {
  const { register, unregister, handleSubmit, setValue, errors } = useForm();
  // 样品代码
  const [sampleCodeItems, setSmapleCodeItems] = useState([]); //db
  const [sampleCodeValues, setSampleCodeValues] = useState(null);
  const [sampleCodeError, setSampleCodeError] = useState(null);

  useEffect(() => {
    register({ name: "samplecode" }, { required: "没有选择样品代码?" });
    setValue("name", currentArgumentPkg.name);
    setValue("checkall", currentArgumentPkg.checkall);
    setValue("sorting", currentArgumentPkg.sorting + 1);
    return () => unregister;
  }, [register, unregister, setValue, currentArgumentPkg]);

  // 加载样品代码
  useEffect(() => {
    (async () => {
      try {
        const json = await fetchSampleCodeAll();
        setSmapleCodeItems(json);
      } catch (e) {
        setSampleCodeError(e.message || e.statusText);
      }
    })();
  }, []);

  useEffect(() => {
    if (sampleCodeItems && currentArgumentPkg) {
      const temp = sampleCodeItems.find(
        c => c.stdcode === currentArgumentPkg.samplecode
      );

      setSampleCodeValues(temp);
      setValue("samplecode", temp);
    }
  }, [sampleCodeItems, currentArgumentPkg, setValue]);

  // 选择样品代码
  const handleChangeSampleCode = selectedOption => {
    setValue("samplecode", selectedOption); // form 的值
    setSampleCodeValues(selectedOption); // 控件的值
  };

  const validatePkgNameDb = async value => {
    if (value === currentArgumentPkg.name) {
      return false || "不能使用同样的名字!";
    }
    if (sampleCodeValues) {
      try {
        const json = await fetchPkgNameExists(
          sampleCodeValues.category.id,
          value
        );
        return !json || "已存在";
      } catch (error) {
        alert("exist api error!");
      }
    } else {
      return false || "没有样品代码";
    }
  };

  const submit = data => {
    copy(data);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(submit)}>
        <div className="form-group row">
          <i className="glyphicon glyphicon-cloud"></i>
          <label class="col-sm-2 col-form-label">样品代码:</label>
          <Select
            className={css`
              display: inlneblock;
              width: 70%;
              margin-left: 2%;
            `}
            name="samplecode"
            placeholder="样品代码"
            value={sampleCodeValues}
            options={sampleCodeItems}
            onChange={handleChangeSampleCode}
            // react-select/issues/3018
            getOptionValue={option => option["id"]}
            getOptionLabel={option => `${option.stdcode} | ${option.name}`}
          />
          <div style={{ display: "inlneblock", color: "red" }}>
            {sampleCodeError}
            {errors.samplecode && errors.samplecode.message}
          </div>
        </div>
        <div className="form-group row">
          <label class="col-sm-2 col-form-label">包名:</label>
          <div class="col-sm-10">
            <input
              ref={register({
                required: "不能为空",
                maxLength: {
                  value: 128,
                  message: "不能大于128个字"
                },
                validate: value => validatePkgNameDb(value) //异步验证
              })}
              className="form-control"
              name="name"
              type="text"
            />
            {errors.name?.message}
          </div>
        </div>

        <div className="form-group row">
          <label for="inputCheckall" class="col-sm-2 col-form-label">
            是否全选:
          </label>
          <div class="col-sm-1">
            <input
              id="inputChaeckall"
              className="form-control"
              name="checkall"
              type="checkbox"
              ref={register}
            />
            {errors.checkall?.message}
          </div>
        </div>

        <div className="form-group row">
          <label class="col-sm-2 col-form-label">排序号:</label>
          <div class="col-sm-5">
            <input
              ref={register({
                required: "不能为空"
              })}
              name="sorting"
              type="number"
              defaultValue="0"
            />
            {errors.sorting?.message}
          </div>
        </div>

        <button
          type="submit"
          onClick={handleSubmit}
          className="btn btn-primary"
        >
          保存复制(包下边的参数一并保存)
        </button>
      </form>
    </div>
  );
}
