import React, { useState, useRef, useEffect } from "react";
import ContentEditable from "react-contenteditable";

// 控件 收件
export default function ExtraCostList({
  initValues = [],
  handleExtraCostChange,
  isdelete = false
}) {
  const [data, setData] = useState(initValues);
  const [row, setRow] = useState({
    name: "",
    price: "",
    unit: "",
    qty: "",
    remark: ""
  });
  const firstEditable = useRef();

  const disableNewlines = event => {
    const keyCode = event.keyCode || event.which;
    if (keyCode === 13) {
      event.returnValue = false;
      if (event.preventDefault) event.preventDefault();
    }
  };

  const pasteAsPlainText = event => {
    event.preventDefault();
    const text = event.clipboardData.getData("text/plain");
    document.execCommand("insertHTML", false, text);
  };

  const highlightAll = () => {
    setTimeout(() => {
      document.execCommand("selectAll", false, null);
    }, 0);
  };

  const validateNumber = event => {
    const keyCode = event.keyCode || event.which;
    const string = String.fromCharCode(keyCode);
    const regex = /[0-9]/;

    if (!regex.test(string)) {
      event.returnValue = false;
      if (event.preventDefault) event.preventDefault();
    }
  };

  const validateFloat = event => {
    const keyCode = event.keyCode || event.which;
    const string = String.fromCharCode(keyCode);
    const regex = /[0-9]|\./;

    if (!regex.test(string)) {
      event.returnValue = false;
      if (event.preventDefault) event.preventDefault();
    }
  };

  const trimSpaces = string => {
    return string
      .replace(/&nbsp;/g, "")
      .replace(/&amp;/g, "&")
      .replace(/&gt;/g, ">")
      .replace(/&lt;/g, "<");
  };

  //  为新增准备信息
  const handleContentEditable = event => {
    const {
      currentTarget: {
        dataset: { column }
      },
      target: { value }
    } = event;
    setRow({ ...row, [column]: value });
  };

  // 新增
  const add = () => {
    let newId = 0;
    if (data && data.length > 0) {
      newId =
        Math.max.apply(
          Math,
          data.map(i => i.id)
        ) + 1;
    }
    // 输入修正
    const trimmedRow = {
      ...row,
      name: trimSpaces(row.name).trim(),
      unit: trimSpaces(row.unit).trim(),
      remark: trimSpaces(row.remark).trim()
    };
    if (Array.isArray(data)){
      setData([...data, { ...trimmedRow, id: newId }]); // null,不能执行此操作,所以Props不能为null
    }
    else{
      setData([{ ...trimmedRow, id: newId }]); //当data===null 时
    }

    // 清空
    setRow({ name: "", unit: "", price: "", remark: "", qty: "" });
    firstEditable.current.focus();
  };

  // 修改每一项时,动态更新该条记录
  const handleContentEditableUpdate = event => {
    const {
      currentTarget: {
        dataset: { row, column }     // row 代表找到修改行的id data-row={item.id}
      },
      target: { value }
    } = event;
   
    setData(
      data.map(item => {
        return item.id === parseInt(row, 10)
          ? { ...item, [trimSpaces(column).trim()]: trimSpaces(value).trim() }
          : item;
      })
    );
  };

  // 删除
  const deleteRow = row => {
    setData(data.filter(item => item !== row));
  };

  // props call back
  useEffect(() => {
    handleExtraCostChange(data);    
  }, [data, handleExtraCostChange]);

  useEffect(() => {
    setData(initValues);
  }, [initValues]);

  return (
    <div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th style={{ width: "20%" }}>名称</th>
            <th style={{ width: "10%" }}>单价</th>
            <th style={{ width: "10%" }}>单位</th>
            <th style={{ width: "10%" }}>数量</th>
            <th style={{ width: "40%" }}>收费说明</th>
            <th style={{ width: "10%" }}>操作</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item) => (
              <tr key={item.id}>
                <td style={{ width: "10%" }}>
                  <ContentEditable
                    html={item.name}
                    data-column="name"
                    data-row={item.id}
                    className="content-editable"
                    onKeyPress={disableNewlines}
                    onPaste={pasteAsPlainText}
                    onFocus={highlightAll}
                    onChange={handleContentEditableUpdate}
                  />
                </td>
                <td style={{ width: "10%" }}>
                  <ContentEditable
                    html={item.price.toString()}
                    data-column="price"
                    data-row={item.id}
                    className="content-editable"
                    onKeyPress={validateFloat}
                    onPaste={pasteAsPlainText}
                    onFocus={highlightAll}
                    onChange={handleContentEditableUpdate}
                  />
                </td>

                <td style={{ width: "10%" }}>
                  <ContentEditable
                    html={item.unit}
                    data-column="unit"
                    data-row={item.id}
                    className="content-editable"
                    onKeyPress={disableNewlines}
                    onPaste={pasteAsPlainText}
                    onFocus={highlightAll}
                    onChange={handleContentEditableUpdate}
                  />
                </td>
                <td style={{ width: "10%" }}>
                  <ContentEditable
                    html={item.qty.toString()}
                    data-column="qty"
                    data-row={item.id}
                    className="content-editable"
                    onKeyPress={validateNumber}
                    onPaste={pasteAsPlainText}
                    onFocus={highlightAll}
                    onChange={handleContentEditableUpdate}
                  />
                </td>
                <td style={{ width: "10%" }}>
                  <ContentEditable
                    html={item.remark}
                    data-column="remark"
                    data-row={item.id}
                    className="content-editable"
                    onKeyPress={disableNewlines}
                    onPaste={pasteAsPlainText}
                    onFocus={highlightAll}
                    onChange={handleContentEditableUpdate}
                  />
                </td>
                <td style={{ width: "10%" }}>
                  <button
                    disabled={isdelete}
                    onClick={e => {
                      e.preventDefault();
                      deleteRow(item);
                    }}
                  >
                    删除
                  </button>
                </td>
              </tr>
            ))}

          <tr>
            <td>
              <ContentEditable
                html={row.name}
                data-column="name"
                className="content-editable"
                innerRef={firstEditable}
                onKeyPress={disableNewlines}
                onPaste={pasteAsPlainText}
                onFocus={highlightAll}
                onChange={handleContentEditable}
              />
            </td>
            <td>
              <ContentEditable
                html={row.price}
                data-column="price"
                className="content-editable"
                onKeyPress={validateFloat}
                onPaste={pasteAsPlainText}
                onFocus={highlightAll}
                onChange={handleContentEditable}
              />
            </td>

            <td>
              <ContentEditable
                html={row.unit}
                data-column="unit"
                className="content-editable"
                onKeyPress={disableNewlines}
                onPaste={pasteAsPlainText}
                onFocus={highlightAll}
                onChange={handleContentEditable}
              />
            </td>
            <td>
              <ContentEditable
                html={row.qty}
                data-column="qty"
                className="content-editable"
                onKeyPress={validateNumber}
                onPaste={pasteAsPlainText}
                onFocus={highlightAll}
                onChange={handleContentEditable}
              />
            </td>
            <td>
              <ContentEditable
                html={row.remark}
                data-column="remark"
                className="content-editable"
                onKeyPress={disableNewlines}
                onPaste={pasteAsPlainText}
                onFocus={highlightAll}
                onChange={handleContentEditable}
              />
            </td>
            <td>
              <button
                disabled={!(row.name && row.price && row.unit && row.qty)}
                onClick={e=>{
                  e.preventDefault();
                  add();
                }}
              >
                新增
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <hr />
      <ul>
        <p>样品附加信息条数:{data && data.length}附加费总金额:{data && data.map(item => parseFloat(item.price)*parseInt(item.qty)).reduce((x, y) => x + y,0)}</p>
        {data &&
          data.map((item, index) => (
            <li key={index}>
              {item.id}--{item.name}--{item.price}元--{item.qty}{item.unit}--金额{parseFloat(item.price)*parseInt(item.qty)}
            </li>
          ))}
      </ul>
    </div>
  );
}
