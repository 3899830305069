import React,{useState} from "react";
import {fetchStandardUpdateIsenable} from  "../apidata/fetchStandard"

export default function StandardUpdateIsenable({currentStandard,updateUi}) {
  const [msg,setMsg] = useState();

  const onSubmit =async data => {
    try {
      const json = await fetchStandardUpdateIsenable(currentStandard.id);
      updateUi(json);     
    } catch (error) {
      setMsg(error.message);
    }
   
  };

  return (
    <>
      <h2 style={{textAlign:"center"}}>{currentStandard.isenable ? "停用" :"启用"}标准</h2>
        {msg}
        <p>标准代码:{currentStandard.stdcode}</p>
        <p>标准名称:{currentStandard.name}</p>
        <button
          type="submit"
          onClick={()=>{
            onSubmit();
          }}
          className="btn btn-primary"
        >
          {currentStandard.isenable ? "停用" :"启用"}
        </button> 
     
    </>
  );
}

