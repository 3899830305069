import React, { useState, useEffect } from "react";
import {useForm} from "react-hook-form";
import Select from "react-select";
import { css } from "emotion";

import baseURL from "../Config";

const initValues = {
  stdcode: null,
  name: null,
  remark: null,
  category: null
};

function SampleCodeForm({ saveTodb, complete, isEdite, fillValues = initValues }) {
  const {
    register,
    unregister,
    handleSubmit,
    errors,
    watch,
    setValue,
    reset 
  } = useForm({
    defaultValues: fillValues
  });

  // 选择类别
  const [values, setReactSelect] = useState({ selectedOption: [] });
  const handleMultiChange = selectedOption => {
    setValue("category", selectedOption); // form 的值
    setReactSelect({ selectedOption }); // 控件的值
  };
  useEffect(() => {
    register({ name: "category" });
    const { stdcode, name, remark, category } = fillValues;
    setValue("stdcode", stdcode);
    setValue("name", name);
    setValue("remark", remark);
    setValue("category", category); // form 的值
    setReactSelect({ selectedOption: category }); // 控件的值
    return () => unregister;
  }, [fillValues, setValue, register,unregister]);

  // 读取类别
  const [options, setOptions] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(`${baseURL}/api/v1/category/categories`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        },
      });
      const json = await res.json();
      setOptions(json);
    };
    fetchData();
  }, []);

  //const [data, setData] = useState(null);
  //提交到父类保存
  const onSubmit = data => {
    if(values.selectedOption===null){
      alert("没有选择到类目,请重新选择");
      return ;

    }
   // setData(data);    
    saveTodb(data);
   
  };
  // 清空
  useEffect(() => {
    reset(initValues);
    setReactSelect({ selectedOption: null });
  }, [complete, reset]);

  const handleCancel = () => {
    reset(initValues);
    setReactSelect({ selectedOption: null });
  };


  const validateSampleCodeDb = value => {
    const temp = new Promise((resolve, reject) => {
      // 修改时
      if (isEdite && value === fillValues.stdcode) {
        return resolve(true);
      }
  
      fetch(`${baseURL}/api/v1/samplecode/exists`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        },
        body: JSON.stringify({ stdcode: value.toUpperCase() })
      })
        .then(res => {
          if (res.ok) {
            return res.json();
          }
          throw res;
        })
        .then(res => {
          if (res.result) {
            return resolve( false || "样品代码已存在,无需再添加!!!");
          } else {
            return resolve(true);
          }
        })
        .catch(error => {
          return reject(false || error.message || error.statusText);
        });
    });
  
    return temp;
  };

  return (
    <>
      {isEdite ? <h3 style={{textAlign:"center"}}>样品代码(修改)</h3> : <h3 style={{textAlign:"center"}} >样品代码(新增)</h3>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group row">
          <label htmlFor="stdcode" className="col-sm-1 col-form-label">
            样品代码
          </label>
          <div className="col-sm-5">
            <input
              name="stdcode"
              ref={register({
                required: "不能为空",
                pattern: {
                  value: /[A-Za-z]{3}/,
                  message: "必须为字母"
                },
                validate:
                 {
                  a: value => value.length === 3 || "位数必须为3个字母",
                  b: value => validateSampleCodeDb(value)    //异步验证magic
                }
                 
              })}
              type="text"
              className="form-control"
              required
            />
          </div>
          <div className="col-sm-5">
            {errors.stdcode && errors.stdcode.message}
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="name" className="col-sm-1 col-form-label">
            样品名称
          </label>
          <div className="col-sm-5">
            <input
              name="name"
              ref={register({ required: "不能为空" })}
              type="text"
              className="form-control"
              required
            />
          </div>
          <div className="col-sm-5">{errors.name && errors.name.message}</div>
        </div>

        <div className="form-group row">
          <label htmlFor="remark" className="col-sm-1 col-form-label">
            备注
          </label>
          <div className="col-sm-5">
            <input
              name="remark"
              ref={register}
              type="text"
              className="form-control"            
            />
          </div>
          <div className="col-sm-5">
            {errors.remark && errors.remark.message}
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="category" className="col-sm-2 col-form-label">
            类目(项目)
          </label>
          <div className="col-sm-10">
            <Select
              className={css`
                width: 50%;
                margin-left: -10%;
              `}
              name="categorySelect"
              placeholder="项目(类别)"
              value={values.selectedOption}
              options={options}
              onChange={handleMultiChange}
              // react-select/issues/3018
              getOptionValue={option => option["id"]}
              getOptionLabel={option => `${option.certificate===0 ? "公路" : "水运" } | ${option.stdcode} | ${option.name}`}
            />
          </div>
        </div>

        {/* <input type="submit" /> */}
        <button
          type="submit"
          onClick={handleSubmit}
          className="btn btn-primary"
        >
          保存
        </button>

        <button
          type="reset"
          onClick={handleCancel}
          className="btn btn-secondary"
        >
          清空
        </button>
      </form>
      <hr />
      <div>{watch("stdcode")}</div>
     
    </>
  );


}

export default SampleCodeForm;










