import React from 'react'

export default function ProductTable(props) {
    return (
        <table>
            <thead>
                <tr>
                    <th>名称</th>
                    <th>价格</th>
                    <th>操作</th>
                </tr>
            </thead>

            <tbody>
                {   props.products.length >0 ? (
                    props.products.map(product=>(
                        <tr key={product.id}>
                            <td>
                                {product.name}
                            </td>
                            <td>
                                {product.price}
                            </td>
                            <td>
                                <button onClick={()=>{props.editRow(product)}} className="button muted-button" >
                                    修改
                                </button>
                                <button onClick={()=>{props.deleteProduct(product.id)}} className="button muted-button">
                                    删除
                                </button>
                            </td>
                        </tr>
                    ))
                
                ):(
                <tr>
                    <td colSpan={3}>No DATA</td>
                </tr>

                )
                }
            </tbody>
        </table>
    )
}
