import React, { useState, useEffect, useRef } from "react";
import ExtraInfoList from "./ExtraInfoList";
import { useForm } from "react-hook-form";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { css } from "emotion";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import {
  AlertDialog,
  AlertDialogLabel,
  AlertDialogDescription,
} from "@reach/alert-dialog";

import { fetchCategories } from "../apidata/fetchSampleData";
import {
  fetchExtraModelAddUpdate,
  fetchModelName,
  fetchExtraModelDelete,
} from "../apidata/fetchExtraModelData";
import ExtraInfoModelNameUpdate from "./ExtraInfoModelNameUpdate";

export default function ExtraInfoModel() {
  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
  } = useForm();

  // register control component
  useEffect(() => {
    register({ name: "category" }, { required: "类目绝对不能不知道的?" });
    register({ name: "modelname" }, { required: "模板名称?" });
    register({ name: "model" }, { required: "模板" });
    return () => unregister;
  }, [register, unregister, setValue]);

  const [msg, setMsg] = useState();

  // 修改
  const [showDialogUpdate, setShowDialogUpdate] = useState(false);
  const openUpdate = () => setShowDialogUpdate(true);
  const closeUpdate = () => setShowDialogUpdate(false);

  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const cancelRef = useRef();
  const openDelete = () => setShowDialogDelete(true);
  const closeDelete = () => setShowDialogDelete(false);

  // 大类别
  const [categoryItems, setCategoryItems] = useState([]); //db
  const [categoryValues, setCategoryValues] = useState(null);
  const [categoryError, setCategoryError] = useState(null);

  // 模板名称
  const [modelNameItems, setModelNameItems] = useState([]); //db
  const [modelNameValues, setModelNameValues] = useState(null);
  const [modelNameError, setModelNameError] = useState(null);

  // 待提交的数据,子控件发上来的数居
  const [infoItems, setInfoItems] = useState();

  // 发下去的数据
  const [infoItemsOrg, setInfoItemsOrg] = useState([]);

  // 子控件发上来数据
  const handleExtraInfoChange1 = (data) => {
    setInfoItems(data);
    setValue("model", data);
  };

  // 加载大类
  useEffect(() => {
    (async () => {
      try {
        const json = await fetchCategories();
        setCategoryItems(json);
      } catch (e) {
        setCategoryError(e.message || e.statusText);
      }
    })();
  }, []);

  // 加载模板名
  useEffect(() => {
    if (!categoryValues) {
      return;
    }
    // 清空下两级
    setModelNameValues(null);
    setInfoItemsOrg([]);
    (async () => {
      try {
        const json = await fetchModelName(categoryValues["id"]);
        setModelNameItems(json);
        console.log(json);
      } catch (e) {
        setModelNameError(e.message || e.statusText);
      }
    })();
  }, [categoryValues]);

  useEffect(() => {
    if (modelNameValues && modelNameValues.model) {
      setInfoItemsOrg(modelNameValues.model);
    }
    if (modelNameValues && modelNameValues.id === modelNameValues.name) {
      setInfoItemsOrg([]);
    }
  }, [modelNameValues]);

  // 选择大类
  const handleChangeCategory = (selectedOption) => {
    if (selectedOption) {
      setValue("category", selectedOption); // form 的值
      setCategoryValues(selectedOption); // 控件的值
      return clearErrors("category");
    } else {
      setError("category", "notMatch", "没有选择到类别!");
    }
  };

  // 验证新增还是选择自定义参数包名
  const isValidNewOption = (inputValue, selectValue, selectOptions) => {
    if (
      inputValue.trim().length === 0 ||
      selectOptions.find((option) => option.name === inputValue)
    ) {
      return false;
    }
    return true;
  };

  // 处理模板名(新增和选择两种情况)
  const handleChangeModelName = (newValue, { action }) => {
    switch (action) {
      case "select-option":
        setModelNameValues(newValue); // 控件的值
        setValue("modelname", newValue);
        break;
      case "create-option":
        if (modelNameItems !== null) {
          const options = [...modelNameItems, newValue];
          setModelNameItems(options);
          setModelNameValues(newValue); // 控件的值
          setValue("modelname", newValue);
        }
        break;
      default:
        break;
    }
  };

  const updateUi = (json) => {
    setModelNameItems(
      modelNameItems.map((c) => (c.id === json.data.id ? json.data : c))
    );
    setModelNameValues(json.data);
    setMsg(json.msg);
    closeUpdate();
    setTimeout(() => {
      setMsg(null);
    }, 2000);
  };

  const deleteExtraModel = async () => {
    try {
      const json = await fetchExtraModelDelete(modelNameValues.id);
      setMsg(json.msg);
      setModelNameItems(
        modelNameItems.filter((c) => c.id !== modelNameValues.id)
      );
      setModelNameValues(null);
      //setInfoItemsOrg(null);
      closeDelete();
      setTimeout(() => {
        setMsg(null);
      }, 2000);
    } catch (error) {
      setMsg(error.message);
    }
  };

  const submit = async (data) => {
    const json = await fetchExtraModelAddUpdate(data);
    setMsg(json.msg);
    setTimeout(() => {
      document.location.reload();
    }, 2000);
  };

  return (
    <div>
      <h1
        className={css`
          text-align: center;
        `}
      >
        样品附加信息模板维护
      </h1>
      {msg}
      <form onSubmit={handleSubmit(submit)}>
        <div className="form-group row">
          <label
            className={css`
              width: 20%;
              margin-left: 5%;
            `}
          >
            类目:
          </label>
          <Select
            className={css`
              width: 50%;
              margin-left: -15%;
            `}
            name="category"
            placeholder="项目(类别)"
            value={categoryValues}
            options={categoryItems}
            onChange={handleChangeCategory}
            // react-select/issues/3018
            getOptionValue={(option) => option["id"]}
            getOptionLabel={(option) =>
              `${option.certificate === 0 ? "公路" : "水运"} | ${
                option.stdcode
              } | ${option.name}`
            }
          />
          {categoryError}
        </div>
        <div className="form-group row">
          <label
            className={css`
              width: 20%;
              margin-left: 5%;
            `}
          >
            模板名称:
          </label>
          <CreatableSelect
            className={css`
              width: 60%;
              margin-left: -15%;
            `}
            name="modename"
            options={modelNameItems}
            value={modelNameValues}
            placeholder="可以选择也可以输入"
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.name}
            // 取新值
            getNewOptionData={(inputValue, optionLabel) => ({
              id: inputValue,
              name: optionLabel,
            })}
            isValidNewOption={isValidNewOption}
            onChange={handleChangeModelName}
          />
          {modelNameError}
          <button
            style={{ marginLeft: "40px" }}
            onClick={(e) => {
              e.preventDefault();
              if (
                modelNameValues &&
                modelNameValues.id !== modelNameValues.name
              ) {
                openUpdate();
              } else {
                setMsg("不需要修改");
                setTimeout(() => {
                  setMsg(null);
                }, 2000);
              }
            }}
            className="btn btn-primary"
          >
            修改模板名字
          </button>
          <button
            style={{ marginLeft: "40px" }}
            onClick={(e) => {
              e.preventDefault();
              if (
                modelNameValues &&
                modelNameValues.id !== modelNameValues.name
              ) {
                openDelete();
              } else {
                setMsg("不需删除");
                setTimeout(() => {
                  setMsg(null);
                }, 2000);
              }
            }}
            className="btn btn-danger"
          >
            删除模板
          </button>
        </div>
        {infoItems && infoItems.length}
        <ExtraInfoList
          initValues={infoItemsOrg}
          handleExtraInfoChange={handleExtraInfoChange1}
        />
        <input type="submit" />
      </form>

      {showDialogUpdate && (
        <Dialog
          aria-label="modal"
          isOpen={showDialogUpdate}
          onDismiss={closeUpdate}
        >
          <ExtraInfoModelNameUpdate
            currentExtraInfo={modelNameValues}
            updateUi={updateUi}
          />

          <hr />
          <button onClick={closeUpdate}>取消</button>
        </Dialog>
      )}

      {showDialogDelete && (
        <AlertDialog onDismiss={closeDelete} leastDestructiveRef={cancelRef}>
          <AlertDialogLabel style={{ textAlign: "center" }}>
            请再次确认是否删除?
          </AlertDialogLabel>
          <AlertDialogDescription>
            <p>模板名称:{modelNameValues?.name}</p>
          </AlertDialogDescription>
          <div className="alert-buttons">
            <button className="btn btn-danger" onClick={deleteExtraModel}>
              是, 删除
            </button>
            <button
              style={{ marginLeft: "20px" }}
              ref={cancelRef}
              onClick={closeDelete}
            >
              取消, 我不愿意
            </button>
          </div>
        </AlertDialog>
      )}
    </div>
  );
}
