import React, { useState, useEffect } from "react";
import Items from "./OrderItems";
import Pagination from "../components/Pagination";
import baseURL from "../Config";
import "../App.css";

// import Modal from "./components/Modal";
// import useToggle from "./components/Toggle";

//import ProductApp from "./components/ProductApp"

function getCount() {
  const fetchData = async () => {
    const res = await fetch(`${baseURL}/api/v1/order/count`);
    const res1 = await res.json();
    return res1;
  };

  return fetchData().count;
}

const App = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);

  const [count, setCount] = useState(() => getCount());
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(`${baseURL}/api/v1/order/count`);
      const res1 = await res.json();
      setCount(res1.count);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      const res = await fetch(
        `${baseURL}/api/v1/order/page?pagesize=${pageSize}&page=${currentPage}`
      );
      const res1 = await res.json();
      setItems(res1);
      setLoading(false);
    };

    fetchPosts();
  }, [pageSize, currentPage]);

  // Change page
  const paginate = pageNumber => {
    setCurrentPage(pageNumber);
  };

  // const [open, setOpen] = useToggle(false);
  // const [username, setUsername] = useState("chneg");

  // const onChangeUsername = e => setUsername(e.target.value);

  return (
    <div>
      {/* <button onClick={setOpen}>click</button>
      {open && (
        <Modal open={open} toggle={setOpen}>
          <h1>Hello Modal</h1>

          <form onSubmit={e => e.preventDefault()}>
            <input
              type="text"
              name="username"
              value={username.name}
              onChange={e => onChangeUsername(e)}
            />
            <button onClick={setOpen}>close</button>
          </form>
        </Modal>
      )} */}

      <Items items={items} loading={loading} />
      <Pagination
        pageSize={pageSize}
        totalRecords={count}
        currentPage={currentPage}
        onPageChange={paginate}
        defaultPageNeighbours={2}
      />
     
    </div>
  );
};

export default App;
