import React,{useEffect} from "react";
import { useForm } from "react-hook-form";

export default function InvoiceForm({ invoice, isEdite = false, update }) {
  const { register, unregister,setValue, handleSubmit, errors, reset } = useForm();
   // register control component
   useEffect(() => {
    register({ name: "customer_id" }, { required: "need tag" });
    return () => unregister;
  }, [register, unregister]);


  useEffect(() => {
      if(isEdite){
          setValue("name",invoice.name);
          setValue("taxnumber",invoice.taxnumber);
          setValue("address",invoice.address);
          setValue("phone",invoice.phone);
          setValue("bank",invoice.bank);
          setValue("banknumber",invoice.banknumber);
          setValue("customer_id",invoice.customer_id)

      }else{
        setValue("customer_id",invoice.customer_id)
      }
  }, [setValue,invoice,isEdite])

  const submit = (data) => {
    update(data);
    console.log(data);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(submit)}>
        <div className="form-group">
          <label htmlFor="name">名称:</label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            ref={register({
              required: "不能为空",
              maxLength: {
                value: 128,
                message: "不能大于128个字",
              },
            })}
          />
          {errors.name?.message}
        </div>
        <div className="form-group">
          <label htmlFor="taxnumber">税号:</label>
          <input
            type="text"
            className="form-control"
            id="taxnumber"
            name="taxnumber"
            ref={register({
              required: "不能为空",
              maxLength: {
                value: 24,
                message: "不能大于24个字",
              },
            })}
          />
          {errors.taxnumber?.message}
        </div>
        <div className="form-group">
          <label htmlFor="address">地址:</label>
          <input
            type="text"
            className="form-control"
            id="address"
            name="address"
            ref={register({
              required: "不能为空",
              maxLength: {
                value: 128,
                message: "不能大于128个字",
              },
            })}
          />
           {errors.address?.message}
        </div>
        <div className="form-group">
          <label htmlFor="phone">电话:</label>
          <input
            type="text"
            className="form-control"
            id="phone"
            name="phone"
            ref={register({
              required: "不能为空",
              maxLength: {
                value: 13,
                message: "不能大于13个字",
              },
            })}
          />
           {errors.phone?.message}
        </div>
        <div className="form-group">
          <label htmlFor="bank">开户行:</label>
          <input
            type="text"
            className="form-control"
            id="bank"
            name="bank"
            ref={register({
              required: "不能为空",
              maxLength: {
                value: 64,
                message: "不能大于64个字",
              },
            })}
          />
           {errors.bank?.message}
        </div>
        <div className="form-group">
          <label htmlFor="banknumber">银行帐号:</label>
          <input
            type="text"
            className="form-control"
            id="banknumber"
            name="banknumber"
            ref={register({
              required: "不能为空",
              maxLength: {
                value: 32,
                message: "不能大于32个字",
              },
            })}
          />
         {errors.banknumber?.message}  
        </div>
        <button type="submit" class="btn btn-primary">
          {isEdite ? "修改" : "保存"}
        </button>

        <button 
          style={{marginLeft:"50%"}}
          onClick={() => {
            reset({});
          }}
          class="btn btn-primary"
        >
          清空
        </button>
      </form>
    </div>
  );
}
