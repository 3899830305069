import React, { useState, useEffect } from "react";
import ArgumentForm from "./ArgumentForm";
import Pagination from "../components/Pagination";
import baseURL from "../Config";

import Select from "react-select";
import {
  fetchCategories,  
} from "../apidata/fetchSampleData";

import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";


import Price from  "./Price";
import ExtraCost from  "./ExtraCost";



export default function Argument() {

    // 大类别
  const [categoryItems, setCategoryItems] = useState([]); //db
  const [categoryValues, setCategoryValues] = useState(null);
  const [categoryError, setCategoryError] = useState(null);

  // 加载大类
  useEffect(() => {
    (async () => {
      try {
        const json = await fetchCategories();
        setCategoryItems(json);
      } catch (e) {
        setCategoryError(e.message || e.statusText);
      }
    })();
  }, []);

  // 选择大类
  const handleChangeCategory = selectedOption => {      
      setCategoryValues(selectedOption); // 控件的值  
      setCurrentPage(1); 
  };

  // 新增或修改
  const [showDialogEdite, setShowDialogEdite] = useState(false);
  const openEdite = () => setShowDialogEdite(true);
  const closeEdite = () => {
    setShowDialogEdite(false);
    setIsEdite(false);
  }
 
  // 删除
  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const openDelete = () => setShowDialogDelete(true);
  const closeDelete = () => { setShowDialogDelete(false); }


  // 价格管理
  const [showDialogPrice, setShowDialogPrice] = useState(false);
  const openPrice = () => setShowDialogPrice(true);
  const closePrice = () => { setShowDialogPrice(false); }


 // 附加费用
  const [showDialogExtraCost, setShowDialogExtraCost] = useState(false);
  const openExtraCost = () => setShowDialogExtraCost(true);
  const closeExtraCost = () => { setShowDialogExtraCost(false); }

  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState(null);

  // 参数列表
  const [items, setItems] = useState(null);

  // 分页相关
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);

  // 修改,删除
  const [currentArgument, setCurrentArgument] = useState();
  const [isEdite, setIsEdite] = useState(false);

  // 反馈完成,指示清空
  const [complete, setComplete] = useState(false);

  // 总数
  const [count, setCount] = useState(0);
  useEffect(() => {
    let category_id = 1 ;
      if(categoryValues){
        category_id = categoryValues["id"];        
      }
    const fetchData = async () => {      
      const res = await fetch(`${baseURL}/api/v1/argument/count/${category_id}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
        }
      });
      const res1 = await res.json();
      setCount(res1.count);
    };

    fetchData();
  }, [categoryValues]);

  // 分页取数据
  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      let category_id = 1 ;
      if(categoryValues){
        category_id = categoryValues["id"];        
      }
      const res = await fetch(
        `${baseURL}/api/v1/argument/argumentsbycategory/${category_id}?pagesize=${pageSize}&page=${currentPage}`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
          }
        }
      );

      const result = await res.json();
      setItems(result);      
      setLoading(false);

      // else{
      //   const error=res.error;
      //   setMsg(error.message || error.statusText);
      // }
    };
    fetchPosts().catch(setMsg);
  }, [categoryValues, pageSize, currentPage, complete]);

  // Change page
  const paginate = pageNumber => {
    setCurrentPage(pageNumber);
  };

 
  // 保存,持久
  const saveTodb = data => {
    if (isEdite) {
      UpdateSample(data);      
    } else {
      addSample(data);
    }   
   
    closeEdite();
    setTimeout(() => {
      setMsg(null);
    }, 5000);
  };

 
 
 

  return (
    <>
      <Dialog isOpen={showDialogDelete} aria-label="model" onDismiss={closeDelete}>

      <h1>请再三确认哦???</h1>    
      <p>{currentArgument?.stdcode}--{currentArgument?.name}</p>     
            <form onSubmit={e => e.preventDefault()}>
              {/* 实删 */}
              <button
                type="submit"
                onClick={() => {
                  alert("现不支持删除?");
                  closeDelete();
                  setIsEdite(false);
                  return;
                  // deleteSample(currentArgument);
                  // closeDelete();
                  // setIsEdite(false);
                }}
                className="btn btn-danger"
              >
                确定要删除
              </button>
              <button type="reset" onClick={closeDelete} className="btn btn-info" >
                取消
              </button>
            </form>       
      </Dialog>


      
      <Dialog aria-label="modal" isOpen={showDialogEdite} onDismiss={closeEdite}> 
        <ArgumentForm
        saveTodb={saveTodb}       
        isEdite={isEdite}
        // 不是修改,就不要传props,重要
        fillValues={isEdite ? currentArgument : undefined}
      />
      <hr/>
        <button onClick={closeEdite}>关闭</button>
      </Dialog>


      <Dialog  aria-label="modal" isOpen={showDialogPrice} onDismiss={closePrice}> 
        <p>价格管理</p> 
        <p>参数名称:{currentArgument?.stdcode} | {currentArgument?.name}--({currentArgument?.category.name})</p>     
        <Price argument_id={currentArgument?.id} />
        <hr />
        <button onClick={closePrice}>取消</button>
       </Dialog>


       <Dialog  aria-label="modal" isOpen={showDialogExtraCost} onDismiss={closeExtraCost}> 
        <p>附加费用管理</p> 
        <p>参数名称:{currentArgument?.stdcode} | {currentArgument?.name}--({currentArgument?.category.name})</p>     
        <ExtraCost argument_id={currentArgument?.id} />
        <button onClick={closeExtraCost}>取消</button>
       </Dialog>
     

      <div>
      <h2 style={{textAlign:"center"}}>参数维护</h2>
      <button onClick={openEdite}>新增</button>
      </div>
      {/* 操作提示区 */}
      {msg && (
        <div className="alert alert-primary" role="alert">
          {msg}
        </div>
      )}
      
      <div className="form-group row">
            <i className="glyphicon glyphicon-cloud"></i>
            <label className="col-sm-2 col-form-label">类目:</label>
            <Select
              className="select-small"
              name="category"
              placeholder="项目(类别)"
              value={categoryValues}
              options={categoryItems}
              onChange={handleChangeCategory}
              // react-select/issues/3018
              getOptionValue={option => option["id"]}
              getOptionLabel={option => `${option.certificate===0 ? "公路" : "水运"} | ${option.stdcode} | ${option.name}`}
            />
            <div style={{ display: "inlneblock", color: "red" }}>
              {categoryError}             
            </div>
       </div>
      <Pagination
        pageSize={pageSize}
        totalRecords={count}
        currentPage={currentPage}
        onPageChange={paginate}
        defaultPageNeighbours={2}
      />

      {/* 数据列表区 */}
      <table className="table table-sm table-hover">
        <thead className="thead-dark">
          <tr>
            <th>序号</th>
            <th>类别</th>
            <th>参数代码</th>
            <th>参数名称</th>
            <th>单位</th>
            <th>方法</th>         
            <th>备注</th>
            <th>排序</th>
            <th>价格管理</th>
            <th>附加费用</th>
            <th>操作</th>
            <th>操作</th>
          </tr>
        </thead>
        <caption>当前查询共{count}条参数</caption>
        <tbody>
          {items ? (
            items.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item.category.name}</td>
                <td>{item.stdcode}</td>
                <td>{item.name}</td>
                <td>{item.unit}</td>
                <td>{item.method}</td>                
                <td>{item.remark}</td>
                <td>{item.sorting}</td>
                <td>
                  <button
                    onClick={() => {
                      setCurrentArgument(item);                      
                      openPrice();
                    }}
                    className="btn btn-secondary"
                  >
                    价格管理
                  </button>
                </td>
                <td>
                  <button
                    onClick={() => {
                      setCurrentArgument(item);                      
                      openExtraCost();
                    }}
                    className="btn btn-secondary"
                  >
                    附加费用
                  </button>
                </td>
                <td>
                  <button
                    onClick={() => {
                      setCurrentArgument(item);  
                      setIsEdite(true);
                      openEdite();
                    }}
                    className="btn btn-secondary"
                  >
                    修改
                  </button>
                </td>
                <td>
                  <button
                    onClick={() => {
                      setCurrentArgument(item); 
                      openDelete(); 
                    }}
                    className="btn btn-danger"
                  >
                    删除
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>没有数据哦!!</td>
            </tr>
          )}
        </tbody>
      </table>
      <span>{loading && "正在加载,请等待..."}</span>    
    </>
  );

  // function deleteSample(item) {
  //   fetch(`${baseURL}/api/v1/argument/argumnet/${item.id}`, {
  //     method: "delete",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
  //     },
  //     body: null
  //   })
  //     .then(res => {
  //       if (res.status === 200) {
  //         setMsg("删除成功");
  //       }
  //     })
  //     .catch(error => {
  //       setMsg(error.message || error.statusText);
  //     });
  //   // 处理界面
  //   setIsEdite(false);
  //   setItems(items.filter(i => i.id !== item.id));
  // }

  function addSample(data) {
    fetch(`${baseURL}/api/v1/argument/arguments`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body: JSON.stringify(data)
    })
      .then(res => {
        if (res.ok) {
          console.log(res.status);
          return res.json();
        }
        throw res;
      })
      .then(res => {
        setMsg("新增成功");
        setComplete(!complete);
      })
      .catch(error => {
        setMsg(error.message || error.statusText);
      });
  }

  function UpdateSample(data) {
    fetch(`${baseURL}/api/v1/argument/argument/${currentArgument.id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      },
      body: JSON.stringify(data)
    })
      .then(res => {
        if (res.ok) {
          console.log(res.status);
          setComplete(!complete);
          setIsEdite(false);
          return res.json();
        }
        throw res;
      })
      .then(res => {
        setMsg("修改成功");
      })
      .catch(error => {
        setMsg(error.message || error.statusText);
      });
  }
}
