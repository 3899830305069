import React, { useEffect } from "react";

import { useForm } from "react-hook-form";

export default function CustArgumentUpdate({ currentArgument, update }) {
  const { register, unregister, setValue, handleSubmit } = useForm();

  // register control component
  useEffect(() => {
    setValue("sorting", currentArgument.sorting);
    return () => unregister;
  }, [register, unregister, currentArgument, setValue]);

  const onSubmit = data => {
    console.log(data);
    update(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <label>排序:</label>
      <input type="number" name="sorting" ref={register} />
      <button type="submit">保存修改</button>
    </form>
  );
}
