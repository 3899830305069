import React, { useState, useEffect, useCallback, useRef } from "react";

import { useForm } from "react-hook-form";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox";

import DatePicker from "react-datepicker";

import { css } from "emotion";
import "./react-datepicker.min.css";
import "./Combox.css";
import "./Form.css";

import format from "date-fns/format";
import addMonths from "date-fns/addMonths";
import subMonths from "date-fns/subMonths";
import addDays from "date-fns/addDays";
import parseISO from "date-fns/parseISO";

import { fetchTrustAllInfo, fetchAddresses } from "../apidata/fetchTrustData";
import {
  fetchCategories,
  fetchSampleCodes,
  fetchMaxId,
  fetchStandardsPart,
  fetchTrustNumber,
  fetchSampleNumber,
  fetchSampleAdd,
  fetchSampleEdite,
  fetchDesignrequire,
} from "../apidata/fetchSampleData";

import { fetchStandardsFilter } from "../apidata/fetchStandard";

import { fetchArgumentPkgs } from "../apidata/fetchArgumentPkgData";

import {
  fetchArguments,
  fetchArgumentsFromPkg,
} from "../apidata/fetchArgument";

import { fetchCustomers } from "../apidata/fetchCustomerData";
import { fetchProjectnames } from "../apidata/fetchTrustData";

import useQueryProjectPart from "../apidata/useQueryProjectPart";
import useQueryGetSampleAddress from "../apidata/useQueryGetSampleAddress";
import useQuerySampleNickName from "../apidata/useQuerySampleNickName";
import useQuerySampleStatus from "../apidata/useQuerySampleStatus";
import useQueryMakefactory from "../apidata/useQueryMakefactory";

import {
  payments,
  samplesources,
  sendways,
  testkinds,
  sampledealwiths,
} from "../Enum";

import ExtraInfoList from "./ExtraInfoList";
import { fetchModelName } from "../apidata/fetchExtraModelData";

import SampleCodeFind from "./SampleCodeFind";

// 初始值,停用
const initValuesDefault = {
  category: null,
  samplecode: null,
  argumentpkg: null,
  arguments: null,
  accordings: null,
  standards: null,
  trustname: null,
  projectname: null,
  sampleaddress: null,
  projectpart: null,
  samplenickname: null,
  samplestatus: null,
  makefactory: null,
  address: null,

  payment: 0,
  trustdate: new Date(),
  trustperson: null,
  trustpersonphone: null,
  trustpersondate: new Date(),
  lookname: null,
  look_person: null,
  lookperson_cardnumber: null,
  samplingname: null,
  samplingperson: null,
  samplingperson_cardnumber: null,

  modelnumber: null,
  numberofsample: null,
  lotnumber: null,
  batchnumber: null,
  makedate: null,
  factorynumber: null,
  desginrequire: null,
  agetime: null,
  trustdoc: null,
  remark: null,

  samplesource: 0,
  isoffice: 0,
  testkind: 0,

  // extracost:0,
  totalcost: 0,
  reportdate: new Date(),
  sampledealwith: 0,
  sendway: 0,
  receivepersondate: new Date(),
};

let count = 0;
export default function SampleFrom({
  id = null,
  operate = "add",
  initValues = initValuesDefault,
}) {
  count++;

  const {
    register,
    unregister,
    handleSubmit,
    errors,
    watch,
    setValue,
    reset,
    setError,
    clearErrors,
  } = useForm({ validateCriteriaMode: "all" });

  // register control component
  useEffect(() => {
    register({ name: "category" }, { required: "类目绝对不能不知道的?" });
    register({ name: "samplecode" }, { required: "请给我一个唯一的代号?" });
    register({ name: "argumentpkg" });
    register({ name: "arguments" }, { required: "没有参数,你如何做测试?" });
    register(
      { name: "accordings" },
      { required: "没有依据,就等于无没有规距?" }
    );
    register({ name: "standards" }, { required: "没有标准,为何要做检测?" });

    // 委托单位
    register(
      { name: "customer" },
      {
        required: "没有客户,检测会亏本的哦?",
      }
    );
    register(
      { name: "projectname" },
      {
        required: "不能为空",
        maxLength: {
          value: 128,
          message: "不能大于128个字",
        },
      }
    );
    register(
      { name: "projectpart" },
      {
        required: "不能为空",
        maxLength: {
          value: 128,
          message: "不能大于128个字",
        },
      }
    );
    register(
      { name: "sampleaddress" },
      {
        required: "不能为空",
        maxLength: {
          value: 64,
          message: "不能大于64个字",
        },
      }
    );
    register(
      { name: "samplenickname" },
      {
        required: "不能为空",
        maxLength: {
          value: 64,
          message: "不能大于64个字",
        },
      }
    );
    register(
      { name: "samplestatus" },
      {
        required: "不能为空",
        maxLength: {
          value: 128,
          message: "不能大于128个字",
        },
      }
    );
    register(
      { name: "makefactory" },
      {
        required: "不能为空",
        maxLength: {
          value: 128,
          message: "不能大于128个字",
        },
      }
    );
    register({ name: "address" });
    // 附加信息
    register({ name: "extrainfo" });

    register({ name: "trustdate" });
    register({ name: "trustpersondate" });
    register({ name: "reportdate" });
    register({ name: "receivepersondate" });

    setValue("trustdate", new Date());
    setValue("trustpersondate", new Date());
    setValue("reportdate", new Date());
    setValue("receivepersondate", new Date());

    // register({ name: "extracost" })
    // register({ name: "iscustnumber" });
    register({ name: "payment" });
    register({ name: "samplesource" });
    register({ name: "testkind" });
    register({ name: "sendway" });
    register({ name: "sampledealwith" });

    return () => unregister;
  }, [register, unregister, setValue]);
  // server 提示信息
  const [msg, setMsg] = useState(null);
  // 找开全参数列表
  const [turnOnArguments, setTurnOnArguments] = useState(false);
  // 按类目打开标准(全部)
  const [turnOnStandards, setTurnOnStandards] = useState(false);
  // 按参数打开标准(部分)
  const [turnOnStandardsPart, setTurnOnStandardsPart] = useState(false);

  // 指示提交
  const [isSubmit, setIsSubmit] = useState(false);
  // 提示加载委托信息
  const [loading, setLoading] = useState(false);

  // 焦点
  const firstInput = useRef();

  // 修改时,首次覆盖
  const [isFirst, setIsFirst] = useState(true);

  // 显示单号的日期年,以server为准
  const currentday = new Date();
  const year = currentday.getFullYear();

  // 大类别
  const [categoryItems, setCategoryItems] = useState([]); //db
  const [categoryValues, setCategoryValues] = useState(null);
  const [categoryError, setCategoryError] = useState(null);

  // 样品代码
  const [sampleCodeItems, setSampleCodeItems] = useState([]); //db
  const [sampleCodeValues, setSampleCodeValues] = useState(null);
  const [sampleCodeError, setSampleCodeError] = useState(null);

  // 自定义参数包
  const [argumentPkgItems, setArgumentPkgItems] = useState([]); //db
  const [argumentPkgValues, setArgumentPkgValues] = useState(null);
  const [argumentPkgError, setArgumentPkgError] = useState(null);

  // 参数
  const [argumentsItems, setArgumentsItems] = useState([]); //db
  const [argumentsValues, setArgumentsValues] = useState(null);
  const [argumentsError, setArgumentsError] = useState(null);

  // 判定依据
  const [accordingsItems, setAccordingsItems] = useState([]); //db
  const [accordingsValues, setAccordingsValues] = useState(null);
  const [accordingsError, setAccordingsError] = useState(null);

  // 判定标准
  const [standardsItems, setStandardsItems] = useState([]); //db
  const [standardsValues, setStandardsValues] = useState(null);
  const [standardsError, setStandardsError] = useState(null);

  // 委托单位
  const [customerValues, setCustomerValues] = useState();
  const [customerError, setCustomerError] = useState();

  // 工程名称
  const [projectNameItems, setProjectNameItems] = useState();
  const [projectNameError, setProjectNameError] = useState();

  // 查询地址
  const [addressItems, setAddressItems] = useState();
  const [addressError, SetaddressError] = useState();

  // 设计要求
  const [designrequireItems, setDesignrequireItems] = useState();
  const [designrequireError, setDesignrequireError] = useState();

  // 工程部位
  const [projectPartIsFetch, setProjectPartIsFetch] = useState(false);
  const [projectPart, setProjectPart] = useState("");
  const projectParts = useQueryProjectPart(projectPart, projectPartIsFetch);

  // 取样地址
  const [sampleAddressIsFetch, setSampleAddressIsFetch] = useState(false);
  const [sampleAddress, setSampleAddress] = useState("");
  const SampleAddresses = useQueryGetSampleAddress(
    sampleAddress,
    sampleAddressIsFetch
  );

  // 样品名称(呢称)
  const [sampleNickNameIsFetch, setSampleNickNameIsFetch] = useState(false);
  const [sampleNickName, setSampleNickName] = useState("");
  const sampleNickNames = useQuerySampleNickName(
    sampleNickName,
    sampleNickNameIsFetch
  );

  // 样品描述
  const [sampleStatusIsFetch, setSampleStatusIsFetch] = useState(false);
  const [sampleStatus, setSampleStatus] = useState("");
  const sampleStatuses = useQuerySampleStatus(
    sampleStatus,
    sampleStatusIsFetch
  );

  // 制造工厂
  const [makeFactoryIsFetch, setMakeFactoryIsFetch] = useState(false);
  const [makeFactory, setMakefactory] = useState("");
  const makefactories = useQueryMakefactory(makeFactory, makeFactoryIsFetch);

  // 模板名
  const [modelNameItems, setModelNameItems] = useState([]); //db
  const [modelNameValues, setModelNameValues] = useState(null);
  const [modelNameError, setModelNameError] = useState(null);
  const [extraInfo, setExtraInfo] = useState();
  const [extraInfoModel, setExtraInfoModel] = useState([]); //初始值不能为null

  const [payment, setPayment] = useState(0);
  const [sampleSource, setSampleSource] = useState(0);
  const [sampleDealWith, setSampleDealWith] = useState(0);
  const [testKind, setTestKind] = useState(0);
  const [sendWay, setSendWay] = useState(0);

  const [trustDate, setTrustDate] = useState(new Date());
  const [trustPeronDate, setTtrustPersonDate] = useState(new Date());
  const [reportDate, setReportDate] = useState(new Date());
  const [receivePersonDate, setReceivePersonDate] = useState(new Date());

  // 附加费用
  const [extraCost, setExtraCost] = useState(0);
  // 服务端两个maxid
  const [maxId, setMaxId] = useState(null);
  // 是否使用手工号
  const [isCustNumber, setIsCustNumber] = useState(false);
  const [trustNumber, setTrustNumber] = useState(1);
  const [sampleNumber, setSampleNumber] = useState(1);

  // 加载大类
  useEffect(() => {
    (async () => {
      try {
        const json = await fetchCategories();
        setCategoryItems(json);
      } catch (e) {
        setCategoryError(e.message || e.statusText);
      }
    })();
  }, []);

  // 加载样品代码
  useEffect(() => {
    if (!categoryValues) {
      return;
    }
    setSampleCodeValues(null); //清空下一级
    setValue("samplecode", null);
    (async () => {
      try {
        const step2 = await fetchSampleCodes(categoryValues);
        setSampleCodeItems(step2);
      } catch (error) {
        setSampleCodeError(error.message || error.statusText);
      }
    })();
  }, [categoryValues, setValue, operate]);

  // 找到最大样品号
  useEffect(() => {
    if (!sampleCodeValues) {
      return;
    }
    (async () => {
      try {
        const json = await fetchMaxId(sampleCodeValues);
        setMaxId(json);
      } catch (error) {
        setSampleCodeError(error.message || error.statusText); //要改成样号取不到
      }
    })();
  }, [sampleCodeValues]);

  // 动态填充参数包
  useEffect(() => {
    if (!categoryValues) {
      return;
    }
    //清空
    setArgumentPkgValues(null);
    setValue("argumentpkg", null);
    clearErrors("argumentpkg");
    (async () => {
      try {
        const json = await fetchArgumentPkgs(categoryValues);
        setArgumentPkgItems(json);
      } catch (error) {
        setArgumentPkgError(error.message || error.statusText);
      }
    })();
  }, [categoryValues, setValue, clearErrors]);

  // 按大类填充参数
  useEffect(() => {
    if (!categoryValues) {
      return;
    }
    setArgumentsValues(null);
    setValue("arguments", null);
    (async () => {
      try {
        const json = await fetchArguments(categoryValues.id);
        setArgumentsItems(json);
      } catch (error) {
        setArgumentsError(error.message || error.statusText);
      }
    })();
  }, [categoryValues, setValue, turnOnArguments]);

  // 显示所在类的依据和标准
  useEffect(() => {
    if (!categoryValues) {
      return;
    }
    // 关掉按参数出标准
    setTurnOnStandardsPart(false);

    setAccordingsValues(null);
    setValue("accordings", null);
    setStandardsValues(null);
    setValue("sandards", null);

    (async () => {
      try {
        const json = await fetchStandardsFilter(categoryValues);
        setAccordingsItems(json);
        setStandardsItems(json);
      } catch (error) {
        setAccordingsError(error.message || error.statusText);
        setStandardsError(error.message || error.statusText);
      }
    })();
  }, [categoryValues, setValue, turnOnStandards, setTurnOnStandardsPart]);

  // 按小类填充参数
  useEffect(() => {
    if (!argumentPkgValues) {
      return;
    }
    setArgumentsValues(null);
    setValue("arguments", null);

    (async () => {
      try {
        const json = await fetchArgumentsFromPkg(argumentPkgValues.id);
        setArgumentsItems(json);
      } catch (error) {
        setArgumentsError(error.message || error.statusText);
      }
    })();
  }, [argumentPkgValues, setValue]);

  // 按所选参数加载依据和标准(部分)
  useEffect(() => {
    if (
      !argumentsValues ||
      !turnOnStandardsPart ||
      argumentsValues.length === 0
    ) {
      return;
    }
    setAccordingsValues(null);
    setValue("accordings", null);
    setStandardsValues(null);
    setValue("sandards", null);

    const idList = argumentsValues.map((item) => item.id);

    (async () => {
      try {
        const json = await fetchStandardsPart(idList);
        setAccordingsItems(json);
        setStandardsItems(json);
      } catch (error) {
        setAccordingsError(error.message || error.statusText);
        setStandardsError(error.message || error.statusText);
      }
    })();
  }, [argumentsValues, setValue, turnOnStandardsPart]);

  // 委托单位为工程名称,设计要求提供数据
  useEffect(() => {
    if (customerValues) {
      (async () => {
        try {
          const json = await fetchProjectnames(customerValues.name);
          setProjectNameItems(json);
          if (!isFirst) {
            setValue("projectname", "");
          }
        } catch (error) {
          setProjectNameError(error.message || error.statusText);
        }
      })();

      (async () => {
        try {
          const json = await fetchAddresses(customerValues.name);
          setAddressItems(json);
          // 修改或复制,首次加载,不能清空
          if (!isFirst) {
            setValue("address", "");
          }
        } catch (error) {
          SetaddressError(error.message || error.statusText);
        }
      })();

      (async () => {
        try {
          const json = await fetchDesignrequire(customerValues.name);
          setDesignrequireItems(json);
          if (!isFirst) {
            setValue("designrequire", "");
          }
        } catch (error) {
          setDesignrequireError(error.message || error.statusText);
        }
      })();
    }
  }, [customerValues, setValue, isFirst]);

  // 动态填充模板名
  useEffect(() => {
    if (!categoryValues) {
      return;
    }
    //清空
    setModelNameItems(null);
    setModelNameValues(null);

    (async () => {
      try {
        const json = await fetchModelName(categoryValues["id"]);
        setModelNameItems(json);
      } catch (error) {
        setModelNameError(error.message || error.statusText);
      }
    })();
  }, [categoryValues, setValue]);

  // 选到模板名后,载入模板
  useEffect(() => {
    if (modelNameValues && modelNameValues.model) {
      setExtraInfoModel(modelNameValues.model);
    }
  }, [modelNameValues]);

  // pull server data  异步 委托单位数据
  const promiseOptions = async (inputValue) => {
    if (inputValue === null) {
      return Promise.reject("error");
    }
    if (inputValue.length < 1) {
      return Promise.reject("error");
    }

    try {
      const json = await fetchCustomers(inputValue);
      setCustomerValues(null);
      return json;
    } catch (error) {
      setCustomerError(error.message || error.statusText);
      return Promise.reject(error);
    }
  };

  // 选择大类
  const handleChangeCategory = (selectedOption) => {
    if (selectedOption) {
      setValue("category", selectedOption); // form 的值
      setCategoryValues(selectedOption); // 控件的值
      setIsFirst(false);
      return clearErrors("category");
    } else {
      setError("category", "notMatch", "没有选择到类别!");
    }
  };

  // 选择样品代码
  const handleChangeSampleCode = (selectedOption) => {
    if (selectedOption) {
      setValue("samplecode", selectedOption); // form 的值
      setSampleCodeValues(selectedOption); // 控件的值
      setIsFirst(false);
      setIsCustNumber(false); //主动关闭手动号,到时打开,再更新
      return clearErrors("samplecode");
    } else {
      setError("samplecode", "notMatch", "没有选择到样品编号!");
    }
  };

  // 选择参数包(虚包)
  const handleChangeArgumentPkg = (selectedOption) => {
    if (selectedOption) {
      setValue("argumentpkg", selectedOption); // form 的值
      setArgumentPkgValues(selectedOption); // 控件的值
      return clearErrors("argumentpkg");
    } else {
      setError("argumentpkg", "notMatch", "没有选择到自定义参数包?");
    }
  };

  // 选择参数
  const handleChangeArguments = (selectedOption) => {
    if (selectedOption) {
      setValue("arguments", selectedOption); // form 的值
      setArgumentsValues(selectedOption); // 控件的值
      return clearErrors("arguments");
    } else {
      setError("arguments", "notMatch", "没有选择到样品编号!");
    }
  };
  // 计算总价
  useEffect(() => {
    const temp =
      argumentsValues &&
      argumentsValues.length >= 1 &&
      argumentsValues.map((x) => x.price).reduce((x, y) => x + y);
    setValue("totalcost", temp + parseInt(extraCost), "0");
  }, [argumentsValues, extraCost, setValue]);

  // 选择依据
  const handleChangeAccordings = (selectedOption) => {
    if (selectedOption) {
      setValue("accordings", selectedOption); // form 的值
      setAccordingsValues(selectedOption); // 控件的值
      return clearErrors("accordings");
    } else {
      setError("accordings", "notMatch", "没有选择到样品编号!");
    }
  };

  // 选择判定标准
  const handleChangeStandards = (selectedOption) => {
    if (selectedOption) {
      setValue("standards", selectedOption); // form 的值
      setStandardsValues(selectedOption); // 控件的值
      return clearErrors("standards");
    } else {
      setError("standards", "notMatch", "没有选择到样品编号!");
    }
  };

  // 查询委托单位名称
  const handleCustomerChange = (event) => {
    setValue("customer", event); // form 的值
    setCustomerValues(event);
    if (!event) {
      setError("customer", "notMatch", "没有选择到委托单位?");
    }
    return clearErrors("customer");
  };

  // 查询工程部位名称
  const handleProjectPartChange = (event) => {
    // fix space bug
    if (event.target.value !== " ") {
      setProjectPart(event.target.value);
      setValue("projectpart", event.target.value);
      setProjectPartIsFetch(true);
      return clearErrors("projectpart");
    }
  };

  const handlePaymentChange = (event) => {
    setValue("payment", event.target.value);
    setPayment(event.target.value);
  };

  const handleSampleSourceChange = (event) => {
    setValue("samplesource", event.target.value);
    setSampleSource(event.target.value);
  };

  const handleSampleDealWithChange = (event) => {
    setValue("sampledealwith", event.target.value);
    setSampleDealWith(event.target.value);
  };

  const handleTestKindChange = (event) => {
    setValue("testkind", event.target.value);
    setTestKind(event.target.value);
  };

  const handleSendWayChange = (event) => {
    setValue("sendway", event.target.value);
    setSendWay(event.target.value);
  };

  // 查询取样地址
  const handleSampleAddressChange = (event) => {
    // fix space bug
    if (event.target.value !== " ") {
      setSampleAddress(event.target.value);
      setValue("sampleaddress", event.target.value);
      setSampleAddressIsFetch(true);
      return clearErrors("sampleaddress");
    }
  };

  // 查询样品别名
  const handleSampleNickNameChange = (event) => {
    // fix space bug
    if (event.target.value !== " ") {
      setSampleNickName(event.target.value);
      setValue("samplenickname", event.target.value);
      setSampleNickNameIsFetch(true);
      return clearErrors("samplenickname");
    }
  };

  // 查询样品描述
  const handleSampleStatusChange = (event) => {
    // fix space bug
    if (event.target.value !== " ") {
      setSampleStatus(event.target.value);
      setValue("samplestatus", event.target.value);
      setSampleStatusIsFetch(true);
      return clearErrors("samplestatus");
    }
  };

  // 查询制造工厂
  const handleMakefactoryChange = (event) => {
    // fix space bug
    if (event.target.value !== " ") {
      setMakefactory(event.target.value);
      setValue("makefactory", event.target.value);
      setMakeFactoryIsFetch(true);
      return clearErrors("makefactory");
    }
  };

  // 模板选择
  const handleChangeModel = (selectedOption) => {
    setModelNameValues(selectedOption);
  };

  // 接受从子控件发上来的,附加信息
  const handleExtraInfoChange = (data) => {
    setExtraInfo(data);
    setValue("extrainfo", extraInfo); //给form
  };

  // 报告日期
  const handleReportDateChange = (date) => {
    setValue("reportdate", date);
    setReportDate(date);
  };
  // 委托日期
  const handleTrustDateChange = (date) => {
    setValue("trustdate", date);
    setTrustDate(date);
  };
  // 委托人日期
  const handleTrustPersonDateChange = (date) => {
    setValue("trustpersondate", date);
    setTtrustPersonDate(date);
  };

  // 收样人日期
  const handleReceivePersonDateChange = (date) => {
    setValue("receivepersondate", date);
    setReceivePersonDate(date);
  };
  // 手工号与自动号切换
  const handleIsCustNumberChange = () => {
    if (!sampleCodeValues) {
      return;
    }
    setValue("iscustnumber", !isCustNumber);
    setIsCustNumber(!isCustNumber);
    fetchMaxId(sampleCodeValues)
      .then((json) => {
        setTrustNumber(json.trustnumber);
        setSampleNumber(json.samplenumber);
      })
      .catch((error) => {
        setMsg(error.message || error.statusText);
      });
  };
  const handleTrustNumberChange = (event) => {
    setTrustNumber(event.target.value);
  };
  const handleSampleNumberChange = (event) => {
    setSampleNumber(event.target.value);
  };

  // 查询助手,查询(委托单位+工程名称下的信息)
  const fetchMyorder = async (trustname, projectname) => {
    if (!trustname || trustname.length < 5) {
      alert("请选择委托单位");
      return;
    }
    if (!projectname || projectname.length < 3) {
      alert("请先输入工程名称");
      return;
    }
    setLoading(true);
    try {
      const json = await fetchTrustAllInfo(trustname, projectname);
      fillpart(json);
    } catch (error) {
      setMsg(error.message || error.statusText);
    }
    setLoading(false);
  };
  // 填充委托信息
  const fillpart = useCallback(
    (trust) => {
      setValue("trustperson", trust.delegrate_person);
      clearErrors("trustperson");
      setValue("trustpersonphone", trust.delegrate_phone);
      clearErrors("trustpersonphone");
      setValue("sampleperson", trust.sample_person);
      clearErrors("sampleperson");
      setValue("sampleperson_cardnumber", trust.sampleperson_certificatenumber);
      clearErrors("sampleperson_cardnumber");
      setValue("lookname", trust.lookunit_name);
      clearErrors("lookname");
      setValue("lookperson", trust.look_person);
      clearErrors("lookperson");
      setValue("lookperson_cardnumber", trust.look_certificatenumber);
      clearErrors("lookperson_cardnumber");
      setValue("samplingname", trust.samplingunit);
      clearErrors("samplingname");
      setValue("samplingperson", trust.sampling_person);
      clearErrors("samplingperson");
      setValue("samplingperson_cardnumber", trust.sampling_certificatenumber);
      clearErrors("samplingperson_cardnumber");

      setValue("address", trust.address);
      clearErrors("address");

      // setPayment(trust.payment);
      // setValue("payment", trust.payment);  暂时不能用
    },
    [setValue, clearErrors]
  );

  // 验证委托单号
  const validateTrustNumberFromDb = async (value) => {
    if (!isCustNumber) {
      return true;
    }

    try {
      const has = await fetchTrustNumber(value);
      return !has || "系统已存在?"; //存在,就要取反,表明验证不通过
    } catch (error) {
      setMsg(error.statusText);
      return false || "系统异常?";
    }
  };
  // 验证样品单号
  const validateSampleNumberFromDb = async (value) => {
    if (!isCustNumber) {
      return true;
    }
    if (!sampleCodeValues) {
      return false || "样品代码没有选?";
    }
    console.log(sampleCodeValues);
    console.log(value);
    try {
      const has = await fetchSampleNumber(sampleCodeValues.stdcode, value);
      return !has || "系统已还在?";
    } catch (error) {
      setMsg(error.statusText);
      return false || "系统异常?";
    }
  };

  // 清空
  const clear = () => {
    // 受控清空
    setCustomerValues(null);
    setCategoryValues(null);
    setSampleCodeValues(null);
    setArgumentPkgValues(null);
    setArgumentsValues(null);
    setAccordingsValues(null);
    setStandardsValues(null);
    setExtraCost(0);

    // 别的简单的清空
    reset(initValues);

    // inputTrustName.current.value=""; 此句与setValue 或 reset冲突
    // 下边没有作用
    setProjectPart(null);
    setSampleAddress(null);
    setSampleNickName(null);
    setSampleStatus(null);
    setMakefactory(null);
  };

  //  copy or edite
  useEffect(() => {
    if (
      operate !== "add" &&
      isFirst &&
      categoryItems &&
      initValues.category_id
    ) {
      console.log("category");
      const temp = categoryItems.find((c) => (c.id = initValues.category_id));
      setCategoryValues(temp);
      setValue("category", temp);
    }
  }, [
    operate,
    isFirst,
    initValues,
    categoryItems,
    setCategoryValues,
    setValue,
  ]);

  //  copy or edite
  useEffect(() => {
    if (
      operate !== "add" &&
      isFirst &&
      categoryValues &&
      sampleCodeItems &&
      initValues.samplecode
    ) {
      const temp = sampleCodeItems.find(
        (c) => (c.stdcode = initValues.samplecode)
      );
      setSampleCodeValues(temp);
      setValue("samplecode", temp);
    }
  }, [
    operate,
    isFirst,
    initValues,
    categoryValues,
    sampleCodeItems,
    setSampleCodeValues,
    setValue,
  ]);
  //  copy or edite
  useEffect(() => {
    if (
      operate !== "add" &&
      isFirst &&
      categoryValues &&
      argumentsItems &&
      initValues.arguments
    ) {
      console.log("arguments");
      setArgumentsValues(initValues.arguments);
      setValue("arguments", initValues.arguments);
      setValue("totalcost", initValues.totalcost); // 保证原总价还原.
    }
  }, [
    operate,
    isFirst,
    initValues,
    categoryValues,
    argumentsItems,
    setArgumentsValues,
    setValue,
  ]);
  //  copy or edite
  useEffect(() => {
    if (
      operate !== "add" &&
      isFirst &&
      categoryValues &&
      accordingsItems &&
      initValues.accordings
    ) {
      console.log("accordings");
      setAccordingsValues(initValues.accordings);
      setValue("accordings", initValues.accordings);
    }
  }, [
    operate,
    isFirst,
    initValues,
    categoryValues,
    accordingsItems,
    setAccordingsValues,
    setValue,
  ]);
  //  copy or edite
  useEffect(() => {
    if (
      operate !== "add" &&
      isFirst &&
      categoryValues &&
      standardsItems &&
      initValues.standards
    ) {
      console.log("standards");
      setStandardsValues(initValues.standards);
      setValue("standards", initValues.standards);
    }
  }, [
    operate,
    isFirst,
    initValues,
    categoryValues,
    standardsItems,
    setStandardsValues,
    setValue,
  ]);

  //  copy or edite
  useEffect(() => {
    if (operate !== "add" && isFirst) {
      setMaxId({
        trustnumber: initValues.trust.trustnumber,
        samplenumber: initValues.samplenumber,
      });
    }
  }, [initValues, operate, isFirst]);

  // 修改,复制时填充,初始化界面
  useEffect(() => {
    if (operate !== "add") {
      // 以后可以与上边共用
      setValue("trustperson", initValues.trust.trustperson);
      setValue("trustpersonphone", initValues.trust.trustpersonphone);
      setValue("sampleperson", initValues.trust.sampleperson);
      setValue(
        "sampleperson_cardnumber",
        initValues.trust.sampleperson_cardnumber
      );
      setValue("lookname", initValues.trust.lookname);
      setValue("lookperson", initValues.trust.lookperson);
      setValue("lookperson_cardnumber", initValues.trust.lookperson_cardnumber);
      setValue("samplingname", initValues.trust.samplingname);
      setValue("samplingperson", initValues.trust.samplingperson);
      setValue(
        "samplingperson_cardnumber",
        initValues.trust.samplingperson_cardnumber
      );

      setCustomerValues({
        id: initValues.trust.customer_id,
        pinyin: "pinyin",
        name: initValues.trust.trustname,
      });
      setProjectPart(initValues.projectpart);
      setSampleAddress(initValues.sampleaddress);
      setSampleNickName(initValues.samplenickname);
      setSampleStatus(initValues.samplestatus);
      setMakefactory(initValues.makefactory);
      setValue("customer", {
        id: initValues.trust.customer_id,
        pinyin: "pinyin",
        name: initValues.trust.trustname,
      });
      setValue("projectname", initValues.trust.projectname);
      setValue("projectpart", initValues.projectpart);
      setValue("sampleaddress", initValues.sampleaddress);
      setValue("samplenickname", initValues.samplenickname);
      setValue("samplestatus", initValues.samplestatus);
      setValue("makefactory", initValues.makefactory);
      setValue("address", initValues.trust.address);
      //inputTrustName.current.value=initValues.trust.trustname;

      setPayment(initValues.trust.payment); // 要记得json格式
      setSampleSource(initValues.trust.samplesource);
      setTestKind(initValues.trust.testkind);
      setSampleDealWith(initValues.trust.sampledealwith);
      setSendWay(initValues.trust.sendway);
      setValue("payment", initValues.trust.payment);
      setValue("samplesource", initValues.trust.samplesource);
      setValue("testkind", initValues.trust.testkind);
      setValue("sampledealwith", initValues.trust.sampledealwith);
      setValue("sendway", initValues.trust.sendway);

      setReportDate(parseISO(initValues.reportdate));
      setTrustDate(parseISO(initValues.trustdate));
      setTtrustPersonDate(parseISO(initValues.trustpersondate));
      setReceivePersonDate(parseISO(initValues.receivepersondate));

      setValue("reportdate", parseISO(initValues.reportdate));
      setValue("trustdate", parseISO(initValues.trustdate));
      setValue("trustperondate", parseISO(initValues.trust.trustpersondate));
      setValue("receivepersondate", parseISO(initValues.receivepersondate));

      setExtraCost(initValues.extracost);
      setValue("extracost", initValues.extracost);

      setValue("totalcost", initValues.totalcost);
      setValue("numberofsample", initValues.numberofsample);
      setValue("modelnumber", initValues.modelnumber);
      setValue("designrequire", initValues.designrequire);
      setValue("trustdoc", initValues.trustdoc);
      setValue("remark", initValues.remark);

      // 附加信息
      setExtraInfoModel(
        initValues.extrainfo === null ? [] : initValues.extrainfo
      );
      setValue("extrainfo", initValues.extrainfo);

      setTrustNumber(initValues.trust.trustnutmber + 1);
      setValue("trustnumber", initValues.trust.trustnumber + 1);

      setSampleNumber(initValues.samplenumber + 1);
      setValue("samplenumber", initValues.samplenumber + 1);
    } else {
      //  新增 初始值
      setValue("extracost", 0);
      setValue("iscustnumber", false);

      setValue("payment", 0);
      setValue("samplesource", 0);
      setValue("testkind", 0);
      setValue("sampledealwith", 0);
      setValue("sendway", 0);
    }
  }, [setValue, initValues, operate]);

  // 提交server
  const onSubmit = async (data) => {
    // triggerValidation();
    console.log(data);
    setIsSubmit(true);
    if (operate === "edite") {
      try {
        const json = await fetchSampleEdite(id, data);
        setMsg(json.msg);
        setTimeout(() => {
          document.location.reload();
          firstInput.current.focus();
          //window.scrollTo(0, 0);
        }, 2000);
      } catch (error) {
        setMsg(error.message || error.statusText);
      }
    } else {
      try {
        const json = await fetchSampleAdd(data);
        setMsg(json.msg);
        setTimeout(() => {
          document.location.reload();
          firstInput.current.focus();
        }, 2000);
      } catch (error) {
        setMsg(error.message || error.statusText);
      }
    }
    setIsSubmit(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        className={css`
          display: grid;
          grid-template-columns: 1fr 0.2fr;
          grid-auto-rows: max-content;
          // grid-template-rows: 1fr 1fr 1fr 1fr; //一样高
          grid-gap: 5px;
          box-sizing: border-box;
        `}
      >
        <div
          className={css`
            grid-column: 1;
            grid-row: 1;
            border: 1px solid black;
            padding: 5px;
          `}
        >
          <div>
            <label className="label">委托单位:</label>

            <div
              className={css`
                width: 80%;
                height: atuo;
                display: inline-block;
              `}
            >
              <AsyncSelect
                name="trustname"
                cacheOptions
                defaultOptions
                loadOptions={promiseOptions}
                getOptionValue={(option) => option["id"]}
                onChange={handleCustomerChange}
                getOptionLabel={(option) => `${option.pinyin} | ${option.name}`}
                value={customerValues}
                placeholder="请输入委托单位名称"
                ref={firstInput}
              />
            </div>
          </div>

          <div className="form-group row">
            {customerError}
            <div className="error">{errors.customer?.message}</div>
          </div>

          <div className="form-group row">
            <i className="glyphicon glyphicon-cloud"></i>
            <label className="label">类目:</label>
            <Select
              className="select-small"
              name="category"
              placeholder="项目(类别)"
              value={categoryValues}
              options={categoryItems}
              onChange={handleChangeCategory}
              // react-select/issues/3018
              getOptionValue={(option) => option["id"]}
              getOptionLabel={(option) => `${option.stdcode} | ${option.name}`}
            />
            <div style={{ display: "inlneblock", color: "red" }}>
              {categoryError}
              {errors.category && errors.category.message}
            </div>
          </div>

          <div className="form-group row">
            <label className="label">样品代码:</label>
            <Select
              className="select-small"
              name="samplecode"
              placeholder="样品代码"
              value={sampleCodeValues}
              options={sampleCodeItems}
              onChange={handleChangeSampleCode}
              // react-select/issues/3018
              getOptionValue={(option) => option["id"]}
              getOptionLabel={(option) => `${option.stdcode} | ${option.name}`}
            />
            <div style={{ display: "inlneblock", color: "red" }}>
              {sampleCodeError}
              {errors.samplecode && errors.samplecode.message}
            </div>
          </div>

          <div className="form-group row">
            <label className="label">自定义参数包:</label>
            <Select
              className="select-small"
              name="argumnentpkg"
              isClearable
              placeholder="参数包(可以不选的哦,不小心选了的话,等下可以点右边取消)"
              value={argumentPkgValues}
              options={argumentPkgItems}
              onChange={handleChangeArgumentPkg}
              // react-select/issues/3018
              getOptionValue={(option) => option["id"]}
              getOptionLabel={(option) => `${option.name}`}
            />
            <span
              onClick={() => {
                setTurnOnArguments(!turnOnArguments);
              }}
            >
              我不想选了
            </span>
            {argumentPkgError}
            {errors.argumentPkg && errors.argumentPkg.message}
          </div>

          <div className="form-group row">
            <label className="label">参数选择:</label>
            <Select
              className="select-big"
              name="arguments"
              isMulti
              closeMenuOnSelect={false}
              isClearable
              placeholder="参数(可多选)"
              value={argumentsValues}
              options={argumentsItems}
              onChange={handleChangeArguments}
              // react-select/issues/3018
              getOptionValue={(option) => option["id"]}
              getOptionLabel={(option) => `${option.stdcode} | ${option.name}`}
            />
          </div>
          <div className="error">
            {argumentsError}
            {errors.arguments && errors.arguments.message}
          </div>

          <div className="form-group row">
            <label className="label">检测依据:</label>
            <Select
              className="select-big"
              name="accordings"
              isMulti
              closeMenuOnSelect={false}
              isClearable
              placeholder="检测依据(可多选)"
              value={accordingsValues}
              options={accordingsItems}
              onChange={handleChangeAccordings}
              // react-select/issues/3018
              getOptionValue={(option) => option["id"]}
              getOptionLabel={(option) => `${option.stdcode} | ${option.name}`}
            />
            <button
              style={{
                marginLeft: "10px",
                width: "atuo",
                height: "36px",
                display: "inlineblock",
              }}
              onClick={(e) => {
                e.preventDefault();
                setTurnOnStandards(!turnOnStandards);
              }}
            >
              按项目
            </button>
            <button
              style={{
                marginLeft: "10px",
                width: "atuo",
                height: "36px",
                display: "inlineblock",
              }}
              onClick={(e) => {
                e.preventDefault();
                setTurnOnStandardsPart(true);
              }}
            >
              按参数
            </button>
          </div>
          <div className="error">
            {accordingsError}
            {errors.accordings && errors.accordings.message}
          </div>

          <div className="form-group row">
            <label className="label">判定依据:</label>
            <Select
              className="select-big"
              name="standards"
              isMulti
              closeMenuOnSelect={false}
              isClearable
              placeholder="判定依据(可多选)"
              value={standardsValues}
              options={standardsItems}
              onChange={handleChangeStandards}
              // react-select/issues/3018
              getOptionValue={(option) => option["id"]}
              getOptionLabel={(option) => `${option.stdcode} | ${option.name}`}
            />
          </div>
          <div className="error">
            {standardsError}
            {errors.standards && errors.standards.message}
          </div>
        </div>

        <div
          className={css`
            grid-column: 1;
            grid-row: 2;
            border: 1px solid black;
            padding: 5px;
          `}
        >
          <div className="form-group row">
            <label htmlFor="myBrowser" className="label">
              工程名称:
            </label>
            <input
              className={css`
                width: 75%;
                height: 36px;
                display: inline-block;
              `}
              list="projectnames"
              id="projectname"
              name="projectname"
              ref={register}
              autoComplete="off"
            />
            <datalist id="projectnames">
              {projectNameItems &&
                projectNameItems.map((item, index) => (
                  <option key={index} value={item} />
                ))}
            </datalist>

            <button
              style={{
                marginLeft: "10px",
                width: "atuo",
                height: "36px",
                display: "inlineblock",
              }}
              disabled={loading}
              onClick={(e) => {
                e.preventDefault();
                fetchMyorder(customerValues["name"], watch("projectname"));
              }}
            >
              {loading ? "正在加载" : "一键填写"}
            </button>
          </div>
          <div
            className="form-group row"
            style={{ paddingLeft: "15%", paddingRight: "5%" }}
          >
            {" "}
            <p>{watch("projectname")}</p>
          </div>
          <div className="error">
            {projectNameError}
            {errors.projectname?.message}
          </div>

          <div className="form-group row">
            <label className="label">委托人:</label>
            <input
              className="half"
              name="trustperson"
              type="text"
              ref={register({
                required: "不能为空",
                maxLength: {
                  value: 16,
                  message: "不能大于个字",
                },
              })}
            />
            {errors.trustperson?.message}
            <label className="label">委托人电话:</label>
            <input
              className="half"
              name="trustpersonphone"
              type="text"
              ref={register({
                required: "不能为空",
                maxLength: {
                  value: 13,
                  message: "不能大于13个字",
                },
              })}
            />
          </div>

          <div className="form-group row">
            <label className="label">取样人:</label>
            <input
              className="half"
              name="sampleperson"
              type="text"
              ref={register({
                required: "不能为空",
                maxLength: {
                  value: 16,
                  message: "不能大于16个字",
                },
              })}
            />
            {errors.sampleperson?.message}
            <label className="label">取样人证号:</label>
            <input
              className="half"
              name="sampleperson_cardnumber"
              type="text"
              ref={register({
                required: "不能为空",
                maxLength: {
                  value: 64,
                  message: "不能大于64个字",
                },
              })}
            />
            {errors.sampleperson_cardnumber?.message}
          </div>

          <div className="form-group row">
            <label className="label">见证单位:</label>
            <input
              className="line"
              name="lookname"
              type="text"
              ref={register({
                required: "不能为空",
                maxLength: {
                  value: 128,
                  message: "不能大于128个字",
                },
              })}
            />
            {errors.lookname?.message}
          </div>

          <div className="form-group row">
            <label className="label">见证人:</label>
            <input
              className="half"
              name="lookperson"
              type="text"
              ref={register({
                required: "不能为空",
                maxLength: {
                  value: 16,
                  message: "不能大于16个字",
                },
              })}
            />
            {errors.lookperson?.message}
            <label className="label">见证人证号:</label>
            <input
              className="half"
              name="lookperson_cardnumber"
              type="text"
              ref={register({
                required: "不能为空",
                maxLength: {
                  value: 64,
                  message: "不能大于64个字",
                },
              })}
            />
            {errors.lookperson_cardnumber?.message}
          </div>

          <div className="form-group row">
            <label className="label">抽样单位:</label>
            <input
              className="line"
              name="samplingname"
              type="text"
              ref={register({
                required: "不能为空",
                maxLength: {
                  value: 128,
                  message: "不能大于128个字",
                },
              })}
            />
            {errors.samplingname?.message}
          </div>

          <div className="form-group row">
            <label className="label">抽样人:</label>
            <input
              className="half"
              name="samplingperson"
              type="text"
              ref={register({
                required: "不能为空",
                maxLength: {
                  value: 16,
                  message: "不能大于16个字",
                },
              })}
            />
            {errors.samplingperson?.message}
            <label className="label">抽样人证号:</label>
            <input
              className="half"
              name="samplingperson_cardnumber"
              type="text"
              ref={register({
                required: "不能为空",
                maxLength: {
                  value: 64,
                  message: "不能大于64个字",
                },
              })}
            />
            {errors.samplingperson_cardnumber?.message}
          </div>

          <div className="form-group row">
            <label className="label">支付方式</label>
            <select
              className="half"
              name="payment"
              value={payment}
              onChange={handlePaymentChange}
            >
              <option value="0">现金</option>
              <option value="1">转帐</option>
              <option value="2">合同</option>
              <option value="3">年结</option>
            </select>
          </div>

          <div className="form-group row">
            <label className="label">样品来源:</label>
            <select
              className="half"
              name="samplesource"
              value={sampleSource}
              onChange={handleSampleSourceChange}
            >
              <option value="0">委托送样</option>
              <option value="1">委托取样</option>
              <option value="2">现场检测</option>
            </select>

            <label className="label">测试种类:</label>
            <select
              className="half"
              name="testkind"
              value={testKind}
              onChange={handleTestKindChange}
            >
              <option value="0">委托送样检测</option>
              <option value="1">见证取样检测</option>
              <option value="2">质量监督检测</option>
              <option value="3">仲裁检测</option>
              <option value="4">人员对比</option>
              <option value="5">仪器对比</option>
              <option value="6">能力验证</option>
            </select>
          </div>

          <div className="form-group row">
            <label className="label">样品处置</label>
            <select
              className="half"
              name="sampledealwith"
              value={sampleDealWith}
              onChange={handleSampleDealWithChange}
            >
              <option value="0">检测方处理</option>
              <option value="1">委托方取回</option>
            </select>

            <label className="label">取件方式</label>
            <select
              className="half"
              name="sendway"
              value={sendWay}
              onChange={handleSendWayChange}
            >
              <option value="0">自取</option>
              <option value="1">邮寄</option>
            </select>
          </div>

          <div className="form-group row">
            <label htmlFor="address" className="label">
              邮寄地址:
            </label>
            <input
              className={css`
                width: 80%;
                height: 30px;
                display: inline-block;
              `}
              list="addresses"
              id="address"
              name="address"
              ref={register}
              autoComplete="off"
            />
            <datalist id="addresses">
              {addressItems &&
                addressItems.map((item, index) => (
                  <option key={index} value={item} />
                ))}
            </datalist>
          </div>
        </div>
        <div>{addressError}</div>

        <div
          className={css`
            grid-column: 1;
            grid-row: 3;
            border: 1px solid black;
            padding-top: 5px;
          `}
        >
          <div className="form-group row">
            <label className="label">工程部位:</label>
            <Combobox
              className="combox-big"
              onSelect={(item) => {
                setValue("projectpart", item);
                setProjectPartIsFetch(false);
                setProjectPart(item);
              }}
            >
              <ComboboxInput
                value={projectPart}
                onChange={handleProjectPartChange}
                aria-label="projectpart"
                autoComplete="off"
              />
              {projectParts.length > 0 && (
                <ComboboxPopover className="shadow-popup">
                  <ComboboxList>
                    {projectParts.map((item, index) => {
                      return <ComboboxOption key={index} value={item} />;
                    })}
                  </ComboboxList>
                </ComboboxPopover>
              )}
            </Combobox>
          </div>
          <div className="error">{errors.projectpart?.message}</div>

          <div className="form-group row">
            <label className="label">取样地址:</label>
            <Combobox
              className="combox-big"
              onSelect={(item) => {
                setValue("sampleaddress", item);
                setSampleAddressIsFetch(false);
                setSampleAddress(item);
              }}
            >
              <ComboboxInput
                value={sampleAddress}
                onChange={handleSampleAddressChange}
                aria-label="getsampleaddress"
                autoComplete="off"
              />
              {SampleAddresses.length > 0 && (
                <ComboboxPopover className="shadow-popup">
                  <ComboboxList>
                    {SampleAddresses.map((item, index) => {
                      return <ComboboxOption key={index} value={item} />;
                    })}
                  </ComboboxList>
                </ComboboxPopover>
              )}
            </Combobox>
          </div>
          <div className="error">{errors.sampleaddress?.message}</div>

          <div className="form-group row">
            <label className="label">样品名称:</label>
            <Combobox
              className="combox-big"
              onSelect={(item) => {
                setValue("samplenickname", item);
                setSampleNickNameIsFetch(false);
                setSampleNickName(item);
              }}
            >
              <ComboboxInput
                value={sampleNickName}
                onChange={handleSampleNickNameChange}
                aria-label="samplenickname"
                autoComplete="off"
              />
              {sampleNickNames.length > 0 && (
                <ComboboxPopover className="shadow-popup">
                  <ComboboxList>
                    {sampleNickNames.map((item, index) => {
                      return <ComboboxOption key={index} value={item} />;
                    })}
                  </ComboboxList>
                </ComboboxPopover>
              )}
            </Combobox>
          </div>
          <div className="error">{errors.samplenickname?.message}</div>

          <div className="form-group row">
            <label className="label">样品状态描述:</label>
            <Combobox
              className="combox-big"
              onSelect={(item) => {
                setValue("samplestatus", item);
                setSampleStatusIsFetch(false);
                setSampleStatus(item);
              }}
            >
              <ComboboxInput
                value={sampleStatus}
                onChange={handleSampleStatusChange}
                aria-label="samplestatus"
                autoComplete="off"
              />
              {sampleStatuses.length > 0 && (
                <ComboboxPopover className="shadow-popup">
                  <ComboboxList>
                    {sampleStatuses.map((item, index) => {
                      return <ComboboxOption key={index} value={item} />;
                    })}
                  </ComboboxList>
                </ComboboxPopover>
              )}
            </Combobox>
          </div>
          <div className="error">{errors.samplestatus?.message}</div>

          <div className="form-group row">
            <label className="label">设计要求:</label>
            <input
              className="line"
              list="designrequires"
              name="designrequire"
              ref={register({
                required: "不能为空",
                maxLength: {
                  value: 128,
                  message: "不能大于128个字",
                },
              })}
              autoComplete="off"
            />
            <datalist id="designrequires">
              {designrequireItems &&
                designrequireItems.map((item, index) => (
                  <option key={index} value={item} />
                ))}
            </datalist>
          </div>
          <div className="error">
            {designrequireError}
            {errors.desginrequire?.message}
          </div>

          <div className="form-group row">
            <label className="label">委托文档:</label>
            <input
              className="line"
              name="trustdoc"
              type="text"
              ref={register({
                required: "不能为空",
                maxLength: {
                  value: 64,
                  message: "不能大于64个字",
                },
              })}
              defaultValue={initValues.trustdoc}
            />
          </div>
          <div className="error">{errors.trustdoc?.message}</div>

          <div className="form-group row">
            <label className="label">备注:</label>
            <textarea name="remark" maxLength="128" ref={register} />
          </div>
        </div>

        <div
          className={css`
            grid-column: 1;
            grid-row: 4;
            border: 1px solid black;
            padding-top: 5px;
          `}
        >
          <div className="form-group row">
            <label className="label">数量:</label>
            <input
              className="half"
              name="numberofsample"
              type="text"
              ref={register({
                required: "不能为空",
                maxLength: {
                  value: 64,
                  message: "不能大于64个字",
                },
              })}
            />
            {errors.numberofsample?.message}

            <label className="label">规格/型号/牌号:</label>
            <input
              className="half"
              name="modelnumber"
              type="text"
              ref={register({
                required: "不能为空",
                maxLength: {
                  value: 128,
                  message: "不能大于128个字",
                },
              })}
            />
            {errors.modelnumber?.message}
          </div>

          <div className="form-group row">
            <label className="label">制造工厂:</label>
            <Combobox
              className="combox-big"
              onSelect={(item) => {
                setValue("makefactory", item);
                setMakeFactoryIsFetch(false);
                setMakefactory(item);
              }}
            >
              <ComboboxInput
                value={makeFactory}
                onChange={handleMakefactoryChange}
                aria-label="makefactory"
                autoComplete="off"
              />
              {makefactories.length > 0 && (
                <ComboboxPopover className="shadow-popup">
                  <ComboboxList>
                    {makefactories.map((item, index) => {
                      return <ComboboxOption key={index} value={item} />;
                    })}
                  </ComboboxList>
                </ComboboxPopover>
              )}
            </Combobox>
          </div>
          <div className="error">{errors.makefactory?.message}</div>
        </div>

        <div
          className={css`
            grid-column: 1;
            grid-row: 5;
            border: 1px solid black;
            padding-top: 5px;
          `}
        >
          <div className="form-group row">
            <label className="label">模板名称:</label>
            <Select
              className="select-small"
              name="modelname"
              placeholder="附加信息模板,可不选"
              value={modelNameValues}
              options={modelNameItems}
              onChange={handleChangeModel}
              // react-select/issues/3018
              getOptionValue={(option) => option["id"]}
              getOptionLabel={(option) => `${option.id} | ${option.name}`}
            />
            <span
              onClick={() => {
                setModelNameValues(null);
                setExtraInfo(null);
                setExtraInfoModel([]);
              }}
            >
              我不想要此模板及下边的附加信息了.
            </span>
            <div style={{ display: "inlneblock", color: "red" }}>
              {modelNameError}
            </div>
          </div>
          <ExtraInfoList
            initValues={extraInfoModel}
            handleExtraInfoChange={handleExtraInfoChange}
          />
        </div>

        <div
          className={css`
            grid-column: 1;
            grid-row: 6;
            border: 1px solid black;
            padding: 5px;
          `}
        >
          <div className="form-group row">
            <label className="label">附加费用:</label>
            <input
              className="half"
              name="extracost"
              value={extraCost}
              onChange={(e) => {
                setValue("extracost", e.target.value);
                setExtraCost(e.target.value);
                return clearErrors("extracost");
              }}
              ref={register}
              type="number"
            />
            <label className="label">总费用:</label>
            <input
              className="half"
              name="totalcost"
              type="number"
              defaultValue="0"
              ref={register}
            />
          </div>

          <div className="form-group row">
            <label className="label">报告日期:</label>
            <DatePicker
              dateFormat="yyyy年MM月dd日"
              selected={reportDate}
              onChange={handleReportDateChange}
              minDate={subMonths(new Date(), 1)}
              maxDate={addMonths(new Date(), 3)}
            />
            <label className="label">委托日期:</label>
            <DatePicker
              dateFormat="yyyy年MM月dd日"
              selected={trustDate}
              onChange={handleTrustDateChange}
              minDate={subMonths(new Date(), 1)}
              maxDate={addDays(new Date(), 30)}
            />
          </div>

          <div className="form-group row">
            <label className="label">委托人日期:</label>
            <DatePicker
              dateFormat="yyyy年MM月dd日"
              selected={trustPeronDate}
              onChange={handleTrustPersonDateChange}
              minDate={subMonths(new Date(), 1)}
              maxDate={addDays(new Date(), 30)}
            />

            <label className="label">收样日期:</label>
            <DatePicker
              dateFormat="yyyy年MM月dd日"
              selected={receivePersonDate}
              onChange={handleReceivePersonDateChange}
              minDate={subMonths(new Date(), 1)}
              maxDate={addDays(new Date(), 30)}
            />
          </div>
          <button
            className="btn btn-primary"
            disabled={isSubmit}
            onClick={handleSubmit}
          >
            {isSubmit ? "正在提交..." : "提交"}
          </button>
          {msg}
        </div>

        <div
          className={css`
            grid-column: 1;
            grid-row: 7;
            border: 1px solid black;
            padding: 5px;
          `}
        >
          <p>归属项目:{categoryValues?.name}</p>
          <p>
            归属样品名称(固定称号):{sampleCodeValues?.stdcode}
            {sampleCodeValues?.name}
          </p>
          <p>
            委托单号:
            {!isCustNumber &&
              operate !== "edite" &&
              sampleCodeValues &&
              maxId &&
              `JHWT-${year}-${maxId.trustnumber.toString().padStart(4, "0")}`}
            {!isCustNumber &&
              operate === "edite" &&
              sampleCodeValues &&
              maxId &&
              `JHWT-${year}-${initValues.trust.trustnumber
                .toString()
                .padStart(4, "0")}`}
            {/*专家模式 */}
            {isCustNumber &&
              `JHWT-${year}-${trustNumber.toString().padStart(4, "0")}`}
          </p>
          <p>样品名称:{watch("samplenickname")} </p>
          <p>
            样品单号:
            {/* 原单号 */}
            {!isCustNumber &&
              operate !== "edite" &&
              sampleCodeValues &&
              maxId &&
              `JHYP-${year}-${
                sampleCodeValues.stdcode
              }-${maxId.samplenumber.toString().padStart(4, "0")}`}
            {!isCustNumber &&
              operate === "edite" &&
              `(原单号)JHYP-${year}-${
                initValues.samplecode
              }-${initValues.samplenumber.toString().padStart(4, "0")}`}
            {!isCustNumber &&
              operate === "edite" &&
              sampleCodeValues?.stdcode !== initValues.samplecode &&
              sampleCodeValues &&
              maxId &&
              `(新单号)JHYP-${year}-${
                sampleCodeValues.stdcode
              }-${maxId.samplenumber.toString().padStart(4, "0")}`}
            {/*专家模式 */}
            {isCustNumber &&
              sampleCodeValues &&
              `JHWT-${year}-${
                sampleCodeValues.stdcode
              }-${sampleNumber.toString().padStart(4, "0")}`}
          </p>
          <p>检测参数:</p>
          <ol>
            {argumentsValues &&
              argumentsValues.map((item) => (
                <li key={item.id}>
                  {item.stdcode}--{item.name}--价格:({item.price}元)
                </li>
              ))}
          </ol>
          <p>附加费用:{extraCost} </p>
          <p>总费用:{watch("totalcost")} </p>
          <p>检测依据:</p>
          <ol>
            {accordingsValues &&
              accordingsValues.map((item) => (
                <li key={item.id}>
                  {item.stdcode}--{item.name}
                </li>
              ))}
          </ol>

          <p>检测标准:</p>
          <ol>
            {standardsValues &&
              standardsValues.map((item) => (
                <li key={item.id}>
                  {item.stdcode}--{item.name}
                </li>
              ))}
          </ol>
          <hr />
          <p>委托单位:{customerValues?.name} </p>
          <p>工程名称:{watch("projectname")} </p>
          <p>委托人:{watch("trustperson")} </p>
          <p>委托人电话:{watch("trustperphone")} </p>
          <p>取样人:{watch("sampleperson")} </p>
          <p>取样人证号:{watch("sampleperson_cardnumber")} </p>
          <p>见证单位:{watch("lookname")} </p>
          <p>见证人:{watch("lookperson")} </p>
          <p>见证证号:{watch("lookperson_cardnumber")} </p>
          <p>抽样单位:{watch("samplingname")} </p>
          <p>抽样人:{watch("samplingperson")} </p>
          <p>抽样人证号:{watch("sampleperson_cardnumber")} </p>
          <hr />

          <p>工程部位:{watch("projectpart")} </p>
          <p>取样地址:{watch("sampleaddress")} </p>
          <p>样品名称:{watch("samplenickname")} </p>
          <p>样品描述:{watch("samplestatus")} </p>

          <p>样品数量:{watch("numberofsample")} </p>
          <p>规格/型号/牌号:{watch("modelnumber")} </p>
          <p>样品数量:{watch("numberofsample")} </p>
          <p>制造工厂:{watch("makefactory")} </p>
          <p>设计要求:{watch("designrequire")} </p>
          <p>委托文档:{watch("trustdoc")} </p>
          <p>备注:{watch("remark")} </p>
          <hr />

          <p>支付方式:{payments[parseInt(payment)]}</p>
          <p>
            样品来源:
            {samplesources[parseInt(sampleSource)]}
          </p>
          <p>测试种类:{testkinds[parseInt(testKind)]} </p>
          <hr />
          <p>
            样品处置:
            {sampledealwiths[parseInt(sampleDealWith)]}
          </p>

          <p>报告领取方式:{sendways[parseInt(sendWay)]}</p>
          <p>寄送地址:{watch("address")} </p>

          <p>报告日期:{format(reportDate, "yyyy年MM月dd日")} </p>
          <p>委托日确:{format(trustDate, "yyyy年MM月dd日")} </p>
          <p>委托人签名日期:{format(trustPeronDate, "yyyy年MM月dd日")} </p>
          <p>收样日期:{format(receivePersonDate, "yyyy年MM月dd日")} </p>
          <p>附加信息:</p>
          <ol>
            {extraInfo &&
              extraInfo.map((item) => (
                <li key={item.id}>
                  {item.print ? "打印" : "不打印"}|{item.name}:{item.value}
                </li>
              ))}
          </ol>

          <input
            className="btn btn-primary"
            disabled={isSubmit}
            type="submit"
          />
          <p>{msg}</p>
        </div>

        <div
          className={css`
            grid-column: 2;
            grid-row: 1 / 2;
            border: 1px solid black;
            box-sizing: border-box;
            padding: 10px;
          `}
        >
          <div>
            <input
              name="iscustnumber"
              id="iscustnumber"
              value={isCustNumber}
              onChange={handleIsCustNumberChange}
              ref={register}
              type="checkbox"
            />
            <label htmlFor="iscustnumber">启用专家模式</label>
          </div>

          {isCustNumber && (
            <>
              <div>
                <label>委托单号:</label>
              </div>
              <div>
                <input
                  name="trustnumber"
                  value={trustNumber}
                  onChange={handleTrustNumberChange}
                  ref={register({
                    required: "不能为空",
                    min: {
                      value: 1,
                      message: "最小为1",
                    },
                    max: {
                      value: 9999,
                      message: "最大为9999",
                    },
                    validate: (value) => validateTrustNumberFromDb(value),
                  })}
                  type="number"
                />
              </div>
              <div> {errors.trustnumber && errors.trustnumber.message}</div>

              <div>
                <label>样品单号:</label>
              </div>

              <div>
                <input
                  name="samplenumber"
                  value={sampleNumber}
                  onChange={handleSampleNumberChange}
                  ref={register({
                    required: "不能为空",
                    min: {
                      value: 1,
                      message: "最小为1",
                    },
                    max: {
                      value: 9999,
                      message: "最大为9999",
                    },
                    validate: (value) => validateSampleNumberFromDb(value),
                  })}
                  type="number"
                />
              </div>
              <div>{errors.samplenumber && errors.samplenumber.message}</div>
            </>
          )}

          <div>
            <p>
              委托单号:
              {!isCustNumber &&
                operate !== "edite" &&
                sampleCodeValues &&
                maxId &&
                `JHWT-${year}-${maxId.trustnumber.toString().padStart(4, "0")}`}
              {!isCustNumber &&
                operate === "edite" &&
                sampleCodeValues &&
                maxId &&
                `JHWT-${year}-${initValues.trust.trustnumber
                  .toString()
                  .padStart(4, "0")}`}
              {/*专家模式 */}
              {isCustNumber &&
                `JHWT-${year}-${trustNumber.toString().padStart(4, "0")}`}
            </p>
            <p>
              样品单号:
              {/* 原单号 */}
              {!isCustNumber &&
                operate !== "edite" &&
                sampleCodeValues &&
                maxId &&
                `JHYP-${year}-${
                  sampleCodeValues.stdcode
                }-${maxId.samplenumber.toString().padStart(4, "0")}`}
              {!isCustNumber &&
                operate === "edite" &&
                `(原单号)JHYP-${year}-${
                  initValues.samplecode
                }-${initValues.samplenumber.toString().padStart(4, "0")}`}
              {!isCustNumber &&
                operate === "edite" &&
                sampleCodeValues?.stdcode !== initValues.samplecode &&
                sampleCodeValues &&
                maxId &&
                `(新单号)JHYP-${year}-${
                  sampleCodeValues.stdcode
                }-${maxId.samplenumber.toString().padStart(4, "0")}`}
              {/*专家模式 */}
              {isCustNumber &&
                sampleCodeValues &&
                `JHYP-${year}-${
                  sampleCodeValues.stdcode
                }-${sampleNumber.toString().padStart(4, "0")}`}
            </p>
          </div>

          <SampleCodeFind />
        </div>

        <div
          className={css`
            grid-column: 2;
            grid-row: 2 / 6;
            border: 1px solid black;
          `}
        >
          {count}
          {msg}
          <hr />
          <p>信息提示</p>
          <p>
            {" "}
            参数费用:
            {argumentsValues &&
              argumentsValues.length >= 1 &&
              argumentsValues.map((x) => x.price).reduce((x, y) => x + y)}{" "}
          </p>

          <button
            className="btn btn-primary"
            disabled={isSubmit}
            onClick={handleSubmit}
          >
            提交
          </button>

          <button
            className="btn btn-outline-dark"
            onClick={(e) => {
              e.preventDefault();
              clear(e);
              //inputTrustName.current.value = "";
            }}
          >
            清空
          </button>
        </div>
      </div>
    </form>
  );
}
