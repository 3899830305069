import React, { useState, useRef, useEffect } from "react";
import ContentEditable from "react-contenteditable";
import { useForm } from "react-hook-form";

import { fetchSampleCodeAll } from "../apidata/fetchSampleCodeData";


// 工程名称,样品代码折扣
export default function CodeDiscount({
  initValues = [],
  handleCodeDiscountChange,
  isdelete = false
}) {


  const { register, unregister, handleSubmit, setValue, errors } = useForm();

  useEffect(() => {
    return () => unregister;
  }, [register, unregister]);

  
  const [data, setData] = useState([]);
  const [row, setRow] = useState({
    stdcode: "",
    name: "",
    discount: ""
  });
  const firstEditable = useRef();

  const disableNewlines = event => {
    const keyCode = event.keyCode || event.which;
    if (keyCode === 13) {
      event.returnValue = false;
      if (event.preventDefault) event.preventDefault();
    }
  };

  const pasteAsPlainText = event => {
    event.preventDefault();
    const text = event.clipboardData.getData("text/plain");
    document.execCommand("insertHTML", false, text);
  };

  const highlightAll = () => {
    setTimeout(() => {
      document.execCommand("selectAll", false, null);
    }, 0);
  };

  const validateFloat = event => {
    const keyCode = event.keyCode || event.which;
    const string = String.fromCharCode(keyCode);
    const regex = /[0-9]|\./;

    if (!regex.test(string)) {
      event.returnValue = false;
      if (event.preventDefault) event.preventDefault();
    }
  };

  const trimSpaces = string => {
    return string
      .replace(/&nbsp;/g, "")
      .replace(/&amp;/g, "&")
      .replace(/&gt;/g, ">")
      .replace(/&lt;/g, "<");
  };

  //  为新增准备信息
  const handleContentEditable = event => {
    const {
      currentTarget: {
        dataset: { column }
      },
      target: { value }
    } = event;
    setRow({ ...row, [column]: value });
  };

  // 新增
  const add = () => {
    let newId = 0;
    if (data && data.length > 0) {
      newId =
        Math.max.apply(
          Math,
          data.map(i => i.id)
        ) + 1;
    }
    // 输入修正
    const trimmedRow = {
      ...row,
      stdcode: trimSpaces(row.stdcode),
      name: trimSpaces(row.name)
    };
    setData([...data, { ...trimmedRow, id: newId }]); // null,不能执行此操作,所以Props不能为null

    // 清空
    setRow({ stdcode: "", name: "", discount: "" });
    firstEditable.current.focus();
  };

  // 修改每一项时,动态更新该条记录
  const handleContentEditableUpdate = event => {
    const {
      currentTarget: {
        dataset: { row, column } // row 代表找到修改行的id data-row={item.id}
      },
      target: { value }
    } = event;

    setData(
      data.map(item => {
        return item.id === parseInt(row, 10)
          ? { ...item, [column]: value }
          : item;
      })
    );
  };

  // 删除
  const deleteRow = row => {
    setData(data.filter(item => item !== row));
  };

  // props call back
  useEffect(() => {
    handleCodeDiscountChange(data);
  }, [data, handleCodeDiscountChange]);

  useEffect(() => {
    // initValues === undefined || initValues===null || initValues.length === 0
    //https://stackoverflow.com/questions/24403732/check-if-array-is-empty-or-does-not-exist-js
    if (!Array.isArray(initValues) || !initValues.length) {
      (async () => {
        const json = await fetchSampleCodeAll();
        const temp = json.map(c => {
          return { id: c.id, stdcode: c.stdcode, name: c.name, discount: 1 };
        });
        setData(temp);
      })();
    } else {
      setData(initValues);
    }
  }, [initValues]);

  const submit = subdata => {
    console.log(subdata);
    setData(data.map(c=>{
      return  { id: c.id, stdcode: c.stdcode, name: c.name, discount: parseFloat(subdata.discount) }
    }));
     setValue("discount",1)
  };

  return (
    <div>
      <form onSubmit={handleSubmit(submit)}>
        <div className="form-group row">
            <label>折扣:</label>
            <input
              ref={register({
                pattern: {
                  value: /^[0-9]*\.?[0-9]*$/,
                  message: "数字哦"
                },
                validate: {
                  min: value => parseFloat(value, 10) >= 0 || "应该大于0",
                  max: value => parseFloat(value, 10) <= 1 || "小于等于1"
                }
              })}
              name="discount"
              type="text"
            />
              <input type="submit" />
              {errors.discount?.message}
        </div>
        

      </form>
       
      <table className="table table-bordered">
        <thead>
          <tr>
            <th style={{ width: "10%" }}>代码</th>
            <th style={{ width: "70%" }}>名称</th>
            <th style={{ width: "10%" }}>折扣</th>
            <th style={{ width: "10%" }}>操作</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map(item => (
              <tr key={item.id}>
                <td style={{ width: "10%" }}>{item.stdcode}</td>
                <td style={{ width: "70%" }}>{item.name}</td>
                <td style={{ width: "10%" }}>
                  <ContentEditable
                    html={item.discount.toString()}
                    data-column="discount"
                    data-row={item.id}
                    className="content-editable"
                    onKeyPress={validateFloat}
                    onPaste={pasteAsPlainText}
                    onFocus={highlightAll}
                    onChange={handleContentEditableUpdate}
                  />
                </td>

                <td style={{ width: "10%" }}>
                  <button
                    disabled={isdelete}
                    onClick={e => {
                      e.preventDefault();
                      deleteRow(item);
                    }}
                  >
                    删除
                  </button>
                </td>
              </tr>
            ))}

          <tr>
            <td>
              <ContentEditable
                html={row.stdcode}
                data-column="stdcode"
                className="content-editable"
                innerRef={firstEditable}
                onKeyPress={disableNewlines}
                onPaste={pasteAsPlainText}
                onFocus={highlightAll}
                onChange={handleContentEditable}
              />
            </td>
            <td>
              <ContentEditable
                html={row.name}
                data-column="name"
                className="content-editable"
                onKeyPress={disableNewlines}
                onPaste={pasteAsPlainText}
                onFocus={highlightAll}
                onChange={handleContentEditable}
              />
            </td>

            <td>
              <ContentEditable
                html={row.discount}
                data-column="discount"
                className="content-editable"
                onKeyPress={validateFloat}
                onPaste={pasteAsPlainText}
                onFocus={highlightAll}
                onChange={handleContentEditable}
              />
            </td>

            <td>
              <button
                disabled={!(row.stdcode && row.name && row.discount)}
                onClick={add}
              >
                新增
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      {/* <button type="submit" onClick={handleCodeDiscountChange(data)} className="btn btn-primary">
        保存修改
      </button>           */}
    </div>
  );
}
