import React, { useState, useEffect, useRef } from "react";
import Pagination from "./Pagination";
import { Dialog } from "@reach/dialog";
import {
  AlertDialog,
  AlertDialogLabel,
  AlertDialogDescription,
} from "@reach/alert-dialog";
import "@reach/dialog/styles.css";
import AsyncSelect from "react-select/async";
import { css } from "emotion";
import { fetchCustomers } from "../apidata/fetchCustomerData";
import {
  fetchRepayCount,
  fetchRepayList,
  fetchRepayAdd,
  fetchRepayUpdate,
  fetchRepayDelete,
} from "../apidata/fetchRepay";

import RepayForm from "./RepayForm";
import CallHistoryForm from "./CallHistoryForm";
import RepayDetail from "./RepayDetail";

import { useAlert } from "react-alert";

export default function RepayList() {
  const alert = useAlert();
  // 开票信息
  const [showDialogRepay, setShowDialogRepay] = useState(false);
  const openRepay = () => setShowDialogRepay(true);
  const closeRepay = () => setShowDialogRepay(false);

  // call history信息
  const [showDialogStatus, setShowDialogStatus] = useState(false);
  const openStatus = () => setShowDialogStatus(true);
  const closeStatus = () => setShowDialogStatus(false);

  // call history信息
  const [showDialogCallHistory, setShowDialogCallHistory] = useState(false);
  const openCallHistory = () => setShowDialogCallHistory(true);
  const closeCallHistory = () => setShowDialogCallHistory(false);

  // 删除结账单
  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const cancelRef = useRef();
  const openDelete = () => setShowDialogDelete(true);
  const closeDelete = () => setShowDialogDelete(false);

  const [isEditeRepay, setIsEditeRepay] = useState(false);
  const [currentRepay, setCurrentRepay] = useState();

  const [msg, setMsg] = useState();
  // 委托单位
  const [customerValues, setCustomerValues] = useState();
  const [customerError, setCustomerError] = useState();

  const [count, setCount] = useState();
  //分页相关
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  // 结账列表
  const [repayItems, setRepayItems] = useState(null);

  useEffect(() => {
    if (customerValues) {
      (async () => {
        try {
          const json = await fetchRepayCount(customerValues.id);
          setCount(json);
        } catch (error) {
          setMsg(error.message || error.statusText);
        }
      })();
    }
  }, [customerValues]);

  useEffect(() => {
    if (customerValues) {
      (async () => {
        try {
          const json = await fetchRepayList(
            customerValues.id,
            pageSize,
            currentPage
          );
          setRepayItems(json);
        } catch (error) {
          setMsg(error.message || error.statusText);
        }
      })();
    }
  }, [customerValues, pageSize, currentPage]);
  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // pull server data  异步 委托单位数据
  const promiseOptions = async (inputValue) => {
    if (inputValue === null) {
      return Promise.reject("error");
    }
    if (inputValue.length < 1) {
      return Promise.reject("error");
    }

    try {
      const json = await fetchCustomers(inputValue);
      setCustomerValues(null);
      return json;
    } catch (error) {
      setCustomerError(error.message || error.statusText);
      return Promise.reject(error);
    }
  };

  // 查询委托单位名称
  const handleCustomerChange = (event) => {
    setCustomerValues(event);
  };

  const submit = async (data) => {
    if (isEditeRepay) {
      try {
        const res = await fetchRepayUpdate(currentRepay.id, data);
        alert.success(res.msg);
        closeRepay();
        setIsEditeRepay(false);
        setRepayItems(
          repayItems.map((c) => (c.id === currentRepay.id ? res.data : c))
        );
      } catch (error) {
        alert.error(error.message);
      }
    } else {
      try {
        const res = await fetchRepayAdd(data);
        alert.success(res.msg);
        closeRepay();
        setRepayItems([res.data, ...repayItems]);
      } catch (error) {
        alert.error(error.message);
      }
    }
  };

  const deleteRepay = async () => {
    try {
      const res = await fetchRepayDelete(currentRepay.id);
      alert.success(res.msg);
      closeDelete();
      setRepayItems(repayItems.filter((c) => c.id !== currentRepay.id));
    } catch (error) {
      alert.error(error.message);
      closeDelete();
    }
  };

  const updateCallHistory = (id, data) => {
    setRepayItems(
      repayItems.map((c) =>
        c.id === id ? { ...c, callhistory: data.callhistory } : c
      )
    );
  };

  const updateStatus = (id, data) => {
    setRepayItems(repayItems.map((c) => (c.id === id ? data : c)));
  };

  return (
    <div>
      <div
        className={css`
          margin-bottom: 5px;
        `}
      >
        <label>委托单位:</label>
        <AsyncSelect
          name="trustname"
          className={css`
            width: 80%;
            height: atuo;
            display: inline-block;
          `}
          cacheOptions
          defaultOptions
          loadOptions={promiseOptions}
          getOptionValue={(option) => option["id"]}
          onChange={handleCustomerChange}
          getOptionLabel={(option) => `${option.pinyin} | ${option.name}`}
          value={customerValues}
          placeholder="请输入委托单位名称,再开始账目追踪功能"
        />
        <button
          disabled={!customerValues}
          style={{ marginLeft: "10px" }}
          onClick={(e) => {
            e.preventDefault();
            setIsEditeRepay(false);
            setCurrentRepay({ customer_id: customerValues.id });
            openRepay();
          }}
        >
          新增按日结账单
        </button>
        <span style={{ color: "red" }}>{customerError}</span>
      </div>
      <p>{msg}</p>
      <table className="table table-sm table-hover">
        <thead className="thead-dark">
          <tr>
            <th>序号</th>
            <th>来样日期</th>
            <th>结账金额</th>
            <th>催款次数</th>
            <th>状态</th>
            <th>工程名称</th>
            <th>操作</th>
            <th>操作</th>
            <th>操作</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          {repayItems ? (
            repayItems.map((item, index) => (
              <tr
                key={item.id}
                style={{ color: item.status === -1 ? "red" : null }}
              >
                <td>{index + 1}</td>
                <td>{item.receivepersondate}</td>
                <td>{item.amount}</td>
                <td>{item.callhistory?.length}</td>
                <td>{item.status === 0 ? "催款中" : "完成"}</td>
                <td>{item.projectname}</td>
                <td>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setCurrentRepay(item);
                      openStatus();
                    }}
                  >
                    详情
                  </button>
                </td>
                <td>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setCurrentRepay(item);
                      openCallHistory();
                    }}
                  >
                    催款记录
                  </button>
                </td>
                <td>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setIsEditeRepay(true);
                      setCurrentRepay(item);
                      openRepay();
                    }}
                  >
                    修改
                  </button>
                </td>
                <td>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setCurrentRepay(item);
                      openDelete();
                    }}
                  >
                    删除
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>没有数据.</td>
            </tr>
          )}
        </tbody>
      </table>

      <Pagination
        pageSize={pageSize}
        totalRecords={count}
        currentPage={currentPage}
        onPageChange={paginate}
        defaultPageNeighbours={2}
      />

      {showDialogRepay && (
        <Dialog
          aria-label="modal"
          isOpen={showDialogRepay}
          onDismiss={closeRepay}
        >
          <h1>{isEditeRepay ? "修改结账信息" : "增加结账信息"}</h1>
          <RepayForm
            isEdite={isEditeRepay}
            currentRepay={currentRepay}
            submitRepay={submit}
          />
          <hr />
          <button onClick={closeRepay}>取消</button>
        </Dialog>
      )}

      {showDialogStatus && (
        <Dialog
          aria-label="modal"
          isOpen={showDialogStatus}
          onDismiss={closeStatus}
        >
          <h1>催款信息</h1>
          <RepayDetail currentRepay={currentRepay} updateUi={updateStatus} />
          <hr />
          <button onClick={closeStatus}>关闭</button>
        </Dialog>
      )}

      {showDialogCallHistory && (
        <Dialog
          aria-label="modal"
          isOpen={showDialogCallHistory}
          onDismiss={closeCallHistory}
        >
          <h1>催款信息</h1>
          <CallHistoryForm
            currentRepay={currentRepay}
            updateUi={updateCallHistory}
          />
          <hr />
          <button onClick={closeCallHistory}>关闭</button>
        </Dialog>
      )}

      {showDialogDelete && (
        <AlertDialog onDismiss={closeDelete} leastDestructiveRef={cancelRef}>
          <AlertDialogLabel style={{ textAlign: "center", color: "red" }}>
            请再次确认是否删除?
          </AlertDialogLabel>
          <AlertDialogDescription>
            <p>日期:{currentRepay.receivepersondate}</p>
            <p>结账金额:{currentRepay.amount}</p>
            <p>工程名称:{currentRepay.projectname}</p>
          </AlertDialogDescription>
          <div className="alert-buttons">
            <button className="btn btn-danger" onClick={deleteRepay}>
              是, 删除
            </button>
            <button
              style={{ marginLeft: "20px" }}
              ref={cancelRef}
              onClick={closeDelete}
            >
              取消, 我不愿意
            </button>
          </div>
        </AlertDialog>
      )}
    </div>
  );
}
