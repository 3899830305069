import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

export default function PkgPriceUpdate({ pkgprice, verItems, update }) {
  const { register, unregister, handleSubmit, setValue } = useForm();

  useEffect(() => {
    register({ name: "id" });
    setValue("id", pkgprice.id);
    setValue("price", pkgprice.price);
    setValue("ver", pkgprice.ver);
    return () => unregister;
  }, [setValue, register, unregister, pkgprice]);

  const submit = data => {
    update(data);
    console.log(data);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(submit)}>
        <label>版本</label>
        <select name="ver" ref={register} disabled={true}>
          {verItems.map((item, index) => (
            <option key={index} value={item.code}>
              {item.name}
            </option>
          ))}
        </select>
        <label>价格</label>
        <input ref={register} name="price" type="number" step="0.01" />
        <button
          type="submit"
          onClick={handleSubmit}
          className="btn btn-primary"
        >
          保存修改
        </button>
      </form>
    </div>
  );
}
