import React, { useState, useEffect } from "react";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import Pagination from "../components/Pagination";
import baseURL from "../Config";
import OrderView from "./OrderView";

export default function JholdSelect() {
  // 详情
  const [showDialogDetail, setShowDialogDetail] = useState(false);
  const openDetail = () => setShowDialogDetail(true);
  const closeDetail = () => setShowDialogDetail(false);

  const [currentSample, setCurrentSample] = useState();

  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState();
  const [count, setCount] = useState(0);

  const [trustpersonInput, setTrustpersonInput] = useState();
  const [trustperson, setTrustperson] = useState();

  //分页相关
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(15);
  // 委托单列表
  const [sampleItems, setSampleItems] = useState(null);

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (trustperson) {
      (async () => {
        try {
          setLoading(true);
          const res = await fetch(
            `${baseURL}/api/v1/order/count/${trustperson}`,
            {
              method: "get",
              headers: {
                "Content-Type": "application/json",
                Authorization:
                  "Bearer " + JSON.parse(sessionStorage.getItem("token")),
              },
            }
          );
          const result = await res.json();
          setCount(result);
          setCurrentPage(1);
          setLoading(false);
        } catch (error) {
          setMsg(
            `系统异常,请联系开发公司.:${error.message || error.statusText}`
          );
        }
      })();
    }
  }, [trustperson]);

  useEffect(() => {
    if (trustperson) {
      (async () => {
        try {
          setLoading(true);
          const res = await fetch(
            `${baseURL}/api/v1/order/list/${trustperson}/${pageSize}/${currentPage}`,
            {
              method: "get",
              headers: {
                "Content-Type": "application/json",
                Authorization:
                  "Bearer " + JSON.parse(sessionStorage.getItem("token")),
              },
            }
          );
          const result = await res.json();
          setSampleItems(result);
          setLoading(false);
        } catch (error) {
          setMsg(
            `系统异常,请联系开发公司.:${error.message || error.statusText}`
          );
        }
      })();
    }
  }, [trustperson, pageSize, currentPage]);

  return (
    <div>
      <div className="form-group row">
        <label className="label">委托人姓名:</label>
        <input
          type="text"
          value={trustpersonInput}
          onChange={(e) => {
            setTrustpersonInput(e.target.value);
          }}
        />
        <button
          onClick={(e) => {
            e.preventDefault();
            setTrustperson(trustpersonInput);
          }}
        >
          查询
        </button>
        <span>仅查询2019旧数据</span>
      </div>
      <div>
        {msg}
        <table className="table table-sm table-hover">
          <thead className="thead-dark">
            <tr>
              <th>序号</th>
              <th>委托单号</th>
              <th>委托人</th>
              <th>样品编号</th>
              <th>样品名称</th>
              <th>规格型号</th>
              <th>金额</th>
              <th>报告日期</th>
              <th>委托日期</th>
              <th>委托单位</th>
              <th>工程名称</th>
              <th>操作</th>
            </tr>
          </thead>
          <caption>当前查询共{count}条</caption>
          <tbody>
            {sampleItems ? (
              sampleItems.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item.wtcode}</td>
                  <td>{item.delegrate_person}</td>
                  <td>{item.ypcode}</td>
                  <td>{item.samplenameview}</td>
                  <td>{item.mytype}</td>
                  <td>{item.totalcost}</td>
                  <td>{item.reportdate}</td>
                  <td>{item.delegrate_date}</td>
                  <td>
                    {item.delegrateunit_name?.length > 10
                      ? item.delegrateunit_name?.substring(0, 10) + "..."
                      : item.delegrateunit_name}
                  </td>
                  <td>
                    {item.projectname?.length > 9
                      ? item.projectname?.substring(0, 9) + "..."
                      : item.projectname}
                  </td>
                  <td>
                    <button
                      onClick={() => {
                        setCurrentSample(item);
                        openDetail();
                      }}
                    >
                      详情
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7}>没有数据哦!!</td>
              </tr>
            )}
          </tbody>
        </table>
        {loading ? "正在加载" : null}
        <Pagination
          pageSize={pageSize}
          totalRecords={count}
          currentPage={currentPage}
          onPageChange={paginate}
          defaultPageNeighbours={2}
        />
      </div>
      {showDialogDetail && (
        <Dialog
          aria-label="modal"
          isOpen={showDialogDetail}
          onDismiss={closeDetail}
        >
          <OrderView order={currentSample} />
          <hr />
          <button onClick={closeDetail}>关闭</button>
        </Dialog>
      )}
    </div>
  );
}
