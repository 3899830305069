import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

export default function ExtraCostAdd({ add }) {
  const { register, unregister, handleSubmit, setValue, errors } = useForm();

  useEffect(() => {
    return () => unregister;
  }, [register, unregister]);

  const submit = data => {
    add(data);
    setValue("price", null);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(submit)}>
        <div className="form-group row">
          <label className="col-sm-1 col-form-label">名称:</label>
          <div className="col-sm-8">
            <input
              ref={register({
                required: "不能为空",
                maxLength: {
                  value: 32,
                  message: "不能大于32个字"
                }
              })}
              name="name"
              type="text"
            />
            {errors.name?.message}
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-1 col-form-label">附加单价:</label>
          <div className="col-sm-8">
            <input
              ref={register({
                required: "不能为空"
              })}
              name="price"
              type="number"
            />
            {errors.price?.message}
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-1 col-form-label">单位:</label>
          <div className="col-sm-8">
            <input
              ref={register({
                required: "不能为空",
                maxLength: {
                  value: 16,
                  message: "不能大于16个字"
                }
              })}
              name="unit"
              type="text"
            />
            {errors.unit?.message}
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-1 col-form-label">说明</label>
          <div className="col-sm-8">
            <input
              ref={register({
                required: "不能为空",
                maxLength: {
                  value: 64,
                  message: "不能大于64个字"
                }
              })}
              name="remark"
              type="text"
            />
            {errors.remark?.message}
          </div>
        </div>

        <div className="form-group row">
          <div className="col-sm-3">
            <button
              type="submit"
              onClick={handleSubmit}
              className="btn btn-primary"
            >
              增加
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
