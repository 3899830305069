import React,{useState,useEffect} from 'react'
import Select from "react-select";
import {fetchSampleCodeAll} from "../apidata/fetchSampleCodeData"

export default function SampleCodeFind() {
// 样品代码
const [sampleCodeItems, setSampleCodeItems] = useState([]); //db
const [sampleCodeValues, setSampleCodeValues] = useState(null);
const [sampleCodeError, setSampleCodeError] = useState(null);

useEffect(() => {
    (async () => {
        try {
          const json = await fetchSampleCodeAll();
          setSampleCodeItems(json);
        } catch (e) {
          setSampleCodeError(e.message || e.statusText);
        }
      })();
}, [])

const handleSampleCodeChange = selectedOption =>{
    setSampleCodeValues(selectedOption);
}



    return (
        <div>
            <label style={{ display: "inlneblock" }} >样品代码查询助手:</label>
            <Select
              style={{ display: "inlneblock"}}
              name="samplecde"
              placeholder="通过样品编码查询类目"
              value={sampleCodeValues}
              options={sampleCodeItems}
              onChange={handleSampleCodeChange}
              // react-select/issues/3018
              getOptionValue={option => option["id"]}
              getOptionLabel={option => `${option.stdcode} | ${option.name}`}
            />
            <div style={{ display: "inlneblock", color: "red" }}>
              {sampleCodeError}              
            </div>
         
        <div>
             <p>{sampleCodeValues && `类目名称: ${sampleCodeValues.category?.certificate===0 ? "公路" : "水运"}`}</p>
             <p>{sampleCodeValues && `类目编码: ${sampleCodeValues.category?.stdcode}`}</p>  
             <p>{sampleCodeValues && `类目名称: ${sampleCodeValues.category?.name}`}</p>
        </div>
            
        </div>
    )
}
