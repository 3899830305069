import React, { useContext } from "react";
import { AuthContext } from "../App";

//{state.user.username}
export const Header = () => {
  const { state, dispatch } = useContext(AuthContext);

  //    useEffect(()=>{
  //       setTimeout(()=>{ dispatch({type:"LOGOUT"})},20000000)
  //     },[dispatch])

  return (
    <nav className="navbar navbar-expand-md navbar-light bg-light border">
      <a className="navbar-brand" href="/">
        <span className="blue">广西交航</span>
      </a>
      <button
        aria-controls="navbar"
        aria-expanded="false"
        aria-label="Toggle navigation"
        className="navbar-toggler"
        data-target="#navbar"
        data-toggle="collapse"
        type="button"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbar">
        {state.isAuthenticated && (
          <ul className="navbar-nav ml-auto mt-2">
            <li className="nav-item">{state.user.username}</li>
            <li className="nav-item" style={{ marginLeft: "auto" }}>
              <span
                onClick={() => {
                  dispatch({ type: "LOGOUT" });
                }}
                className="btn btn-danger"
              >
                退出
              </span>
            </li>
          </ul>
        )}
      </div>
    </nav>
  );
};

export default Header;
