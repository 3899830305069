import baseURL from "../Config";

export const fetchPriceVerList = async () => {
  try {
    const res = await fetch(`${baseURL}/api/v1/pricever/list`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token")),
      },
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAddPriceVer = async (data) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/pricever/add`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token")),
      },
      body: JSON.stringify(data),
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchUpdatePriceVer = async (id, data) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/pricever/update/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token")),
      },
      body: JSON.stringify(data),
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchDeletePriceVer = async (id) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/pricever/delete/${id}`, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token")),
      },
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetcheExistsPriceVer = async (name) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/pricever/exists`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token")),
      },
      body: JSON.stringify({ name: name }),
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchFindPriceVer = async (ver) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/pricever/find/${ver}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token")),
      },
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};
