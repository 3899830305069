import React, { useState, useEffect } from "react";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import { css } from "emotion";

import Pagination from "../components/Pagination";
import {
  fetchCustomerCount,
  fetchCustomerItems,
} from "../apidata/fetchCustomerData";
import CustomerForm from "./CustomerForm";

export default function CustomerList() {
  const [loading, setLoading] = useState(false);

  const [msg, setMsg] = useState(null);

  const [currentCustomer, setCurentCustomer] = useState();
  // 修改
  const [showDialogUpdate, setShowDialogUpdate] = useState(false);
  const openUpdate = () => setShowDialogUpdate(true);
  const closeUpdate = () => setShowDialogUpdate(false);

  // 总数
  const [count, setCount] = useState(1);
  useEffect(() => {
    (async () => {
      try {
        const temp = await fetchCustomerCount();
        setCount(temp);
      } catch (error) {
        setMsg(`系统异常,请联系开发公司.:${error.message || error.statusText}`);
      }
    })();
  }, []);
  //分页相关
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(20);
  // 样品列表
  const [customerItems, setCustomerItems] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const temp = await fetchCustomerItems(pageSize, currentPage);
        setCustomerItems(temp);
        setLoading(false);
      } catch (error) {
        setMsg(`系统异常,请联系开发公司.:${error.message || error.statusText}`);
      }
    })();
  }, [pageSize, currentPage]);

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const updateUi = async (json) => {
    setMsg(json.msg);
    setCustomerItems(
      customerItems.map((c) => (c.id === json.data.id ? json.data : c))
    );
    setTimeout(() => {
      setMsg(null);
    }, 3000);
    closeUpdate();
  };

  return (
    <div>
      <h1 style={{ textAlign: "center" }}>总委托单位总数{count}</h1>
      <span>{msg}</span>
      <ul>
        {customerItems &&
          customerItems.map((item) => (
            <li
              key={item.id}
              className={css`
                padding: 3px;
              `}
            >
              [{item.id}]--{item.pinyin}--{item.name}
              <button
                onClick={() => {
                  setCurentCustomer(item);
                  openUpdate();
                }}
              >
                修改
              </button>
            </li>
          ))}
      </ul>

      <Pagination
        pageSize={pageSize}
        totalRecords={count}
        currentPage={currentPage}
        onPageChange={paginate}
        defaultPageNeighbours={2}
      />
      <span>{loading && "正在加载!!,请等待"}</span>
      {showDialogUpdate && (
        <Dialog
          aria-label="modal"
          isOpen={showDialogUpdate}
          onDismiss={closeUpdate}
        >
          <CustomerForm
            currentCustomer={currentCustomer}
            isEdite={true}
            changeUi={updateUi}
          />
          <hr />
          <button onClick={closeUpdate}>取消</button>
        </Dialog>
      )}
    </div>
  );
}
