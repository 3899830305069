import React, { useState,useEffect } from "react";
import "./Pagination.css";
import PropTypes from "prop-types";

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

// 辅助数组
const range = (from, to, step = 1) => {
  let i = from;
  const range = [];
  while (i <= to) {
    range.push(i);
    // 痛苦,忘了下边,死循环,流览器提示内存不足,浪费了好几天
    i += step;
  }
  return range;
};

const Pagination = ({
  pageSize = 10,
  totalRecords,
  currentPage,
  onPageChange,
  defaultPageNeighbours = 2
}) => {
  const totalPages = Math.ceil(totalRecords / pageSize);
  const [myCurrentPage, setMyCurrentPage] = useState(currentPage);
  const [pageNeighbours] = useState(defaultPageNeighbours);
  // 跳转页码
  const [pageValue, setPageValue] = useState(2);
 
  useEffect(() => {
     setMyCurrentPage(currentPage);     
  }, [currentPage])

  let pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  /**
   * 核心逻辑
   * Let's say we have 10 pages and we set pageNeighbours to 2
   * Given that the current page is 6
   * The pagination control will look like the following:
   *
   * (1) < {4 5} [6] {7 8} > (10)
   *
   * (x) => terminal pages: first and last page(always visible)
   * [x] => represents current page
   * {...x} => represents page neighbours
   */
  const fetchPageNumbers = () => {
    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, myCurrentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, myCurrentPage + pageNeighbours);

      let pages = range(startPage, endPage);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  // 切换页码
  const handleChange = (page, e) => {
    e.preventDefault();
    setMyCurrentPage(page);    
    onPageChange(page);
    if(page < totalPages){
       setPageValue(page+1); // 跳转框
    }
    else{
      setPageValue(page)
    }
   
  };

  // 后退5页
  const handleMoveLeft = evt => {
    evt.preventDefault();
    setMyCurrentPage(myCurrentPage - (pageNeighbours * 2 + 1));
    onPageChange(myCurrentPage - (pageNeighbours * 2 + 1));
  };
  // 前进5页
  const handleMoveRight = evt => {
    evt.preventDefault();
    setMyCurrentPage(myCurrentPage + (pageNeighbours * 2 + 1));
    onPageChange(myCurrentPage + (pageNeighbours * 2 + 1));
  };

  // 上一页
  const upPage = e => {
    e.preventDefault();
    if (myCurrentPage <= 1) {
      return;
    }

    setMyCurrentPage(myCurrentPage - 1);
    onPageChange(myCurrentPage);
  };
  // 下一页
  const downPage = e => {
    e.preventDefault();
    if (myCurrentPage >= totalPages) {
      return;
    }
    setMyCurrentPage(myCurrentPage + 1);
    onPageChange(myCurrentPage);
  };

  //  到指定页
  const goToPage = e => {
    const num = parseInt(e);
    // 不是数字,返回
    if (isNaN(num)) {
      return;
    }
    if(num < 1){
      return ;
    }
    if(num > totalPages){
      return;
    }
    // 不在页码范围跳到最后一页
   // const fixnum = Math.max(0, Math.min(num, totalPages));
    setMyCurrentPage(num);
    onPageChange(num);
  };

  const mypages = fetchPageNumbers();

  if (!totalRecords || totalPages === 1) {
    return null;
  }

  return (
    <>
      {/* <div>        
        <button onClick={upPage}>上一页</button>
        <button onClick={downPage}>下一页</button>       
      </div> */}
      <nav>
        <ul className="pagination">
          <li className="page-item">
            <a className="page-link" href="!#" onClick={upPage}>
              上一页
            </a>
          </li>
          {mypages.map((page, index) => {
            // 显示上一页指示(后退5页)
            if (page === LEFT_PAGE) {
              return (
                <li key={index} className="page-item">
                  <a
                    className="page-link"
                    href="!#"
                    aria-label="Previous"
                    onClick={handleMoveLeft}
                  >
                    <span aria-hidden="true">Previous&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </a>
                </li>
              );
            } else if (page === RIGHT_PAGE) {
              //显示下一页指示(前进5页)
              return (
                <li key={index} className="page-item">
                  <a
                    className="page-link"
                    href="!#"
                    aria-label="Next"
                    onClick={handleMoveRight}
                  >
                    <span aria-hidden="true">&raquo;Next</span>
                    <span className="sr-only">Next</span>
                  </a>
                </li>
              );
            }
            // 显示数字页码指示
            return (
              <li
                key={index}
                className={`page-item${
                  myCurrentPage === page ? " active" : ""
                }`}
              >
                <a
                  onClick={e => handleChange(page, e)}
                  href="!#"
                  className="page-link"
                >
                  {page}
                </a>
              </li>
            );
          })}        
          <li className="page-item">
            <a className="page-link" href="!#" onClick={downPage}>
              下一页
            </a>
          </li>
          <li className="tips">
             <span>当前第{myCurrentPage}页/共{totalPages}页,到第</span>
          </li>
          <li className="tips">
               <form onSubmit={e => {
                 e.preventDefault();
                 goToPage(pageValue);
                 }}>
                <input
                type="number"
                min = "1"
                max ={totalPages}
                value={pageValue}
                onChange={e => {                 
                  setPageValue(e.target.value);
                }}
              />
              <span>页</span>
              </form>
          </li>
          <li className="tips">
          <button onClick={ () => goToPage(pageValue)}>跳转</button>
          </li>
        </ul>
      </nav>
    </>
  );
};
// 验证
Pagination.propTypes = {
  pageSize: PropTypes.number,
  totalRecords: PropTypes.number,
  pageNeighbours: PropTypes.number,
  onPageChanged: PropTypes.func,
  neighbours: PropTypes.number
};

export default Pagination;
