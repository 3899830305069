import baseURL from "../Config";

export const fetchProjectCount = async (customer_id) => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/projectname/count/${customer_id}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      }
    );

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchProjectNameAll = async (customer_id) => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/projectname/projectnames/${customer_id}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      }
    );

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchProjectNames = async (customer_id, pagesize, page) => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/projectname/projectnames?customer_id=${customer_id}&pagesize=${pagesize}&page=${page}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      }
    );

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchProjectNameAdd = async (data) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/projectname/projectnames`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token")),
      },
      body: JSON.stringify(data),
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchProjectNameUpdate = async (project_id, data) => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/projectname/projectname/${project_id}`,
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
        body: JSON.stringify(data),
      }
    );

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchProjectNameUpdateCodeDiscount = async (project_id, data) => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/projectname/codediscount/${project_id}`,
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
        body: JSON.stringify({ codediscount: data }),
      }
    );

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchProjectNameDelete = async (project_id) => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/projectname/delete/${project_id}`,
      {
        method: "delete",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      }
    );

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      const json2 = await res.json();
      throw new Error(json2.msg);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchProjectNameExists = async (customer_id, name) => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/projectname/exists/${customer_id}`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
        body: JSON.stringify({ name: name }),
      }
    );

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchFindProjectname = async (query) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/projectname/find`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token")),
      },
      body: JSON.stringify({ query: query }),
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchPriceverByProject_id = async (projectname_id) => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/projectname/getpricever/${projectname_id}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      }
    );
    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchFindProjectNameById = async (id) => {
  try {
    const res = await fetch(`${baseURL}/api/v1/projectname/findbyid/${id}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token")),
      },
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("server can't deal request!");
    }
  } catch (error) {
    throw new Error(error);
  }
};
