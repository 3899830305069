import React, { useState, useEffect } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { css } from "emotion";
import DatePicker, { registerLocale } from "react-datepicker";
import zhCN from "date-fns/locale/zh-CN";
import format from "date-fns/format";
import subMonths from "date-fns/subMonths";
import addDays from "date-fns/addDays";
import { useAlert } from "react-alert";

import { fetchCustomers } from "../apidata/fetchCustomerData";
import { fetchProjectNameAll } from "../apidata/fetchProjectNameData";
import { fetchTrustPersons } from "../apidata/fetchTrustData";

import {
  fetchSampleByCustomer,
  fetchSampleByProject,
  fetchSampleByTrustperson,
  fetchSampleInnerByDate,
  fetchSampleInnerByCustomer,
  fetchSampleInnerByProject,
  fetchSumByCustomer,
  fetchSumGroupByProject,
} from "../apidata/fetchSampleExcel";
import { fetchDownloadurl } from "../apidata/fetchQiniu";
export default function SampleExport() {
  registerLocale("zhCN", zhCN); //日期中文化
  const [msg, setMsg] = useState();
  const alert = useAlert();
  const [startDate, setStartDate] = useState(subMonths(new Date(), 1));
  const [endDate, setEndDate] = useState(new Date());
  // 委托单位
  const [customerValues, setCustomerValues] = useState();
  const [customerError, setCustomerError] = useState();

  // 工程名称
  const [projectNameItems, setProjectNameItems] = useState([]); //db
  const [projectNameValues, setProjectNameValues] = useState();
  const [projectNameError, setProjectNameError] = useState();

  // 委托人
  const [trustPersonValues, setTrustPersonValues] = useState();
  const [trustPersonError, setTrustPersonError] = useState();

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  // pull server data  异步 委托单位数据
  const promiseOptions = async (inputValue) => {
    if (inputValue === null) {
      return Promise.reject("error");
    }
    if (inputValue.length < 1) {
      return Promise.reject("error");
    }

    try {
      const json = await fetchCustomers(inputValue);
      setCustomerValues(null);
      return json;
    } catch (error) {
      setCustomerError(error.message || error.statusText);
      return Promise.reject(error);
    }
  };

  // 按委托单位取到对应的工程名称
  useEffect(() => {
    if (customerValues) {
      (async () => {
        try {
          const json = await fetchProjectNameAll(customerValues.id);
          setProjectNameItems(json);
        } catch (error) {
          setProjectNameError(error.message || error.statusText);
        }
      })();
    }
  }, [customerValues]);

  // 查询委托单位名称
  const handleCustomerChange = (event) => {
    setCustomerValues(event);
    return setCustomerError(null);
  };

  // 查询工程名称
  const handleProjectNameChange = (e) => {
    setProjectNameValues(e);
    return setProjectNameError(null);
  };

  // pull server data  异步 委托人数据
  const promiseOptionsTrustPerson = async (inputValue) => {
    if (inputValue === null) {
      return Promise.reject("error");
    }
    if (inputValue.length < 1) {
      return Promise.reject("error");
    }

    try {
      const json = await fetchTrustPersons(inputValue);
      setTrustPersonValues(null);
      return json;
    } catch (error) {
      setTrustPersonError(error.message || error.statusText);
      return Promise.reject(error);
    }
  };

  // 查询委托人
  const handleTrustPersonChange = (event) => {
    setTrustPersonValues(event);
    return setTrustPersonError(null);
  };
  // 按客户费用清单
  const queryByCustomer = async () => {
    const diffdays =
      (endDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000);
    if (diffdays < 1) {
      alert.error("开始日期需要小于结束日期");
      setMsg();
      setTimeout(() => {
        setMsg(null);
      }, 6000);
      return;
    }
    if (diffdays > 366) {
      alert.error("日期不能大于一年");
      setMsg("日期不能大于一年");
      setTimeout(() => {
        setMsg(null);
      }, 6000);
      return;
    }
    if (!customerValues) {
      alert.error("没有选到委托单位");
      setMsg("没有选到委托单位");
      setTimeout(() => {
        setMsg(null);
      }, 6000);
      return;
    }
    setLoading(true);
    try {
      const res = await fetchSampleByCustomer(
        customerValues.id,
        format(startDate, "yyyy-MM-dd"),
        format(endDate, "yyyy-MM-dd")
      );
      const blob = await res.blob();
      let url = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = url;
      link.download = `按单位费用清单_${
        customerValues.name
      }${startDate.toLocaleDateString()}至${endDate.toLocaleDateString()}`;
      link.click();
      window.URL.revokeObjectURL(url);
      alert.success("导出成功");
      setMsg("导出成功");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    } catch (error) {
      alert.error("导出失败" + error.message);
      setMsg("导出失败" + error.message);
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    }
    setLoading(false);
  };

  // 按工程费用清单
  const queryByProject = async () => {
    const diffdays =
      (endDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000);
    if (diffdays < 1) {
      alert.error("开始日期需要小于结束日期");
      setMsg("开始日期需要小于结束日期");
      setTimeout(() => {
        setMsg(null);
      }, 6000);
      return;
    }
    if (diffdays > 366) {
      alert.error("日期不能大于一年");
      setMsg("日期不能大于一年");
      setTimeout(() => {
        setMsg(null);
      }, 6000);
      return;
    }
    if (!projectNameValues) {
      alert.error("没有选到工程");
      setMsg("没有选到工程");
      setTimeout(() => {
        setMsg(null);
      }, 6000);
      return;
    }
    setLoading(true);
    try {
      const res = await fetchSampleByProject(
        projectNameValues.id,
        format(startDate, "yyyy-MM-dd"),
        format(endDate, "yyyy-MM-dd")
      );
      const blob = await res.blob();
      let url = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = url;
      link.download = `按工程费用清单_${
        projectNameValues.name
      }_${startDate.toLocaleDateString()}至${endDate.toLocaleDateString()}`;
      link.click();
      window.URL.revokeObjectURL(url);
      alert.success("导出成功");
      setMsg("导出成功");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    } catch (error) {
      alert.error("导出失败" + error.message);
      setMsg("导出失败" + error.message);
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    }
    setLoading(false);
  };

  // 按委托人费用清单
  const queryByTustperson = async () => {
    const diffdays =
      (endDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000);
    if (diffdays < 1) {
      alert.error("开始日期需要小于结束日期");
      setMsg("开始日期需要小于结束日期");
      setTimeout(() => {
        setMsg(null);
      }, 6000);
      return;
    }
    if (diffdays > 366) {
      alert.error("日期不能大于一年");
      setMsg("日期不能大于一年");
      setTimeout(() => {
        setMsg(null);
      }, 6000);
      return;
    }
    if (!trustPersonValues) {
      alert.error("没有选到委托人");
      setMsg("没有选到委托人");
      setTimeout(() => {
        setMsg(null);
      }, 6000);
      return;
    }
    setLoading(true);
    try {
      const res = await fetchSampleByTrustperson(
        trustPersonValues.trustperson,
        format(startDate, "yyyy-MM-dd"),
        format(endDate, "yyyy-MM-dd")
      );
      const blob = await res.blob();
      let url = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = url;
      link.download = `按委托人费用清单_${
        trustPersonValues.trustperson
      }${startDate.toLocaleDateString()}至${endDate.toLocaleDateString()}`;
      link.click();
      window.URL.revokeObjectURL(url);
      alert.success("导出成功");
      setMsg("导出成功");
      setTimeout(() => {
        setMsg(null);
      }, 6000);
    } catch (error) {
      alert.error("导出失败" + error.message);
      setMsg("导出失败" + error.message);
      setTimeout(() => {
        setMsg(null);
      }, 6000);
    }
    setLoading(false);
  };

  // 按日期 财务账
  const queryByDateInner = async () => {
    const diffdays =
      (endDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000);
    if (diffdays < 1) {
      alert.error("开始日期需要小于结束日期");
      setMsg("开始日期需要小于结束日期");
      setTimeout(() => {
        setMsg(null);
      }, 6000);
      return;
    }
    if (diffdays > 92) {
      alert.error("日期不在90天内,最多查90天,如超过三个月,分多次查");
      setMsg("日期不在90天内,最多查90天,如超过三个月,分多次查");
      setTimeout(() => {
        setMsg(null);
      }, 6000);
      return;
    }

    setLoading(true);
    try {
      const res = await fetchSampleInnerByDate(
        format(startDate, "yyyy-MM-dd"),
        format(endDate, "yyyy-MM-dd")
      );
      const blob = await res.blob();
      let url = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = url;
      link.download = `财务对帐单${startDate.toLocaleDateString()}至${endDate.toLocaleDateString()}`;
      link.click();
      window.URL.revokeObjectURL(url);
      alert.success("导出成功");
      setMsg("导出成功");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    } catch (error) {
      alert.error("导出失败" + error.message);
      setMsg("导出失败" + error.message);
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    }
    setLoading(false);
  };

  // 按工程汇总金额
  const sumGroupByProject = async () => {
    const diffdays =
      (endDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000);
    if (diffdays < 1) {
      alert.error("开始日期需要小于结束日期");
      setMsg("开始日期需要小于结束日期");
      setTimeout(() => {
        setMsg(null);
      }, 6000);
      return;
    }
    if (diffdays > 367) {
      alert.error("日期不在365天内,最多查365天,如超过1年,分多次查");
      setMsg("日期不在365天内,最多查365天,如超过1年,分多次查");
      setTimeout(() => {
        setMsg(null);
      }, 6000);
      return;
    }

    setLoading(true);
    try {
      const res = await fetchSumGroupByProject(
        format(startDate, "yyyy-MM-dd"),
        format(endDate, "yyyy-MM-dd")
      );
      const blob = await res.blob();
      let url = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = url;
      link.download = `按工程汇总金额对帐单${new Date().toLocaleDateString()}`;
      link.click();
      window.URL.revokeObjectURL(url);
      alert.success("导出成功");
      setMsg("导出成功");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    } catch (error) {
      alert.error("导出失败" + error.message);
      setMsg("导出失败" + error.message);
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    }
    setLoading(false);
  };
  // 按客户 财务账
  const queryByCustomerInner = async () => {
    const diffdays =
      (endDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000);
    if (diffdays < 1) {
      alert.error("开始日期需要小于结束日期");
      setMsg("开始日期需要小于结束日期");
      setTimeout(() => {
        setMsg(null);
      }, 6000);
      return;
    }
    if (diffdays > 183) {
      alert.error("日期不在180天,最多查180天,如超过6个月,分多次查");
      setMsg("日期不在180天,最多查180天,如超过6个月,分多次查");
      setTimeout(() => {
        setMsg(null);
      }, 6000);
      return;
    }
    if (!customerValues) {
      alert.error("没有选到委托单");
      setMsg("没有选到委托单位");
      setTimeout(() => {
        setMsg(null);
      }, 6000);
      return;
    }
    setLoading(true);
    try {
      const res = await fetchSampleInnerByCustomer(
        customerValues.id,
        format(startDate, "yyyy-MM-dd"),
        format(endDate, "yyyy-MM-dd")
      );
      const blob = await res.blob();
      let url = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = url;
      link.download = `财务对帐单${
        customerValues.name
      }${startDate.toLocaleDateString()}至${endDate.toLocaleDateString()}`;
      link.click();
      window.URL.revokeObjectURL(url);
      alert.success("导出成功");
      setMsg("导出成功");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    } catch (error) {
      alert.error("导出失败" + error.message);
      setMsg("导出失败" + error.message);
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    }
    setLoading(false);
  };

  // 按工程 财务账
  const queryByProjectInner = async () => {
    const diffdays =
      (endDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000);
    if (diffdays < 1) {
      alert.error("开始日期需要小于结束日期");
      setMsg("开始日期需要小于结束日期");
      setTimeout(() => {
        setMsg(null);
      }, 6000);
      return;
    }
    if (diffdays > 183) {
      alert.error("日期不在180天,最多查180天,如超过6个月,分多次查");
      setMsg("日期不在180天,最多查180天,如超过6个月,分多次查");
      setTimeout(() => {
        setMsg(null);
      }, 6000);
      return;
    }
    if (!projectNameValues) {
      alert.error("没有选到工程");
      setMsg("没有选到工程");
      setTimeout(() => {
        setMsg(null);
      }, 6000);
      return;
    }
    setLoading(true);
    try {
      const res = await fetchSampleInnerByProject(
        projectNameValues.id,
        format(startDate, "yyyy-MM-dd"),
        format(endDate, "yyyy-MM-dd")
      );
      const blob = await res.blob();
      let url = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = url;
      link.download = `财务对帐单${
        projectNameValues.name
      }${startDate.toLocaleDateString()}至${endDate.toLocaleDateString()}`;
      link.click();
      window.URL.revokeObjectURL(url);
      alert.success("导出成功");
      setMsg("导出成功");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    } catch (error) {
      alert.error("导出失败" + error.message);
      setMsg("导出失败" + error.message);
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    }
    setLoading(false);
  };

  // 基于客户, 按工程,按天汇总
  const sumByCustomer = async () => {
    const diffdays =
      (endDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000);
    if (diffdays < 1) {
      alert.error("开始日期需要小于结束日");
      setMsg("开始日期需要小于结束日期");
      setTimeout(() => {
        setMsg(null);
      }, 6000);
      return;
    }
    if (diffdays > 365 * 2 + 1) {
      alert.error("日期区间不能大于二年");
      setMsg("日期区间不能大于二年");
      setTimeout(() => {
        setMsg(null);
      }, 6000);
      return;
    }
    if (!customerValues) {
      alert.error("没有选到委托单位");
      setMsg("没有选到委托单位");
      setTimeout(() => {
        setMsg(null);
      }, 6000);
      return;
    }
    setLoading(true);
    try {
      const res = await fetchSumByCustomer(
        customerValues.id,
        format(startDate, "yyyy-MM-dd"),
        format(endDate, "yyyy-MM-dd")
      );
      const blob = await res.blob();
      let url = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = url;
      link.download = `按工程按天汇总_${
        customerValues.name
      }${startDate.toLocaleDateString()}至${endDate.toLocaleDateString()}`;
      link.click();
      window.URL.revokeObjectURL(url);
      alert.success("导出成功");
      setMsg("导出成功");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    } catch (error) {
      alert.error("导出失败" + error.message);
      setMsg("导出失败" + error.message);
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    }
    setLoading(false);
  };

  // 下载
  const download = async () => {
    setLoading2(true);
    try {
      const key = "summary.xlsx";
      const res = await fetchDownloadurl({ key });
      const file = await fetch(res.downloadurl, {
        method: "get",
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const blob = await file.blob();
      let url = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = url;
      link.download = `单项产值${new Date()
        .toLocaleString()
        .slice(0, 10)}.xlsx`;
      link.click();
      window.URL.revokeObjectURL(url);
      alert.success(JSON.stringify("下载成功"));
    } catch (error) {
      alert.error(error.message);
    }
    setLoading2(false);
  };

  return (
    <div>
      <div>
        <div style={{ display: "inline-block" }}>
          <label>开始日期:</label>
          <DatePicker
            dateFormat="yyyy-MM-dd"
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
            }}
            minDate={subMonths(new Date(), 60)}
            maxDate={addDays(new Date(), 1)}
            locale="zhCN"
          />
        </div>
        <div style={{ display: "inline-block" }}>
          <label>结束日期:</label>
          <DatePicker
            dateFormat="yyyy-MM-dd"
            selected={endDate}
            onChange={(date) => {
              setEndDate(date);
            }}
            minDate={subMonths(new Date(), 60)}
            maxDate={addDays(new Date(), 1)}
            locale="zhCN"
          />
        </div>
        <span>手输日期更快捷,试试输入:2020-06-06</span>
        <button disabled={loading} onClick={queryByDateInner}>
          按日期导出(用于内部财务)
        </button>
        <button
          style={{ marginLeft: "50px" }}
          disabled={loading}
          onClick={sumGroupByProject}
        >
          按工程汇总费用
        </button>
      </div>
      <div>
        <p style={{ color: "red" }}>
          以下所有导出全部基于上边选择的日期区间,部分导出对日期区间有限制,请按提示选择日期区间
        </p>
      </div>
      <div
        className={css`
          margin-bottom: 5px;
        `}
      >
        <label>委托单位:</label>
        <AsyncSelect
          name="trustname"
          className={css`
            width: 80%;
            height: atuo;
            display: inline-block;
          `}
          cacheOptions
          defaultOptions
          loadOptions={promiseOptions}
          getOptionValue={(option) => option["id"]}
          onChange={handleCustomerChange}
          getOptionLabel={(option) => `${option.pinyin} | ${option.name}`}
          value={customerValues}
          placeholder="请输入委托单位名称"
        />
        {customerError}
        <div
          className={css`
            margin-top: 5px;
            margin-bottom: 10px;
          `}
        >
          <button
            className={css`
              margin-left: 5%;
              background-color: white;
              border: 2px solid #4caf50;
              color: black;
              text-align: center;
              text-decoration: none;
              display: inline-block;
              font-size: 16px;
              cursor: pointer;
              &:hover {
                color: white;
                background: #4caf50;
                border-color: #494949;
              }
            `}
            disabled={loading}
            onClick={queryByCustomer}
          >
            按委托单位导出(用于客户)
          </button>
          <button
            className={css`
              margin-left: 10%;
            `}
            disabled={loading}
            onClick={queryByCustomerInner}
          >
            按委托单位导出(用于内部财务对帐)
          </button>

          <button
            className={css`
              margin-left: 10%;
            `}
            disabled={loading}
            onClick={sumByCustomer}
          >
            按委托单位导出费用汇总(按天汇总,按工程汇总)
          </button>
        </div>
      </div>

      <div
        className={css`
          margin-bottom: 10px;
        `}
      >
        <label>工程名称:</label>
        <Select
          className={css`
            width: 80%;
            height: atuo;
            display: inline-block;
          `}
          name="project"
          placeholder="请选输入委托单位后,再来选择工程名称"
          value={projectNameValues}
          options={projectNameItems}
          onChange={handleProjectNameChange}
          // react-select/issues/3018
          getOptionValue={(option) => option["id"]}
          getOptionLabel={(option) => option.name}
        />
        <div
          className={css`
            margin-top: 5px;
            margin-bottom: 10px;
          `}
        >
          <button
            className={css`
            margin-left: 5%;
            background-color: white; 
            border: 2px solid #4CAF50;
            color: black; 1000000           
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 16px;           
            cursor: pointer;
            &:hover {
              color: white;
              background: #4CAF50;
              border-color: #494949;
            }
          `}
            disabled={loading}
            onClick={queryByProject}
          >
            按工程导出(用于客户)
          </button>
          <button
            className={css`
              margin-left: 5%;
            `}
            disabled={loading}
            onClick={queryByProjectInner}
          >
            按工程导出(用于内部财务对帐)
          </button>
        </div>

        <div style={{ display: "inlneblock", color: "red" }}>
          {projectNameError}
        </div>
      </div>

      <div className="form-group">
        <label>委 托 人 :</label>
        <AsyncSelect
          name="trustperson"
          className={css`
            width: 20%;
            height: atuo;
            display: inline-block;
          `}
          cacheOptions
          defaultOptions
          loadOptions={promiseOptionsTrustPerson}
          getOptionValue={(option) => option.id}
          onChange={handleTrustPersonChange}
          getOptionLabel={(option) => option.trustperson}
          value={trustPersonValues}
          placeholder="请输入委托人"
        />
        {trustPersonError}
        <div
          className={css`
            margin-left: 5%;
            margin-top: 10px;
            margin-bottom: 10px;
          `}
        >
          <button
            className={css`
              margin-left: 5%;
              background-color: white; 
              border: 2px solid #4CAF50;
              color: black; 1000000           
              text-align: center;
              text-decoration: none;
              display: inline-block;
              font-size: 16px;
              margin: 4px 2px;
              cursor: pointer;
              &:hover {
                color: white;
                background: #4CAF50;
                border-color: #494949;
              }
            `}
            disabled={loading}
            onClick={queryByTustperson}
          >
            按委托人导出(用于客户)
          </button>
        </div>
      </div>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <div>
        <button
          disabled={loading2}
          onClick={() => {
            download();
          }}
        >
          单项产值汇总下载
        </button>
      </div>
      <p>
        单项产值从2020-01-01开始,每月30-31日系统自动生成.请于每月5号以后随时来下载上月以前的所有单项产值汇总.
      </p>
      {msg}
    </div>
  );
}
