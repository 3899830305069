import React, { useState } from "react";
import OrderView from "./OrderView";

const Items = ({ items, loading }) => {
  if (loading) {
    return <h2>Loading...</h2>;
  }

  return (
    <ul className="list-group sm-4">
      {items.map((item, index) => (
        <li key={item.id} className="list-group-item">
          第{index + 1}条:{item.wtcode}<span className="badge badge-primary badge-pill">{item.samplecode}</span>
          <VisableItem item={item} /> <br />
          {item.samplename}
          <br />
          {item.delegrateunit_name}

        </li>
      ))}
    </ul>
  );
};

// 展开
export const VisableItem = ({ item }) => {
  const [visable, setVisable] = useState(false);
  return (
    <>
      <button
        onClick={() => {
          setVisable(!visable);
        }}
      >
        详情
      </button>
      {visable && <OrderView order={item} />}
    </>
  );
};

export default Items;
