import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

export default function ExtraCostUpdate({ extraCost, update }) {
  const { register, unregister, handleSubmit, setValue,errors } = useForm();

  useEffect(() => {
    register({ name: "id" });
    setValue("id", extraCost.id);
    setValue("name", extraCost.name);
    setValue("unit", extraCost.unit);
    setValue("price", extraCost.price);
    setValue("qty", extraCost.qty);
    setValue("remark", extraCost.remark);

    
    return () => unregister;
  }, [setValue, register, unregister, extraCost]);

  const submit = data => {
    update(data);
    console.log(data);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(submit)}>
      <label>名称</label>
        <input  ref={register({
            required: "不能为空",
            maxLength: {
              value: 32,
              message: "不能大于32个字"
            }
          })} name="name"  type="text" />
        {errors.name?.message}

       
       
        <label>价格</label>
        <input ref={register({
                required: "不能为空"})} name="price"  type="number" />
        {errors.price?.message}


        <label>单位</label>
        <input  ref={register({
            required: "不能为空",
            maxLength: {
              value: 16,
              message: "不能大于16个字"
            }
          })} name="unit"  type="text" />
        {errors.unit?.message}
       

        <label>说明</label>
        <input ref={register({           
            maxLength: {
              value: 64,
              message: "不能大于64个字"
            }
          })} name="remark"  type="text" />
        {errors.name?.message}
        <button
          type="submit"
          onClick={handleSubmit}
          className="btn btn-primary"
        >
          保存修改
        </button>
      </form>
    </div>
  );
}
