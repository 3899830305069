import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useForm } from "react-hook-form";


import CustArgumentUpdate from "./CustArgumentUpdate";

import {
  fetchArguments,
  fetchArgumentsFromPkg
} from "../apidata/fetchArgument";

import {
  fetchCustArgumentAdd,
  fetchCustArgumentUpdate,
  fetchCustArgumentDelete
} from "../apidata/fetchCustArgument";

export default function CustArgument({ currentArgumentPkg }) {
  const {
    register,
    unregister,
    setValue,
    handleSubmit,
    errors
  } = useForm();

  // register control component
  useEffect(() => {
    register({ name: "arguments" }, { require: "不能为空" });
    return () => unregister;
  }, [register, unregister]);

  const [msg, setMsg] = useState();

  // 指示新增时 刷新
  const [complete, setComplete] = useState(false);

  // 自定义参数(原来已打包的,用于显示)
  const [custArgumentItems, setCustArgumentItems] = useState(null); //

  // 选择参数
  const [argumentsItems, setArgumentsItems] = useState([]); //db
  const [argumentsValues, setArgumentsValues] = useState(null);
  const [argumentsError, setArgumentsError] = useState(null);

  const [isEdite, setIsEdite] = useState(false);
  const [currentArgument, setCurrentArgument] = useState();

  // 按种类查询参数
  useEffect(() => {
    if (!currentArgumentPkg) {
      return;
    }
    (async () => {
      try {
        const json = await fetchArguments(currentArgumentPkg.category_id);
        setArgumentsItems(json);
      } catch (e) {
        setArgumentsError(e.message || e.statusText);
      }
    })();
  }, [currentArgumentPkg]);

  // 按自定义参数包名 查询挂靠的参数
  useEffect(() => {
    if (!currentArgumentPkg) {
      return;
    }
    (async () => {
      try {
        const json = await fetchArgumentsFromPkg(currentArgumentPkg.id);
        setCustArgumentItems(json);
      } catch (e) {
        setMsg(e.message || e.statusText);
      }
    })();
  }, [currentArgumentPkg, complete]);

  // 选择参数
  const handleChangeArguments = selectedOption => {
    setValue("arguments", selectedOption); // form 的值
    setArgumentsValues(selectedOption); // 控件的值
  };

  const updateCustArgument = async data => {
    try {
      const json = await fetchCustArgumentUpdate(
        currentArgumentPkg.id,
        currentArgument.id,
        data
      );
      setMsg(json.msg);
      setCustArgumentItems(
        custArgumentItems.map(c =>
          c.id === currentArgument.id
            ? { ...currentArgument, sorting: parseInt(data.sorting) }
            : c
        )
      );
      setIsEdite(false);
      setTimeout(() => {
        setMsg(null);
      }, 2000);
    } catch (error) {
      setMsg(error.message);
    }
  };

  const deleteCustomArgument = async data => {
    try {
      const json = await fetchCustArgumentDelete(
        currentArgumentPkg.id,
        data.id
      );
      setMsg(json.msg);
      setCustArgumentItems(custArgumentItems.filter(c => c.id !== data.id));
      setIsEdite(false);
      setTimeout(() => {
        setMsg(null);
      }, 2000);
    } catch (error) {
      setMsg(error.message);
    }
  };
  // 挂参数
  const onSubmit = async data => {
    if (!Array.isArray(argumentsValues) || !argumentsValues) {
       setMsg("不能为空");
       setTimeout(() => {
         setMsg(null);
       }, 2000);
       return ;
    }
    try {
      const json = await fetchCustArgumentAdd(currentArgumentPkg.id, data);
      setMsg(json.msg);
      setComplete(!complete);
      setArgumentsValues(null);
      setTimeout(() => {
        setMsg(null);
      }, 2000);
    } catch (error) {
      setMsg(error.message);
    }
  };

  return (
    <div>
      {msg}
      {isEdite && (
        <>
          <CustArgumentUpdate
            currentArgument={currentArgument}
            update={updateCustArgument}
          />
          <button
            onClick={() => {
              setIsEdite(false);
            }}
          >
            取消修改
          </button>
        </>
      )}
      <ol>
        {custArgumentItems &&
          custArgumentItems.map(item => (
            <li key={item.id}>
              {item.id}--{item.stdcode}--{item.name}---{item.remak}
              --排序({item.sorting})
              <button
                onClick={() => {
                  setCurrentArgument(item);
                  setIsEdite(true);
                }}
                className="btn btn-primary"
              >
                修改序号
              </button>
              <span> </span>
              <button
                onClick={() => {
                  deleteCustomArgument(item);
                }}
                className="btn btn-danger"
              >
                删除
              </button>
            </li>
          ))}
      </ol>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group row">
          <label className="label">参数选择:</label>
          <Select
            className="select-big"
            name="arguments"
            isMulti
            closeMenuOnSelect={false}
            isClearable
            placeholder="参数(可多选)"
            value={argumentsValues}
            options={argumentsItems}
            onChange={handleChangeArguments}
            // react-select/issues/3018
            getOptionValue={option => option["id"]}
            getOptionLabel={option => `${option.stdcode} | ${option.name}`}
          />
        </div>
        <div className="error">
          {argumentsError}
          {errors.arguments && errors.arguments.message}
        </div>
        <button
          type="submit"
          onClick={handleSubmit}
          className="btn btn-primary"
        >
          增加
        </button>
      </form>
    </div>
  );
}
