import React, { useState, useEffect,useRef } from "react";
import { useForm } from "react-hook-form";
import "@reach/dialog/styles.css";
import {
  AlertDialog,
  AlertDialogLabel,
  AlertDialogDescription
} from "@reach/alert-dialog";

import { fetchPriceVerList,fetchAddPriceVer,fetchUpdatePriceVer,fetchDeletePriceVer, fetcheExistsPriceVer } from "../apidata/fetchPriceVer";

export default function PriceVer() {
  const { register, unregister, handleSubmit, setValue, errors,reset } = useForm();

  useEffect(() => {
    return () => unregister;
  }, [register, unregister]);
 
  const [msg,setMsg] = useState();
  const [priceVerItems, setPriceVerItems] = useState();
  const [priceVerError, setPriceVerError] = useState();

  const [isEdite,setIsEdite] = useState(false);
  const [curentPriceVer,setCurrentPriceVer]  = useState();

  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const cancelRef = useRef();
  const openDelete = () => setShowDialogDelete(true);
  const closeDelete = () => setShowDialogDelete(false);


  useEffect(() => {
    (async () => {
      try {
        const json = await fetchPriceVerList();
        setPriceVerItems(json);
      } catch (e) {
        setPriceVerError(e.message || e.statusText);
      }
    })();
  }, []);


  const validateStdcodeDb = async value => {
    if(isEdite && value ===curentPriceVer.name){
        return false || "不需要修改";
    }
    try {
      const has = await fetcheExistsPriceVer(value);
      if (has) {
        return false || "已存在";
      } else {
        return true;
      }
    } catch (error) {
      setMsg("系统错误");
    }
  };

 const submit = async data => {
     if (data.name===""){
         return;
     }
     if (!isEdite){
        try {
        const json = await fetchAddPriceVer(data);
        setMsg(json.msg);
        setPriceVerItems([json.data,...priceVerItems]);
        reset();
        setTimeout(() => {
            setMsg(null);
        }, 2000);
            
        } catch (error) {
            setMsg(error.message);            
        }
     }
     else{
        try {
            const json = await fetchUpdatePriceVer(curentPriceVer.id,data);
            setMsg(json.msg);
            setPriceVerItems( priceVerItems.map(c=>c.id===curentPriceVer.id ? json.data : c));
            setIsEdite(false);
            reset();
            setTimeout(() => {
                setMsg(null);
            }, 2000);                
            } catch (error) {
                setMsg(error.message);            
            }

     }

 };
 const deletePriceVer =async ()=>{

    try {
        const json = await fetchDeletePriceVer(curentPriceVer.id);
        setMsg(json.msg);
        setPriceVerItems( priceVerItems.filter(c=>c.id !== curentPriceVer.id));
        closeDelete();
        setTimeout(() => {
            setMsg(null);
        }, 2000);                
        } catch (error) {
            setMsg(error.message);            
        }

 }

  return (
    <div>
         <h2 style={{textAlign:"center"}}>价格版本</h2>
        {msg}{priceVerError}
      <form onSubmit={handleSubmit(submit)}>
        <div className="form-group row">
          <label className="col-sm-1 col-form-label">版本名称:</label>
          <div className="col-sm-5">
            <input
              className="form-control"
              ref={register({
                minLength: {
                  value: 3,
                  message: "不能小于3个字"
                },
                maxLength: {
                  value: 20,
                  message: "不能大于20个字"
                },
                validate: value => validateStdcodeDb(value) //异步验证
              })}
              name="name"
              type="text"
            />
            {errors.name?.message}
          </div>
          <div className="col-sm-1">
            <button className="btn btn-primary" type="submit" onClick={handleSubmit(submit)} >{isEdite? "修改" : "新增"}</button>
          </div>
          <div className="col-sm-1">
          {isEdite &&   <button className="btn btn-dark" onClick={()=>{
                reset();
                setIsEdite(false);
            }}  >取消修改</button>}
         
         </div>
        </div>
      </form>

      <table className="table table-sm table-hover">
        <thead className="thead-dark">
          <tr>
            <th>识别码</th>
            <th>版本名称</th>                  
            <th>操作</th>            
            <th>操作</th>
          </tr>
        </thead>

        <tbody>
        {priceVerItems &&
          priceVerItems.map(item => (
            <tr key={item.id}>
                <td>
                    {item.code}
                </td>
                <td>
                    {item.name}
                </td>
                <td>
                    <button onClick={()=>{                   
                  setCurrentPriceVer(item);
                  setIsEdite(true);
                  setValue("name",item.name);
                     }}>修改</button>
                </td>
                <td>
                <button 
                    className="btn btn-danger"
                    onClick={()=>{                  
                    setCurrentPriceVer(item);
                    openDelete();
                     }}>删除</button>

                </td>
              
            
            </tr>
          ))}

        </tbody>
    </table>

    {showDialogDelete && (
        <AlertDialog onDismiss={closeDelete} leastDestructiveRef={cancelRef}>
          <AlertDialogLabel style={{ textAlign: "center" }}>
            请再次确认是否删除?
          </AlertDialogLabel>
          <AlertDialogDescription>
             {curentPriceVer.NAME}
          </AlertDialogDescription>
          <div className="alert-buttons">
            <button onClick={deletePriceVer}>是, 删除</button>
            <button ref={cancelRef} onClick={closeDelete}>
              取消, 我不愿意
            </button>
          </div>
        </AlertDialog>
      )}
     
    </div>
  );
}
